import React, { useRef, useEffect, useState } from 'react';
import { fabric } from 'fabric';
import '../polyfill'; // Adjust the path as necessary
import Sidebar from '../SideBar/sidebar';
import { smoothPath } from "svg-smoother";

import { setActiveCanvas } from '../../Redux/slice/canvasSlice';
import {
  handleDownloadSVG,
  handleEraserClick,
  handleDrawingMode,
  handlePathCreated,
  handleFreeDrawingClick,
  handleUploadSVG,
  handleSetColor,
  handleMouseDoubleClick,
  addControlPointsToSelectedObjects,
  handleSelectionCreated,
  togglePathSelect, 
  handleDisableFreeDrawingClick,
  fabricPathToSVG,
  getPoints,
} from '../Tools/paintTools';
// import { useOriginalVisibility } from '../SideBar/SBComponents/combineMenu'
import { useSelector } from 'react-redux';
import { calculatePathLength, calculatePatternHeight, calculatePatternWidth, chalkAndSponge, drawNormalsForPath, recalculateBoundingBox } from '../../Utils/polygonControls';
import { Popover, Button, InputLabel, Select, MenuItem, TextField, Typography, FormControl, FormControlLabel, Radio, RadioGroup, Slider, Box, Menu, Tooltip, Drawer } from '@mui/material';
import Footer from '../Footer/footer';
import Guides from "@scena/react-guides";
import { Lock, ShapeLine } from '@mui/icons-material';
import paper from 'paper';
import { install } from 'chart-js-fabric';

import { VennDiagramController, VennDiagramChart } from 'chartjs-chart-venn';
import { Chart, registerables } from 'chart.js';
import * as d3 from "d3";
import { VennDiagram } from 'venn.js';
import ColorPicker from 'react-best-gradient-color-picker'
import { DrawerHeader, StyledDrawer, StyledShapeEditor, StyledSubMenuContainer, drawerWidth } from '../../Utils/styledComps';
import { createLinearGradient, createRadialGradient, gradientCheck } from '../Tools/canvasTools';
import { handleSpraying } from '../Tools/colorTools';
import { Bezier } from 'bezier-js';
import { ShapeEditor } from '../ShapeEditor/ShapeEditor';


Chart.register(...registerables, VennDiagramController, VennDiagramChart);

install(fabric);

const Canvas = () => {
  const [originalVisibility, setOriginalVisibiltiy] = useState(false);
  const handleChangeView = () => {
    setOriginalVisibiltiy(!originalVisibility);
  };
  const bg = useSelector(
    (state) => state.canvasProps.backgroundColor || "rgba(255,255,255,255)"
  );
  // let isUserAction = true;
  const canvasRef = useRef(null);
  const horizonalGuidesRef = useRef();
  const verticalGuidesRef = useRef();

  const sidebarRef = useRef(null);
  const appBarRef1 = useRef(null);
  const undoStack = useRef([]);
  const redoStack = useRef([]);
  const [isUserAction, setIsUserAction] = useState(true);
  const isUserActionRef = useRef(true); // Use ref for immediate updates

  const appBarRef2 = useRef(null);
  const footerRef = useRef(null);
  const [canvases, setCanvases] = useState([]);
  const [pathSelect, setPathSelect] = useState(false);

  const conversionRates = { px: 1, cm: 37.7952756, in: 96, feet: 1152, mm: 3.77953 };
  const [activeCanvasIndex, setActiveCanvasIndex] = useState(0);
  const [canvas, setCanvas] = useState(null);
  const [showRanges, setShowRanges] = useState(false);
  const [isErasing, setIsErasing] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [selectedColor, setSelectedColor] = useState('transparent');
  const [selectedStrokeColor, setSelectedStrokeColor] = useState('black');
  const [selectedColorForRotationCopy, setSelectedColorForRotationCopy] = useState("transparent");
  const [selectedStrokeForRotationCopy, setSelectedStrokeForRotationCopy] = useState("black")
  const [rotationSettings, setRotationSettings] = useState({
    numCopies: 6,
    startingAngle: 0,
    rotationAngle: 60,
    gap: 0,
    distributeEvenly: 1,
    mirrorCopies: 0,
    splitElements: 0,
    linkStyles: 0,
    method: 'Normal',
    originX: 0,
    originY: 0,
  });
  const [guidesOffsets,setGuidesOffsets]=useState({
    offsetX:"",
    offsetY:""
  })
  // Handler to update specific fields in the rotationSettings object
  const updateRotationSettings = (key, value) => {
    console.log("updateRotationSettings", key, value);
    if(key.includes('origin')){
      let numValue = parseFloat(value)
      setRotationSettings((prev) => ({ ...prev, [key]: numValue }));
      console.log("updateRotationSettings: if origin: ", key, numValue);
      return
    }
    setRotationSettings((prev) => ({ ...prev, [key]: value }));
  };


  useEffect(() => {
    if (canvas) {
      console.log('Canvas found, updating objects...');

      gradientCheck(canvas, selectedColor, selectedStrokeColor, setSelectedColor, setSelectedStrokeColor);
    } else {
      console.error('Canvas not found!');
    }
  }, [selectedColor, selectedStrokeColor]);




  useEffect(() => {
    if (rotationSettings.linkStyles) {
      applyColorWithLinkStyle()
    } else if (rotationSettings.splitElements) {
      applyColorToIndividualCopy()
    }

  }, [selectedColorForRotationCopy,selectedStrokeForRotationCopy])

  const [opacity, setOpacity] = useState(0);
  const [opacityObj, setOpacityObj] = useState(1);
  const [isVisibleCenter, setIsVisibleCenter] = useState(false);
  const [spraySelect, setSpraySelect] = useState(false);
  let i = 0;
  const toggleVisibility = () => {
    if (canvas) {
      canvas.forEachObject((obj) => {
        if (obj.controlPoint && obj.controlText) {
          obj.controlPoint.set({ visible: !isVisibleCenter });
          obj.controlText.set({ visible: !isVisibleCenter });
        }
      })
      canvas.renderAll();
      setIsVisibleCenter(!isVisibleCenter);
    }
  };
  const [activeObj, setActiveObj] = useState(null);
  const [sprayObj, setSprayObj] = useState(null);
  const sprayObjRef = useRef(sprayObj);
  const spraySelectRef = useRef(spraySelect);

  useEffect(() => {
    if (canvas) {
      const initialState = canvas.toJSON();
      undoStack.current = [initialState]; // Store the initial state
      // redoStack.current = [];
    }
  }, [canvas]);

  const saveState = () => {
    console.log("Outside if condition", isUserActionRef.current);
    if (!isUserActionRef.current) return; // Use ref to check the correct state

    const currentState = canvas.toJSON();

    // Exclude control objects
    console.log("Before filtering the control objects", currentState);
    currentState.objects = currentState.objects.filter((obj) => {
      console.log("Check Object of currentState", obj);
      return obj.type !== "circle" && obj.type !== "text";
    });

    if (currentState.objects.length === 0) {
      console.log("saveState - Skipping empty state");
      return;
    }

    const lastState = undoStack.current[undoStack.current.length - 1];

    console.log("Check current state", currentState);
    // Only push if the state is different
    if (
      !lastState ||
      JSON.stringify(currentState) !== JSON.stringify(lastState)
    ) {
      undoStack.current.push(currentState);

      //  redoStack.current = []; // Clear redo stack on new action
      console.log(
        "saveState - Updated stacks:",
        "undo:",
        undoStack.current,
        "redo:",
        redoStack.current
      );
    }
  };

  const undo = () => {
    if (undoStack.current.length <= 1) {
      console.log("UNDO - No previous state available.");
      return;
    }

    isUserActionRef.current = false; // Disable saveState temporarily
    setIsUserAction(false);

    const currentState = undoStack.current.pop(); // Remove current state

    if (currentState && currentState.objects.length > 0) {
      redoStack.current.push(currentState);
    }

    const prevState = undoStack.current[undoStack.current.length - 1]; // Get the last valid state

    if (prevState) {
      canvas.loadFromJSON(prevState, () => {
        canvas.renderAll();
        // console.log("UNDO - Applied:", prevState);
        console.log("UNDO - After:", undoStack.current, redoStack.current);

        setTimeout(() => {
          isUserActionRef.current = true; // Re-enable saveState
          setIsUserAction(true);
        }, 100);
      });
    } else {
      console.log("UNDO - No states to undo.");
    }
  };

  const redo = () => {
    if (redoStack.current.length === 0) {
      console.log("REDO - No states to redo.");
      return;
    }

    isUserActionRef.current = false; // Disable saveState temporarily
    setIsUserAction(false);

    const nextState = redoStack.current.pop(); // Retrieve next state
    if (nextState && nextState.objects.length > 0) {
      undoStack.current.push(nextState); // Store in undo stack
    }

    if (nextState) {
      canvas.loadFromJSON(nextState, () => {
        canvas.renderAll();
        // console.log("REDO - Applied:", nextState);
        console.log(
          "REDO - After:",
          "undoStack:",
          undoStack.current,
          "redoStack:",
          redoStack.current
        );

        setTimeout(() => {
          isUserActionRef.current = true; // Re-enable saveState
          setIsUserAction(true);
        }, 100);
      });
    } else {
      console.log("REDO - No states to redo.");
    }
  };

  const fabricToPaperPath = (fabricObj) => {
    if (fabricObj.type !== 'path') {
      return null;
    }

    const pathData = fabricObj.path.map(segment => segment.join(' ')).join(' ');

    const path = new paper.Path(pathData);

    const scaleX = fabricObj.scaleX || 1;
    const scaleY = fabricObj.scaleY || 1;
    const skewX = fabricObj.skewX || 0;
    const skewY = fabricObj.skewY || 0;
    const angle = fabricObj.angle || 0;
    const translateX = fabricObj.left || 0;
    const translateY = fabricObj.top || 0;
    console.log('fusePathsForLPE: fabricPath properties:', angle, scaleX, scaleY, skewX, skewY, translateX, translateY, fabricObj.width, fabricObj.height);

    const matrix = new paper.Matrix();

    // matrix.translate(translateX, translateY);
    matrix.rotate(angle);
    matrix.skew(skewX, skewY);
    matrix.scale(scaleX, scaleY);

    path.transform(matrix);

    let centerX = fabricObj.controlPoint.left;
    let centerY = fabricObj.controlPoint.top;
    if (fabricObj.rotatedCopies) {
      centerX = fabricObj.left;
      centerY = fabricObj.top;
    }
    i += 1;
    console.log(`fusePathsForLPE: centerX,centerY of ${i}: `, centerX, centerY, angle, translateX, translateY)
    path.position = new paper.Point(centerX, centerY);

    return path;
  };




  const paperToFabricPath = (paperPath, fabricObj) => {
    // Export SVG path data
    const pathData = paperPath.exportSVG({ asString: true });
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(pathData, 'image/svg+xml');
    const pathElement = svgDoc.querySelector('path');
    const d = pathElement.getAttribute('d');

    // Extract the position from the Paper.js path
    const position = paperPath.position;

    console.log('fusePathsForLPE: position:', position);

    // Create a new Fabric.js path
    const canvasPoint = canvas.calcViewportBoundaries();
    const rightPointx = canvasPoint.br.x;
    const fabricPath = new fabric.Path(d, {
      fill: fabricObj.fill,
      stroke: fabricObj.stroke,
      originX: 'center',
      originY: 'center',
      left: originalVisibility ?
        (2 * position.x >= rightPointx ? (1 / 2) * position.x : 2 * position.x + fabricObj.width + 2)
        : position.x,
      top: position.y,
      strokeWidth: fabricObj.strokeWidth
    });

    console.log('Fabric.js Path (after converting from Paper.js):', fabricPath.toSVG());

    return fabricPath;
  };


  const fusePathsForLPE = (objArr) => {
    let activeObj = objArr;
    if (!activeObj || activeObj.length < 2) {
      return;
    }
    console.log('fusePathsForLPE: ', activeObj)
    const canvasElement = document.createElement('canvas');
    canvasElement.width = size.width;
    canvasElement.height = size.height;

    // Set up Paper.js with the newly created canvas
    paper.setup(canvasElement);
    // Convert Fabric.js objects to Paper.js paths with correct transformations
    const paperPaths = activeObj.map(fabricToPaperPath);

    // Log the Paper.js paths data
    paperPaths.forEach((path, index) => {
      console.log(`fusePathsForLPE: Paper.js Path ${index} (before union):`, path.exportSVG({ asString: true }));
    });

    let combinedPath = paperPaths[0];
    for (let i = 1; i < paperPaths.length; i++) {
      combinedPath = combinedPath.unite(paperPaths[i]);
    }

    console.log('fusePathsForLPE: Combined Paper.js Path (after union):', combinedPath.exportSVG({ asString: true }));

    const combinedFabricPath = paperToFabricPath(combinedPath, objArr[0]);
    combinedFabricPath.set({
      class:`rotatedCopies-${i}`
    })
    console.log('fusePathsForLPE: Combined Fabric.js Path (after converting from Paper.js):', combinedFabricPath.toSVG());

    canvas.add(combinedFabricPath);

    if (!originalVisibility)
      activeObj.forEach((obj,index) => index!==0&&canvas.remove(obj));
      activeObj[0].set({
        stroke:'transparent',
        fill:'transparent',
        
      })
      activeObj[0].on('modified', () => {
        handlePathRotateCopies()
      })
      togglePathSelect(canvas, activeObj[0], setActiveNodes, activeNodes, handlesConnected);

    canvas.discardActiveObject();
    canvas.setActiveObject(activeObj[0]);
    canvas.requestRenderAll();
  };

  const combineSelectedPaths = () => {
    if (!activeObj || activeObj.length < 2) {
      return;
    }
  
    const canvasElement = document.createElement('canvas');
    canvasElement.width = size.width;
    canvasElement.height = size.height;
  
    // Set up Paper.js with the newly created canvas
    paper.setup(canvasElement);
  
    // Convert Fabric.js objects to Paper.js paths with correct transformations
    const paperPaths = activeObj.map(fabricToPaperPath).map((path) => {
      if (!path.closed) {
        path.closePath();
      }
      return path;
    });
  
    // Log the Paper.js paths data
    paperPaths.forEach((path, index) => {
      console.log(`Paper.js Path ${index} (before union):`, path.exportSVG({ asString: true }));
    });
  
    let combinedPath = paperPaths[0];
    for (let i = 1; i < paperPaths.length; i++) {
      console.log(`Union Step ${i}:`, combinedPath.exportSVG({ asString: true }));
      combinedPath = combinedPath.unite(paperPaths[i]);
    }
  
    console.log('Combined Paper.js Path (after union):', combinedPath.exportSVG({ asString: true }));
  
    const combinedFabricPath = paperToFabricPath(combinedPath, activeObj[0]);
  
    console.log('Combined Fabric.js Path (after converting from Paper.js):', combinedFabricPath.toSVG());
  
    canvas.add(combinedFabricPath);
  
    if (!originalVisibility)
      activeObj.forEach(obj => canvas.remove(obj));
  
    canvas.discardActiveObject();
    canvas.setActiveObject(combinedFabricPath);
    canvas.requestRenderAll();
  };

  const combineSelectedObjectsFragmentation = () => {
    if (activeObj.length < 2) {
      alert("Select two or more objects to fragment.");
      return;
    }

    paper.setup(document.createElement("canvas"));
    // Ensure paths are closed and have consistent direction
    const closeAndFixDirection = (path) => {
      if (!path.closed) {
        path.closePath();
      }
      if (!path.clockwise) {
        path.reverse(); // Reverse direction to make consistent
      }
      return path;
    };
  
    let paperPaths = activeObj.map(fabricToPaperPath).map(closeAndFixDirection);
  
    // Log paths before intersection for debugging
    paperPaths.forEach((path, index) => {
      console.log(`Path ${index} before intersection:`, path.exportSVG({ asString: true }));
    });
  
    // Get the intersection between the first shape and the second shape
    let intersectionPath = paperPaths[0].intersect(paperPaths[1]);
  
    if (intersectionPath.length === 0) {
      console.error("No valid intersection result. The paths might not overlap.");
      return;
    }
    // Subtract the intersection from the second shape to "cut out" the non-intersecting part
    let remainingPath = paperPaths[1].subtract(intersectionPath);
    // Convert the modified paths back to Fabric.js
    const modifiedPaths = [
      paperToFabricPath(intersectionPath, activeObj[0]), // Keep intersection
      paperToFabricPath(remainingPath, activeObj[1]),   // Second shape after cutting the non-intersecting part
    ];
    // Remove original objects from the canvas
    activeObj.forEach((obj) => canvas.remove(obj));
    // Add the modified paths back to the canvas
    modifiedPaths.forEach((path) => canvas.add(path));

    canvas.discardActiveObject();
    canvas.requestRenderAll();
  };

  const combineSelectedObjectsIntersection = () => {
    if (!activeObj || activeObj.length < 2) {
      return;
    }

    paper.setup(document.createElement('canvas'));
    // Ensure paths are closed and have consistent direction
    const closeAndFixDirection = (path) => {
      if (!path.closed) {
        path.closePath();
      }
      if (!path.clockwise) {
        path.reverse(); // Reverse direction to make consistent
      }
      return path;
    };
  
    let paperPaths = activeObj.map(fabricToPaperPath).map(closeAndFixDirection);
  
    // Log paths before intersection for debugging
    paperPaths.forEach((path, index) => {
      console.log(`Path ${index} before intersection:`, path.exportSVG({ asString: true }));
    });
  
    // Perform intersection between the paths
    let intersectedPath = paperPaths[0];
    for (let i = 1; i < paperPaths.length; i++) {
      intersectedPath = intersectedPath.intersect(paperPaths[i]);
    }
    // Check if the intersection is valid
    if (intersectedPath.length === 0) {
      console.error("No valid intersection result. The paths might not overlap.");
      return;
    }   
    const intersectedFabricPath = paperToFabricPath(intersectedPath, activeObj[0]);
    canvas.add(intersectedFabricPath);
    if (!originalVisibility) {
      activeObj.forEach(obj => canvas.remove(obj));
    }
    canvas.discardActiveObject();
    canvas.setActiveObject(intersectedFabricPath);
    canvas.requestRenderAll();
  };
  const combineSelectedObjectsDifference = () => {
    if (!activeObj || activeObj.length < 2) {
      return;
    }
    paper.setup(document.createElement('canvas'));
  
    // Ensure paths are closed and have consistent direction
    const closeAndFixDirection = (path) => {
      if (!path.closed) {
        path.closePath();
      }
      if (!path.clockwise) {
        path.reverse(); // Reverse direction to make consistent
      }
      return path;
    };
  
    // Convert selected Fabric.js objects to Paper.js paths and ensure they are valid
    let paperPaths = activeObj.map(fabricToPaperPath).map(closeAndFixDirection); 
    // Perform difference operation on paths using Paper.js
    let differencePath = paperPaths[0];
    for (let i = 1; i < paperPaths.length; i++) {
      differencePath = differencePath.subtract(paperPaths[i]);
    }
    // If the difference result is empty, log an error
    if (differencePath.length === 0) {
      console.error("No valid difference result. The paths may not be subtracting as expected.");
      return;
    }
  
    // Convert the resulting Paper.js path back to a Fabric.js path
    const differenceFabricPath = paperToFabricPath(differencePath, activeObj[0]);
  
    // Add the resulting path to the Fabric.js canvas
    canvas.add(differenceFabricPath);
    // Optionally remove the original paths if not visible
    if (!originalVisibility) {
      activeObj.forEach(obj => canvas.remove(obj));
    }
  
    // Set the new path as the active object
    canvas.discardActiveObject();
    canvas.setActiveObject(differenceFabricPath);
    canvas.requestRenderAll();
  };
  

  const combineSelectedPathsExclusion = () => {
    if (!activeObj || activeObj.length < 2) {
      return;
    }
    paper.setup(document.createElement('canvas'));

    // Convert selected Fabric.js objects to Paper.js paths
    let paperPaths = activeObj.map(fabricToPaperPath);
    paperPaths.forEach(path => {
      if (!path.closed) path.closePath();
    });
    paperPaths.forEach((path) => path.setClockwise(true));

    // Perform exclusion (difference) on the paths using Paper.js
    let excludedPath = paperPaths[0];
    for (let i = 1; i < paperPaths.length; i++) {
      excludedPath = excludedPath.exclude(paperPaths[i]);
    }
    // Check if the exclusion operation returned a valid result
    if (!excludedPath) {
      console.error("Exclusion operation failed.");
      return;
    }

    // Convert the resulting Paper.js path back to a Fabric.js path
    const excludedFabricPath = paperToFabricPath(excludedPath, activeObj[0]);

    // Add the excluded path to the Fabric.js canvas
    canvas.add(excludedFabricPath);

    // Remove the original paths from the Fabric.js canvas
    if (!originalVisibility){
      activeObj.forEach(obj => canvas.remove(obj));
    }
    // Update the active object and render the canvas
    canvas.discardActiveObject();
    canvas.setActiveObject(excludedFabricPath);
    canvas.requestRenderAll();
  };
  const changeOpacity = (val) => {
    if (canvas) {
      console.log("Changing opacity to: ", val);
      if (canvas.backgroundImage) {
        console.log("opacity if bgImage: ", canvas.backgroundImage, opacity, val);
        canvas.backgroundImage.set('opacity', val);
      } else {
        const opaqueRect = new fabric.Rect({
          left: 0,
          top: 0,
          width: canvas.width / zoom,
          height: canvas.height / zoom,
          fill: bg,
          opacity: val,
        });
        console.log("opacity: if no bgImage", opaqueRect);

        canvas.setBackgroundImage(opaqueRect.toDataURL(), canvas.renderAll.bind(canvas));
      }
      setOpacity(val);
      canvas.renderAll();
    }
  }

  const changeOpacityOfObj = (val) => {
    var obj = canvas.getActiveObject();
    if (obj) {
      obj.set({
        opacity: val
      });
      setOpacityObj(val);
      canvas.renderAll();
    }
  }
  // useEffect(() => {
  //   if (canvas) {
  //     console.log("opacity: ", canvas, opacity);
  //     if (canvas.backgroundImage) {
  //       console.log("opacity if bgImage: ", canvas.backgroundImage, opacity);
  //       canvas.backgroundImage.opacity = opacity;
  //       canvas.renderAll();
  //     } else {
  //       const opaqueRect = new fabric.Rect({
  //         left: 0,
  //         top: 0,
  //         width: canvas.width,
  //         height: canvas.height,
  //         fill: bg,
  //         opacity: opacity
  //       });
  //       console.log("opacity: if no bgImage", opaqueRect);

  //       canvas.setBackgroundImage(opaqueRect.toDataURL(), canvas.renderAll.bind(canvas));
  //       canvas.renderAll();
  //     }
  //   }
  // }, [opacity]);

  const [size, setSize] = useState({
    width: 595,
    height: 842
  });
  const [zoom, setZoom] = useState(1); // 70 % zoom setting is change based on AG-20 jira ticket 
  const [path, setPath] = useState(null);
  const [canvasImage, setCanvasImage] = useState([]);
  const captureInitialCanvasImages = () => {
    const initialImages = canvases.map(canvas => canvas.toDataURL({ format: 'png', quality: 1.0 }));
    setCanvasImage(initialImages);
  };

  useEffect(() => {
    if (canvases.length > 0) {
      captureInitialCanvasImages();
      console.log("first load canvases: ", canvases);
    }
  }, [canvases]);

  const [containerDim, setContainerDim] = useState({});

  const adjustCanvasContainerDimensions = () => {
    const appBarHeight1 = appBarRef1.current ? appBarRef1.current.clientHeight : 0;
    const appBarHeight2 = appBarRef2.current ? appBarRef2.current.clientHeight : 0;
    const combinedAppBarHeight = appBarHeight1 + appBarHeight2;
    const footerHeight = footerRef.current ? footerRef.current.clientHeight : 0;
    const sidebarWidth = sidebarRef.current ? sidebarRef.current.clientWidth : 0;
    const container = document.getElementById('canvas-container');
    console.log("adjustCanvasContainerDimensions:", combinedAppBarHeight, footerHeight, sidebarWidth)
    if (container) {
      const containerHeight = window.innerHeight - combinedAppBarHeight - footerHeight;
      const containerWidth = window.innerWidth - sidebarWidth;
      container.style.height = `${containerHeight}px`;
      container.style.width = `${containerWidth}px`;
      // container.style.marginLeft = `${sidebarWidth}px`;
      container.style.marginTop = `${combinedAppBarHeight}px`;
      container.style.paddingTop = '40px';
      setContainerDim({
        height: containerHeight,
        width: containerWidth,
        paddingTop: 40,
        marginTop: combinedAppBarHeight,
      });


      console.log("adjustCanvasContainerDimensions: containerHeight,containerHeight,container.style:", containerHeight, containerHeight, container.style)

    }
  };



  useEffect(() => {
    adjustCanvasContainerDimensions();
    // if(canvas && canvases.length === 1){
    //   saveState();
    // }
  }, [canvas]);


  const handleZoomChange = (event, newValue) => {
    setZoom(newValue / 100);
    if (canvas) {
      canvas.setZoom(newValue / 100);
      canvas.setViewportTransform(canvas.viewportTransform);
      updateCanvasDimensions(size.width, size.height, newValue / 100);
      updateGuides(newValue / 100)
    }
  };

  // Function to handle mouse wheel zoom
  const handleWheel = (event) => {
    if (canvasRef.current && canvasRef.current.contains(event.target)) {
      event.preventDefault();

      const delta = event.deltaY > 0 ? -0.05 : 0.05; // Adjust zoom step
      const newZoom = zoom + delta;

      const clampedZoom = Math.max(0.3, Math.min(newZoom, 2));

      if (clampedZoom !== zoom) {
        setZoom(clampedZoom);
        if (canvas) {
          canvas.setZoom(clampedZoom);
          canvas.setViewportTransform(canvas.viewportTransform);
          updateCanvasDimensions(size.width, size.height, clampedZoom);
          updateGuides(clampedZoom);
        }
      }
    }
  };
   
  const handleZoomIn = () => {
    const newZoom = Math.min(zoom * 100 + 10, 300);
    console.log("newZoom in", newZoom);
    handleZoomChange(null, newZoom);
  };

  const handleZoomOut = () => {
    const newZoom = Math.max(zoom * 100 - 10, 10);
    console.log("newZoom out", newZoom);
    console.log("zoom outttttt.....");
    handleZoomChange(null, newZoom);
    // handleZoomChange(newZoom);
  }

  const handleZoomToPage = () => {
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;

    const viewportWidth = canvas.getWidth();
    const viewportHeight = canvas.getHeight();

    const zoomFactorX = viewportWidth / canvasWidth;
    const zoomFactorY = viewportHeight / canvasHeight;

    const zoomFactor = Math.min(zoomFactorX, zoomFactorY);
    setZoom(zoomFactor)
    canvas.setZoom(zoomFactor);

    canvas.viewportTransform[4] = (viewportWidth - canvasWidth * zoomFactor) / 2;
    canvas.viewportTransform[5] = (viewportHeight - canvasHeight * zoomFactor) / 2;
  }


  const zoomSelection = () => {
    const activeObject = canvas.getActiveObject();

    if (activeObject) {
      const objectBounds = activeObject.getBoundingRect(true);
      const viewportWidth = canvas.getWidth();
      const viewportHeight = canvas.getHeight();
      const zoomFactorX = viewportWidth / objectBounds.width;
      const zoomFactorY = viewportHeight / objectBounds.height;
      const zoomFactor = Math.min(zoomFactorX, zoomFactorY);

      const zoomPoint = new fabric.Point(
        objectBounds.left + objectBounds.width / 2,
        objectBounds.top + objectBounds.height / 2
      );

      canvas.zoomToPoint(zoomPoint, zoomFactor);
      setZoom(zoomFactor);
      const vpt = canvas.viewportTransform;
      vpt[4] = (viewportWidth - objectBounds.width * zoomFactor) / 2 - objectBounds.left * zoomFactor;
      vpt[5] = (viewportHeight - objectBounds.height * zoomFactor) / 2 - objectBounds.top * zoomFactor;

      canvas.setViewportTransform(vpt);

      canvas.renderAll();
    } else {
      console.log("No object is selected.");
    }
  }




  const updateCanvasDimensions = (width, height, zoomLevel) => {
    if (canvas) {
      canvas.setDimensions({ width: width * zoomLevel, height: height * zoomLevel });
    }
  };


  const handlePredefinedSizeChange = (size, lanes = 1) => {
    let heightInPixels;
    let widthInPixels;

    switch (size) {
      case 'A5':
        heightInPixels = 595;
        widthInPixels = 420;
        break;
      case 'A4':
        heightInPixels = 842;
        widthInPixels = 595;
        break;
      case 'A3':
        heightInPixels = 1191;
        widthInPixels = 842;
        break;
      case 'A2':
        heightInPixels = 1684;
        widthInPixels = 1191;
        break;
      case '3X4':
        heightInPixels = 1200;
        widthInPixels = 900;
        break;
      case '4X3':
        heightInPixels = 900;
        widthInPixels = 1200;
        break;
      case '54X40':
        heightInPixels = 5184;
        widthInPixels = 3840;
        break;
      case '40X54':
        heightInPixels = 3840;
        widthInPixels = 5184;
        break;
      case '48X36':
        heightInPixels = 4608;
        widthInPixels = 3456;
        break;
      case '36X48':
        heightInPixels = 3456;
        widthInPixels = 4608;
        break;
      case 'fullBanner':
        heightInPixels = 60;
        widthInPixels = 468;
        break;
      case 'halfBanner':
        heightInPixels = 60;
        widthInPixels = 234;
        break;
      case 'skyscraper':
        heightInPixels = 600;
        widthInPixels = 120;
        break;
      case 'wideSkyscraper':
        heightInPixels = 600;
        widthInPixels = 160;
        break;
      case 'mediumBanner':
        heightInPixels = 250;
        widthInPixels = 300;
        break;
      case 'leaderboard':
        heightInPixels = 90;
        widthInPixels = 728;
        break;
      case 'halfPage':
        heightInPixels = 600;
        widthInPixels = 300;
        break;
      case 'largeLeaderboard':
        heightInPixels = 90;
        widthInPixels = 970;
        break;
      case 'billBoard':
        heightInPixels = 250;
        widthInPixels = 970;
        break;
      case 'largeRectangle':
        heightInPixels = 280;
        widthInPixels = 336;
        break;
      case 'smallSquare':
        heightInPixels = 200;
        widthInPixels = 200;
        break;
      case 'square':
        heightInPixels = 250;
        widthInPixels = 250;
        break;
      case 'verticalBanner':
        heightInPixels = 240;
        widthInPixels = 120;
        break;
      case 'portrait':
        heightInPixels = 1050;
        widthInPixels = 300;
        break;

      case 'linkedIn':
        heightInPixels = 627;
        widthInPixels = 1200;
        break;
      case 'instagram':
        heightInPixels = 1080;
        widthInPixels = 1080;
        break;
      case 'facebook':
        heightInPixels = 630;
        widthInPixels = 1200;
        break;
      case 'twitter':
        heightInPixels = 675;
        widthInPixels = 1200;
        break;

      default:
        break;
    }

    setSize({
      height: heightInPixels,
      width: widthInPixels
    });

    if (canvas) {
      const removeExistingTextboxes = () => {
        const objects = canvas.getObjects();
        objects.forEach((obj) => {
          if (obj.type === 'textbox') {
            canvas.remove(obj);
          }
          if (obj.type === 'rect') {
            canvas.remove(obj);
          }
        });
      };
      removeExistingTextboxes();
      updateCanvasDimensions(widthInPixels, heightInPixels, zoom);
      removeExistingTextboxes();
      const shouldConsiderLanes = ['3X4', '4X3', '54X40', '40X54', '48X36', '36X48'].includes(size);

      if (shouldConsiderLanes) {
        if (lanes === 1) {
          addTextBoxWithBorder('Title', widthInPixels * 0.1, heightInPixels * 0.001, widthInPixels * 0.8, heightInPixels * 0.1, 24);
          addTextBoxWithBorder('Body', widthInPixels * 0.1, heightInPixels * 0.110, widthInPixels * 0.8, heightInPixels * 0.8, 18);
        } else if (lanes === 2) {
          addTextBoxWithBorder('Title', widthInPixels * 0.005, heightInPixels * 0.001, widthInPixels * 0.98 + widthInPixels * 0.015, heightInPixels * 0.1, 24);
          addTextBoxWithBorder('Body 1', widthInPixels * 0.005, heightInPixels * 0.110, widthInPixels * 0.49, heightInPixels * 0.8, 18);
          addTextBoxWithBorder('Body 2', widthInPixels * 0.505, heightInPixels * 0.110, widthInPixels * 0.49, heightInPixels * 0.8, 18);

        } else if (lanes === 3) {
          addTextBoxWithBorder('Title', widthInPixels * 0.0025, heightInPixels * 0.001, widthInPixels * 0.96 + widthInPixels * 0.035, heightInPixels * 0.1, 24);
          addTextBoxWithBorder('Body 1', widthInPixels * 0.0025, heightInPixels * 0.110, widthInPixels * 0.32, heightInPixels * 0.8, 18);
          addTextBoxWithBorder('Body 2', widthInPixels * 0.34, heightInPixels * 0.110, widthInPixels * 0.32, heightInPixels * 0.8, 18);
          addTextBoxWithBorder('Body 3', widthInPixels * 0.675, heightInPixels * 0.110, widthInPixels * 0.32, heightInPixels * 0.8, 18);
        }
      }
      canvas.renderAll();
    }
  };

  const addTextBoxWithBorder = (text, left, top, width, height, fontSize) => {
    const rect = new fabric.Rect({
      left: left,
      top: top,
      width: width,
      height: height,
      angle: 0,
      strokeWidth: 1,
      stroke: 'black',
      fill: 'transparent',
      strokeDashArray: [5, 5],
      hasBorders: true,
      hasControls: true,
      transparentCorners: false
    });

    const textBox = new fabric.Textbox(text, {
      left: left,
      top: top,
      width: width,
      height: height,
      textAlign: "center",
      fontSize: fontSize,
      editable: true,
      hasBorders: false,
      hasControls: false,
      class: 'textBox',
      overflow: 'hidden',
      textBackgroundColor: 'transparent',
      splitByGrapheme: true
    });

    canvas.add(rect);
    canvas.add(textBox);

    textBox.bringToFront();
    const syncTextBoxWithRect = () => {
      textBox.set({
        left: rect.left,
        top: rect.top,
        scaleX: rect.scaleX,
        scaleY: rect.scaleY,
        angle: rect.angle,
        skewX: rect.skewX,
        skewY: rect.skewY
      });
      textBox.setCoords();
      canvas.renderAll();
    };

    // Synchronize the textbox with the rect on transform events
    rect.on('moving', syncTextBoxWithRect);
    rect.on('scaling', syncTextBoxWithRect);
    rect.on('rotating', syncTextBoxWithRect);
    rect.on('skewing', syncTextBoxWithRect);
  };







  const handleSizeChange = (newSize) => {
    if (!newSize) return;

    let heightInPixels = parseFloat(newSize.height);
    let widthInPixels = parseFloat(newSize.width);

    switch (newSize.unit) {
      case 'px X px':
        break;
      case 'cm X cm':
        heightInPixels *= 37.7952756;
        widthInPixels *= 37.7952756;
        break;
      case 'mm X mm':
        heightInPixels *= 3.77952756;
        widthInPixels *= 3.77952756;
        break;
      case 'inch X inch':
        heightInPixels *= 96;
        widthInPixels *= 96;
        break;
      case 'feet x feet':
        heightInPixels *= 1152;
        widthInPixels *= 1152;
        break;
      default:
        break;
    }

    setSize({
      height: heightInPixels,
      width: widthInPixels
    });

    if (canvas) {
      updateCanvasDimensions(widthInPixels, heightInPixels, zoom);
    }
  };
  const removeCanvas = () => {
    const newCanvases = canvases.filter((val, index) => index !== activeCanvasIndex)
  }
  const updateGuides = (zoomProp) => {
    const zoomValue = zoomProp ?? zoom; // Use zoomProp if provided, otherwise use zoom state
  
    const canvasContainer = document.getElementById('canvas-container');
    if (canvasContainer) {
      const canvasElement = canvasContainer.querySelector('div.canvas-container');
      console.log('updateGuides: canvasContainer, canvasElement: ', canvasContainer, canvasElement);
  
      if (canvasElement) {
        const canvasRect = canvasElement.getBoundingClientRect();
        const containerRect = canvasContainer.getBoundingClientRect();
        const offsetX = (canvasRect.x - containerRect.x - 30) / zoomValue;
        const offsetY = (canvasRect.y - containerRect.y - 30) / zoomValue;
  
        console.log('updateGuides: canvasRect, containerRect: ', zoomValue, canvasRect, canvasContainer.style, containerRect);
        
        setGuidesOffsets({
          offsetX,
          offsetY,
        });
  
        if (horizonalGuidesRef.current) {
          horizonalGuidesRef.current.scroll(-offsetX, zoomValue);
          horizonalGuidesRef.current.scrollGuides(-offsetY, zoomValue);
        }
  
        if (verticalGuidesRef.current) {
          verticalGuidesRef.current.scroll(-offsetY, zoomValue);
          verticalGuidesRef.current.scrollGuides(-offsetX, zoomValue);
        }
      }
    }
  }
  // useEffect(() => {


  //   updateGuides();

  //   window.addEventListener("resize", updateGuides);

  //   return () => {
  //     window.removeEventListener("resize", updateGuides);
  //   };
  // }, [canvases, size]);

  useEffect(() => {
    const handleResize = () => {
      updateGuides(); // Use the default zoom state
    };
  
    updateGuides(); // Initial update
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [canvases, size]); // Use only relevant dependencies

  const addNewCanvas = (isDuplicate) => {
    const newIndex = canvases.length;
    const newCanvas = createNewCanvas(canvasRef.current, bg, size, newIndex, isDuplicate);
    // canvasRef.current = null;
    setCanvases((prevCanvases) => [...prevCanvases, newCanvas]);
    setActiveCanvasIndex(activeCanvasIndex);
    // setCanvas(canvas);

    console.log("addNewCanvas:", newCanvas, canvases);
  };

  useEffect(() => {
    const removeCanvasWithoutLowerClass = () => {
      const canvasContainers = document.querySelectorAll('#canvas-container > canvas');
      canvasContainers.forEach(canvas => {
        if (!canvas.classList.contains('lower-canvas') && canvas.id === 'canvas0') {
          canvas.parentNode.removeChild(canvas);
          console.log(`Removed canvas ${canvas.id} from DOM.`);
        }
      });
    };

    if (canvases.length > 0) {
      removeCanvasWithoutLowerClass();
    }

  }, [canvases.length < 2]);


  const createNewCanvas = (ref, bg, size, index, isDuplicate = false) => {
    const newCanvasElement = document.createElement('canvas');
    console.log('createNewCanvas: newCanvasElement: 1', newCanvasElement);
    newCanvasElement.setAttribute('id', `canvas${index}`);

    ref.appendChild(newCanvasElement);
    console.log('createNewCanvas: ref: 1', ref);
    console.log('createNewCanvas: newCanvasElement: 2', newCanvasElement);
    const newCanvas = new fabric.Canvas(newCanvasElement, {
      id: `canvas-${index}`,
      isDrawingMode: false,
      backgroundColor: bg,
      controlsAboveOverlay: true,
      width: size.width * zoom,
      height: size.height * zoom,
      altActionKey:"none"
    });


    newCanvas.setZoom(zoom)
    newCanvas.freeDrawingBrush = new fabric.PencilBrush(newCanvas);
    newCanvas.freeDrawingBrush.color = 'black';
    newCanvas.freeDrawingBrush.width = 1;

    if (canvases.length > 0 && index !== activeCanvasIndex) {
      newCanvasElement.style.display = 'none';
      newCanvasElement.parentElement.style.display = 'none';
    }
    // if (!canvas || canvases.length === 0) {
    // console.log("saveState: called when canvas rendered.");
    // undoStack.current.push(newCanvas.toJSON());
    // redoStack.current = [];
    // }
    if (isDuplicate === true) {
      // Serialize objects from the current canvas
      const serializedObjects = canvas.getObjects().map((obj) => obj.toObject());
    
      // Reconstruct (enliven) objects for the new canvas
      fabric.util.enlivenObjects(serializedObjects, (enlivenedObjects) => {
        enlivenedObjects.forEach((enlivenedObj) => {
          // Add each enlivened object to the new canvas
          newCanvas.add(enlivenedObj);
        });
    
        // Copy additional properties like zoom and background color
        newCanvas.setZoom(canvas.getZoom());
        newCanvas.setBackgroundColor(canvas.backgroundColor, newCanvas.renderAll.bind(newCanvas));
        newCanvas.renderAll();
      });
    }
    
    
    console.log("createNewCanvas: ", newCanvas)
    return newCanvas;
  };
  const [custControls, setCustControls] = useState(false);
  const activeObjRef = useRef(activeObj);

  useEffect(() => {
    // Update the ref whenever activeObj changes
    activeObjRef.current = activeObj;
  }, [activeObj]);
  
  useEffect(() => {
    if (canvases.length > 0) {
      canvases.forEach((newCanvas, index) => {
        if (index == activeCanvasIndex) {
          console.log(`Attaching event listeners to Canvas ${index + 1}`);
          if (!newCanvas.__eventListenersAttached) {
            newCanvas.__eventListenersAttached = true;

            console.log("newCanvas & index: ", newCanvas, index);

            newCanvas.forEachObject(obj => {
              obj.selectable = true;

              console.log("forEach: object:", obj)
            });

            const handleResize = () => {
              const outerCanvasContainer = document.getElementById('canvas-container');

              const ratio = newCanvas.getWidth() / newCanvas.getHeight();
              const containerWidth = outerCanvasContainer.clientWidth;
              const containerHeight = outerCanvasContainer.clientHeight;

              const scale = containerWidth / newCanvas.getWidth();
              const zoom = newCanvas.getZoom() * scale;
              // newCanvas.setDimensions({ width: containerWidth, height: containerWidth / ratio });
              adjustCanvasContainerDimensions();
            };

            window.addEventListener("resize", handleResize);

            const handlePathCreated = (e) => {
              console.log("handlePathCreated: e,canvas:", e, canvas);

              if (index === activeCanvasIndex) {
                const path = e.path;
                setPath(path);
                console.log("handlePathCreated: Path created:", path);
              }
            };

            let initialAngle = 0;
            let initialMouseAngle = 0;
            let anchorPoint = { x: 0, y: 0 };
            function throttle(func, limit) {
              let lastFunc;
              let lastRan;
              return function (...args) {
                if (!lastRan) {
                  func.apply(this, args);
                  lastRan = Date.now();
                } else {
                  clearTimeout(lastFunc);
                  lastFunc = setTimeout(() => {
                    if (Date.now() - lastRan >= limit) {
                      func.apply(this, args);
                      lastRan = Date.now();
                    }
                  }, limit - (Date.now() - lastRan));
                }
              };
            }

            function initializeRotationHandler(eventData, transform, x, y) {
              const target = transform.target;
              initialAngle = target.angle;

              initialMouseAngle = Math.atan2(y - anchorPoint.y, x - anchorPoint.x);

              const boundingBox = target.getBoundingRect();
              anchorPoint = {
                x: boundingBox.left + boundingBox.width / 2,
                y: boundingBox.top + boundingBox.height / 2
              };
              console.log("initialRotation: ", anchorPoint, boundingBox, target)
              return true;
            }
            const rotationHandler = throttle(function (eventData, transform, x, y) {
              const target = transform.target;
              const anchor = new fabric.Point(anchorPoint.x, anchorPoint.y);
              // Compute the current mouse angle relative to the anchor point
              const currentMouseAngle = Math.atan2(y - anchor.y, x - anchor.x);
              const relativeMouseAngle = currentMouseAngle - initialMouseAngle;
              const newAngle = fabric.util.radiansToDegrees(relativeMouseAngle + initialAngle);
              console.log("rotationHandler: newAngle after def: newAngle, relativeMouseAngle, currentMouseAngle, initialAngle", newAngle, relativeMouseAngle, currentMouseAngle, initialAngle);
              const control = target.controls.customRotationControlsCenter;
              console.log("rotationHandler:");

              // Calculate the new center point after rotation
              const radian = fabric.util.degreesToRadians(newAngle - target.angle);
              const rotatedCenter = fabric.util.rotatePoint(target.getCenterPoint(), anchor, radian);
              console.log("rotationHandler: rotatedCenter: target.getCenterPoint(), anchor, radian:", rotatedCenter, target.getCenterPoint(), anchor, radian);

              // Apply the new angle and position
              target.set({
                angle: newAngle,
                left: rotatedCenter.x,
                top: rotatedCenter.y,
                originX: 'center',
                originY: 'center'
              });
              console.log("rotationHandler: all at end:", newAngle, rotatedCenter, target.originX, target.originY)
              target.setCoords();
              canvas.requestRenderAll();
              return true;
            },
            2);
            function dragHandler(eventData, transform, x, y) {
              const target = transform.target;
              const control = target.controls.customRotationControlsCenter;

              // Get the pointer position in canvas coordinates
              const pointer = canvas.getPointer(eventData.e);

              // Get the inverse of the object's transformation matrix
              const invertedMatrix = fabric.util.invertTransform(target.calcTransformMatrix());

              // Apply the inverse matrix to the pointer to get local coordinates
              const localPoint = fabric.util.transformPoint(new fabric.Point(pointer.x, pointer.y), invertedMatrix);

              // Constrain the local coordinates within the object's bounding box
              const newX = Math.max(-target.width / 2, Math.min(localPoint.x, target.width / 2));
              const newY = Math.max(-target.height / 2, Math.min(localPoint.y, target.height / 2));

              // Update the control's position relative to the object's dimensions
              control.x = newX / target.width;
              control.y = newY / target.height;

              // Convert the local coordinates back to canvas coordinates for the anchor point
              const controlPoint = fabric.util.transformPoint(
                { x: newX, y: newY },
                fabric.util.multiplyTransformMatrices(
                  target.calcTransformMatrix(),
                  canvas.viewportTransform
                )
              );
              anchorPoint = { x: controlPoint.x, y: controlPoint.y };

              // Update the object's coordinates and request render
              target.setCoords();
              canvas.requestRenderAll();

              return true;
            }




            let initialSkewX = 0;
            let initialSkewY = 0;

            const initializeSkewHandler = (eventData, transform) => {
              const target = transform.target;
              if (!target || !(target instanceof fabric.Object)) {
                console.error("Target is not a valid Fabric.js object");
                return;
              }
              initialSkewX = target.skewX || 0;
              initialSkewY = target.skewY || 0;
            };

            canvas.on("mouse:down", (opt) => {
              const { e, transform } = opt;
              if (transform && transform.corner) {
                initializeSkewHandler(e, transform);
              }

              handleCanvasClick(opt);
            });

            const skewHandler = (eventData, transform) => {
              const target = transform.target;

              if (!target || !(target instanceof fabric.Object)) {
                console.error("Target is not a valid Fabric.js object");
                return;
              }

              // Get the pointer position directly from the event
              const pointer = target.canvas.getPointer(eventData.e);
              if (!pointer) {
                console.error("Pointer position could not be retrieved");
                return;
              }

              // Get the initial bounding box of the target
              const bbox = target.getBoundingRect(true);
              const centerX = bbox.left + bbox.width / 2;
              const centerY = bbox.top + bbox.height / 2;

              // Calculate the deltas from the center of the bounding box
              const deltaX = pointer.x - centerX;
              const deltaY = pointer.y - centerY;

              // Determine which skew direction to adjust based on the control corner
              let newSkewX = initialSkewX;
              let newSkewY = initialSkewY;
              let sensitivity = 30;
              switch (transform.corner) {
                case 'customSkewControls1':
                  newSkewX = initialSkewX + (deltaX * sensitivity / target.width);
                  break;
                case 'customSkewControls2':
                  newSkewX = initialSkewX - (deltaX * sensitivity / target.width);
                  break;
                case 'customSkewControls':
                  newSkewY = initialSkewY + (deltaY * sensitivity / target.height);
                  break;
                case 'customSkewControls3':
                  newSkewY = initialSkewY - (deltaY * sensitivity / target.height);
                  break;
              }

              // Update the target with new skew values
              target.set({
                skewX: newSkewX,
                skewY: newSkewY,
              });

              target.setCoords();
              target.canvas.requestRenderAll();
            };




            const img = document.createElement('img');
            const cornerImg = document.createElement('img');
            const sideImageHorizontal = document.createElement('img');
            const sideImageVertical = document.createElement('img');


            const cornerIcons = "/shapes/rotate-option-svgrepo-com.svg";
            const sideIconHorizontal = "/shapes/expand-items-svgrepo-com.svg";
            const sideIconVertical = "/shapes/expand-vertical.svg";
            const deleteIcon = "/shapes/anchor-svgrepo-com.svg"

            img.src = deleteIcon;
            cornerImg.src = cornerIcons;
            sideImageHorizontal.src = sideIconHorizontal;
            sideImageVertical.src = sideIconVertical;
            function renderIcon(ctx, left, top, styleOverride, fabricObject) {
              var size = this.cornerSize;
              ctx.save();
              ctx.translate(left, top);
              ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
              ctx.drawImage(img, (-size / 2), (-size / 2), size, size);
              ctx.restore();
            }

            function renderIconCorner(ctx, left, top, styleOverride, fabricObject) {
              var size = this.cornerSize;
              ctx.save();
              ctx.translate(left, top);
              ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
              ctx.drawImage(cornerImg, -size / 2, -size / 2, size, size);
              ctx.restore();
            }
            function renderIconSides(ctx, left, top, styleOverride, fabricObject) {
              var size = this.cornerSize;
              ctx.save();
              ctx.translate(left, top);
              ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
              ctx.drawImage(sideImageHorizontal, -size / 2, -size / 2, size, size);
              ctx.restore();
            }
            function renderIconSidesVertical(ctx, left, top, styleOverride, fabricObject) {
              var size = this.cornerSize;
              ctx.save();
              ctx.translate(left, top);
              ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
              ctx.drawImage(sideImageVertical, -size / 2, -size / 2, size, size);
              ctx.restore();
            }

            let controlOptions = {
              cornerSize: 18,
              actionHandler: rotationHandler,
              visible: custControls
            }


            fabric.Object.prototype.set({
              transparentCorners: false,
              cornerStyle: 'circle',
              cornerColor: 'lightblue',
              cornerStrokeColor: 'darkblue',
              cornerSize: 12
            });



            fabric.Object.prototype.controls.customRotationControls = new fabric.Control({
              ...controlOptions, x: 0.5, y: 0.5, render: renderIconCorner,
              cursorStyle: 'crosshair',
            });
            fabric.Object.prototype.controls.customRotationControls1 = new fabric.Control({
              ...controlOptions, x: -0.5, y: 0.5, render: renderIconCorner, cursorStyle: 'crosshair',
            });
            fabric.Object.prototype.controls.customRotationControls2 = new fabric.Control({
              ...controlOptions, x: 0.5, y: -0.5, render: renderIconCorner, cursorStyle: 'crosshair',
            });
            fabric.Object.prototype.controls.customRotationControls3 = new fabric.Control({
              ...controlOptions, x: -0.5, y: -0.5, render: renderIconCorner, cursorStyle: 'crosshair',
            });
            fabric.Object.prototype.controls.customSkewControls = new fabric.Control({ ...controlOptions, x: 0.5, y: 0, actionName: 'skew', render: renderIconSides, actionHandler: skewHandler, cursorStyle: 's-resize' });
            fabric.Object.prototype.controls.customSkewControls1 = new fabric.Control({ ...controlOptions, x: 0, y: 0.5, actionName: 'skew', render: renderIconSidesVertical, actionHandler: skewHandler, cursorStyle: 'w-resize' });
            fabric.Object.prototype.controls.customSkewControls2 = new fabric.Control({ ...controlOptions, x: 0, y: -0.5, actionName: 'skew', render: renderIconSidesVertical, actionHandler: skewHandler, cursorStyle: 'w-resize' });
            fabric.Object.prototype.controls.customSkewControls3 = new fabric.Control({ ...controlOptions, x: -0.5, y: 0, actionName: 'skew', render: renderIconSides, actionHandler: skewHandler, cursorStyle: 's-resize' });

            fabric.Object.prototype.controls.customRotationControlsCenter = new fabric.Control({
              ...controlOptions, x: 0, y: 0, actionHandler: dragHandler, render: renderIcon, cursorStyle: 'crosshair',
            });

            newCanvas.on("mouse:down", (opt) => {
              const { e, transform } = opt;
              if (transform && anchorPoint.x === 0 && anchorPoint.y === 0) {
                initializeRotationHandler(e, transform, e.clientX, e.clientY);
              }
            });

            const handleCanvasChange = (index) => {
              captureInitialCanvasImages();
              console.log("handleCanvasChange: canvas & canvasImage: ", canvas, canvasImage)
              if (canvas && canvas?.patternPath?.cloneParent) {
                console.log("handleCanvasChange: canvas && canvas.patternPath: ", canvas.patternPath)

                canvas.forEachObject((obj) => {
                  if (obj.class === "distributeCopy-cloned") {
                    console.log("handleCanvasChange: obj if its pattern clone: ", obj);

                    canvas.forEachObject((parent) => {
                      if (parent.class === "patternPath") {
                        obj.scaleX = parent.scaleX;
                        obj.scaleY = parent.scaleY;
                        // obj.angle = parent.angle;
                        obj.stroke = parent.stroke;
                        obj.fill = parent.fill;
                        obj.opacity = parent.opacity;
                        obj.path = parent.path;

                        // Ensure child object renders correctly
                        obj.setCoords();

                        console.log(
                          "handleCanvasChange: obj if its pattern clone: its parent ",
                          parent
                        );
                      }
                    });
                  }
                });
              }
              // saveState(); // Save only when necessary
            };
            let isDragging = false;
            let lastPosX = 0;
            let lastPosY = 0;
            let lastOrder;
            const handleMouseDown = (opt) => {
              const evt = opt.e;
              console.log("handleMouseDown: ", opt?.target?.order, lastOrder);
              if (!opt.target) {
                // No object was clicked
                handleClose();
              } else if (lastOrder !== opt.target.order) {
                handleObjectSelected(canvas);
                lastOrder = opt.target.order;
              }
              if (evt.altKey === true) {
                isDragging = true;
                canvas.selection = false;
                lastPosX = evt.clientX;
                lastPosY = evt.clientY;
              }
            };

            const handleMouseMove = (opt) => {
              if (isDragging) {
                const e = opt.e;
                const vpt = newCanvas.viewportTransform;
                vpt[4] += e.clientX - lastPosX;
                vpt[5] += e.clientY - lastPosY;
                newCanvas.requestRenderAll();
                lastPosX = e.clientX;
                lastPosY = e.clientY;
              }
            };

            const handleMouseUp = () => {
              newCanvas.setViewportTransform(newCanvas.viewportTransform);
              isDragging = false;
              newCanvas.selection = true;
            };

            const handleSelectionUpdated = (e) => {
              if (index === activeCanvasIndex) {
                const selectedObject = e.target;
                if (selectedObject && selectedObject.type === "path") {
                  setPath(selectedObject);
                }
              }
            };

            function storeInitialPathData() {
              const selectedObjects = canvas.getActiveObjects();
              selectedObjects.forEach((obj) => {
                if (obj.type === "path" && !obj.initialPathData) {
                  // Store the initial path data in a custom property
                  obj.initialPathData = fabricPathToSVG(obj);
                }
              });
              canvas.forEachObject((obj) => {
                if (obj.class === "curveLine") {
                  obj.set({
                    visible: true,
                  });
                }
              });
            }
            newCanvas.on("selection:created", storeInitialPathData);
            newCanvas.on("selection:updated", storeInitialPathData);
            let objectCounter = 1;

            newCanvas.on("object:added", (e) => {
              const addedObject = e.target;
              console.log("object:added: ", addedObject, e.target);

              if (addedObject) {
                console.log(
                  "object:added: selectedColor, selectedStrokeColor:",
                  selectedColor,
                  selectedStrokeColor
                );

                addedObject.set({
                  order: `object-${objectCounter}`,
                });
                objectCounter++;

                // Only save state if it's NOT a control point
                console.log("Outside of addedObject", addedObject);
                if (
                  addedObject.class !== "centerControl" &&
                  addedObject.class !== "curveLine"
                ) {
                  saveState();
                }
                console.log("saveState: object:added: called.", addedObject);

                // newCanvas.renderAll();
                handleCanvasChange(index);
              }
            });
            newCanvas.on("object:modified", (e) => {
              const modifiedObject = e.target;
              console.log(
                "Check Modified Object ",
                modifiedObject,
                modifiedObject.controlPoint.class,
                modifiedObject.controlText.type
              );

              if (
                modifiedObject.controlPoint.class !== "centerControl" &&
                modifiedObject.controlText.type !== "centerControl"
              ) {
                console.log("After the if condition", modifiedObject,modifiedObject.controlPoint.class,modifiedObject.controlText.class);
                saveState();
              }
              handleCanvasChange(index);
            });
            newCanvas.on("object:removed", () => {
              console.log("saveState: object:removed: BEFORE removing object.");
              saveState();
              handleCanvasChange(index);
            });

            newCanvas.on("mouse:down", handleMouseDown);
            newCanvas.on("mouse:move", handleMouseMove);
            newCanvas.on("mouse:up", handleMouseUp);
            newCanvas.on("mouse:down", (e) => {
              const clickedObj = e.target;
              if (
                clickedObj &&
                clickedObj.type === "textbox" &&
                clickedObj.hyperlinks
              ) {
                // Get selection start based on click
                const pointer = newCanvas.getPointer(e.e);
                const selectionIndex =
                  clickedObj.getSelectionStartFromPointer(pointer);

                // Find the hyperlink for the clicked portion
                const clickedLink = clickedObj.hyperlinks.find((link) => {
                  return (
                    selectionIndex >= link.startIndex &&
                    selectionIndex <= link.endIndex
                  );
                });

                if (clickedLink && clickedLink.url) {
                  window.open(clickedLink.url, "_blank");
                }
              }
            });

            newCanvas.on("object:scaling", function (e) {
              const activeObject = e.target;
              if (!activeObject) return;

              const isCtrlPressed = e.e.ctrlKey;
              const isShiftPressed = e.e.shiftKey;
            
              // For uniform scaling when both Ctrl and Shift are pressed
              if (isCtrlPressed && isShiftPressed) {
                if (!activeObject._initialAspectRatio) {
                  activeObject._initialAspectRatio = activeObject.scaleX / activeObject.scaleY;
                }
            
                const scalingFrom = activeObject.__corner;
            
                if (scalingFrom === "ml" || scalingFrom === "mr") {
                  activeObject.set({
                    scaleY: activeObject.scaleX / activeObject._initialAspectRatio,
                  });
                } else if (scalingFrom === "mt" || scalingFrom === "mb") {
                  activeObject.set({
                    scaleX: activeObject.scaleY * activeObject._initialAspectRatio,
                  });
                }
            
                newCanvas.requestRenderAll();
              } else {
                
                if (activeObject._initialAspectRatio) {
                  delete activeObject._initialAspectRatio;
                }
              }
            });

            function removeControlPointsFromSelectedObjects(selectedObjects) {
              if (selectedObjects) {
                selectedObjects.forEach((obj) => {
                  if (obj.controlPoint) {
                    newCanvas.remove(obj.controlPoint);
                    newCanvas.remove(obj.controlText);
                    delete obj.controlPoint;
                    delete obj.controlText;
                  }

                });
                canvas.forEachObject((obj) => {
                  if (obj.class === "curveLine") {
                    obj.set({
                      visible: false
                    })
                  }
                })
              }
            }

            newCanvas.on('selection:cleared', (e) => {
              removeControlPointsFromSelectedObjects(e.deselected);
              setActiveObj(null);
              setObjeGuideHeight(null);
              setObjeGuideWidth(null);
            });
            function updateControlPoints(selectedObjects) {
              selectedObjects.forEach((obj) => {
                if (obj.controlPoint) {
                  const center = obj.getCenterPoint();
                  if (obj.group) {
                    const group = obj.group.getCenterPoint();
                    center.x += group.x;
                    center.y += group.y;
                  }
                  obj.controlPoint.set({ left: center.x - 5, top: center.y - 5 });
                  obj.controlText.set({ left: center.x - 5, top: center.y - 25, text: `${Math.round(center.x)}, ${Math.round(center.y)}` });
                  obj.controlPoint.setCoords();
                  obj.controlText.setCoords();
                }
              });
            }

            newCanvas.on('object:moving', (e) => {
              console.log("Object:Moving: e.target ", e.target)
              const obj = e.target;
              const canvasWidth = newCanvas.getWidth();
              const canvasHeight = newCanvas.getHeight();
            
              // Ensure the object doesn't move out of the canvas bounds
              if (obj.left < 0) obj.left = 0;  // Don't allow moving past left
              if (obj.top < 0) obj.top = 0;    // Don't allow moving past top
            
              if (obj.left > canvasWidth) {
                obj.left = canvasWidth;  // Don't allow moving past right edge
              }
              if (obj.top  > canvasHeight) {
                obj.top = canvasHeight;  // Don't allow moving past bottom edge
              }
              if (e.target._objects) {
                updateControlPoints(e.target._objects);
              } else {
                updateControlPoints([e.target]);
              }
            });

            newCanvas.on('object:scaling', (e) => {
              if (e.target._objects) {
                updateControlPoints(e.target._objects);
              } else {
                updateControlPoints([e.target]);
              }
            });

            newCanvas.on('object:rotating', (e) => {
              if (e.target._objects) {
                updateControlPoints(e.target._objects);
              } else {
                updateControlPoints([e.target]);
              }
            });

            newCanvas.on('object:skewing', (e) => {
              if (e.target._objects) {
                updateControlPoints(e.target._objects);
              } else {
                updateControlPoints([e.target]);
              }
            });


            // newCanvas.on('after:render', function() {
            //   newCanvas.contextContainer.strokeStyle = '#555';

            //   newCanvas.forEachObject(function(obj) {
            //     if(obj.class==='textBox'){
            //     var bound = obj.getBoundingRect();

            //     newCanvas.contextContainer.strokeRect(
            //       bound.left + 0.5,
            //       bound.top + 0.5,
            //       bound.width,
            //       bound.height
            //     );
            //     }
            //   })
            // })


            newCanvas.on('selection:updated', (e) => {
              removeControlPointsFromSelectedObjects(e.deselected);
              addControlPointsToSelectedObjects(e.selected, canvas, isVisibleCenter);
            });

            newCanvas.on('selection:created', (e) => {
              console.log("handleSelectionCreated , Canvas.getActiveObject() :", e, newCanvas.getActiveObject())
            });

            newCanvas.on('path:created', handlePathCreated);
            newCanvas.on('selection:updated', handleSelectionUpdated);
            newCanvas.on('mouse:dblclick', () => { handleMouseDoubleClick(newCanvas) });

          }
        } else {
          console.log(`Removing event listeners: from Canvas ${index + 1}`);
          newCanvas.__eventListenersAttached = false;

          // Remove all event listeners here
          newCanvas.off();
        }
      })
    }
  }, [canvases, activeCanvasIndex])
  useEffect(() => {
    if (canvas) {
      canvas.on('selection:created', (e) => {
        handleObjectSelected(canvas);
        addControlPointsToSelectedObjects(e.selected, canvas, isVisibleCenter);
      });
    }
  }, [canvas])
  useEffect(() => {
    if (canvas) {
      const canvasElement = canvasRef.current;

      canvasElement.addEventListener('contextmenu', function (event) {
        event.preventDefault(); // This will block the right-click context menu
        return false; // Some browsers need this to ensure the event is fully handled
      });
      console.log('canvasElement: ', canvasElement)
    }
  }, [canvas])
  useEffect(() => {
    const newCanvas = createNewCanvas(canvasRef.current, bg, size, 0);
    setCanvas(newCanvas);
    setCanvases([newCanvas]);
    setActiveCanvas(0);

    return () => {
      newCanvas.dispose();
    };

  }, []);
  useEffect(() => {
    console.log(`canvas at any time: ${activeCanvasIndex}:`, activeCanvasIndex, canvases);
  }, [activeCanvasIndex]);
  const switchCanvas = (index) => {
    console.log("Canvas Switch: in switchCanvas just before switching:", canvas, canvases)
    setActiveCanvasIndex(index);

    canvases.forEach((canvas, i) => {
      const canvasElement = document.getElementById(`canvas${i}`);
      console.log("Switch: in forEach: canvasElement: ", canvasElement);

      if (i === index) {
        canvasElement.style.display = 'block';
        canvasElement.parentElement.style.display = 'block';
        setCanvas(canvas);
        console.log("Canvas Switch: in forEach: activeCanvas: ", index, canvas);
      } else {
        canvasElement.style.display = 'none';
        canvasElement.parentElement.style.display = 'none';

        console.log("Canvas Switch: in forEach: inactiveCanvas: ", index, canvas);

      }
    });
    console.log("Canvas Switch: final:", index, canvas, activeCanvasIndex);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isFullscreen) {
        if (event.key === 'ArrowRight') {
          setActiveCanvasIndex((prevIndex) => (prevIndex + 1) % canvases.length);
        } else if (event.key === 'ArrowLeft') {
          setActiveCanvasIndex((prevIndex) => (prevIndex - 1 + canvases.length) % canvases.length);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFullscreen, canvases.length]);
  useEffect(() => {
    switchCanvas(activeCanvasIndex);
  }, [activeCanvasIndex]);


  useEffect(() => {
    if (!canvas || !canvas.getContext()) return;

    console.log("Canvas Switch: event listeners: if any change is made to the canvas:", activeCanvasIndex, canvases, canvas);

    try {
      canvas.renderAll();
    } catch (error) {
      console.error("Error rendering canvas:", error);
    }


  }, [activeCanvasIndex, canvases]);

  useEffect(() => {
    if (canvas) {
      let activeObject = canvas.getActiveObject();
      if (activeObject && custControls) {
        if (activeObject.controlPoint) {
          canvas.remove(activeObject.controlPoint);
          canvas.remove(activeObject.controlText);
          delete activeObject.controlPoint;
          delete activeObject.controlText;
        } else if (activeObject._objects) {
          activeObject._objects.forEach((obj) => {
            if (obj.controlPoint) {
              canvas.remove(obj.controlPoint);
              canvas.remove(obj.controlText);
              delete obj.controlPoint;
              delete obj.controlText;
            }
          })
        }
      }
    }
  }, [custControls])
  const addCustomControls = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      const defaultControlNames = ['tl', 'tr', 'bl', 'br', 'mt', 'mb', 'ml', 'mr', 'mtr'];
      if (!custControls) {
        defaultControlNames.forEach(control => {
          activeObject.setControlVisible(control, false);
        });
        fabric.Object.prototype.set({
          transparentCorners: false,
          borderColor: 'gray',
          padding: 10,
        });
      } else {
        defaultControlNames.forEach(control => {
          activeObject.setControlVisible(control, true);

        });
        fabric.Object.prototype.set({
          transparentCorners: false,
          borderColor: 'blue',
          padding: 0,
        });

      }

      // Add custom controls
      activeObject.setControlVisible('customRotationControls', !custControls);
      activeObject.setControlVisible('customRotationControls1', !custControls);
      activeObject.setControlVisible('customRotationControls2', !custControls);
      activeObject.setControlVisible('customRotationControls3', !custControls);
      activeObject.setControlVisible('customRotationControlsCenter', !custControls);
      activeObject.setControlVisible('customSkewControls', !custControls);
      activeObject.setControlVisible('customSkewControls1', !custControls);
      activeObject.setControlVisible('customSkewControls2', !custControls);
      activeObject.setControlVisible('customSkewControls3', !custControls);


      setCustControls(!custControls);
      canvas.requestRenderAll();
    }
  };



  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = ()=>{
    setOpen(true);
  }
  const [objGuideWidth, setObjeGuideWidth] = useState(null);
  const [objGuideHeight, setObjeGuideHeight] = useState(null);
  const handleObjectSelected = ( newCanvas) => {

    var obj = newCanvas.getActiveObjects();
    console.log('handleObjectSelected: obj: ', obj);
    if(obj?.[0]){
    if (obj[0]?.class === 'circle' && obj[0]?.circlePathInstance) {
      const circlePath = obj[0]?.circlePathInstance;

      obj[0].setStartAngle = (angle) => {
        circlePath.setStartAngle(angle);
      };

      obj[0].setEndAngle = (angle) => {
        circlePath.setEndAngle(angle);
      };

      obj[0].setRadius = (radius) => {
        circlePath.setRadius(radius);
      };

      obj[0].setAngleType = (type) => {
        circlePath.setAngleType(type);
      };
    }

    if (obj[0]?.class == "star" && !obj[0]?.spokeRatio) {
      handleSpokeRatioChange(0.4);
      obj[0].set({ spokeRatio: 0.4 });
    }

    if (!obj[0]?.fill) {
      obj[0].set({ fill: 'transparent' });
    }
    if (!obj[0]?.unit) {
      obj[0].set({ unit: 'px' });
    }
    console.log("handleObjectSelected:", obj);
    if (obj[0]?.class === 'textbox' || obj[0]?.class?.includes('cloned')) {
      console.log("handleObjectSelected: textbox", obj);
      setActiveObj(obj);
      handleClose();
      setAnchorEl(null);
    } else {
      setActiveObj(obj);
      setAnchorEl(canvasRef.current);
      setOpen(true);
    }

    if (obj[0]?.class === 'chart') {
      const initialColors = obj[0]?.chart.data.datasets[0]?.backgroundColor;
      const initialData = obj[0]?.chart.data.datasets[0]?.data;

      setColors(initialColors);
      setDatasetData(initialData);
      console.log('handleColorChange: initial setCOlor ', initialColors);
    }
  }


  };
  useEffect(() => {
    if (canvas) {
      updateGuideRanges(showRanges);
      const activeObject = canvas.getActiveObject();

      activeObject?.on('moving', () => updateGuideRanges(showRanges));
      activeObject?.on('scaling', () => updateGuideRanges(showRanges));
      activeObject?.on('selected', () => updateGuideRanges(showRanges));

      // obj[0].on('rotating', () => updateGuideRanges(obj[0]));
      activeObject?.on('modified', () => updateGuideRanges(showRanges));
    }
  }, [showRanges, canvas?.getActiveObject()])
  useEffect(() => { console.log('handleCanvasClick: sprayObj:', sprayObj) }, [sprayObj])
  const [numberOfCopies, setNumberOfCopies] = useState(1);
  const [areaOfSpray, setAreaOfSpray] = useState(10);
  const numberOfCopiesRef = useRef(numberOfCopies);  // Create a ref to hold the latest value
  const areaOfSprayRef = useRef(areaOfSpray);
  const sprayCircleRef = useRef(null);

  const handleMouseMove = (options) => {
    const pointer = canvas.getPointer(options.e);
    const radius = areaOfSprayRef.current;
    if (spraySelectRef.current) {
      if (!sprayCircleRef.current) {
        sprayCircleRef.current = new fabric.Circle({
          radius: radius,
          left: pointer.x,
          originX: 'center',
          originY: 'center',
          top: pointer.y,
          fill: 'rgba(0, 0, 0, 0.1)',  // Light fill to show the area of spray
          stroke: 'black',             // Black stroke to outline the circle
          strokeWidth: 1,
          selectable: false,           // Make the circle non-selectable
          evented: false               // Disable event handling for the circle
        });

        canvas.add(sprayCircleRef.current);
      } else {
        // If the circle exists, just update its position
        sprayCircleRef.current.set({ left: pointer.x, top: pointer.y });
      }
      canvas.renderAll();
    }


  };

  const handleMouseOut = () => {
    if (sprayCircleRef.current) {
      canvas.remove(sprayCircleRef.current);
      sprayCircleRef.current = null;
      canvas.renderAll();
    }
  };


  // Sync ref with state value when it changes
  useEffect(() => {
    numberOfCopiesRef.current = numberOfCopies;
    areaOfSprayRef.current = areaOfSpray;
    if (canvas) {
      canvas.on('mouse:move', handleMouseMove);
      canvas.on('mouse:out', handleMouseOut);
    }
  }, [numberOfCopies, areaOfSpray, canvas]);

  const handleCanvasClick = (options) => {
    if (spraySelectRef.current && sprayObjRef.current) {
      console.log('handleCanvasClick: spraySelect and sprayObj both true', options, numberOfCopiesRef.current);

      const pointer = canvas.getPointer(options.e);
      const radius = areaOfSprayRef.current;
      for (let i = 0; i < numberOfCopiesRef.current; i++) {
        const angle = Math.random() * 2 * Math.PI;
        const distance = Math.random() * radius;
        const offsetX = Math.cos(angle) * distance;
        const offsetY = Math.sin(angle) * distance;

        const newObject = fabric.util.object.clone(sprayObjRef.current);

        newObject.set({ left: pointer.x + offsetX, top: pointer.y + offsetY });

        console.log(`handleCanvasClick: newObject #${i + 1}:`, newObject);
        canvas.add(newObject);
      }
      canvas.renderAll();
    }
  };



  const updateGuideRanges = (show) => {
    // Early return if show is false
    if (!show) {
      setObjeGuideHeight(null);
      setObjeGuideWidth(null);

      return;
    }

    // Get the active object(s) from the canvas
    const activeObject = canvas.getActiveObject();

    if (!activeObject) {
      console.log("No active object found");
      return;
    }

    // Handle single object or group of objects
    let objectLeft, objectTop, objectWidth, objectHeight;

    if (activeObject.type === 'group') {
      // For groups, use the bounding box of the group
      const groupBounds = activeObject.getBoundingRect();
      objectLeft = groupBounds.left;
      objectTop = groupBounds.top;
      objectWidth = groupBounds.width;
      objectHeight = groupBounds.height;
    } else {
      // For single objects, use its properties
      objectLeft = activeObject.left;
      objectTop = activeObject.top;
      objectWidth = activeObject.width * activeObject.scaleX;
      objectHeight = activeObject.height * activeObject.scaleY;
    }

    // Set the ranges
    const rangeStart = objectLeft;
    const rangeEnd = objectLeft + objectWidth;
    const rangeStartHeight = objectTop;
    const rangeEndHeight = objectTop + objectHeight;

    setObjeGuideWidth([[rangeStart, rangeEnd]]);
    setObjeGuideHeight([[rangeStartHeight, rangeEndHeight]]);

    console.log("updateGuideRanges: rangeStart, rangeEnd, objeGuideWidth:", rangeStart, rangeEnd);
    console.log("updateGuideRanges: rangeStartHeight, rangeEndHeight, objeGuideHeight:", rangeStartHeight, rangeEndHeight);
  };




  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    console.log('handleClose called: ', open)
  };
  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;
  const handlePropertyChange = (property, value) => {
    
    if (activeObj && canvas) {
      const obj = activeObj[0];

      if (property === 'width' && obj.width !== value) {
        let newWidth = value * conversionRates[activeObj[0].unit]
        const scaleX = newWidth / obj.width;
        obj.scaleX *= scaleX;
        obj.width = newWidth;
        // obj.left -= (value * conversionRates[activeObj[0].unit] - obj.width) / 2;
        console.log('handlePropertyChange: width change: ', obj, obj.width, value, obj.left)
      }
      else if (property === 'unit' && obj.unit !== value) {
        console.log("handlePropertyChange: Handle Property unit : ", obj, obj.width);

        // const widthInPixels = obj.width * conversionRates[obj.unit];
        // const heightInPixels = obj.height * conversionRates[obj.unit];
        // console.log("handlePropertyChange: widthInPixels heightInPixels",conversionRates[obj.unit], widthInPixels, heightInPixels)

        // obj.width = widthInPixels / conversionRates[value]; 
        // obj.height = heightInPixels / conversionRates[value]; 
        // console.log("handlePropertyChange: Handle Property Change unit : ", obj.width,obj.height,widthInPixels,heightInPixels); 
        obj.unit = value;
      }

      else if (property === 'height' && obj.height !== value) {
        let newHeight = value * conversionRates[activeObj[0].unit]

        const scaleY = newHeight / obj.height;
        obj.scaleY *= scaleY;
        obj.height = newHeight;
        // obj.top -= (value * conversionRates[activeObj[0].unit] - obj.height) / 2;
      } else if (property === 'cornerRadiusX' && obj.type === 'rect') {
        obj.set({ rx: value })
      } else if (property === 'cornerRadiusY' && obj.type === 'rect') {
        obj.set({ ry: value });
      } else if (property === "radius") {
        obj.set(property, value);
        obj.setRadius(value);
      } else {
        obj.set(property, value);
      }

      if (property === "angle") {
        obj.set({ centeredRotation: true });
      }

      obj.setCoords();
      canvas.renderAll();
      setActiveObj([obj]);
    }
  };
  const handlePositionChange = (property, value) => {
    if (activeObj && canvas) {
      const obj = activeObj[0];
      if (property === "strokeWidth") {
        let newStroke = value ;
        obj.set({ strokeWidth: newStroke });
      }if(property === 'fill' || property ==='stroke'){
       let color = value;
        if (/linear-gradient/.test(value)) {
          color = createLinearGradient(value);
        }
        if (/radial-gradient/.test(value)) {
          color = createRadialGradient(value);
        }
        console.log('handlePositionChange: ', property,value,color);
      obj.set(property, color);
      console.log('handlePositionChange: color:', color, obj);


      }else{
      obj.set(property, value);
      }


      obj.setCoords();
      setActiveObj([obj]);
      canvas.renderAll();
    }
  };

  const updateStarVertices = (polygon, newCorners) => {
    const radius = polygon.width / 2;
    const innerRadius = radius / 2;
    const centerX = polygon.left + radius;
    const centerY = polygon.top + radius;

    const vertices = [];
    for (let i = 0; i < newCorners * 2; i++) {
      const angle = (i * Math.PI) / newCorners;
      const length = i % 2 === 0 ? radius : innerRadius;
      vertices.push({
        x: centerX + length * Math.sin(angle),
        y: centerY - length * Math.cos(angle),
      });
    }

    // Update points in the polygon
    polygon.points = vertices;
    polygon.width = radius * 2;
    polygon.height = radius * 2;

    canvas.renderAll();
  };
  const [sentFromCorner, setSentFromCorner] = useState(false);
  const [sentFromSpoke, setSentFromSpoke] = useState(false);

  const polygonToPath = (polygon) => {
    const points = polygon.points || [];
    let pathData = '';

    if (points.length > 0) {
      pathData += `M ${points[0].x} ${points[0].y} `;
      for (let i = 1; i < points.length; i++) {
        pathData += `L ${points[i].x} ${points[i].y} `;
      }
      pathData += 'Z'; // Close the path
    }

    return pathData.trim();
  };


  const pathToPolygon = (pathData) => {
    const points = [];
    const commands = pathData.match(/[MLC][^MLC]+/g) || [];

    let lastPoint = null;

    commands.forEach(command => {
      const type = command[0];
      const coords = command.slice(1).trim().split(/[\s,]+/).map(Number);

      if (type === 'M' || type === 'L') {
        // Move or line to
        for (let i = 0; i < coords.length; i += 2) {
          const point = { x: coords[i], y: coords[i + 1] };
          points.push(point);
          lastPoint = point;
        }
      } else if (type === 'C') {
        // Approximate cubic Bezier curve (simplified here)
        for (let i = 0; i < coords.length; i += 6) {
          const endPoint = { x: coords[i + 4], y: coords[i + 5] };
          points.push(endPoint);
        }
      } else if (type === 'A') {
        // Approximate arc (simplified here)
        const endPoint = { x: coords[5], y: coords[6] };
        points.push(endPoint);
      }
    });

    // Ensure points form a closed loop
    if (points.length > 1 && points[0].x === points[points.length - 1].x && points[0].y === points[points.length - 1].y) {
      points.pop();
    }

    return points;
  };

  const handleStarCornersChange = (value) => {
    if (activeObj && canvas) {
      const obj = activeObj[0];
      if (obj && obj.type === 'path') {
        const newCorners = parseInt(value, 10);
        if (newCorners >= 2) {
          // Convert path to polygon
          const polygonPoints = pathToPolygon(obj.initialPathData);
          console.log("handleStarCornerChange: polygonPoints: ", polygonPoints, obj.path, obj.path.join(' '));
          const polygon = new fabric.Polygon(polygonPoints);
          console.log("handleStarCornerChange: polygon: ", polygon);

          // Update the polygon vertices
          updateStarVertices(polygon, newCorners);
          if (obj.spokeRatio !== 0.4) {
            updateStarSpokeRatio(polygon, obj.spokeRatio);
          }
          // Convert polygon back to path
          const newPathString = polygonToPath(polygon);
          let newPath = svgPathToFabric(newPathString);
          console.log("handleStarCornerChange: newPath: ", newPath, newPathString);
          if (obj.cornerRadius && obj.cornerRadius !== 0) {
            const roundedSVGPath = roundPathCorners(newPathString, factoral ? cornerRadius / 10 : 10 * cornerRadius, factoral);
            newPath = svgPathToFabric(roundedSVGPath);
            console.log("handleStarCornerChange: newPath: if cornerRadius", newPath, roundedSVGPath);

          }

          const currentLeft = obj.left;
          const currentTop = obj.top;

          const boundingBox = calculateBoundingBox(polygon.points);
          obj.set({
            width: boundingBox.width,
            height: boundingBox.height,
            path: newPath.path,
            left: currentLeft, top: currentTop, corners: newCorners, cornerRadius
          });

          obj.setCoords(); // Ensure coordinates are updated
          obj.dirty = true; // Mark as dirty to trigger redraw
          setActiveObj([obj]);
          console.log("star props: ", obj.spokeRatio, obj.cornerRadius, obj.corners);
          canvas.renderAll();
        }
      }
    }
  };


  const updateStarSpokeRatio = (star, spokeRatio) => {
    const radius = star.width / 2;
    const innerRadius = radius * spokeRatio;
    const centerX = star.left + radius;
    const centerY = star.top + radius;
    const numPoints = star.points.length; // Total number of points (both outer and inner)


    const vertices = [];
    for (let i = 0; i < numPoints; i++) {
      const angle = (i * Math.PI * 2) / numPoints; // Full circle divided by total number of points
      const length = i % 2 === 0 ? radius : innerRadius; // Alternate between outer and inner radius
      vertices.push({
        x: centerX + length * Math.sin(angle),
        y: centerY - length * Math.cos(angle),
      });
    }

    star.set({ points: vertices });
    star.setCoords();
    canvas.renderAll();
  };
  const calculateBoundingBox = (path) => {
    const boundingBox = {
      left: Infinity,
      top: Infinity,
      right: -Infinity,
      bottom: -Infinity
    };

    path.forEach(point => {
      if (point.x < boundingBox.left) boundingBox.left = point.x;
      if (point.y < boundingBox.top) boundingBox.top = point.y;
      if (point.x > boundingBox.right) boundingBox.right = point.x;
      if (point.y > boundingBox.bottom) boundingBox.bottom = point.y;
    });
    console.log("calculateBoundingBox: ", boundingBox)
    return {
      left: boundingBox.left,
      top: boundingBox.top,
      width: boundingBox.right - boundingBox.left,
      height: boundingBox.bottom - boundingBox.top
    };
  };

  const handleSpokeRatioChange = (value, sentFromCorner = false) => {
    if (activeObj && canvas) {
      const obj = activeObj[0];
      if (obj && obj.type === 'path') {
        const spokeRatio = value; // Ensure value is between 0 and 1
        const polygonPoints = pathToPolygon(obj.initialPathData);
        const polygon = new fabric.Polygon(polygonPoints);

        const { left, top, cornerRadius } = obj;
        if (obj.corners !== 5) {
          updateStarVertices(polygon, obj.corners);
        }

        // Update vertices
        updateStarSpokeRatio(polygon, spokeRatio);
        const newPathString = polygonToPath(polygon);
        let newPath = svgPathToFabric(newPathString);
        if (obj.cornerRadius !== 0) {
          const roundedSVGPath = roundPathCorners(newPathString, factoral ? cornerRadius / 10 : 10 * cornerRadius, factoral);
          newPath = svgPathToFabric(roundedSVGPath);
        }
        // Restore properties
        const boundingBox = calculateBoundingBox(polygon.points);
        obj.set({
          width: boundingBox.width,
          height: boundingBox.height,
          path: newPath.path, left, top, spokeRatio, cornerRadius
        });

        obj.setCoords(); // Ensure coordinates are updated
        obj.dirty = true; // Mark as dirty to trigger redraw


        setActiveObj([obj]);
        console.log("star props: ", boundingBox, obj.spokeRatio, obj.cornerRadius, obj.corners);
        canvas.renderAll();
      }
    }
  };

  const updatePolygonVertices = (polygon, corners) => {
    const radius = 50; // Fixed radius for simplicity
    const centerX = polygon.left;
    const centerY = polygon.top;

    const vertices = [];
    for (let i = 0; i < corners; i++) {
      const angle = (i * Math.PI * 2) / corners;
      vertices.push({
        x: 0.5 * centerX + radius * Math.sin(angle),
        y: 0.5 * centerY - radius * Math.cos(angle),
      });
    }

    polygon.set({ points: vertices });
    polygon.setCoords();
    canvas.renderAll();
  };



  const handlePolygonCornersChange = (value) => {
    if (activeObj && canvas) {
      const obj = activeObj[0];
      if (obj && obj.type === 'path') {
        const newCorners = parseInt(value, 10);
        if (newCorners >= 2) {
          // Convert path to polygon
          const polygonPoints = pathToPolygon(obj.initialPathData);
          console.log("handlePolygonCornerChange: polygonPoints: ", polygonPoints, obj.path, obj.path.join(' '));
          const polygon = new fabric.Polygon(polygonPoints);
          console.log("handlePolygonCornerChange: polygon: ", polygon);

          // Update the polygon vertices
          updatePolygonVertices(polygon, newCorners);
          if (obj.spokeRatio !== 0.4) {
            updateStarSpokeRatio(polygon, obj.spokeRatio);
          }
          // Convert polygon back to path
          const newPathString = polygonToPath(polygon);
          let newPath = svgPathToFabric(newPathString);
          console.log("handlePolygonCornerChange: newPath: ", newPath, newPathString);
          if (obj.cornerRadius && obj.cornerRadius !== 0) {
            const roundedSVGPath = roundPathCorners(newPathString, cornerRadius, true);
            newPath = svgPathToFabric(roundedSVGPath);
            console.log("handlePolygonCornerChange: newPath: if cornerRadius", newPath, roundedSVGPath, obj.cornerRadius);

          }

          // Save the position
          const currentLeft = obj.left;
          const currentTop = obj.top;

          // Update the path object
          obj.set({ path: newPath.path, left: currentLeft, top: currentTop, corners: value });
          obj.setCoords(); // Ensure coordinates are updated for the new position
          setActiveObj([obj]);
          canvas.calcOffset();
          console.log("star props: ", obj.spokeRatio, obj.cornerRadius, obj.corners);

          canvas.renderAll();
        }
      }
    }
  };

  const handleDeleteObject = () => {
    if (activeObj && canvas) {
      
      if (activeObj.length > 1) {
        activeObj.forEach(obj => {
          if (obj.controlPoint) {
            canvas.remove(obj.controlPoint);
            canvas.remove(obj.controlText);
            delete obj.controlPoint;
            delete obj.controlText;
          }
          if (obj.curveControls) {
            // obj.curveControls.forEach(control => {
            //   canvas.remove(control);
            // });
            console.log('handleDeleteObject: ', canvas);
            canvas._objects.forEach(object => {
              if (object.class === 'curveLine') {
                canvas.remove(object);
              }
            })
            delete obj.curveControls;
          }
          canvas.remove(obj);
        });
      } else {
        const obj = activeObj[0];
        if (obj.controlPoint) {
          if (obj.curveControls) {
            console.log('handleDeleteObject: Removing curve controls');
            canvas.getObjects().forEach(object => {
              if (object.class === "curveLine") {
                console.log('handleDeleteObject: Removing curveLine:', object);
                canvas.remove(object);
              }
            });
            delete obj.curveControls;
          }

          canvas.remove(obj.controlPoint);
          canvas.remove(obj.controlText);
          delete obj.controlPoint;
          delete obj.controlText;
        }
        canvas.remove(obj);
      }
      setActiveObj(null);
      setObjeGuideHeight(null);
      setObjeGuideWidth(null);
      handleClose();
      canvas.renderAll();
    }
  };
  console.log("Starting and Ending arrow", activeObj);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Delete") {
        handleDeleteObject();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeObj, canvas])

  const [angleType, setAngleType] = useState("");

  const [startAngle, setStartAngle] = useState(0);
  const [endAngle, setEndAngle] = useState(360);

  const handleStartAngleChange = (e) => {
    const value = parseFloat(e.target.value);
    setStartAngle(value);
    console.log("angleChange: Start angle changed:", value, activeObj[0]);

    if (activeObj[0] && activeObj[0].class === 'circle') {
      activeObj[0].setStartAngle(value);
      setActiveObj({ ...activeObj });
      console.log("angleChange: Start angle changed:", value);
    }
  };

  const handleEndAngleChange = (e) => {
    const value = parseFloat(e.target.value);
    setEndAngle(value);
    if (activeObj[0] && activeObj[0].class === 'circle') {
      activeObj[0].setEndAngle(value);
      setActiveObj({ ...activeObj });
      console.log("angleChange: End angle changed:", value);
    }
  };


  const handleAngleTypeChange = (e) => {
    const value = e.target.value;
    setAngleType(value);
    if (activeObj[0] && activeObj[0].class === 'circle') {
      activeObj[0].setAngleType(value);
      setActiveObj({ ...activeObj });
      console.log("Angle type changed:", value);
    }
  };






  const [cornerRadius, setCornerRadius] = useState(0); // Initial cornerRadius


  function roundPathCorners(pathString, radius, useFractionalRadius) {
    function moveTowardsLength(movingPoint, targetPoint, amount) {
      var width = (targetPoint.x - movingPoint.x);
      var height = (targetPoint.y - movingPoint.y);

      var distance = Math.sqrt(width * width + height * height);

      return moveTowardsFractional(movingPoint, targetPoint, Math.min(1, amount / distance));
    }
    function moveTowardsFractional(movingPoint, targetPoint, fraction) {
      return {
        x: movingPoint.x + (targetPoint.x - movingPoint.x) * fraction,
        y: movingPoint.y + (targetPoint.y - movingPoint.y) * fraction
      };
    }

    // Adjusts the ending position of a command
    function adjustCommand(cmd, newPoint) {
      if (cmd.length > 2) {
        cmd[cmd.length - 2] = newPoint.x;
        cmd[cmd.length - 1] = newPoint.y;
      }
    }

    // Gives an {x, y} object for a command's ending position
    function pointForCommand(cmd) {
      return {
        x: parseFloat(cmd[cmd.length - 2]),
        y: parseFloat(cmd[cmd.length - 1]),
      };
    }

    // Split apart the path, handing concatonated letters and numbers
    var pathParts = pathString.split(/[,\s]/).reduce(function (parts, part) {
      var match = part.match("([a-zA-Z])(.+)");
      if (match) {
        parts.push(match[1]);
        parts.push(match[2]);
      } else {
        parts.push(part);
      }

      return parts;
    }, []);

    // Group the commands with their arguments for easier handling
    var commands = pathParts.reduce(function (commands, part) {
      if (parseFloat(part) == part && commands.length) {
        commands[commands.length - 1].push(part);
      } else {
        commands.push([part]);
      }

      return commands;
    }, []);

    // The resulting commands, also grouped
    var resultCommands = [];

    if (commands.length > 1) {
      var startPoint = pointForCommand(commands[0]);

      // Handle the close path case with a "virtual" closing line
      var virtualCloseLine = null;
      if (commands[commands.length - 1][0] == "Z" && commands[0].length > 2) {
        virtualCloseLine = ["L", startPoint.x, startPoint.y];
        commands[commands.length - 1] = virtualCloseLine;
      }

      // We always use the first command (but it may be mutated)
      resultCommands.push(commands[0]);

      for (var cmdIndex = 1; cmdIndex < commands.length; cmdIndex++) {
        var prevCmd = resultCommands[resultCommands.length - 1];

        var curCmd = commands[cmdIndex];

        // Handle closing case
        var nextCmd = (curCmd == virtualCloseLine)
          ? commands[1]
          : commands[cmdIndex + 1];

        // Nasty logic to decide if this path is a candidite.
        if (nextCmd && prevCmd && (prevCmd.length > 2) && curCmd[0] == "L" && nextCmd.length > 2 && nextCmd[0] == "L") {
          // Calc the points we're dealing with
          var prevPoint = pointForCommand(prevCmd);
          var curPoint = pointForCommand(curCmd);
          var nextPoint = pointForCommand(nextCmd);

          // The start and end of the cuve are just our point moved towards the previous and next points, respectivly
          var curveStart, curveEnd;

          if (useFractionalRadius) {
            curveStart = moveTowardsFractional(curPoint, prevCmd.origPoint || prevPoint, radius);
            curveEnd = moveTowardsFractional(curPoint, nextCmd.origPoint || nextPoint, radius);
          } else {
            curveStart = moveTowardsLength(curPoint, prevPoint, radius);
            curveEnd = moveTowardsLength(curPoint, nextPoint, radius);
          }

          // Adjust the current command and add it
          adjustCommand(curCmd, curveStart);
          curCmd.origPoint = curPoint;
          resultCommands.push(curCmd);

          // The curve control points are halfway between the start/end of the curve and
          // the original point
          var startControl = moveTowardsFractional(curveStart, curPoint, 0.5);
          var endControl = moveTowardsFractional(curPoint, curveEnd, 0.5);

          // Create the curve 
          var curveCmd = ["C", startControl.x, startControl.y, endControl.x, endControl.y, curveEnd.x, curveEnd.y];
          // Save the original point for fractional calculations
          curveCmd.origPoint = curPoint;
          resultCommands.push(curveCmd);
        } else {
          // Pass through commands that don't qualify
          resultCommands.push(curCmd);
        }
      }

      // Fix up the starting point and restore the close path if the path was orignally closed
      if (virtualCloseLine) {
        var newStartPoint = pointForCommand(resultCommands[resultCommands.length - 1]);
        resultCommands.push(["Z"]);
        adjustCommand(resultCommands[0], newStartPoint);
      }
    } else {
      resultCommands = commands;
    }

    return resultCommands.reduce(function (str, c) { return str + c.join(" ") + " "; }, "");
  }


  const [factoral, setFactoral] = useState(false);




  function svgPathToFabric(svgPath) {
    console.log('svgPathToFabric: ', svgPath)
    return new fabric.Path(svgPath);
  }









  const handleChange = (event, newValue) => {
    setCornerRadius(newValue);
    updateSelectedObjectRoundness(newValue);
  };

  function updateSelectedObjectRoundness(roundness) {
    const selectedObjects = canvas.getActiveObjects();

    selectedObjects.forEach(obj => {
      if (obj.type === 'path') {
        if (!obj.initialPathData) {
          console.error("Initial path data not found for object");
          return;
        }
        const initialSVGPath = obj.initialPathData;
        let newPath = initialSVGPath;
        if (obj.class === "star" && obj.spokeRatio && obj.spokeRatio !== 0.4) {
          const polygonPoints = pathToPolygon(newPath);
          const polygon = new fabric.Polygon(polygonPoints);
          updateStarSpokeRatio(polygon, obj.spokeRatio);
          const newPathString = polygonToPath(polygon);
          newPath = newPathString;
          console.log("updateSelectedObjectRoundness: if spokeRatio: initialSVGPath, newPath:", initialSVGPath, newPath);
        }
        if (obj.class === "star" && obj.corners && obj.corners !== 5) {
          const polygonPoints = pathToPolygon(newPath);
          const polygon = new fabric.Polygon(polygonPoints);
          updateStarVertices(polygon, obj.corners);
          const newPathString = polygonToPath(polygon);
          newPath = newPathString;
          console.log("updateSelectedObjectRoundness: if corners: initialSVGPath, newPath:", initialSVGPath, newPath);
        }

        const roundedSVGPath = roundPathCorners(newPath, factoral ? roundness / 10 : 10 * roundness, factoral);
        console.log("updateSelectedObjectRoundness: initialSVGPath: ", newPath);
        console.log("updateSelectedObjectRoundness: roundedSVGPath: ", roundedSVGPath);


        newPath = svgPathToFabric(roundedSVGPath);
        console.log("updateSelectedObjectRoundness: newPath: ", newPath.path);


        obj.set({ path: newPath.path, cornerRadius: roundness });
        obj.dirty = true;
        console.log("star props: ", obj.spokeRatio, obj.cornerRadius, obj.corners);
        canvas.renderAll();
      }


    });
  }


  const handlePolygonDistortionChange = (value) => {
    if (activeObj && canvas) {
      const poly = activeObj[0];
      const polygonPoints = pathToPolygon(poly.initialPathData);
      const obj = new fabric.Polygon(polygonPoints);
      console.log("handlePolygonDistortionChange: ", poly, polygonPoints, obj);

      if (obj.type === 'polygon') {
        const distortionFactor = parseFloat(value);
        if (!isNaN(distortionFactor)) {
          const vertices = [];
          const maxDistortion = distortionFactor; // Maximum distortion distance

          for (let i = 0; i < obj.points.length; i++) {
            const point = obj.points[i];

            // Apply random distortion
            const randomAngle = Math.random() * 25 * Math.PI; // Random angle in radians
            const randomRadius = Math.random() * 25 * maxDistortion; // Random radius within maxDistortion

            // Distortion vector components
            const distortionX = randomRadius * Math.cos(randomAngle);
            const distortionY = randomRadius * Math.sin(randomAngle);

            vertices.push({
              x: point.x + 2 * distortionX,
              y: point.y + 2 * distortionY,
            });
          }

          obj.set({ points: vertices });
          obj.setCoords(); // Update internal coordinates for accurate bounding box

          const newPathString = polygonToPath(obj);
          let newPath = svgPathToFabric(newPathString);
          poly.set({ path: newPath.path, dirty: true });

          canvas.calcOffset(); // Recalculate canvas offset
          canvas.renderAll(); // Render canvas to apply changes

          setActiveObj([poly]);
          console.log("handlePolygonDistortionChange: ", poly, poly.path);
        }
      }
    }
  };
  const [lineStyle, setLineStyle] = useState('solid');

  const handleChangeLine = (event) => {
    setLineStyle(event.target.value);
    updateLineStyle(event.target.value);
  };

  const updateLineStyle = (style) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === 'line') {
      activeObject.set({ strokeDashArray: getDashArray(style) });
      canvas.renderAll();
    } else if (activeObject && activeObject.type === 'path') {
      activeObject.set({ strokeDashArray: getDashArray(style) });
      canvas.renderAll();
    }
  };

  const getDashArray = (style) => {
    switch (style) {
      case 'dotted':
        return [1, 2];
      case 'dashed':
        return [10, 5];
      case 'dashed-dotted':
        return [10, 5, 1, 5];
      case 'solid':
      default:
        return [];
    }
  };

  const [containerScrollDimensions, setContainerScrollDimensions] = useState({
    height: 0, width: 0
  });
  useEffect(() => {
    if (document.getElementById("canvas-container")) {
      setContainerScrollDimensions({
        height: document.getElementById("canvas-container").scrollHeight,
        width: document.getElementById("canvas-container").scrollWidth
      })
    }
  }, [document.getElementById("canvas-container")])
  const [lockGuides, setLockGuides] = useState([]);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl1(null);
  };

  const handleToggleLock = (option) => {
    if (lockGuides.includes(option)) {
      const newLockGuides = lockGuides.filter((item) => item !== option);
      setLockGuides(newLockGuides);

    } else {
      if (option === 'remove') {
        horizonalGuidesRef.current.setState({
          guides: []
        });
        verticalGuidesRef.current.setState({
          guides: []
        });
        return;
      }
      setLockGuides([...lockGuides, option]);
    }
    console.log('handleToggleLock: ', option, lockGuides)
    // handleMenuClose();
  };

  const handleUnlockAll = () => {
    setLockGuides([]);
    handleMenuClose();
  };
  const handleGuideClick = (guide) => {
    console.log('Guide clicked:', guide);
  };
  const handleAddChart = async (chart, chartData) => {
    let newShape = null;
    console.log('handleAddChart: ', chart, chartData);

    switch (chart) {
      case 'barVertical':
        newShape = new fabric.Chart({
          width: 300,
          height: 300,
          class: 'chart',
          chart: {
            type: 'bar',
            data: {
              labels: chartData.labels,
              datasets: [
                {
                  // label: '# of Votes',
                  data: chartData.data,
                  backgroundColor: chartData.backgroundColor,
                },
              ],

            },

          },
        });
        console.log('handleAddChart: barVertical', newShape, chartData);
        break;

      case 'barHorizontal':
        newShape = new fabric.Chart({
          width: 200,
          height: 200,
          class: 'chart',
          chart: {
            type: 'bar',
            data: {
              labels: chartData.labels,
              datasets: [
                {
                  label: '# of Votes',
                  data: [Math.random(), Math.random(), Math.random()],
                  backgroundColor: chartData.backgroundColor,
                },
              ],
            },
            options: {
              indexAxis: 'y',
              scales: {
                x: {
                  beginAtZero: true,
                },
              },
            },
          },
        });
        console.log('handleAddChart: barVertical', newShape, chartData);

        break;

      case 'line':
        newShape = new fabric.Chart({
          width: 200,
          height: 200,
          class: 'chart',
          chart: {
            type: 'line',
            data: {
              labels: chartData.labels,
              datasets: chartData.datasets.map(dataset => ({
                ...dataset,
                backgroundColor: Array.isArray(dataset.backgroundColor)
                  ? dataset.backgroundColor
                  : [dataset.backgroundColor],
              })),
            },
          },
        });
        break;

      case 'pie':
        newShape = new fabric.Chart({
          width: 200,
          height: 200,
          class: 'chart',
          chart: {
            type: 'pie',
            data: {
              labels: chartData.labels,
              datasets: [
                {
                  data: chartData.data,
                  backgroundColor: chartData.backgroundColor,
                },
              ],
            },
          },
        });
        break;

      case 'doughnut':
        newShape = new fabric.Chart({
          width: 200,
          height: 200,
          class: 'chart',
          chart: {
            type: "doughnut",
            data: {
              labels: chartData.labels,
              datasets: [
                {
                  data: chartData.data,
                  backgroundColor: chartData.backgroundColor,
                },
              ],
            },
          },
        });
        break;
        case 'scatter':
          newShape = new fabric.Chart({
            width: 400, // Chart width
            height: 300, // Chart height
            class: 'chart',
            chart: {
              type: 'scatter',
              data: {
                datasets: chartData.datasets.map(dataset => ({
                  label: dataset.label || 'Dataset', // Dataset label
                  data: dataset.data || [], // Scatter data points
                  backgroundColor: Array.isArray(dataset.backgroundColor)
                    ? dataset.backgroundColor
                    : dataset.backgroundColor || 'rgba(75, 192, 192, 0.6)', // Default color
                  borderColor: dataset.borderColor || 'rgba(75, 192, 192, 1)', // Border color
                  borderWidth: 1, // Border width
                })),
              },
              options: {
                responsive: false,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: true,
                    labels: {
                      color: '#000000', // Black text for legend
                      font: {
                        size: 14,
                        weight: 'bold',
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    type: 'linear',
                    position: 'bottom',
                    title: {
                      display: true,
                      text: 'X-Axis Label',
                      color: '#000000', // Black text
                      font: {
                        size: 16,
                        weight: 'bold',
                      },
                    },
                    ticks: {
                      color: '#000000', // Black text for ticks
                      font: {
                        size: 14,
                        weight: 'bold',
                      },
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: 'Y-Axis Label',
                      color: '#000000', // Black text
                      font: {
                        size: 16,
                        weight: 'bold',
                      },
                    },
                    ticks: {
                      color: '#000000', // Black text for ticks
                      font: {
                        size: 14,
                        weight: 'bold',
                      },
                    },
                  },
                },
              },
            },
          });
          break;
        
        

      case 'venn':

            // Function to process chartData and add intersection data
            const processChartData = (data) => {
              const processedData = [...data]; // Clone the original data

              // Find intersection of all combinations
              for (let i = 0; i < data.length; i++) {
                for (let j = i + 1; j < data.length; j++) {
                  const set1 = data[i];
                  const set2 = data[j];

                  // Create intersection data
                  const intersection = {
                    sets: [...set1.sets, ...set2.sets], // Combine set names
                    size: Math.min(set1.size, set2.size), // Use minimum size for intersection
                    color: "#FFD700", // Golden color for intersection
                  };

                  // Add to processedData
                  processedData.push(intersection);
                }
              }

              return processedData;
            };

            // Function to apply colors based on sets
            const applyColorsWithD3 = (container, sets) => {
              d3.select(container).selectAll('.venn-circle path').each(function (d) {
                const set = sets.find(s => s.sets.join(',') === d.sets.join(','));
                if (set) {
                  d3.select(this).style('fill', set.color || '#000000'); // Apply custom color or default to black
                }
              });
            };

            // Function to draw the Venn Diagram
            const drawVennDiagram = (chartData, canvas) => {
              const vennSvg = VennDiagram().width(300).height(300); // Create Venn diagram
              const vennContainer = document.createElement('div');  // Temporary container for Venn diagram

              // Process chartData to add intersection data
              const updatedChartData = processChartData(chartData);
              console.log("Updated Chart Data:", updatedChartData);

              // Draw Venn diagram with the updated chartData
              d3.select(vennContainer).datum(updatedChartData).call(vennSvg); 

              // Apply colors based on the updated chartData sets
              applyColorsWithD3(vennContainer, updatedChartData);

          
              const vennSvgString = new XMLSerializer().serializeToString(vennContainer.querySelector('svg'));

             
              fabric.loadSVGFromString(vennSvgString, (objects, options) => {
                if (!objects || objects.length === 0) {
                  console.error('No objects were parsed from the SVG string.');
                  return;
                }

                try {
             
                  const svgObjects = fabric.util.groupSVGElements(objects, options);
                  svgObjects.set({
                    left: 100,
                    top: 100,
                    class: 'plot',
                    subClass: 'venn',
                    originX: 'center',
                    originY: 'center',
                  });

                
                  canvas.add(svgObjects).renderAll();
                } catch (error) {
                  console.error('Error adding SVG objects to the canvas:', error);
                }
              });
            };
  
            drawVennDiagram(chartData, canvas);

        return;


        case 'boxPlot':
          const margin = { top: 20, right: 20, bottom: 50, left: 50 };
          const width = 400 - margin.left - margin.right; // Chart width
          const height = 300 - margin.top - margin.bottom; // Chart height
        
          // Create SVG container using D3.js
          const boxPlotSvg = d3.select(document.createElement('div'))
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
            .attr('preserveAspectRatio', 'xMinYMin meet')
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);
        
          // Define scales for x and y axes
          const xScale = d3.scaleBand().range([0, width]).padding(0.2);
          const yScale = d3.scaleLinear().range([height, 0]);
        
          // Example chart data structure
          const boxes = chartData;
        
          // Set domains for scales based on data
          xScale.domain(boxes.map((_, i) => i));
          yScale.domain([
            0,
            d3.max(boxes.flatMap(box => box.quartiles.concat(box.outliers))),
          ]);
        
          // Create groups for each box
          const boxGroups = boxPlotSvg.selectAll('.box')
            .data(boxes)
            .enter()
            .append('g')
            .attr('class', 'box')
            .attr('transform', (d, i) => `translate(${xScale(i)}, 0)`);
        
          // Draw each box and its elements
          boxGroups.each(function (d) {
            const boxGroup = d3.select(this);
            const boxWidth = xScale.bandwidth();
        
            // Draw the box
            boxGroup.append('rect')
              .attr('x', boxWidth / 4)
              .attr('y', yScale(d3.max(d.quartiles))) // Upper bound (Q3)
              .attr('width', boxWidth / 2) // Box width
              .attr('height', yScale(d3.min(d.quartiles)) - yScale(d3.max(d.quartiles))) // Height from Q3 to Q1
              .attr('stroke', 'black')
              .attr('fill', d.color || 'lightblue');
        
            // Draw the median line
            boxGroup.append('line')
              .attr('x1', boxWidth / 4)
              .attr('x2', 3 * boxWidth / 4)
              .attr('y1', yScale(d.quartiles[1])) // Median
              .attr('y2', yScale(d.quartiles[1]))
              .attr('stroke', 'black');
        
            // Draw outliers as red dots
            boxGroup.selectAll('.outlier')
              .data(d.outliers)
              .enter()
              .append('circle')
              .attr('class', 'outlier')
              .attr('cx', boxWidth / 2)
              .attr('cy', d => yScale(d))
              .attr('r', 3)
              .attr('fill', 'red');
          });
        
          // Add x-axis
          boxPlotSvg.append('g')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom(xScale).tickFormat((d, i) => `Box ${i + 1}`))
            .selectAll('text')
            .style('text-anchor', 'end')
            .attr('dx', '-0.8em')
            .attr('dy', '0.15em')
            .attr('transform', 'rotate(-45)'); // Rotate x-axis labels for better readability
        
          // Add y-axis
          boxPlotSvg.append('g')
            .call(d3.axisLeft(yScale));
        
          // Serialize SVG into a string for Fabric.js
          const svgString = new XMLSerializer().serializeToString(boxPlotSvg.node().ownerSVGElement);
        
          // Load SVG into Fabric.js
          fabric.loadSVGFromString(svgString, (objects, options) => {
            if (!objects || objects.length === 0) {
              console.error('No objects were parsed from the SVG string.');
              return;
            }
        
            try {
              // Group SVG elements into a Fabric.js object
              const svgObjects = fabric.util.groupSVGElements(objects, options);
              svgObjects.set({
                left: 100,
                top: 100,
                originX: 'center',
                originY: 'center',
              });
        
              // Add SVG object to the Fabric.js canvas
              canvas.add(svgObjects).renderAll();
            } catch (error) {
              console.error('Error adding SVG objects to the canvas:', error);
            }
          });
      
      
          return;
        
      default:
        return;
    }

    canvas.add(newShape);
    // canvas.renderAll();  
  };

  const toggleOrientation = (orientation) => {
    if (canvas) {
      let newWidth, newHeight;

      if (orientation === "Portrait") {
        newWidth = canvas.getHeight();
        newHeight = canvas.getWidth();
        console.log('toggleOrientation : Portrait', newWidth, newHeight);

      } else if (orientation === "Landscape") {
        newWidth = canvas.getWidth();
        newHeight = canvas.getHeight();
      }

      setSize({ width: newWidth, height: newHeight });

      canvas.setWidth(newWidth);
      canvas.setHeight(newHeight);

      // Optionally update zoom or viewport transform here
      // canvas.setZoom(zoom);
      // canvas.viewportTransform = [1, 0, 0, 1, 0, 0]; // Reset viewport transform

      canvas.renderAll(); // Ensure canvas is redrawn

      console.log('toggleOrientation', orientation, size, canvas.getHeight(), canvas.getWidth());
    }
  };



  const handlePresentation = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        setIsFullscreen(true);
      }).catch((err) => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      document.exitFullscreen().then(() => {
        setIsFullscreen(false);
      });
    }
  };
  useEffect(() => {
    const canvasContainer = document.querySelector('#canvas-container');
    console.log('canvasContainer: canvasContainer', canvasContainer)
    if (isFullscreen === true) {
      canvasContainer.style.marginTop = '0px';
      canvasContainer.style.alignContent = 'center';

      canvasContainer.style.height = `${window.innerHeight}px`;
      canvasContainer.style.backgroundColor = `black`;

      canvasContainer.style.width = `100%`;
      // canvas.setHeight(window.innerHeight);
      // canvas.setWidth(size.width);
      canvasContainer.style.paddingTop = '0px';


    } else {
      if (canvas) {
        updateGuides(zoom);
        adjustCanvasContainerDimensions();

        // canvas.setHeight(size.height*zoom);
        // canvas.setWidth(size.width*zoom);
        // canvas.setZoom(zoom); 
        // canvasContainer.style.marginTop = '0px';   
        canvasContainer.style.alignContent = '';
        canvasContainer.style.backgroundColor = `transparent`;
        canvasContainer.style.alignContent = 'bottom';


      }
    }
  }, [isFullscreen]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const [colors, setColors] = useState([]);
  const [datasetData, setDatasetData] = useState([]); // State to manage datasets' data
  const [labelData, setLabelData] = useState([]);

  const handleColorChange = (color, index) => {
    console.log('handleColorChange:', color, index);

    const newColors = [...colors];
    newColors[index] = color;
    setColors(newColors);

    const updatedChartData = {
      ...activeObj[0].chart,
      data: {
        ...activeObj[0].chart.data,
        datasets: [
          {
            ...activeObj[0].chart.data.datasets[0],
            backgroundColor: newColors,
          },
        ],
      },
    };

    console.log('handleColorChange: updatedChartData:', updatedChartData, newColors);

    handleUpdateChart(updatedChartData);
  };

  const handleDatasetDataChange = (newData, index) => {
    console.log('handleDatasetDataChange:', newData, index);

    const newDatasetData = [...datasetData];
    newDatasetData[index] = newData;
    setDatasetData(newDatasetData);

    const updatedChartData = {
      ...activeObj[0].chart,
      data: {
        ...activeObj[0].chart.data,
        datasets: [
          {
            ...activeObj[0].chart.data.datasets[0],
            data: newDatasetData,
          },
        ],
      },
    };

    console.log('handleDatasetDataChange: updatedChartData:', updatedChartData, newDatasetData);

    handleUpdateChart(updatedChartData);
  };


  const handleLabelChange = (newLabel, index) => {
    console.log('handleLabelChange:', newLabel, index);
  
    // Update the label data array
    const updatedLabelData = [...labelData];
    updatedLabelData[index] = {
      ...updatedLabelData[index],
      label: newLabel,
    };
    setLabelData(updatedLabelData);
  
    let updatedChartData = {};
  
    // Scatter plot-specific logic
    if (activeObj[0]?.chart?.type === "scatter") {

      // Update the specific dataset's label
        const updatedDatasets = activeObj[0].chart.data.datasets.map((dataset, datasetIndex) =>
        datasetIndex === index
          ? {
              ...dataset,
              label: newLabel, // Update the label for the dataset at the given index
            }
          : dataset // Keep other datasets unchanged
      );
  
      updatedChartData = {
        ...activeObj[0].chart,
        data: {
          ...activeObj[0].chart.data,

          datasets: updatedDatasets,
        },
      };
    } else {
      // Non-scatter chart logic
      const updatedLabels = updatedLabelData.map((item) => item.label);
  
      updatedChartData = {
        ...activeObj[0].chart,
        data: {
          ...activeObj[0].chart.data,
          datasets: [
            {
              ...activeObj[0].chart.data.datasets[0],
            },
          ],
          labels: updatedLabels,
        },
      };
    }
  
    // Update the chart
    handleUpdateChart(updatedChartData);
    console.log('updateChart:', updatedChartData);
  };


// Handles Dataset Label Change
const handleDatasetLabelChange = (newLabel, datasetIndex) => {
  // Safeguard for chart existence
  if (!activeObj[0]?.chart) {
    console.error("Chart data is missing!");
    return;
  }

  // Update the datasets array
  const updatedDatasets = activeObj[0]?.chart?.data?.datasets.map(
    (dataset, index) =>
      index === datasetIndex
        ? { ...dataset, label: newLabel } // Update the label for the specified dataset
        : dataset
  );

  console.log("Updated dataset", updatedDatasets);

  // Update the entire chart object
  const updatedChartData = {
    ...activeObj[0]?.chart,
    data: {
      ...activeObj[0].chart.data,
      datasets: updatedDatasets,      
    },
  };

  // Update the active object with the new chart data
  handleUpdateChart(updatedChartData);

  console.log(`Dataset ${datasetIndex + 1} label updated to: ${newLabel}`);
};


  
  const handleBorderColorChange = (newColor) => {
    const updatedChartData = {
      ...activeObj[0].chart,
      data: {
        ...activeObj[0].chart.data,
        datasets: [
          {
            ...activeObj[0].chart.data.datasets[0],
            borderColor: newColor,
          },
        ],
      },
    };

    handleUpdateChart(updatedChartData);
  };


  const handleUpdateChart = (updatedChartData) => {
    activeObj[0].set({
      chart: updatedChartData,
    });
    if(updatedChartData.type == 'scatter'){
      activeObj[0].dirty = true;
      activeObj[0].setCoords();
    }
    canvas.renderAll();
  };
  const [activeNodes, setActiveNodes] = useState(null);
  const [handlesConnected, setHandlesConnected] = useState(false);
  useEffect(() => {
    console.log('handleNodeSelect: canvas useEffect:', pathSelect);
    if (canvas)
      togglePathSelect(canvas, pathSelect, setActiveNodes, activeNodes, handlesConnected);
  }, [pathSelect, handlesConnected])

  useEffect(() => { console.log('handleNodeSelect: activeNodes:', activeNodes); }, [activeNodes])
  const calculateMidpoint = (point1, point2) => {
    return {
      x: (point1.x + point2.x) / 2,
      y: (point1.y + point2.y) / 2
    };
  };

  const addNodeAtMidpoint = (path = canvas?.getActiveObject()) => {
    console.log('addNodeAtMidpoint: ', path, activeNodes);

    if (activeNodes?.length !== 2) {
      alert('Please select exactly two points.');
      return;
    }

    const pointIndex1 = activeNodes[0].pointIndex;
    const pointIndex2 = activeNodes[1].pointIndex;

    const point1 = activeNodes[0].cmdIndex ? path.controls[`p${pointIndex1}-${activeNodes[0].cmdIndex}`] : path.controls[`p${pointIndex1}`];
    const point2 = activeNodes[1].cmdIndex ? path.controls[`p${pointIndex2}-${activeNodes[1].cmdIndex}`] : path.controls[`p${pointIndex2}`];

    console.log('addNodeAtMidpoint: point1,point2', point1, point2);

    if (!point1 || !point2) {
      console.warn('Selected points are invalid.');
      return;
    }

    const midpoint = calculateMidpoint(
      { x: point1.left, y: point1.top },
      { x: point2.left, y: point2.top }
    );
    console.log('addNodeAtMidpoint: midpoint', midpoint);

    const controlPoints = Object.keys(path.controls).map(key => path.controls[key]);
    const lastPointIndex = controlPoints.length - 1;
    const isFirstAndLast = (pointIndex1 === 0 && pointIndex2 === lastPointIndex) ||
      (pointIndex1 === lastPointIndex && pointIndex2 === 0);

    let newPointIndex;

    if (isFirstAndLast) {
      // Insert the new point between the last and first points
      // const zIndex = pathCommands.findIndex(cmd => cmd[0] === 'Z');

      newPointIndex = controlPoints.length;
    } else {
      newPointIndex = Math.min(pointIndex1, pointIndex2) + 1;
    }

    path.path.splice(newPointIndex, 0, ['L', midpoint.x, midpoint.y]);
    console.log('addNodeAtMidpoint: newPointIndex', newPointIndex, path.path);

    togglePathSelect(canvas, pathSelect, setActiveNodes, activeNodes);
    path.dirty = true;
    canvas.requestRenderAll();
  };

  const removeNodes = (path = canvas?.getActiveObject()) => {
    console.log('removeNodes: ', path, activeNodes);

    if (!activeNodes || activeNodes?.length === 0) {
      alert('Please select at least one point to remove.');
      return;
    }

    const pointIndices = activeNodes.map(node => node.pointIndex).sort((a, b) => b - a);
    console.log('removeNodes: pointIndices', pointIndices);

    pointIndices.forEach((pointIndex) => {
      if (path.path[pointIndex]) {
        path.path.splice(pointIndex, 1);

        delete path.controls[`p${pointIndex}`];
      }
    });

    Object.keys(path.controls).forEach((key, index) => {
      const control = path.controls[key];
      const newIndex = `p${index}`;

      if (key !== newIndex) {
        path.controls[newIndex] = control;
        delete path.controls[key];
        control.pointIndex = index;
      }
    });

    console.log('removeNodes: updated path', path.path);

    setActiveNodes([]);
    togglePathSelect(canvas, path, setActiveNodes, activeNodes);

    path.dirty = true;
    canvas.requestRenderAll();
  };
  // Function to convert the selected object to a path
  function convertObjectToPath() {
    const activeObject = canvas.getActiveObject(); // Get the selected object
    console.log("ACTIVE object -----", activeObject)
    if (!activeObject) {
      alert('Please select an object to convert to a path.');
      return;
    }
    let pathData;
    // Convert different types of objects to a path
    if (activeObject.type === 'circle') {
      // Convert a circle to an SVG path
      const radius = activeObject.radius * activeObject.scaleX; // account for scaling
      const left = activeObject.left;
      const top = activeObject.top;
      pathData = `M ${left + radius},${top} 
                  A ${radius},${radius} 0 1,0 ${left - radius},${top} 
                  A ${radius},${radius} 0 1,0 ${left + radius},${top} Z`;
    } else if (activeObject.type === 'ellipse') {
      // Convert an ellipse to an SVG path
      const rx = activeObject.rx * activeObject.scaleX;
      const ry = activeObject.ry * activeObject.scaleY;
      const left = activeObject.left;
      const top = activeObject.top;
      pathData = `M ${left + rx},${top} 
                  A ${rx},${ry} 0 1,0 ${left - rx},${top} 
                  A ${rx},${ry} 0 1,0 ${left + rx},${top} Z`;
    } else if (activeObject.type === 'polygon') {
      // Convert a polygon to an SVG path
      const points = activeObject.points.map(
        (point, index) => `${index === 0 ? 'M' : 'L'} ${point.x * activeObject.scaleX + activeObject.left} ${point.y * activeObject.scaleY + activeObject.top}`
      ).join(' ') + ' Z';
      pathData = points;
    } else if (activeObject.type === 'rect') {
      // Convert a rectangle to an SVG path
      const left = activeObject.left;
      const top = activeObject.top;
      const width = activeObject.width * activeObject.scaleX;
      const height = activeObject.height * activeObject.scaleY;
      pathData = `M ${left},${top} 
                  L ${left + width},${top} 
                  L ${left + width},${top + height} 
                  L ${left},${top + height} 
                  Z`;
    } else if (activeObject.type === 'line') {
      // Convert a line to an SVG path
      const { x1, y1, x2, y2 } = activeObject;
      const scaledX1 = x1 * activeObject.scaleX + activeObject.left;
      const scaledY1 = y1 * activeObject.scaleY + activeObject.top;
      const scaledX2 = x2 * activeObject.scaleX + activeObject.left;
      const scaledY2 = y2 * activeObject.scaleY + activeObject.top;
      pathData = `M ${scaledX1},${scaledY1} L ${scaledX2},${scaledY2}`;
    } else {
      alert('This type of object is not supported for conversion.');
      return;
    }

    // Create a new path using the generated path data
    const newPath = new fabric.Path(pathData, {
      fill: activeObject.fill,
      stroke: activeObject.stroke,
      strokeWidth: activeObject.strokeWidth,
      left: activeObject.left,
      top: activeObject.top,
      angle: activeObject.angle
    });

    // Replace the original object with the new path
    canvas.remove(activeObject);
    canvas.add(newPath);
    canvas.setActiveObject(newPath);
    canvas.renderAll();
    alert('Object successfully converted to a path.');
  }

  function circularizeNode(path = canvas?.getActiveObject(), activeNodeIndex = activeNodes?.[0]?.pointIndex, radius = 15) {
    if (!activeNodeIndex) {
      alert("Please select a node to Smoothen");
      return
    }
    if (!path || activeNodeIndex === undefined || activeNodeIndex < 0 || activeNodeIndex >= path.path.length) {
      console.warn('Invalid path or node index.', path, activeNodeIndex, path.path);
      return;
    }


    function pointForCommand(cmd) {
      return {
        x: parseFloat(cmd[cmd.length - 2]),
        y: parseFloat(cmd[cmd.length - 1]),
      };
    }
    console.log('circularizeNode: initial path:', path?.path);

    // Determine prevCmd, curCmd, and nextCmd with wrap-around logic, ignoring 'Z' if it's the last command
    const isLastCmdZ = ['Z', 'z'].includes(path.path[path.path.length - 1][0]);

    const prevCmdIndex = activeNodeIndex === 0
      ? (isLastCmdZ ? path.path.length - 2 : path.path.length - 2) // Skip 'Z' if present at the end
      : activeNodeIndex - 1;

    const prevCmd = path.path[prevCmdIndex];
    const curCmd = path.path[activeNodeIndex];

    const prevPoint = pointForCommand(prevCmd);
    const curPoint = pointForCommand(curCmd);
    console.log('circularizeNode: prevPoint, curPoint, nextPoint:', prevPoint, curPoint);

    const startControl = {
      x: curPoint.x,
      y: curPoint.y,
    };

    // Replace the current line (L) command with two cubic bezier (C) commands
    const curveCmd = ['C', prevPoint.x, prevPoint.y, startControl.x - 25, startControl.y - 25, startControl.x, startControl.y];

    // Handle wrap-around in splice
    if (activeNodeIndex === 0) {
      // Wrap-around: activeNodeIndex is the first element, so replace first and last commands
      path.path.splice(0, 1, curveCmd); // Replace the first command
      // path.path.splice(nextCmdIndex, 1, curveCmd); // Replace the wrapped-around command
    } else if (activeNodeIndex === path.path.length - 2) {
      console.log('circularizeNode: last cmmd: ', activeNodeIndex);

      path.path.splice(activeNodeIndex, 1, curveCmd); // Replace the last command
      // path.path.splice(0, 1, curveCmd2); // Replace the wrapped-around command at the start
    } else {
      // Regular case: replace current and next commands at activeNodeIndex and nextCmdIndex
      path.path.splice(activeNodeIndex, 1, curveCmd);
    }

    // Remove 'Z' if necessary
    if ((activeNodeIndex === 0 || activeNodeIndex === path.path.length - 1) && isLastCmdZ) {
      path.path.pop(); // Remove the 'Z' command
      console.log('circularizeNode: Removed final Z command from path');
    }

    // Ensure the path recalculates bounding box
    togglePathSelect(canvas, path, setActiveNodes, activeNodes);
    recalculateBoundingBox(path, canvas);
    path.dirty = true;
    // canvas.setActiveObject(path);
    canvas.requestRenderAll();
    // recalculateBoundingBox(path);

    console.log('circularizeNode: final path with normalized C:', path.path);
  }



  function handlePathRotateCopies() {
    const activeObject = canvas.getActiveObject();
    if (!activeObject) {
      alert("Please select an object");
      return;
    }

    if (!activeObject.rotatedCopies) {
      activeObject.rotatedCopies = [];
    } else {
      activeObject.rotatedCopies.forEach((copy) => canvas.remove(copy));
      activeObject.rotatedCopies = [];
    }

    activeObject.lockMovementX = true;
    activeObject.lockMovementY = true;

    const { numCopies, startingAngle, rotationAngle, gap, originX, originY, method, splitElements,distributeEvenly } = rotationSettings;

    const centerX = originX || (activeObject.left );
    const centerY = originY || (activeObject.top );
    console.log('handlePathRotateCopies: centerX, centerY, originX,originY, activeObject.left, activeObject.top:',centerX, centerY, originX,originY,activeObject.left, activeObject.top)
    let totalAngle = 360;
    
    let angleStep = totalAngle / numCopies;
    if(!distributeEvenly){
      angleStep = rotationAngle;
  }
    const objectsToMerge = [];
    const adjustedStartingAngle = startingAngle % 360; // Ensure it stays within 0-360 range

    for (let i = 0; i < numCopies; i++) {
      const currentAngle = adjustedStartingAngle + i * angleStep;
      const angleInRadians = (currentAngle * Math.PI) / 180;

      const x = centerX + gap * Math.cos(angleInRadians);
      const y = centerY + gap * Math.sin(angleInRadians);
      let copy;

      if (i === 0) {
        copy = activeObject;
        activeObject.set({ left: x, top: y, selectable: true, });
        if(startingAngle !== 0){
          activeObject.set({
            angle: currentAngle
          })
        }
        activeObject.uniqueId = i + 1; 
      } else {
        const serializedObject = activeObject.toObject(); 
        copy = fabric.util.object.clone(activeObject);
        fabric.util.enlivenObjects([serializedObject], (objects) => {
          copy = objects[0];
          copy.set({
            left: x,
            top: y,
            angle: currentAngle,
            selectable: splitElements, // Make selectable only if splitElements is true
          });

          // Apply Kaleidoscope mirroring
          if (method === "Kaleidoscope" && i % 2 === 1) {
            copy.flipX = !copy.flipX; // Toggle mirroring
          }

          copy.uniqueId = i + 1; // Assign unique ID
          canvas.add(copy);
        });
      }
      const fusedCopyPresent = canvas.getObjects().find(obj => {
        return obj.class && obj.class.includes('rotatedCopies');
      });
      
      console.log('handlePathRotateCopies: fusedCopyPresent: ', fusedCopyPresent);
      
      if (fusedCopyPresent) {
        copy.set({
          fill: fusedCopyPresent.fill,
          stroke: fusedCopyPresent.stroke,
        });
        activeObject.set({
          fill: fusedCopyPresent.fill,
          stroke: fusedCopyPresent.stroke,
        });
        canvas.remove(fusedCopyPresent);
      }
      
      if (copy && copy !== activeObject) {
        activeObject.rotatedCopies.push(copy);
        copy.originalObject = fabric.util.object.clone(activeObject); // Link to the original object
      }

      
    }

    // Merge paths if required
    if (method === "Fuse Paths") {
      // const activeSelection = new fabric.ActiveSelection([activeObject, ...activeObject.rotatedCopies], { canvas });
      // canvas.setActiveObject(activeSelection);    
      // setActiveObj([...activeSelection]);
      activeObject.rotatedCopies.forEach((obj) => {
        obj.controlPoint = new fabric.Circle({
          left: obj.left,
          top: obj.top,
          radius: 5,
          fill: 'lightblue',
          stroke: 'darkblue',
          selectable: false,
          evented: false,
          visible: isVisibleCenter,
        });
    
      })
      console.log('handleRotationCopies: fusePathsForLPE: ')
      fusePathsForLPE([activeObject, ...activeObject.rotatedCopies]);
    }
    setRotationSettings((prev)=>({
      ...prev,
      originX: centerX,
      originY: centerY,
      rotationAngle: angleStep
    }))

    // Sync scaling of all copies with the original
    activeObject.on("scaling", () => {
      const scaleX = activeObject.scaleX;
      const scaleY = activeObject.scaleY;
      activeObject.rotatedCopies.forEach((copy) => copy.set({ scaleX, scaleY }));
      canvas.renderAll();
    });
    activeObject.on("modified", () => {
      const activepath = activeObject.path;
      activeObject.rotatedCopies.forEach((copy) => {
        copy.set({
          path: activepath,
          dirty: true
        })
        recalculateBoundingBox(copy, canvas);
      })
      canvas.renderAll();

    })

    activeObject.on("moving", () => {
      activeObject.set({ left: centerX, top: centerY });
      canvas.renderAll();
    });
    canvas.renderAll();
  }

  function applyColorToSelectedCopy(color) {
    console.log("Extra funcation log");
  }
  function applyColorWithLinkStyle(color) {
    const selectedObject = canvas.getActiveObject();
    if (!selectedObject) {
      alert("Please select an object");
      return;
    }

    // Identify the original object
    const originalObject = selectedObject.originalObject || selectedObject;

    // Apply color to the original object
    originalObject.set({fill: selectedColorForRotationCopy,stroke:selectedStrokeForRotationCopy});

    // Apply color to all rotated copies
    if (originalObject.rotatedCopies && rotationSettings.linkStyles) {
      originalObject.rotatedCopies.forEach((copy) => {
        originalObject.set({fill: selectedColorForRotationCopy,stroke:selectedStrokeForRotationCopy});
      });
    }
    // Re-render the canvas to reflect the changes
    canvas.renderAll();
  }

  function applyColorToIndividualCopy(color) {
    const activeObject = canvas.getActiveObject(); // Get selected object

    if (!activeObject) {
      alert("Please select a copy to apply color.");
      return;
    }
    // Apply color only if rotationSettings.splitElements is true
    if (rotationSettings.splitElements) {
      // Directly set the color to the selected object
      activeObject.set({fill: selectedColorForRotationCopy,stroke:selectedStrokeForRotationCopy});

      canvas.renderAll(); // Re-render the canvas to reflect the changes
    }
  }

  function handleBendAlongPath() {
    const activeObject = canvas.getActiveObject();
    if (!activeObject || activeObject.type !== 'path') {
      alert('Please select a path object to Bend it.');
      return;
    }

    canvas.forEachObject((obj) => {
      if (obj.class === "bendPath") {
        canvas.remove(obj);
      }
    });

    const bb = activeObject.getBoundingRect();
    let { width } = bb;
    const { left, top } = { left: activeObject.left, top: activeObject.top };

    const centerX = left - (width / zoom) / 2;
    const startY = top;
    const endX = left + (width / zoom) / 2;

    console.log('bendPath: boundingBox:', bb, activeObject);
    console.log('bendPath: startY, centerX, endX:', startY, centerX, endX);

    const bendPathData = [
      ['M', centerX, startY],
      ['C', centerX * 1.5, startY, endX / 1.5, startY, endX, startY]
    ];
    console.log('bendPath: bendPathData:', bendPathData);

    const bendPath = new fabric.Path(bendPathData, {
      stroke: 'blue',
      strokeWidth: 1,
      fill: '',
      class: 'bendPath',
      originX: 'center',
      originY: 'center',
      // lockMovementX: true,
      // lockMovementY: true,
      selectable: true,
      evented: true,
    });

    if(!activeObject.ogPath){
      activeObject.set({ ogPath: activeObject.path });
    }
    if(!bendPath.ogPath){
      bendPath.set({ ogPath: bendPath.path });
    }
    let skeletonSamplesCount = 170;
    const matrix = bendPath.calcTransformMatrix();
    const pathOffset = bendPath.pathOffset;

    const ogSkeletonSamples = generateSkeletonSamples(bendPath.ogPath, skeletonSamplesCount, matrix, pathOffset);

    // Add event listeners for the bendPath
    bendPath.on('modified', () => {
      console.log('object:modified: applying......', activeObject, bendPath);
      applyBendToPath(activeObject, bendPath, canvas,ogSkeletonSamples);
    });

    const events = ['modified', 'moving', 'scaling', 'rotating'];
    events.forEach((event) =>
      activeObject.on(event, () => {
        bendPath.set({
          left: activeObject.left,
          top: activeObject.top,
          scaleX: activeObject.scaleX,
          scaleY: activeObject.scaleY,
          angle: activeObject.angle,
        });
      })
    );
    // drawNormalsForPath(ogSkeletonSamples, canvas);

    canvas.add(bendPath);

    canvas.setActiveObject(bendPath);
    canvas.bringToFront(bendPath);

    togglePathSelect(canvas, bendPath, setActiveNodes, activeNodes);

  }



  function applyBendToPath(patternPath, skeletonPath, canvas,ogSkeletonSamples) {

    console.log("applyBend: skeletonPath.path: patternPath: ", skeletonPath.path, patternPath);

    const skeletonLength = calculatePathLength(skeletonPath.path, skeletonPath.calcTransformMatrix(), skeletonPath);

    const matrix = skeletonPath.calcTransformMatrix();
    const pathOffset = skeletonPath.pathOffset;

    const minSamples = 100; // Minimum number of samples
    const maxSamples = 200; // Maximum number of samples
    const samplesPerUnit = 2; // Number of samples per unit length (adjust based on performance needs)
    let skeletonSamplesCount = 170;
    // Dynamically calculate the number of samples
    // let skeletonSamplesCount = Math.max(minSamples, Math.min(maxSamples, skeletonLength * samplesPerUnit));
    const skeletonSamples = generateSkeletonSamples(skeletonPath.path, skeletonSamplesCount, matrix, pathOffset);

    console.log("applyBend: skeletonSamples, ogSkeletonSamples: ", skeletonSamples,ogSkeletonSamples);

    const patternWidth = calculatePatternWidth(patternPath);
    // const skeletonHeight = calculatePatternHeight(skeletonPath);
    const scaleX = skeletonLength / patternWidth;

    console.log("applyBend: patternPath: skeletonLength, patternWidth, scaleX:", skeletonLength, patternWidth, scaleX);

    normalizePatternPath(patternPath, scaleX);
    const transformedPatternData = transformPatternPath(patternPath, skeletonSamples, skeletonLength, skeletonSamplesCount,ogSkeletonSamples);

    patternPath.set({ path: transformedPatternData });
    const transformedSvg = fabricPathToSVG(patternPath);
    const smoothedPath = smoothPath(transformedSvg);
    const smoothedFabPath = svgPathToFabric(smoothedPath)
    console.log("applyBend: smoothPath: ", smoothedPath, smoothedFabPath);
    patternPath.set({ path: smoothedFabPath.path, dirty: true });


    recalculateBoundingBox(patternPath, canvas);

    patternPath.set({
      // scaleX:scaleX,
      left: skeletonPath.left,
      top: skeletonPath.top,
      // scaleY:1
    })
    console.log("applyBend: final patternPath: ", patternPath);

    patternPath.setCoords();
    canvas.renderAll();
  }

  function normalizePatternPath(patternPath, scaleX) {
    console.log("applyBend: normalizePatternPath", patternPath, scaleX);

    patternPath.ogPath.forEach(segment => {


      segment.forEach((value, index) => {
        if (index > 0 && index % 2 === 1) { // Scale x-coordinates
          segment[index] *= scaleX;
          console.log("applyBend: normalizePatternPath: in forEach", segment);
        }
      });
    });
  }

  function transformPoint(coords, matrix, offset = { x: 0, y: 0 }) {
    if (!coords || coords.length < 2) return [undefined, undefined];
    const [x, y] = coords;
    const point = new fabric.Point(x - offset.x, y - offset.y);
    const transformed = fabric.util.transformPoint(point, matrix);
    return { x: transformed.x, y: transformed.y };
  }
  function generateSkeletonSamples(skeletonPath, minSampleCount, matrix, pathOffset = { x: 0, y: 0 }) {
    const skeletonSegments = [];
    let cumulativeLength = 0;

    // Transform the skeleton path
    const transformedPath = skeletonPath.map((segment) => {
        if (segment[0] === 'M' || segment[0] === 'L') {
            const [command, x, y] = segment;
            const transformedPoint = transformPoint([x, y], matrix, pathOffset);
            return [command, transformedPoint.x, transformedPoint.y];
        } else if (segment[0] === 'C') {
            const [command, cx1, cy1, cx2, cy2, x, y] = segment;
            const transformedControl1 = transformPoint([cx1, cy1], matrix, pathOffset);
            const transformedControl2 = transformPoint([cx2, cy2], matrix, pathOffset);
            const transformedEnd = transformPoint([x, y], matrix, pathOffset);
            return [
                command,
                transformedControl1.x, transformedControl1.y,
                transformedControl2.x, transformedControl2.y,
                transformedEnd.x, transformedEnd.y
            ];
        }
        return segment; // Commands like Z (close path) remain unchanged
    });

    // Create skeleton segments
    for (let i = 1; i < transformedPath.length; i++) {
        const prevSegment = transformedPath[i - 1];
        const currSegment = transformedPath[i];

        if (currSegment[0] === 'C') {
            const bezierCurve = new Bezier(
                prevSegment[prevSegment.length - 2],
                prevSegment[prevSegment.length - 1],
                currSegment[1],
                currSegment[2],
                currSegment[3],
                currSegment[4],
                currSegment[5],
                currSegment[6]
            );
            skeletonSegments.push({ type: 'C', curve: bezierCurve });
        } else if (currSegment[0] === 'L') {
            skeletonSegments.push({
                type: 'L',
                line: {
                    start: { x: prevSegment[prevSegment.length - 2], y: prevSegment[prevSegment.length - 1] },
                    end: { x: currSegment[1], y: currSegment[2] },
                },
            });
        }
    }

    const samples = [];
    skeletonSegments.forEach((segment, index) => {
        if (segment.type === 'C') {
            const curve = segment.curve;
            const adaptiveSampleCount = getAdaptiveSampleCountForCurve(curve, minSampleCount);

            for (let i = 0; i <= adaptiveSampleCount; i++) {
                const t = i / adaptiveSampleCount;
                const point = curve.get(t);
                const normal = smoothNormal(curve, t, skeletonSegments, index);

                const nextPoint = curve.get((i + 1) / adaptiveSampleCount);
                const segmentLength = nextPoint ? Bezier.getUtils().dist(point, nextPoint) : 0;

                cumulativeLength += segmentLength;
                samples.push({ point, normal, t, lengthAtT: cumulativeLength, type: 'C' });
            }

            // Add additional samples if overlap is detected
            refineOverlappingSamples(samples, adaptiveSampleCount, curve, segment, index);
        } else if (segment.type === 'L') {
            const { start, end } = segment.line;
            const adaptiveSampleCount = getAdaptiveSampleCountForLine(segment, skeletonSegments, index, minSampleCount);
            const segmentLength = Bezier.getUtils().dist(start, end);

            for (let i = 0; i <= adaptiveSampleCount; i++) {
                const t = i / adaptiveSampleCount;
                const x = start.x + t * (end.x - start.x);
                const y = start.y + t * (end.y - start.y);
                const normal = smoothNormalLine({ x, y }, segment, skeletonSegments, index);

                cumulativeLength += segmentLength / adaptiveSampleCount;
                samples.push({ point: { x, y }, normal, t, lengthAtT: cumulativeLength, type: 'L' });
            }
        }
    });

    return samples;
}

// Refinement function to handle overlapping samples
function refineOverlappingSamples(samples, sampleCount, curve, segment, index) {
    const overlapThreshold = 1; // Minimum distance threshold to consider samples as overlapping
    const refinedSamples = [];

    for (let i = 0; i < samples.length - 1; i++) {
        const currentSample = samples[i];
        const nextSample = samples[i + 1];

        const distance = Bezier.getUtils().dist(currentSample.point, nextSample.point);
        if (distance < overlapThreshold) {
            // Add additional samples between the overlapping points
            const midT = (currentSample.t + nextSample.t) / 2;
            const midPoint = curve.get(midT);
            const midNormal = smoothNormal(curve, midT, segment, index);

            refinedSamples.push({ point: midPoint, normal: midNormal, t: midT, type: 'C' });
        }
    }

    // Merge refined samples back into the main array
    samples.push(...refinedSamples);
    samples.sort((a, b) => a.t - b.t); // Ensure samples remain ordered by t
}

// Helper functions remain unchanged
// ... (reuse `getAdaptiveSampleCountForCurve`, `getAdaptiveSampleCountForLine`, `getMaxCurvature`, `smoothNormal`, etc.)

  

  function getAdaptiveSampleCountForCurve(curve, minSampleCount) {
    const maxCurvature = getMaxCurvature(curve);
    const curvatureMultiplier = Math.min(maxCurvature, 5); // Limit curvature influence
    return Math.ceil(minSampleCount * (1 + curvatureMultiplier));
  }
  

  function getAdaptiveSampleCountForLine(segment, segments, index, minSampleCount) {
    const angleChange = getLineAngleChange(segment, segments, index);
    const angleMultiplier = Math.abs(angleChange) / Math.PI; // Normalize to [0, 1]
    return Math.ceil(minSampleCount * (1 + angleMultiplier * 4)); // Increase samples for sharper bends
  }
  

  function getMaxCurvature(curve) {
    const numSteps = 50; // Steps to evaluate curvature
    let maxCurvature = 0;
  
    for (let i = 0; i <= numSteps; i++) {
      const t = i / numSteps;
      const curvature = Math.abs(curve.curvature(t));
      if (curvature > maxCurvature) {
        maxCurvature = curvature;
      }
    }
  
    return maxCurvature;
  }
  

  function getLineAngleChange(segment, segments, index) {
    if (index === 0 || index >= segments.length - 1) return 0; // No angle for the first or last segment
  
    const prevSegment = segments[index - 1].line;
    const nextSegment = segments[index + 1].line;
  
    const angle1 = Math.atan2(
      prevSegment.end.y - prevSegment.start.y,
      prevSegment.end.x - prevSegment.start.x
    );
    const angle2 = Math.atan2(
      nextSegment.end.y - nextSegment.start.y,
      nextSegment.end.x - nextSegment.start.x
    );
  
    return angle2 - angle1; // Angle difference
  }

  function smoothNormal(curve, t, segments, index) {
    // Compute the base normal for the current segment
    let normal = curve.normal(t);
  
    // Weights for blending adjacent segment normals
    const weightPrev = 0.3;
    const weightNext = 0.3;
    const weightCurrent = 1 - (weightPrev + weightNext);
  
    // Blend with previous and next segment normals for smoothing
    if (index > 0) {
      const prev = segments[index - 1];
      if (prev.type === 'C') {
        const prevNormal = prev.curve.normal(1); // Normal at the end of the previous curve
        normal.x = weightCurrent * normal.x + weightPrev * prevNormal.x;
        normal.y = weightCurrent * normal.y + weightPrev * prevNormal.y;
      }
    }
  
    if (index < segments.length - 1) {
      const next = segments[index + 1];
      if (next.type === 'C') {
        const nextNormal = next.curve.normal(0); // Normal at the start of the next curve
        normal.x = weightCurrent * normal.x + weightNext * nextNormal.x;
        normal.y = weightCurrent * normal.y + weightNext * nextNormal.y;
      }
    }
  
    return normalizeNormal(normal);
  }
  
  function smoothNormalLine(point, segment, segments, index) {
    // Compute the normal for the current line segment
    let normal = {
      x: -(segment.line.end.y - segment.line.start.y),
      y: segment.line.end.x - segment.line.start.x,
    };
  
    // Normalize the base normal
    normal = normalizeNormal(normal);
  
    // Weights for blending adjacent segment normals
    const weightPrev = 0.3;
    const weightNext = 0.3;
    const weightCurrent = 1 - (weightPrev + weightNext);
  
    let prevNormal = { x: 0, y: 0 };
    let nextNormal = { x: 0, y: 0 };
  
    // Blend with previous segment's normal, if it exists
    if (index > 0) {
      const prevSegment = segments[index - 1];
      if (prevSegment.type === 'C') {
        // Normal at the end of the previous curve
        const points = prevSegment.curve.points;
        prevNormal = normalizeNormal({
          x: -(points[points.length - 1].y - points[0].y),
          y: points[points.length - 1].x - points[0].x,
        });
      } else if (prevSegment.type === 'L') {
        prevNormal = normalizeNormal({
          x: -(prevSegment.line.end.y - prevSegment.line.start.y),
          y: prevSegment.line.end.x - prevSegment.line.start.x,
        });
      }
    }
  
    // Blend with next segment's normal, if it exists
    if (index < segments.length - 1) {
      const nextSegment = segments[index + 1];
      if (nextSegment.type === 'C') {
        // Normal at the start of the next curve
        const points = nextSegment.curve.points;
        nextNormal = normalizeNormal({
          x: -(points[1].y - points[0].y),
          y: points[1].x - points[0].x,
        });
      } else if (nextSegment.type === 'L') {
        nextNormal = normalizeNormal({
          x: -(nextSegment.line.end.y - nextSegment.line.start.y),
          y: nextSegment.line.end.x - nextSegment.line.start.x,
        });
      }
    }
  
    // Blend the normals using weights
    const blendedNormal = {
      x: weightCurrent * normal.x + weightPrev * prevNormal.x + weightNext * nextNormal.x,
      y: weightCurrent * normal.y + weightPrev * prevNormal.y + weightNext * nextNormal.y,
    };
  
    return normalizeNormal(blendedNormal);
  }


  function transformPatternPath(patternPath, skeletonSamples, skeletonLength, skeletonSamplesCount,ogSkeletonSamples) {
    const transformedData = [];
    let previousPoint = {
      x: patternPath.ogPath[0][1],
      y: patternPath.ogPath[0][2],
    };

    patternPath.ogPath.forEach((segment) => {
      const command = segment[0];

      if (command === 'M') {
        const newPoint = mapPointToSkeleton(segment[1], segment[2], skeletonLength, skeletonSamples, 1);
        transformedData.push(['M', newPoint.x, newPoint.y]);
      } else if (command === 'L') {
        const points = generateSubdivisionPoints(segment, ogSkeletonSamples, previousPoint,);
        points.forEach(({ x, y,distance }) => {
          const mappedPoint = mapPointToSkeleton(x, y, skeletonLength, skeletonSamples,distance);
          transformedData.push(['L', mappedPoint.x, mappedPoint.y]);
        });
        previousPoint = { x: segment[1], y: segment[2] };
      } else if (command === 'C') {
        const [_, cx1, cy1, cx2, cy2, x2, y2] = segment;

        // Subdivide the curve for smooth transformation
        const points = generateSubdivisionPoints(segment, ogSkeletonSamples, previousPoint);
        points.forEach(({ x, y,distance }) => {
          const mappedPoint = mapPointToSkeleton(x, y, skeletonLength, skeletonSamples,distance);
          transformedData.push(['L', mappedPoint.x, mappedPoint.y]);
        });

        // Update previousPoint to endpoint of the curve
        previousPoint = { x: x2, y: y2 };
      } else if (command === 'Z' || command === 'z') {
        transformedData.push([command]);
      }
    });

    return transformedData;
  }

  function generateSubdivisionPoints(segment, subdivisions, previousPoint) {
    const points = [];
    const command = segment[0];
  
    if (command === 'L') {
      const x1 = previousPoint.x;
      const y1 = previousPoint.y;
      const x2 = segment[1];
      const y2 = segment[2];
      const length = subdivisions.length - 1;
      let i = 0;
      for (let t = 0; t <= 1; t += 1 / length) {
        const x = x1 + t * (x2 - x1);
        const y = y1 + t * (y2 - y1);
        // Record the distance from the subdivision to the closest sample
        const closestSample = subdivisions[i];
        i++;
        console.log('applyBend: generateSubdivisionPoints: i, closestSample:', subdivisions,i, closestSample)
        // Calculate the distance to the closest skeleton sample
        let distance = 1;
        if(closestSample){
          distance = distanceCalc({x, y}, {x:closestSample.point.x, y:closestSample.point.y})

        }
    
        points.push({ x, y, distance });
      }
    } else if (command === 'C') {
      const [_, cx1, cy1, cx2, cy2, x2, y2] = segment;
      const { x: x1, y: y1 } = previousPoint;
  
      for (let t = 0; t <= 1; t += 1 / subdivisions.length) {
        const x = cubicBezier(t, x1, cx1, cx2, x2);
        const y = cubicBezier(t, y1, cy1, cy2, y2);
  
        // Record the distance from the subdivision to the curve midpoint
        const closestSample = subdivisions.reduce((closest, sample) => {
          return Math.abs(sample.point.x - x) + Math.abs(sample.point.y - y) < Math.abs(closest.point.x - x) + Math.abs(closest.point.y - y)
            ? sample
            : closest;
        }, subdivisions[0]);
  
        // Calculate the distance to the closest skeleton sample
        const dx = x - closestSample.point.x;
        const dy = y - closestSample.point.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
  
    
        points.push({ x, y, distance });
      }
    }
    console.log('applyBend: generateSubdivisionPoints: points: ', points);
    return points;
  }
  let distanceCalc = (p1, p2) =>
  Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));

  function mapPointToSkeleton(x, y, skeletonLength, samples,distance) {
    const targetLength = (x / skeletonLength) * samples[samples.length - 1].lengthAtT;
    console.log('applyBend: mapPoint: x, y, skeletonLength', x, y, skeletonLength,distance)

    // Find the closest sample point
    const closestSample = samples.reduce((closest, sample) => {
      return Math.abs(sample.lengthAtT - targetLength) < Math.abs(closest.lengthAtT - targetLength)
        ? sample
        : closest;
    }, samples[0]);
    console.log('applyBend: mapPoint: closestSample, targetLength', closestSample,targetLength)

    const currentDist = distanceCalc({x, y}, {x:closestSample.point.x, y:closestSample.point.y})

    const scaleY = distance/currentDist;

    console.log('applyBend: mapPoint: currentDist, scaleY', currentDist, scaleY)


    const adjustedY = y;
    const unitNormal = normalizeNormal(closestSample.normal);


    return {
      x: closestSample.point.x+ adjustedY * unitNormal.x,
      y: closestSample.point.y+ adjustedY * unitNormal.y,
    };
  }

 function normalizeNormal(normal) {
  const length = Math.sqrt(normal.x * normal.x + normal.y * normal.y);
  if (length === 0) return { x: 0, y: 0 }; // Handle degenerate cases
  return { x: normal.x / length, y: normal.y / length };
}



  function cubicBezier(t, p0, p1, p2, p3) {
    return (
      (1 - t) ** 3 * p0 +
      3 * (1 - t) ** 2 * t * p1 +
      3 * (1 - t) * t ** 2 * p2 +
      t ** 3 * p3
    );
  }
  // function analyzeSkeletonPath(samples, overlapThreshold = 0.1, sharpAngleThreshold = 300) {
  //   const problematicPoints = [];

  //   for (let i = 1; i < samples.length - 1; i++) {
  //     const prev = samples[i - 1].point;
  //     const curr = samples[i].point;
  //     const next = samples[i + 1].point;

  //     // Check for overlaps
  //     const overlap = Math.hypot(curr.x - next.x, curr.y - next.y) < overlapThreshold;
  //     if (overlap) {
  //       problematicPoints.push({ index: i, type: 'overlap' });
  //       continue;
  //     }

  //     // Check for sharp bends
  //     const angle = calculateAngle(prev, curr, next);
  //     if (angle < sharpAngleThreshold) {
  //       problematicPoints.push({ index: i, type: 'sharpBend' });
  //     }
  //   }

  //   return problematicPoints;
  // }

  // function calculateAngle(p1, p2, p3) {
  //   const v1 = { x: p2.x - p1.x, y: p2.y - p1.y };
  //   const v2 = { x: p3.x - p2.x, y: p3.y - p2.y };

  //   const dotProduct = v1.x * v2.x + v1.y * v2.y;
  //   const mag1 = Math.sqrt(v1.x ** 2 + v1.y ** 2);
  //   const mag2 = Math.sqrt(v2.x ** 2 + v2.y ** 2);

  //   const angle = (Math.acos(dotProduct / (mag1 * mag2)) * 180) / Math.PI;

  //   return isNaN(angle) ? 180 : angle; // Default to 180° for straight lines
  // }


  const [showRuler, setShowRuler] = useState(true);
  const rulerToggleClick = () => {
    console.log("click on ruler");
    setShowRuler((prevShowRuler) => !prevShowRuler);
    updateGuides(zoom);
  };

  const [showGuides, setShowGuides] = useState(true);


  function linearizeNode(path = canvas?.getActiveObject(), activeNodeIndex = activeNodes?.[0].pointIndex,) {
    if (!activeNodeIndex) {
      alert("Please select a node to Linearize");
      return
    }
    const curCmd = path.path[activeNodeIndex];
    const length = curCmd.length;
    console.log('linearizeNode: ', curCmd);
    if (curCmd[0].toLowerCase() !== 'l') {
      const newCmd = ['L', curCmd[length - 2], curCmd[length - 1]];
      console.log('linearizeNode: if not l: ', newCmd, length);
      path.path.splice(activeNodeIndex, 1, newCmd);
      recalculateBoundingBox(path, canvas);
      path.dirty = true;
      togglePathSelect(canvas, path, setActiveNodes, activeNodes);
      console.log('linearizeNode: if not l: path:', path);

      // canvas.setActiveObject(path);
      // canvas.requestRenderAll();
    }
  }
  return (
    <div style={{ display: 'flex', overflow: "hidden", background: "gray", }}>
      {!isFullscreen &&
       <Sidebar
      undo={undo}
      redo={redo}
        activeNodes={activeNodes}
        setActiveNodes={setActiveNodes}
        zoom={zoom}
        chalkAndSponge={chalkAndSponge}
        handlesConnected={handlesConnected}
        setHandlesConnected={setHandlesConnected}
        linearizeNode={linearizeNode}
        handlePositionChange={handlePositionChange}
        handleBendAlongPath={handleBendAlongPath}
        handlePathRotateCopies={handlePathRotateCopies}
        rotationSettings={rotationSettings}
        updateRotationSettings={updateRotationSettings}
        circularizeNode={circularizeNode}
        convertObjectToPath={convertObjectToPath}
        onAddChart={handleAddChart}
        combineSelectedObjectsDifference={combineSelectedObjectsDifference}
        combineSelectedObjectsFragmentation={combineSelectedObjectsFragmentation}
        combineSelectedObjectsIntersection={combineSelectedObjectsIntersection}
        combineSelectedPaths={combineSelectedPaths}
        combineSelectedPathsExclusion={combineSelectedPathsExclusion}
        originalVisibility={originalVisibility}
        handleChangeView={handleChangeView}
        canvasArray={canvases}
        activeCanvasIndex={activeCanvasIndex}
        onAddCanvas={addNewCanvas}
        canvasImage={canvasImage}
        sidebarRef={sidebarRef}
        selectedStrokeColor={selectedStrokeColor}
        setSelectedStrokeColor={setSelectedStrokeColor}
        appBarRef1={appBarRef1}
        removeNodes={removeNodes}
        appBarRef2={appBarRef2}
        setOpacity={setOpacity}
        opacity={opacity}
        addNodeAtMidpoint={addNodeAtMidpoint}
        changeOpacity={changeOpacity}
        pathForPattern={path}
        mainCanvas={canvas}
        onSizeChange={handleSizeChange}
        setSize={setSize}
        size={size}
        switchCanvas={switchCanvas}
        onPatternAlongPath={() => handlePathCreated(canvas, path)}
        onPredifenedSizeChange={handlePredefinedSizeChange}
        onDownloadSVG={() => handleDownloadSVG(canvas)}
        onAddStar={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'star')}
        onAddTrapezium={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'trapezium')}
        onAddPolygon={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'polygon')}
        onAddLine={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'line')}
        onAddArrow={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'arrow')}
        onAddRectangle={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'rectangle')}
        onAddCircle={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'circle')}
        onAddTriangle={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'triangle')}
        onAddSquare={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'square')}
        onDoubleArrow={() => handleDrawingMode(canvas, selectedColor, selectedStrokeColor, 'doubleArrow')}
        handleDrawingMode={handleDrawingMode}
        activeObj={activeObj}
        setActiveObj={setActiveObj}
        onFreeDrawing={() => handleFreeDrawingClick(canvas)}
        onDisableFreeDrawing={() => handleDisableFreeDrawingClick(canvas)}
        areaOfSpray={areaOfSpray}
        setAreaOfSpray={setAreaOfSpray}
        pathSelect={pathSelect}
        setPathSelect={setPathSelect}
        numberOfCopies={numberOfCopies}
        spraySelectRef={spraySelectRef}
        setNumberOfCopies={setNumberOfCopies}
        onSetColor={setSelectedColor}
        setSelectedColorForRotationCopy={setSelectedColorForRotationCopy}
        selectedColorForRotationCopy={selectedColorForRotationCopy}
        selectedStrokeForRotationCopy={selectedStrokeForRotationCopy} 
        setSelectedStrokeForRotationCopy={setSelectedStrokeForRotationCopy}
        applyColorToSelectedCopy={applyColorToSelectedCopy}
        onCanvasBgColorChange={(e) => handleSetColor(e, canvas)}
        fillColor={selectedColor}
        handleSpraying={() => handleSpraying(spraySelectRef, setSprayObj, setSpraySelect, sprayObjRef, activeObj)}
        opacityObj={opacityObj}
        changeOpacityOfObj={changeOpacityOfObj}
        onEraser={() => handleEraserClick(canvas, isErasing, setIsErasing)}
        onUploadSVG={(event) => handleUploadSVG(event, canvas)}
        rulerToggleClick={rulerToggleClick}
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        handleZoomToPage={handleZoomToPage}
        zoomSelection={zoomSelection}
        containerDim={containerDim}
        setContainerDim={setContainerDim}
        showGuides={showGuides}
        setShowGuides={setShowGuides}
        setShowRuler={setShowRuler}
        showRuler={showRuler}
      />}
     
        

     
      <div id="canvas-container"
        ref={canvasRef}
        onWheel={handleWheel} // Attach wheel event directly
      >
         
        {!isFullscreen && showRuler && <Tooltip arrow title='Guide Options'>
          <Button
            sx={{
              position: "absolute",
              background: lockGuides.length > 0 ? "#d1d3d8" : "#f0f2f7",
              cursor: 'pointer',
              zIndex: 1000,
              color: "black",
              minWidth: 0,
              p: 0,
              height: "29px",
              width: "29px",
              borderRadius: 0,
              boxShadow: 2,
              '&:hover': {
                background: lockGuides.length > 0 ? "#b0b2b8" : "#e2e4e8"
              }
            }}
            onClick={handleMenuOpen}
          >
            <Lock sx={{ minWidth: 0, fontSize: 16 }} />
          </Button>
        </Tooltip>}

        <Menu
          anchorEl={anchorEl1}
          open={Boolean(anchorEl1)}
          sx={{
            ml: '30px'
          }}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => { setShowRanges(!showRanges); updateGuideRanges(!showRanges) }}>
            {showRanges ? "Hide Shape Ranges" : 'Show Shape Ranges'}
          </MenuItem>
          <MenuItem onClick={() => handleToggleLock("add")}>
            {lockGuides.includes("add") ? "Unlock Add" : "Lock Add"}
          </MenuItem>
          <MenuItem onClick={() => handleToggleLock("change")}>
            {lockGuides.includes("change") ? "Unlock Change" : "Lock Change"}
          </MenuItem>
          <MenuItem onClick={() => handleToggleLock("remove")}>
            {"Remove All Guides"}
          </MenuItem>
          <MenuItem onClick={handleUnlockAll}>
            Unlock All
          </MenuItem>
        </Menu>
        {!isFullscreen && showRuler && (
          <>
            <div className="ruler horizontal">
              <Guides
                showGuides={showGuides}
                onChangeGuides={handleGuideClick}
                lockGuides={lockGuides}
                ref={horizonalGuidesRef}
                guideStyle={{
                  width:
                    containerScrollDimensions.width > 0
                      ? `${containerScrollDimensions.width}px`
                      : "auto",
                }}
                dragGuideStyle={{
                  width:
                    containerScrollDimensions.width > 0
                      ? `${containerScrollDimensions.width}px`
                      : "auto",
                }}
                type="horizontal"
                selectedRanges={objGuideWidth && objGuideWidth}
                selectedBackgroundColor="blue"
                backgroundColor="transparent"
                textColor="black"
                lineColor="black"
                zoom={zoom}
                rulerStyle={{
                  width: document.getElementById("canvas-container")
                    ? `calc(${
                        document.getElementById("canvas-container").scrollWidth
                      }px - 30px)`
                    : "auto",
                  height: "100%",
                  marginLeft: 30,
                }}
                displayDragPos={true}
                displayGuidePos={true}
                useResizeObserver={true}
              />
            </div>
            <div className="ruler vertical">
              <Guides
                showGuides={showGuides}
                onGuideClick={handleGuideClick}
                lockGuides={lockGuides}
                ref={verticalGuidesRef}
                type="vertical"
                guideStyle={{
                  height:
                    containerScrollDimensions.height > 0
                      ? `${containerScrollDimensions.height}px`
                      : "auto",
                }}
                dragGuideStyle={{
                  height:
                    containerScrollDimensions.height > 0
                      ? `${containerScrollDimensions.height}px`
                      : "auto",
                }}
                zoom={zoom}
                selectedRanges={objGuideHeight && objGuideHeight}
                selectedBackgroundColor="blue"
                backgroundColor="transparent"
                textColor="black"
                lineColor="black"
                rulerStyle={{
                  marginTop: "30px",
                  height: document.getElementById("canvas-container")
                    ? `calc(${
                        document.getElementById("canvas-container").scrollHeight
                      }px - 30px)`
                    : "auto",
                  width: "100%",
                }}
                displayDragPos={true}
                displayGuidePos={true}
                useResizeObserver={true}
              />
            </div>
          </>
        )}
      </div>
      {!isFullscreen && (
        <ShapeEditor
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          containerDim={containerDim}
          activeObj={activeObj}
          canvas={canvas}
          handleDeleteObject={handleDeleteObject}
          addCustomControls={addCustomControls}
          handlePropertyChange={handlePropertyChange}
          handleStartAngleChange={handleStartAngleChange}
          handleEndAngleChange={handleEndAngleChange}
          handleAngleTypeChange={handleAngleTypeChange}
          handleStarCornersChange={handleStarCornersChange}
          handleSpokeRatioChange={handleSpokeRatioChange}
          handlePolygonDistortionChange={handlePolygonDistortionChange}
          conversionRates={conversionRates}
          lineStyle={lineStyle}
          handleChangeLine={handleChangeLine}
          cornerRadius={cornerRadius}
          handleChange={handleChange}
          setFactoral={setFactoral}
          factoral={factoral}
          opacityObj={opacityObj}
          handlePositionChange={handlePositionChange}
          handleDatasetDataChange={handleDatasetDataChange}
          handleLabelChange={handleLabelChange}
          handleDatasetLabelChange={handleDatasetLabelChange}
          handleColorChange={handleColorChange}
          handleBorderColorChange={handleBorderColorChange}
          changeOpacityOfObj={changeOpacityOfObj}
        />
      )}
      {!isFullscreen && (
        <Footer
          handlePresentation={handlePresentation}
          footerRef={footerRef}
          toggleOrientation={toggleOrientation}
          zoom={zoom}
          setZoom={setZoom}
          onZoomChange={handleZoomChange}
          toggleVisibility={toggleVisibility}
          canvas={canvas}
          handleZoomOut={handleZoomOut}
          handleZoomIn={handleZoomIn}
        />
      )}
    </div>
  );
};

export default Canvas;
