import React, { useState,useRef } from 'react';
import { TextField, Button, Box, IconButton, Typography, RadioGroup, FormControlLabel, Radio , InputAdornment} from '@mui/material';
import { Add, Remove , Clear, Upload } from '@mui/icons-material';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';


const VennForm = ({ onSubmit }) => {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [sets, setSets] = useState([{ sets: [''], size: 0, color: '#000000' }]);
  const handleUploadClick = (e) => {
    e.stopPropagation();

    fileInputRef.current.click();
  };

  const handleClearClick = () => {
    setFileName('');
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      console.log('handleFileUpload: ', file);
      const reader = new FileReader();
  
      const fileExtension = file.name.split('.').pop().toLowerCase();
  
      if (fileExtension === 'csv') {
        reader.onload = (f) => {
          const data = f.target.result;
          Papa.parse(data, {
            header: true,
            complete: (results) => {
              const parsedData = results.data;
  
              const extractedSets = parsedData.map((row) => ({
                sets: row.sets ? row.sets.split(',').map((str) => str.trim()) : [''],
                size: parseFloat(row.size) || 0,
                color: row.color || '#000000',
              }));
  
              setSets(extractedSets);
            },
          });
        };
        reader.readAsText(file);
      }
  
      else if (fileExtension === 'xlsx') {
        reader.onload = (f) => {
          const data = new Uint8Array(f.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
          const extractedSets = parsedData.slice(1).map((row) => ({
            sets: row[0] ? row[0].split(',').map((str) => str.trim()) : [''],
            size: parseFloat(row[1]) || 0,
            color: row[2] || '#000000',
          }));
  
          setSets(extractedSets);
        };
        reader.readAsArrayBuffer(file);
      }
  
      setFileName(file.name);
    }
  };
  const handleAddSet = () => {
    // Add a new set with default values
    setSets([...sets, { sets: [''], size: 0, color: '#000000' }]);
  };

  const handleRemoveSet = (index) => {
    // Remove the set at the given index
    const updatedSets = sets.filter((_, i) => i !== index);
    setSets(updatedSets);
  };

  const handleSetChange = (index, key, value) => {
    const updatedSets = sets.map((set, i) => {
      if (i === index) {
        const updatedValue = key === 'sets' ? 
          value.split(',').map(str => str.trim()) : value;

        return { ...set, [key]: updatedValue };
      }
      return set;
    });
    setSets(updatedSets);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    console.log("!set.radius ",sets )
    try {
      const processedSets = sets.map(set => {
        const validSets = set.sets.length === 1 && set.sets[0] === '' ? [] : set.sets;

        // if (!set.radius || isNaN(set.radius)) {
        //   throw new Error('Invalid or missing radius in one of the sets.');
        // }
  
        return {
          ...set,
          sets: validSets
        };
      });
      if (!processedSets || processedSets.length === 0) {
        throw new Error('No valid sets provided for the Venn diagram.');
      }
      onSubmit('venn', processedSets);
  
      console.log('handleSubmit: Venn Form: ', processedSets);
  
    } catch (error) {
      alert(`Submission error: ${error.message}`);
    }
  };
  

  return (
    <Box sx={{ px: 2, py: 2, maxWidth: '400px' }}>
      <Typography variant="h6" gutterBottom>
        Venn Diagram Settings
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        {sets.map((set, index) => (
          <Box key={index} sx={{ mb: 2, display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            <Box sx={{ display: 'flex', padding: '15px 5px', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
              <Typography>Data Set {index + 1}</Typography>
              <IconButton color="error" onClick={() => handleRemoveSet(index)}>
                <Remove />
              </IconButton>
            </Box>
            <TextField
              fullWidth
              label={`Set ${index + 1} Name`}
              placeholder="Set Name"
              value={set.sets.join(', ')}
              onChange={(e) => handleSetChange(index, 'sets', e.target.value)}
              sx={{ maxWidth: '100%' }}
            />
            <TextField
              type="number"
              label="Set Size"
              placeholder="Set Size"
              value={set.size}
              onChange={(e) => handleSetChange(index, 'size', parseInt(e.target.value) || 0)}
              sx={{ minWidth: '250px' }}
            />
            <TextField
              type="color"
              label="Color"
              value={set.color}
              onChange={(e) => handleSetChange(index, 'color', e.target.value)}
              sx={{ minWidth: '250px' }}
            />
            <input ref={fileInputRef} type="file" accept=".xlsx, .xls, .csv" style={{ display: 'none' }} onChange={handleFileUpload} />
            <TextField

              value={fileName}
              variant="outlined"
              onClick={handleUploadClick}
              placeholder="Upload a file"
              inputProps={{
                style: {
                  cursor: fileName ? 'default' : 'pointer',
                  color: '#ffffff'
                }
              }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment sx={{ p: 0 }} position="end">
                    <IconButton sx={{ borderRadius: 0, "&:hover": { color: 'blue' } }} onClick={handleUploadClick}>
                      <Upload />
                    </IconButton>
                    {fileName && (
                      <IconButton sx={{ borderRadius: 0, "&:hover": { color: 'red' } }} onClick={handleClearClick}>
                        <Clear />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              />
          </Box>
        ))}
        <Box sx={{ mb: 2 }}>
          <Button variant="contained" color="secondary" onClick={handleAddSet}>
            <Add /> Add Set
          </Button>
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Generate Venn Diagram
        </Button>
      </Box>
    </Box>
  );
};

export default VennForm;
