import {
  Drawer,
  Typography,
  Button,
  Slider,
  Radio,
  TextField,
  MenuItem,
  FormControl,
  Select,
  Box,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Tab,
  Tabs,
  Tooltip,
  Divider,
 
} from "@mui/material";
import {
  ShapeEditorDrawer,
  StyledSubMenu,
  StyledSubMenuContainer,
  StyledSubMenuTitleContainer,
  drawerWidth,
} from "../../Utils/styledComps";
import ColorPicker from "react-best-gradient-color-picker";
import {
  changeOutlierColors,
  changeVennCircleColors,
  changeWhiskerBoxColors,
} from "../Tools/paintTools";
import { useEffect, useState } from "react";
import { IoShapes } from "react-icons/io5";
import { TbDimensions, TbScanPosition, TbSkewX } from "react-icons/tb";
import { BiColorFill } from "react-icons/bi";
import {
  ChevronLeft,
  ChevronRight,
  Close,
  ColorLensOutlined,
  Delete,
  Menu,
  GradientOutlined,
} from "@mui/icons-material";
import { gradientToCssString } from "../Tools/colorTools";
import { CgColorBucket } from "react-icons/cg";
import { PiHamburger } from "react-icons/pi";
import { FaArtstation } from "react-icons/fa6";
function TabPanel(props) {
  const { children, subClass, value, index, type, ...other } = props;
  return (
    <StyledSubMenuContainer
      sx={{ mr: 2, mb: 6, display: value !== index ? "none" : "block" }}
      role="tabpanel"
      // hidden={value !== index}
      id={`${type}-${index}`}
      aria-labelledby={`${subClass}-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ width: "320px", maxHeight: "780px" }}>{children}</Box>
      )}
    </StyledSubMenuContainer>
  );
}
function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function isChart(activeObj) {
  return activeObj?.length > 0
    ? activeObj?.[0]?.class === "chart" || activeObj?.[0]?.class === "plot"
    : true;
}
export const ShapeEditor = ({
  open,
  handleOpen,
  handleClose,
  containerDim,
  activeObj,
  canvas,
  handleDeleteObject,
  addCustomControls,
  handlePropertyChange,
  handleStartAngleChange,
  handleEndAngleChange,
  handleAngleTypeChange,
  handleStarCornersChange,
  handleSpokeRatioChange,
  handlePolygonDistortionChange,
  conversionRates,
  lineStyle,
  handleChangeLine,
  cornerRadius,
  handleChange,
  setFactoral,
  factoral,
  handlePositionChange,
  handleDatasetDataChange,
  handleLabelChange,
  handleDatasetLabelChange,
  handleColorChange,
  handleBorderColorChange,
  startAngle,
  endAngle,
  angleType,
  handlePolygonCornersChange,
  changeOpacityOfObj,
  opacityObj,
  setOpacityObj
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [activeColorTab, setActiveColorTab] = useState(0);
  const [datasetLabels, setDatasetLabels] = useState({});
  const [prevColor, setPrevColor] = useState({
    field:"",
    color:null
  });
  const marks = [
    { value: 0, label: '0%' },
    { value: 0.2, label: '20%' },
    { value: 0.4, label: '40%' },
    { value: 0.6, label: '60%' },
    { value: 0.8, label: '80%' },
    { value: 1, label: '100%' },
  ];

  const [strokePrevColor,setStrokePrevColor] =useState(null)
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    if (newValue === 0) {
      console.log("handleTabChange: newValue:  is 0:", newValue, open);

      if (open) {
        console.log(
          "handleTabChange: newValue:  is 0: is open:",
          newValue,
          open
        );
        handleClose();
        setActiveTab(1);
      } else {
        console.log(
          "handleTabChange: newValue:  is 0: is not open:",
          newValue,
          open
        );

        handleOpen();
        if (activeObj?.length > 0) {
          setActiveTab(1);
        } else {
          setActiveTab(5);
        }
      }
    }
    console.log("handleTabChange: newValue: final", newValue, open);
  };
  const roundToTwoDecimals = (num) => Math.round(num * 100) / 100;

  const tabs = [
    {
      icon: !open ? (
        <ChevronLeft color="primary" />
      ) : (
        <ChevronRight color="error" />
      ),
      title: !open ? "Open Editor" : "Close Editor",
      disabled: false,
    },
    {
      icon: <IoShapes size={22} />,
      title: "Dimensions",
      disabled: activeObj?.length > 0 ? false : true,
    },
    {
      icon: <TbDimensions size={22} />,
      title: "Shape",
      disabled: isChart(activeObj),
    },
    {
      icon: <BiColorFill size={22} />,
      title: "Fill & Stroke",
      disabled: isChart(activeObj),
    },
    {
      icon: <TbScanPosition size={22} />,
      title: "Position",
      disabled: activeObj?.length > 0 ? false : true,
    },
    // {
    //   icon: <FaArtstation size={22} />,
    //   title: "Other",
    //   disabled: !activeObj ?false:true

    // },
  ];
  function tab0() {
    return (
      <>
        <Typography align="center" variant="h6">
          Dimensions
        </Typography>
        {activeObj?.[0]?.chart?.type == "bar" &&
          activeObj?.[0]?.chart?.data.datasets.map((dataset, datasetIndex) => (
            <div key={datasetIndex}>
              <Typography>{dataset.label}</Typography>
              {dataset.backgroundColor.map((color, colorIndex) => (
                <div
                  key={colorIndex}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    gap: 10,
                    justifyContent: "center",
                    flexDirection: "column",
                    alignContent: "center",
                  }}
                >
                  <Typography>Bar {colorIndex + 1}</Typography>
                  <TextField
                    label={`Value for Bar ${colorIndex + 1}`}
                    variant="outlined"
                    size="small"
                    type="number"
                    value={dataset.data[colorIndex]}
                    onChange={(e) =>
                      handleDatasetDataChange(
                        parseFloat(e.target.value),
                        colorIndex
                      )
                    } // Handle data value change
                    // style={{ marginTop: '10px', width: '100%' }}
                  />
                  {/* ColorPicker for selecting the bar color */}
                  <Typography>
                    Label Point {colorIndex + 1}: {color.label}
                  </Typography>
                  <TextField
                    label={`Label for Point ${colorIndex + 1}`}
                    variant="outlined"
                    size="small"
                    value={activeObj[0].chart.data.labels[colorIndex]}
                    onChange={(e) =>
                      handleLabelChange(e.target.value, colorIndex)
                    }
                  />
                  <ColorPicker
                    color={color}
                    value={color}
                    hideControls
                    hideGradientControls
                    hideInputs
                    hidePresets
                    onChange={(newColor) =>
                      handleColorChange(newColor, colorIndex)
                    }
                  />
                </div>
              ))}
            </div>
          ))}
        {(activeObj?.[0]?.chart?.type == "pie" ||
          activeObj?.[0]?.chart?.type == "doughnut" ||
          activeObj?.[0]?.chart?.type == "barVertical" ||
          activeObj?.[0]?.chart?.type == "barHorizontal") &&
          activeObj?.[0]?.chart?.data.datasets.map((dataset, datasetIndex) => (
            <div key={datasetIndex}>
              <Typography>{dataset.label}</Typography>
              {dataset.data.map((value, dataIndex) => (
                <div
                  key={dataIndex}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    gap: 10,
                    justifyContent: "center",
                    flexDirection: "column",
                    alignContent: "center",
                  }}
                >
                  <Typography>
                    Label Point {dataIndex + 1}: {value.label}
                  </Typography>
                  <TextField
                    label={`Label for Point ${dataIndex + 1}`}
                    variant="outlined"
                    size="small"
                    value={activeObj[0]?.chart?.data?.labels?.[dataIndex]}
                    onChange={(e) =>
                      handleLabelChange(e.target.value, dataIndex)
                    }
                  />

                  <Typography>Data Point {dataIndex + 1}</Typography>

                  <TextField
                    label={`Value for Data Point ${dataIndex + 1}`}
                    variant="outlined"
                    size="small"
                    type="number"
                    value={value} // Bind to the current data point value
                    onChange={(e) =>
                      handleDatasetDataChange(
                        parseFloat(e.target.value),
                        dataIndex
                      )
                    } // Handle data value change
                  />

                  {dataset.backgroundColor &&
                    dataset.backgroundColor[dataIndex] && (
                      <ColorPicker
                        color={dataset.backgroundColor[dataIndex]}
                        value={dataset.backgroundColor[dataIndex]}
                        hideControls
                        hideGradientControls
                        hideInputs
                        hidePresets
                        onChange={(newColor) =>
                          handleColorChange(newColor, dataIndex)
                        } // Handle background color change
                      />
                    )}
                </div>
              ))}

              <div
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  gap: 10,
                  justifyContent: "center",
                  flexDirection: "column",
                  alignContent: "center",
                }}
              >
                <Typography>Border Color</Typography>

                <ColorPicker
                  color={dataset.borderColor}
                  value={dataset.borderColor}
                  hideControls
                  hideGradientControls
                  hideInputs
                  hidePresets
                  onChange={(newColor) => handleBorderColorChange(newColor)} // Handle border color change
                />
              </div>
            </div>
          ))}
        {activeObj?.[0]?.chart?.type == "scatter" &&
          activeObj?.[0]?.chart?.data.datasets.map((dataset, datasetIndex) => (
            <div key={datasetIndex}>
              {/* <Typography>{dataset?.label}</Typography> */}
              {dataset.data.map((value, dataIndex) => (
                <div
                  key={dataIndex}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    gap: 10,
                    justifyContent: "center",
                    flexDirection: "column",
                    alignContent: "center",
                  }}
                >
                  <Typography>
                    Label Point {dataIndex + 1}: {value.label}
                  </Typography>
                  <TextField
                    label={`Label for Point ${dataIndex + 1}`}
                    variant="outlined"
                    size="small"
                    value={dataset.label}
                    onChange={(e) =>
                      handleLabelChange(e.target.value, dataIndex)
                    }
                  />

                  <Typography>Data Point {dataIndex + 1}</Typography>

                  <TextField
                    label={`Value for Data Point ${dataIndex + 1}`}
                    variant="outlined"
                    size="small"
                    type="number"
                    value={dataset.data} // Bind to the current data point value
                    onChange={(e) =>
                      handleDatasetDataChange(
                        parseFloat(e.target.value),
                        dataIndex
                      )
                    } // Handle data value change
                  />

                  {dataset.backgroundColor &&
                    dataset.backgroundColor[dataIndex] && (
                      <ColorPicker
                        color={dataset.backgroundColor[dataIndex]}
                        value={dataset.backgroundColor[dataIndex]}
                        hideControls
                        hideGradientControls
                        hideInputs
                        hidePresets
                        onChange={(newColor) =>
                          handleColorChange(newColor, dataIndex)
                        } // Handle background color change
                      />
                    )}
                </div>
              ))}

              <div
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  gap: 10,
                  justifyContent: "center",
                  flexDirection: "column",
                  alignContent: "center",
                }}
              >
                <Typography>Border Color</Typography>

                <ColorPicker
                  color={dataset.borderColor}
                  value={dataset.borderColor}
                  hideControls
                  hideGradientControls
                  hideInputs
                  hidePresets
                  onChange={(newColor) => handleBorderColorChange(newColor)} // Handle border color change
                />
              </div>
            </div>
          ))}
        {activeObj?.[0]?.chart?.type === "line" &&
          activeObj?.[0]?.chart?.data.datasets.map((dataset, datasetIndex) => (
            <div
              key={datasetIndex}
              style={{
                marginBottom: "10px",
                display: "flex",
                gap: 10,
                justifyContent: "center",
                flexDirection: "column",
                alignContent: "center",
              }}
            >
              <Typography>Dataset Point {datasetIndex + 1}</Typography>

              <TextField
                label={`Dataset for Point ${datasetIndex + 1}`}
                variant="outlined"
                size="small"
               value={datasetLabels[datasetIndex] || dataset.label || ""}
                onChange={(e) =>{
                  const newLabel = e.target.value;
                  setDatasetLabels((prevLabels) => ({
                    ...prevLabels,
                    [datasetIndex]: newLabel,
                  }));
                handleDatasetLabelChange(e.target.value, datasetIndex, "dateset")
                }}
              />

              {dataset?.data?.map((value, dataIndex) => (
                <div
                  key={dataIndex}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    gap: 10,
                    justifyContent: "center",
                    flexDirection: "column",
                    alignContent: "center",
                  }}
                >
                  <Typography>Label Point {dataIndex + 1}</Typography>

                  <TextField
                    label={`Label for Point ${dataIndex + 1}`}
                    variant="outlined"
                    size="small"
                    value={activeObj[0]?.chart?.data?.labels?.[dataIndex] || ""}
                    onChange={(e) =>
                      handleLabelChange(e.target.value, dataIndex)
                    }
                  />

                  <Typography>Data Point {dataIndex + 1}</Typography>
                  <TextField
                    label={`Value for Data Point ${dataIndex + 1}`}
                    variant="outlined"
                    size="small"
                    type="number"
                    value={value} // Bind to the current data point value
                    onChange={(e) =>
                      handleDatasetDataChange(
                        parseFloat(e.target.value),
                        dataIndex
                      )
                    } // Handle data value change
                  />

                  {dataset.backgroundColor &&
                    dataset.backgroundColor[dataIndex] && (
                      <ColorPicker
                        color={dataset.backgroundColor[dataIndex]}
                        value={dataset.backgroundColor[dataIndex]}
                        hideControls
                        hideGradientControls
                        hideInputs
                        hidePresets
                        onChange={(newColor) =>
                          handleColorChange(newColor, dataIndex)
                        } // Handle background color change
                      />
                    )}
                </div>
              ))}

              <div
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  gap: 10,
                  justifyContent: "center",
                  flexDirection: "column",
                  alignContent: "center",
                }}
              >
                <Typography>Border Color</Typography>
                <ColorPicker
                  color={dataset.borderColor}
                  value={dataset.borderColor}
                  hideControls
                  hideGradientControls
                  hideInputs
                  hidePresets
                  onChange={(newColor) => handleBorderColorChange(newColor)} // Handle border color change
                />
              </div>
            </div>
        ))}

        {activeObj?.[0].subClass === "venn"
          ? activeObj?.[0]?._objects
              ?.filter((obj) => obj.type === "path" && obj?.fill?.length > 0)
              .map((dataset, datasetIndex) => (
                <div key={dataset}>
                  <Typography>{dataset.label}</Typography>
                  <div style={{ marginBottom: "10px" }}>
                    <Typography>Venn Section {datasetIndex + 1}</Typography>
                    <ColorPicker
                      color={dataset.fill}
                      hideControls
                      hideGradientControls
                      hideInputs
                      hidePresets
                      value={dataset.fill}
                      onChange={(newColor) =>
                        changeVennCircleColors(dataset, newColor, canvas)
                      }
                    />
                  </div>
                </div>
              ))
          : activeObj?.[0]?._objects?.map((dataset, datasetIndex) => {
              if (dataset.type === "rect" && dataset?.fill?.length > 0) {
                return (
                  <div key={datasetIndex} style={{ marginBottom: "20px" }}>
                    <Typography>Box {datasetIndex + 1}</Typography>
                    <ColorPicker
                      color={dataset.fill}
                      value={dataset.fill}
                      hideControls
                      hideGradientControls
                      hideInputs
                      hidePresets
                      onChange={(newColor) =>
                        changeWhiskerBoxColors(dataset, newColor, canvas)
                      }
                    />
                  </div>
                );
              }

              if (dataset.type === "circle" && dataset?.fill?.length > 0) {
                return (
                  <div key={datasetIndex} style={{ marginBottom: "20px" }}>
                    <Typography>Outlier {datasetIndex + 1}</Typography>
                    <ColorPicker
                      hideControls
                      hideGradientControls
                      hideInputs
                      hidePresets
                      color={dataset.fill}
                      value={dataset.fill}
                      onChange={(newColor) =>
                        changeOutlierColors(dataset, newColor, canvas)
                      }
                    />
                  </div>
                );
              }

              return null;
            })}
        {activeObj?.[0].class == "circle" && (
          <>
            <TextField
              label="Radius"
              type="number"
              value={activeObj?.[0].radius}
              onChange={(e) =>
                handlePropertyChange("radius", parseFloat(e.target.value))
              }
              fullWidth
              margin="normal"
            />

            <TextField
              label="Start Angle"
              type="number"
              value={startAngle}
              onChange={handleStartAngleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="End Angle"
              type="number"
              value={endAngle}
              onChange={handleEndAngleChange}
              fullWidth
              margin="normal"
            />

            <FormControl fullWidth margin="normal">
              <InputLabel>Angle Type</InputLabel>
              <Select value={angleType} onChange={handleAngleTypeChange}>
                <MenuItem value="arc">Arc</MenuItem>
                <MenuItem value="chord">Chord</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        {activeObj?.[0]?.class === "star" ||
        activeObj?.[0]?.class === "polygon" ? (
          <>
            <TextField
              label="Corners"
              type="number"
              value={activeObj?.[0].corners}
              onChange={(e) =>
                activeObj?.[0]?.class === "star"
                  ? handleStarCornersChange(e.target.value)
                  : handlePolygonCornersChange(e.target.value)
              }
              fullWidth
              margin="normal"
              inputProps={{ min: 3 }} // Star must have at least 5 corners
            />
            {activeObj?.[0]?.class !== "polygon" && (
              <TextField
                inputMode="numeric"
                inputProps={{
                  step: "0.01",
                  min: "0",
                  max: "1",
                }}
                label="Spoke Ratio"
                type="number"
                value={activeObj?.[0]?.spokeRatio} // Default value, adjust as needed
                onChange={(e) =>
                  handleSpokeRatioChange(parseFloat(e.target.value))
                }
                fullWidth
                margin="normal"
              />
            )}
          </>
        ) : null}

        {activeObj?.[0]?.class !== "circle" &&
          activeObj?.[0]?.class !== "chart" &&
          activeObj?.[0]?.class !== "plot" &&
          activeObj?.[0]?.class !== "line" && (
            <>
              <TextField
                label="Distortion Factor"
                type="number"
                onChange={(e) => handlePolygonDistortionChange(e.target.value)}
                fullWidth
                margin="normal"
                inputProps={{
                  step: "0.01",
                  min: "0.1",
                  max: "10",
                }}
              />
            </>
          )}
        {activeObj?.[0].class !== "circle" &&
          activeObj?.[0].class !== "line" && (
            <>
              <TextField
                label="Width"
                type="number"
                value={
                  roundToTwoDecimals(
                  activeObj?.[0].width / conversionRates[activeObj?.[0].unit])
                }
                onChange={(e) =>
                  handlePropertyChange(
                    "width",
                    e.target.value ? parseFloat(e.target.value) : 0.1
                  )
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Height"
                type="number"
                value={
                  roundToTwoDecimals(activeObj?.[0].height / conversionRates[activeObj?.[0].unit])
                }
                onChange={(e) =>
                  handlePropertyChange(
                    "height",
                    e.target.value ? parseFloat(e.target.value) : 0.1
                  )
                }
                fullWidth
                margin="normal"
              />
            </>
          )}
        {activeObj?.[0]?.class !== "chart" &&
          activeObj?.[0]?.class !== "plot" && (
            <TextField
              label="Stroke Width"
              type="number"
              value={
                activeObj?.[0].strokeWidth 
              }
              onChange={(e) =>
                handlePositionChange(
                  "strokeWidth",
                  e.target.value ? parseFloat(e.target.value) : 0.1
                )
              }
              fullWidth
              margin="normal"
            />
          )}
      </>
    );
  }

  function tab1() {
    return (
      <>
        <Typography align="center" variant="h6">
          Shape
        </Typography>
        {activeObj?.[0].class !== "circle" && (
          <div style={{ marginTop: "20px", padding: "20px" }}>
            <Box
              sx={{
                ml: "-10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography gutterBottom>Corner Radius: </Typography>
              {cornerRadius}
            </Box>
            <Slider
              value={
                activeObj?.[0].cornerRadius
                  ? activeObj?.[0].cornerRadius
                  : cornerRadius
              }
              min={0}
              max={10}
              step={0.01}
              onChange={handleChange}
              valueLabelDisplay="auto"
              aria-labelledby="roundness-slider"
            />
          </div>
        )}
        {activeObj?.[0].class !== "circle" && (
          <FormControl>
            <Typography>Radius Is Factoral:</Typography>
            <RadioGroup
              aria-label="angle-type"
              name="angle-type"
              value={factoral}
              onChange={(e) => {
                canvas.renderAll();
                setFactoral(e.target.value);
              }}
            >
              <FormControlLabel
                value={true}
                label="Yes"
                control={<Radio checked={factoral} />}
              />
              <FormControlLabel
                value={false}
                label="No"
                control={<Radio checked={!factoral} />}
              />
            </RadioGroup>
          </FormControl>
        )}
        <>
          <Typography>Line Type:</Typography>
          <FormControl sx={{ width: "100%" }}>
            <Select value={lineStyle}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 100, // Limit the height
                  overflowY: "auto", // Enable vertical scrolling
                },
              },
            }} onChange={handleChangeLine}>
              <MenuItem value="solid">Solid</MenuItem>
              <MenuItem value="dotted">Dotted</MenuItem>
              <MenuItem value="dashed">Dashed</MenuItem>
              <MenuItem value="dashed-dotted">Dashed-Dotted</MenuItem>
            </Select>
          </FormControl>
        </>
      </>
    );
  }
  const handleColorTabChange = (event, newValue) => {
    setActiveColorTab(newValue);
    console.log("handleColorTabChange:", newValue);
  };
  
  console.log("prevColor",prevColor)
  function tab2() {
    const allyColorProps = (index) => {
      return {
        id: `tab-1-${index}`,
        "aria-controls": `tabColor-${index}`,
      };
    };
    return (
      <>
        <Typography align="center" variant="h6">
          Fill & Stroke
        </Typography>
        <Tabs
          variant="fullWidth"
          value={activeColorTab}
          onChange={handleColorTabChange}
        >
          <Tooltip arrow title="Fill">
            <Tab
              label={<CgColorBucket size={22} />}
              {...allyColorProps(activeTab)}
            />
          </Tooltip>
          <Tooltip arrow title="Stroke">
            <Tab label={<ColorLensOutlined />} {...allyColorProps(activeTab)} />
          </Tooltip>
          <Tooltip arrow title="Opacity">
            <Tab label={<GradientOutlined />} {...allyColorProps(activeTab)} />
          </Tooltip>
        </Tabs>
        <TabPanel
          subClass={"tab-1"}
          type={"tabColor"}
          value={activeColorTab}
          index={0}
        >
         <div style={{ display: 'flex', width:"90%",padding:"10px", alignItems: 'center', justifyContent: 'space-around' }}>
                    <StyledSubMenu>
                      Previous Colour
                      <div style={{
                        width: '100px',
                        height: '30px',
                        border: '1px solid black',
                        background: prevColor.field === "fill" ? prevColor.color : undefined,
                        backgroundColor: prevColor.field === "fill" ? prevColor.color : undefined,
                        marginTop: '5px'
                      }} />
                    </StyledSubMenu>

                    <StyledSubMenu>
                      Current Colour
                      <div style={{
                        width: '100px',
                        height: '30px',
                        border: '1px solid black',
                        background: fillValue ? fillValue : undefined,
                        backgroundColor: !fillValue ? fillValue : undefined,
                        marginTop: '5px'
                      }} />
                    </StyledSubMenu>
                  </div>
          <ColorPicker
            color={fillValue}
            value={fillValue}
            width={280}
            // hideControls hideGradientControls hideInputs hidePresets
            onChange={(newColor) =>{
              console.log("fillValue",fillValue)
                
               if(fillValue !==undefined)
                {
                  setPrevColor((prev) => ({
                  field: "fill",
                  color:fillValue == "rgba(255,0,0, 1)" ? "transparent": fillValue // Store the last used color
                }));
              }
                handlePositionChange("fill", newColor)
              } } // Handle background color change
          /> 
        </TabPanel>
        <TabPanel
          subClass={"tab-1"}
          type={"tabColor"}
          value={activeColorTab}
          index={1}
        >
          <div style={{ display: 'flex', width:"90%",padding:"10px", alignItems: 'center', justifyContent: 'space-around' }}>
                    <StyledSubMenu>
                      Previous Colour
                      <div style={{
                        width: '100px',
                        height: '30px',
                        border: '1px solid black',
                        background: strokePrevColor ? strokePrevColor : undefined,
                        backgroundColor: strokePrevColor ? strokePrevColor : undefined,
                        marginTop: '5px'
                      }} />
                    </StyledSubMenu>

                    <StyledSubMenu>
                      Current Colour
                      <div style={{
                        width: '100px',
                        height: '30px',
                        border: '1px solid black',
                        background: strokeValue ? strokeValue : undefined,
                        backgroundColor: !strokeValue ? strokeValue : undefined,
                        marginTop: '5px'
                      }} />
                    </StyledSubMenu>
                  </div>
          <ColorPicker
            color={strokeValue}
            value={strokeValue}
            width={280}
            // hideControls hideGradientControls hideInputs hidePresets
            onChange={(newColor) =>{

              setStrokePrevColor(strokeValue || "transparent" )
            handlePositionChange("stroke", newColor)}} // Handle background color change
          />
      
        </TabPanel>
        <TabPanel
          subClass={"tab-1"}
          type={"tabColor"}
          value={activeColorTab}
          index={2}
        > <label style={{fontSize:"15px"}}>Please Select a shape to change its opacity.</label>
                <div style={{  marginLeft:"20px",display:"flex", color:"white",}}>
               

                <Slider
                  sx={{
                    marginTop: '10px',
                    maxWidth: '220px',
                    '& .MuiSlider-mark': {
                      backgroundColor: 'blue',
                      height: '8px',
                      width: '2px',
                      '&.MuiSlider-markActive': {
                        opacity: 1,
                        backgroundColor: 'navyblue',
                      },
                    },
                    '& .MuiSlider-markLabel': {
                      color: 'white',
                    },
                  }}
                  value={opacityObj}
                  onChange={(e, newValue) => changeOpacityOfObj(newValue)}
                  step={0.01}
                  min={0}
                  max={1}
                  valueLabelDisplay="auto"
                  marks={marks}
                />
                </div>
              </TabPanel>
      </>
    );
  }

  function tab3() {
    return (
      <>
        {activeObj?.[0].class !== "line" && (
          <>
            <TextField
              label="SkewX"
              type="number"
              value={activeObj?.[0].skewX}
              onChange={(e) =>
                handlePropertyChange("skewX", parseFloat(e.target.value))
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="SkewY"
              type="number"
              value={activeObj?.[0].skewY}
              onChange={(e) =>
                handlePropertyChange("skewY", parseFloat(e.target.value))
              }
              fullWidth
              margin="normal"
            />
          </>
        )}
        {activeObj?.[0].class !== "circle" &&
          activeObj?.[0].class !== "line" && (
            <TextField
              label="Rotation (In Degree)"
              type="number"
              value={activeObj?.[0].angle}
              onChange={(e) =>
                handlePropertyChange("angle", parseFloat(e.target.value))
              }
              fullWidth
              margin="normal"
            />
          )}
        <TextField
          label="X-Axis"
          type="number"
          value={activeObj[0].left}
          onChange={(e) =>
            handlePositionChange("left", parseFloat(e.target.value))
          }
          fullWidth
          margin="normal"
        />
        <TextField
          label="Y-Axis"
          type="number"
          value={activeObj[0].top}
          onChange={(e) =>
            handlePositionChange("top", parseFloat(e.target.value))
          }
          fullWidth
          margin="normal"
        />
      </>
    );
  }

  function tab4() {
    return <Typography align="center">Please Select a Shape</Typography>;
  }
  useEffect(() => {
    if (
      (activeObj?.[0]?.class === "chart" || activeObj?.[0]?.class === "plot") &&
      (activeTab === 1 || activeTab === 2)
    ) {
      setActiveTab(0);
    }
    console.log("handleTabChange: newValue: ", activeTab);
  }, [activeObj?.[0]?.class]);

  const strokeValue =
    typeof activeObj?.[0]?.stroke === "object"
      ? gradientToCssString(activeObj?.[0]?.stroke)
      : activeObj?.[0]?.stroke === "black"? "black" : activeObj?.[0]?.stroke 
  const fillValue =
    typeof activeObj?.[0]?.fill === "object"
      ? gradientToCssString(activeObj?.[0]?.fill)
      : activeObj?.[0]?.fill === "transparent" ? "rgba(255,0,0, 1)" :activeObj?.[0]?.fill  ;

      console.log("fillValue",fillValue)

      useEffect(()=>{
        console.log("activeObj",activeObj)
        if(activeObj?.[0]?.fill === "transparent"){
            setPrevColor((prev) => ({
            field: "fill",
            color:activeObj?.[0]?.fill === "transparent" && "transparent"// Store the last used color
          }));
        } 
        else if(activeObj?.[0]?.stroke === "black"){
          setStrokePrevColor(activeObj?.[0]?.stroke === "black" ? "transparent":null)
        } 
      },[activeObj])
      console.log("StrokePrevColor",strokePrevColor)
  return (
    <ShapeEditorDrawer
      open={open}
      onClose={handleClose}
      variant="permanent"
      anchor="right"
      sx={{
        "& .MuiDrawer-paper": {
          ...(!open && {
            // width: `calc(${theme.spacing(7)} + 1px)`, // Keep tabs visible when closed
            marginTop: `${containerDim.marginTop + 0.45}px`,
            overflow: "hidden",
          }),
          ...(open && {
            width: drawerWidth + 160, // Full width when open
            marginTop: `${containerDim.marginTop + 0.45}px`,
            overflow: "auto",
          }),
        },
      }}
    >
      <div style={{ display: "flex" }}>
        <Tabs
          sx={{
            p: 0,
            justifyContent: "center",
            ...(open ? {} : { alignItems: "center" }),
          }}
          orientation="vertical"
          value={activeTab}
          onChange={handleTabChange}
          aria-label="edit panel tabs"
        >
          {tabs.map((tab, index) => (
            <Tooltip placement="left" key={index} arrow title={tab.title}>
              <Tab
                sx={{
                  ml: 0,
                  p: 0,
                  mt: 4,
                  minWidth: 50,
                }}
                hidden={index === 5 ? true : false}
                label={tab.icon}
                disabled={tab?.disabled}
                {...a11yProps(index)}
              />
            </Tooltip>
          ))}
        </Tabs>

        {open && (
          <TabPanel
            subClass={"tab"}
            type={"tabpanel"}
            value={activeTab}
            index={activeTab}
            sx={{ flexGrow: 1 }}
          >
            <div
              style={{
                display: "flex",
                padding: "0px 10px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TextField
                label="Unit"
                type="number"
                value={activeObj?.[0].unit || "px"}
                onChange={(e) => handlePropertyChange("unit", e.target.value)}
                select
                margin="normal"
              >
                <MenuItem value="px">Pixels (px)</MenuItem>
                <MenuItem value="cm">Centimeters (cm)</MenuItem>
                <MenuItem value="mm">Millimeters (mm)</MenuItem>
                <MenuItem value="in">Inches (in)</MenuItem>
                <MenuItem value="feet">Feet (feet)</MenuItem>
              </TextField>
              <Tooltip
                arrow
                title={
                  activeObj?.length === 1 ? "Delete Object" : "Delete Objects"
                }
              > 
                <Button
                  variant="outlined"
                  sx={{
                    height: 50,
                    width: 50,
                    background: "red",
                    "&:hover": { background: "red" },
                  }}
                  onClick={handleDeleteObject}
                >
                  <Delete />
                </Button>
              </Tooltip>
              <Tooltip arrow title={"Skew Controls"}>
                <Button
                  variant="outlined"
                  sx={{
                    height: 50,
                    width: 50,
                    background: "blue",
                    "&:hover": { background: "blue" },
                  }}
                  onClick={addCustomControls}
                >
                  <TbSkewX size={22} />
                </Button>
              </Tooltip>
            </div>
            <Divider />
            <div
              style={{
                overflow: "auto",
                flexDirection: "column",
                display: "flex",
                height: "390px",
              }}
            >
              {activeTab === 1 && tab0()}
              {activeTab === 2 && tab1()}
              {activeTab === 3 && tab2()}
              {activeTab === 4 && tab3()}
              {!activeObj && activeTab === 5 && tab4()}
            </div>
          </TabPanel>
        )}
      </div>
    </ShapeEditorDrawer>
  );
};
