import { FileCopyOutlined, Home,  InterestsOutlined, StayCurrentLandscape, Pattern, ViewList, MergeTypeRounded, } from '@mui/icons-material';
import { BiColorFill } from 'react-icons/bi';
import { IoShapes } from 'react-icons/io5';
import { TbDimensions, TbScanPosition } from 'react-icons/tb';
export const mainMenus = [
    { label: "File", name: "file", icon: <FileCopyOutlined sx={{fontSize: "20px"}}/>  },
    { label: "Home", name: "home", icon: <Home sx={{fontSize: "20px"}}/> },
    { label: "Canvas", name: "canvas", icon: <StayCurrentLandscape sx={{fontSize: "20px"}}/> },
    { label: "Shapes", name: "shapes", icon: <InterestsOutlined sx={{fontSize: "20px"}}/> },
    { label: "Path", name: "path", icon: <Pattern sx={{fontSize: "20px"}}/> },
    // { label: "Download", name: "download", icon: <DownloadOutlined /> },
    { label: "View", name: "view", icon: <ViewList sx={{fontSize: "20px"}}/> },
    { label: "Combine", name: "combinations", icon: <MergeTypeRounded sx={{fontSize: "20px"}}/> },
    // { label: "Rotation Copy", name: "RotationCopies", icon: <CropRotateIcon /> },
    // { label: "Clipboard", name: "clipboard", icon: <ContentPasteSearchOutlined/> },
  ];
