import { fabric } from "fabric";

export class StraightConnectorLine {
  constructor(canvas, options) {
    this.canvas = canvas;
    this.strokeWidth = options.strokeWidth || 3;

    this.arrowPath = this._createLinePath(
      options.startX,
      options.startY,
      options.endX,
      options.endY
    );

    if (this.arrowPath instanceof fabric.Object) {
      this.canvas.add(this.arrowPath);
    } else {
      console.error("Error: Line path is not a valid Fabric.js object.");
    }

    this._bindEvents();
  }

  _createLinePath(startX, startY, endX, endY) {
    // Line path from start to end (no arrows)
    const pathData = `
      M ${startX} ${startY} 
      L ${endX} ${endY}
    `;

    return new fabric.Path(pathData, {
      stroke: "black",
      strokeWidth: this.strokeWidth,
      fill: "black", // No fill since it's just a line
      objectCaching: false,
      class:"line",
      selectable: true,
      evented: true,
    });
  }

  _bindEvents() {
    if (!this.arrowPath) return;

    this.arrowPath.on("selected", () => {
      console.log("Line selected");
    });

    this.arrowPath.on("deselected", () => {
      console.log("Line deselected");
    });

    this.arrowPath.on("removed", () => {
      console.log("Line removed");
    });
  }
}

export class ArrowConnectorLine {
  constructor(canvas, options) {
    this.canvas = canvas;
    this.strokeWidth = options.strokeWidth || 3;
    this.arrowSize = options.arrowSize || 12; // Adjust arrow size
    this.arrowStrokeWidth = options.arrowStrokeWidth || 1; // Smaller stroke for arrowhead

    this.arrowPath = this._createArrowPath(
      options.startX,
      options.startY,
      options.endX,
      options.endY
    );

    if (this.arrowPath instanceof fabric.Object) {
      this.canvas.add(this.arrowPath);
    } else {
      console.error("Error: Arrow path is not a valid Fabric.js object.");
    }

    this._bindEvents();
  }

  _createArrowPath(startX, startY, endX, endY) {
    const arrowSize = this.arrowSize;
    const angle = Math.atan2(endY - startY, endX - startX);

    // Create the arrow base position at the end point
    const arrowBaseX = endX - (arrowSize * Math.cos(angle));
    const arrowBaseY = endY - (arrowSize * Math.sin(angle));

    // Create the arrowhead with the correct angle and size
    const arrowLeftX = arrowBaseX - arrowSize * Math.cos(angle - Math.PI / 6);
    const arrowLeftY = arrowBaseY - arrowSize * Math.sin(angle - Math.PI / 6);

    const arrowRightX = arrowBaseX - arrowSize * Math.cos(angle + Math.PI / 6);
    const arrowRightY = arrowBaseY - arrowSize * Math.sin(angle + Math.PI / 6);

    // Line path from start to end, and the arrowhead at the end
    const pathData = `
      M ${startX} ${startY} 
      L ${endX} ${endY} 
      M ${arrowLeftX} ${arrowLeftY} 
      L ${endX} ${endY} 
      L ${arrowRightX} ${arrowRightY} 
      Z
    `;

    return new fabric.Path(pathData, {
      stroke: "black",
      strokeWidth: this.strokeWidth,
      fill: "black", // Filled arrow
      objectCaching: false,
      selectable: true,
      class:"line",
      evented: true,
      // Apply smaller stroke for the arrowhead
      strokeWidth: this.arrowStrokeWidth,
    });
  }

  _bindEvents() {
    if (!this.arrowPath) return;

    this.arrowPath.on("selected", () => {
      console.log("Arrow selected");
    });

    this.arrowPath.on("deselected", () => {
      console.log("Arrow deselected");
    });

    this.arrowPath.on("removed", () => {
      console.log("Arrow removed");
    });
  }
}

export class DoubleArrowConnectorLine {
  constructor(canvas, options) {
    this.canvas = canvas;
    this.strokeWidth = options.strokeWidth || 3;
    this.arrowStrokeWidth = options.arrowStrokeWidth || 1; // Smaller stroke for arrowhead

    this.arrowPath = this._createArrowPath(
      options.startX,
      options.startY,
      options.endX,
      options.endY
    );

    if (this.arrowPath instanceof fabric.Object) {
      this.canvas.add(this.arrowPath);
    } else {
      console.error("Error: Arrow path is not a valid Fabric.js object.");
    }

    this._bindEvents();
  }

  _createArrowPath(startX, startY, endX, endY) {
    const arrowSize = 12;  // Arrowhead size
    const angle = Math.atan2(endY - startY, endX - startX);

    // Corrected arrow base position considering stroke width
    const arrowBaseX = endX - (arrowSize * Math.cos(angle));
    const arrowBaseY = endY - (arrowSize * Math.sin(angle));

    const arrowLeftX = arrowBaseX - arrowSize * Math.cos(angle - Math.PI / 6);
    const arrowLeftY = arrowBaseY - arrowSize * Math.sin(angle - Math.PI / 6);

    const arrowRightX = arrowBaseX - arrowSize * Math.cos(angle + Math.PI / 6);
    const arrowRightY = arrowBaseY - arrowSize * Math.sin(angle + Math.PI / 6);

    // Adding an arrow at the start point
    const startArrowBaseX = startX + (arrowSize * Math.cos(angle));
    const startArrowBaseY = startY + (arrowSize * Math.sin(angle));

    const startArrowLeftX = startArrowBaseX + arrowSize * Math.cos(angle - Math.PI / 6);
    const startArrowLeftY = startArrowBaseY + arrowSize * Math.sin(angle - Math.PI / 6);

    const startArrowRightX = startArrowBaseX + arrowSize * Math.cos(angle + Math.PI / 6);
    const startArrowRightY = startArrowBaseY + arrowSize * Math.sin(angle + Math.PI / 6);

    const pathData = `
      M ${startX} ${startY} 
      L ${endX} ${endY}
      M ${arrowLeftX} ${arrowLeftY}
      L ${endX} ${endY}
      L ${arrowRightX} ${arrowRightY}
      M ${startArrowLeftX} ${startArrowLeftY}
      L ${startX} ${startY}
      L ${startArrowRightX} ${startArrowRightY}
      Z
    `;

    return new fabric.Path(pathData, {
      stroke: "black",
      strokeWidth: this.strokeWidth,
      fill: "black", // Filled arrow
      objectCaching: false,
      selectable: true,
      class:"line",
      evented: true,
      
      strokeWidth: this.arrowStrokeWidth,
    });
  }

  _bindEvents() {
    if (!this.arrowPath) return;

    this.arrowPath.on("selected", () => {
      console.log("Arrow selected");
    });

    this.arrowPath.on("deselected", () => {
      console.log("Arrow deselected");
    });

    this.arrowPath.on("removed", () => {
      console.log("Arrow removed");
    });
  }
}

export class CurvedPathConnector {
  constructor(canvas, options) {
    this.canvas = canvas;

    // Create the initial path for the curved line (without the arrow)
    this.path = new fabric.Path(this._createPathString(options), {
      stroke: "black",
      strokeWidth: options.strokeWidth || 3,
      fill: "transparent",
      class: "line",
      selectable: true,
      evented: true,
    });

    // Add the path to the canvas
    this.canvas.add(this.path);

    // Bind movement events to ensure the line can be updated and moved
    this._bindEvents();
  }

  _bindEvents() {
    // Bind movement event to update the line position
    this.path.on("moving", () => {
      this._updatePathPosition();
    });

    // Bind scaling event to update the path when it's scaled
    this.path.on("scaling", () => {
      this._updatePathPosition();
    });
  }

  _updatePathPosition() {
    // This method ensures the position is correct during move or scale
    this.canvas.renderAll();
  }

  _createPathString(options) {
    // Create a Bezier curve string for the curved connector
    const controlPoint1X = (options.startX + options.endX) / 2;
    const controlPoint1Y = options.startY;
    const controlPoint2X = (options.startX + options.endX) / 2;
    const controlPoint2Y = options.endY;

    return `M ${options.startX} ${options.startY} C ${controlPoint1X} ${controlPoint1Y}, ${controlPoint2X} ${controlPoint2Y}, ${options.endX} ${options.endY}`;
  }
}
export class CurvedArrowConnector {
  constructor(canvas, options) {
    this.canvas = canvas;
    this.strokeWidth = options.strokeWidth || 3;
    this.arrowSize = options.arrowSize || 10;

    // Create the initial path for the curved line (with a single arrow at the end)
    this.path = new fabric.Path(this._createPathString(options), {
      stroke: "black",
      strokeWidth: this.strokeWidth,
      fill: "transparent",
      class: "line",
      selectable: true,
      evented: true,
    });

    // Add the path to the canvas
    this.canvas.add(this.path);

    // Bind movement events to ensure the line can be updated and moved
    this._bindEvents();
  }

  _bindEvents() {
    // Bind movement event to update the line position
    this.path.on("moving", () => {
      this._updatePathPosition();
    });

    // Bind scaling event to update the path when it's scaled
    this.path.on("scaling", () => {
      this._updatePathPosition();
    });
  }

  _updatePathPosition() {
    // This method ensures the position is correct during move or scale
    this.canvas.renderAll();
  }

  _createPathString(options) {
    // Create a Bezier curve string for the curved connector
    const controlPoint1X = (options.startX + options.endX) / 2;
    const controlPoint1Y = options.startY;
    const controlPoint2X = (options.startX + options.endX) / 2;
    const controlPoint2Y = options.endY;

    // Only add the end arrow (remove the start arrow)
    const endArrowPath = this._createArrowPath(options.endX, options.endY, true);   // End arrow
    
    return `M ${options.startX} ${options.startY} 
            C ${controlPoint1X} ${controlPoint1Y}, ${controlPoint2X} ${controlPoint2Y}, ${options.endX} ${options.endY} 
            ${endArrowPath}`;
  }

  _createArrowPath(x, y, isEndArrow) {
    const arrowSize = this.arrowSize;

    // For the end arrow, adjust the direction (flip the arrow)
    const arrowTipX = x;
    const arrowX1 = x + (isEndArrow ? -arrowSize / 2 : arrowSize / 2);
    const arrowY1 = y - arrowSize / 2;
    const arrowX2 = x + (isEndArrow ? -arrowSize / 2 : arrowSize / 2);
    const arrowY2 = y + arrowSize / 2;

    // Returning the corrected arrow path to be centered on the curve's end point
    return `M ${arrowTipX} ${y} L ${arrowX1} ${arrowY1} L ${arrowX2} ${arrowY2} Z`;
  }
}

export class CurvedDoubleArrowConnector {
  constructor(canvas, options) {
    this.canvas = canvas;
    this.strokeWidth = options.strokeWidth || 3;
    this.arrowSize = options.arrowSize || 10;

    // Create the initial path for the curved line (with arrows)
    this.path = new fabric.Path(this._createPathString(options), {
      stroke: "black",
      strokeWidth: this.strokeWidth,
      fill: "transparent",
      class: "line",
      selectable: true,
      evented: true,
    });

    // Add the path to the canvas
    this.canvas.add(this.path);

    // Bind movement events to ensure the line can be updated and moved
    this._bindEvents();
  }

  _bindEvents() {
    // Bind movement event to update the line position
    this.path.on("moving", () => {
      this._updatePathPosition();
    });

    // Bind scaling event to update the path when it's scaled
    this.path.on("scaling", () => {
      this._updatePathPosition();
    });
  }

  _updatePathPosition() {
    // This method ensures the position is correct during move or scale
    this.canvas.renderAll();
  }

  _createPathString(options) {
    // Create a Bezier curve string for the curved connector
    const controlPoint1X = (options.startX + options.endX) / 2;
    const controlPoint1Y = options.startY;
    const controlPoint2X = (options.startX + options.endX) / 2;
    const controlPoint2Y = options.endY;

    // Add arrows to the start and end of the curve
    const startArrowPath = this._createArrowPath(options.startX, options.startY, false); // Reverse start arrow
    const endArrowPath = this._createArrowPath(options.endX, options.endY, true);   // Reverse end arrow
    
    return `M ${options.startX} ${options.startY} 
            C ${controlPoint1X} ${controlPoint1Y}, ${controlPoint2X} ${controlPoint2Y}, ${options.endX} ${options.endY} 
            ${startArrowPath} ${endArrowPath}`;
  }

  _createArrowPath(x, y, isEndArrow) {
    const arrowSize = this.arrowSize;

    // For the end arrow, adjust the direction (flip the arrow)
    const arrowTipX = x;
    const arrowX1 = x + (isEndArrow ? -arrowSize / 2 : arrowSize / 2);
    const arrowY1 = y - arrowSize / 2;
    const arrowX2 = x + (isEndArrow ? -arrowSize / 2 : arrowSize / 2);
    const arrowY2 = y + arrowSize / 2;

    // Returning the corrected arrow path to be centered on the curve's start or end point
    return `M ${arrowTipX} ${y} L ${arrowX1} ${arrowY1} L ${arrowX2} ${arrowY2} Z`;
  }
}
export class ElbowConnectorLine {
  constructor(canvas, options) {
    this.canvas = canvas;
    this.strokeWidth = options.strokeWidth || 3;

    // Create the path for the elbow connector (like the straight connector)
    this.path = this._createElbowPath(
      options.startX,
      options.startY,
      options.elbowX,
      options.elbowY,
      options.endX,
      options.endY
    );

    if (this.path instanceof fabric.Object) {
      this.canvas.add(this.path);
    } else {
      console.error("Error: Path is not a valid Fabric.js object.");
    }

    this._bindEvents();
  }

  _createElbowPath(startX, startY, elbowX, elbowY, endX, endY) {
    // Create path for the elbow connector using SVG string
    const pathData = `M ${startX} ${startY} L ${elbowX} ${startY} L ${elbowX} ${endY} L ${endX} ${endY}`;

    return new fabric.Path(pathData, {
      stroke: "black",
      strokeWidth: this.strokeWidth,
      fill: "",
      class:"line",
      objectCaching: false,
      selectable: true,
      evented: true,
    });
  }

  _bindEvents() {
    if (!this.path) return;

    this.path.on("selected", () => {
      console.log("Line selected");
    });

    this.path.on("deselected", () => {
      console.log("Line deselected");
    });

    this.path.on("removed", () => {
      console.log("Line removed");
    });
  }
}

export class ElbowConnectorLineWithArrow {
  constructor(canvas, options) {
    this.canvas = canvas;
    this.strokeWidth = options.strokeWidth || 3;
    this.arrowSize = options.arrowSize || 10; // Default arrow size

    // Create the path for the elbow connector with an integrated arrow
    this.path = this._createElbowPath(
      options.startX,
      options.startY,
      options.elbowX,
      options.elbowY,
      options.endX,
      options.endY
    );

    if (this.path instanceof fabric.Object) {
      this.canvas.add(this.path);
    } else {
      console.error("Error: Path is not a valid Fabric.js object.");
    }

    this._bindEvents();
  }

  _createElbowPath(startX, startY, elbowX, elbowY, endX, endY) {
    // Create the path for the elbow connector, including the arrow
    const pathData = this._createPathString(
      startX,
      startY,
      elbowX,
      elbowY,
      endX,
      endY
    );

    return new fabric.Path(pathData, {
      stroke: "black",
      strokeWidth: this.strokeWidth,
      fill: "",
      class:"line",
      objectCaching: false,
      selectable: true,
      evented: true,
    });
  }

  _createPathString(startX, startY, elbowX, elbowY, endX, endY) {
    // Create path string with elbow and arrow integrated into it
    const arrowPath = this._createArrowPath(endX, endY);
    return `M ${startX} ${startY} L ${elbowX} ${startY} L ${elbowX} ${endY} L ${endX} ${endY} ${arrowPath}`;
  }

  _createArrowPath(endX, endY) {
    // Arrow is always pointing to the right (along the x-axis)
    const arrowAngle = 0; // No rotation, the arrow is pointing right
    const arrowSize = this.arrowSize;

    // Calculate the coordinates for the arrowhead (right direction)
    const arrowX1 = endX - arrowSize;
    const arrowY1 = endY - arrowSize / 2;
    const arrowX2 = endX - arrowSize;
    const arrowY2 = endY + arrowSize / 2;

    // Return the path for the arrowhead with a solid fill color
    return `M ${endX} ${endY} L ${arrowX1} ${arrowY1} L ${arrowX2} ${arrowY2} Z`;
  }

  _bindEvents() {
    if (!this.path) return;

    this.path.on("selected", () => {
      console.log("Line selected");
    });

    this.path.on("deselected", () => {
      console.log("Line deselected");
    });

    this.path.on("removed", () => {
      console.log("Line removed");
    });
  }
}

export class ElbowConnectorLineWithDoubleArrow {
  constructor(canvas, options) {
    this.canvas = canvas;
    this.strokeWidth = options.strokeWidth || 3;
    this.arrowSize = options.arrowSize || 10; // Default arrow size

    // Create the path for the elbow connector with integrated arrows
    this.path = this._createElbowPath(
      options.startX,
      options.startY,
      options.elbowX,
      options.elbowY,
      options.endX,
      options.endY
    );

    if (this.path instanceof fabric.Object) {
      this.canvas.add(this.path);
    } else {
      console.error("Error: Path is not a valid Fabric.js object.");
    }

    this._bindEvents();
  }

  _createElbowPath(startX, startY, elbowX, elbowY, endX, endY) {
    // Create the path for the elbow connector, including the arrows at both ends
    const pathData = this._createPathString(
      startX,
      startY,
      elbowX,
      elbowY,
      endX,
      endY
    );

    return new fabric.Path(pathData, {
      stroke: "black",
      strokeWidth: this.strokeWidth,
      fill: "",
      class:"line",
      objectCaching: false,
      selectable: true,
      evented: true,
    });
  }

  _createPathString(startX, startY, elbowX, elbowY, endX, endY) {
    // Create path string with elbow and two arrows integrated into it
    const startArrowPath = this._createArrowPath(startX, startY, true); // Left arrow at the start
    const endArrowPath = this._createArrowPath(endX, endY, false);   // Right arrow at the end
    
    return `M ${startX} ${startY} L ${elbowX} ${startY} L ${elbowX} ${endY} L ${endX} ${endY} ${startArrowPath} ${endArrowPath}`;
  }

  _createArrowPath(x, y, isStart) {
    // Arrow direction is determined by isStart: true for left, false for right
    const arrowAngle = isStart ? 180 : 0; // Left arrow for start (180 degrees), Right arrow for end (0 degrees)
    const arrowSize = this.arrowSize;

    // Calculate the coordinates for the arrowhead
    const arrowX1 = x + (isStart ? arrowSize : -arrowSize);
    const arrowY1 = y - arrowSize / 2;
    const arrowX2 = x + (isStart ? arrowSize : -arrowSize);
    const arrowY2 = y + arrowSize / 2;

    // Return the path for the arrowhead with a solid fill color
    return `M ${x} ${y} L ${arrowX1} ${arrowY1} L ${arrowX2} ${arrowY2} Z`;
  }

  _bindEvents() {
    if (!this.path) return;

    this.path.on("selected", () => {
      console.log("Line selected");
    });

    this.path.on("deselected", () => {
      console.log("Line deselected");
    });

    this.path.on("removed", () => {
      console.log("Line removed");
    });
  }
}



export class CirclePath {
  constructor(canvas, options) {
    this.canvas = canvas;
    this.startAngle = options.startAngle || 0;
    this.endAngle = options.endAngle || 360;
    this.radius = options.radius || 50;
    this.left = options.left || 0;
    this.top = options.top || 0;
    this.angleType = options.angleType || "arc"; // Default to 'arc'
    this.originX = options.originX;
    this.originY = options.originY;
    this._createCirclePath();

    // Attach instance to path for later use
    this.path.circlePathInstance = this;
  }

  _createCirclePath() {
    // Capture current transformations
    const transforms = this.path
      ? {
          scaleX: this.path.scaleX,
          scaleY: this.path.scaleY,
          angle: this.path.angle,
          skewX: this.path.skewX,
          skewY: this.path.skewY,
          left: this.path.left,
          top: this.path.top,
        }
      : {};

    if (this.path) {
      this.canvas.remove(this.path);
    }

    this.path = new fabric.Path(this._createPathArray(), {
      stroke: "black",
      strokeWidth: 2,
      fill: "transparent",
      selectable: true,
      evented: true,
      radius: this.radius,
      left: this.left,
      top: this.top,
      class: "circle",
      ...transforms, // Reapply the captured transformations
    });

    this.path.setStartAngle = (angle) => {
      this.setStartAngle(angle);
    };

    this.path.setEndAngle = (angle) => {
      this.setEndAngle(angle);
    };

    this.path.setRadius = (radius) => {
      this.setRadius(radius);
    };

    this.path.setAngleType = (type) => {
      this.setAngleType(type);
    };

    this.canvas.add(this.path);
    this.canvas.renderAll();
  }

  _createPathArray() {
    const centerX = this.left + this.radius;
    const centerY = this.top + this.radius;
    const startPoint = this._calculatePoint(this.startAngle);
    const endPoint = this._calculatePoint(this.endAngle);
    const largeArcFlag = this.endAngle - this.startAngle >= 180 ? "1" : "0";
    const sweepFlag = this.endAngle - this.startAngle >= 0 ? "1" : "0";

    let pathArray;
    if (this.angleType === "chord") {
      // Chord segment
      if (Math.abs(this.endAngle - this.startAngle) === 360) {
        pathArray = [
          ["M", startPoint.x, startPoint.y],
          [
            "A",
            this.radius,
            this.radius,
            0,
            largeArcFlag,
            sweepFlag,
            endPoint.x,
            endPoint.y,
          ],
          ["Z"],
        ];
      } else {
        pathArray = [
          ["M", startPoint.x, startPoint.y],
          [
            "A",
            this.radius,
            this.radius,
            0,
            largeArcFlag,
            sweepFlag,
            endPoint.x,
            endPoint.y,
          ],
          ["L", endPoint.x, endPoint.y],
          ["Z"],
        ];
      }
    } else if (
      this.startAngle === this.endAngle ||
      Math.abs(this.endAngle - this.startAngle) === 360
    ) {
      // Full circle
      pathArray = [
        ["M", centerX, centerY - this.radius],
        [
          "A",
          this.radius,
          this.radius,
          0,
          1,
          1,
          centerX,
          centerY + this.radius,
        ],
        [
          "A",
          this.radius,
          this.radius,
          0,
          1,
          1,
          centerX,
          centerY - this.radius,
        ],
      ];
    } else {
      // Arc segment
      pathArray = [
        ["M", startPoint.x, startPoint.y],
        [
          "A",
          this.radius,
          this.radius,
          0,
          largeArcFlag,
          sweepFlag,
          endPoint.x,
          endPoint.y,
        ],
        ["L", centerX, centerY],
        ["Z"],
      ];
    }

    return pathArray;
  }

  _calculatePoint(angle) {
    const rad = fabric.util.degreesToRadians(angle);
    return {
      x: this.left + this.radius + this.radius * Math.cos(rad),
      y: this.top + this.radius + this.radius * Math.sin(rad),
    };
  }

  _updatePath() {
    this._createCirclePath();
  }

  setRadius(radius) {
    this.radius = radius;
    this._updatePath();
  }

  setStartAngle(angle) {
    this.startAngle = angle;
    this._updatePath();
  }

  setEndAngle(angle) {
    this.endAngle = angle;
    this._updatePath();
  }

  setAngleType(type) {
    this.angleType = type;
    this._updatePath();
  }
}

export class SnippedRectangle {
  constructor(canvas, options) {
    this.canvas = canvas;

    // Create a regular rectangle
    this.rectangle = new fabric.Rect({
      left: options.left,
      top: options.top,
      width: options.width,
      height: options.height,
      fill: options.fill || "transparent",
      stroke: options.stroke || "black",
      strokeWidth: options.strokeWidth || 1,
      selectable: options.selectable !== undefined ? options.selectable : true,
      evented: options.evented !== undefined ? options.evented : true,
      originX: "left",
      originY: "top",
    });

    // Create a clipping path to snip the top-right corner
    const clipPath = new fabric.Path(
      `M 0 0 L ${options.width - options.cornerRadius} 0 L ${options.width} ${
        options.cornerRadius
      } L ${options.width} ${options.height} L 0 ${options.height} Z`,
      {
        top: options.top,
        left: options.left,
        fill: "transparent",
        stroke: "transparent",
      }
    );

    this.rectangle.set({
      clipPath: clipPath,
    });

    // Add objects to the canvas
    this.canvas.add(this.rectangle);

    // Log object to verify creation and addition
    console.log("Rectangle added to canvas:", this.rectangle);
  }
}

export class RoundedPolygon {
  constructor(canvas, options) {
    this.canvas = canvas;
    this.cornerRadius = options.cornerRadius || 10;
    this.points = options.points || [];

    // Create the path with rounded corners
    this.path = new fabric.Path(this.createPathData(), {
      left: options.left,
      top: options.top,
      fill: options.fill || "transparent",
      stroke: options.stroke || "black",
      strokeWidth: options.strokeWidth || 1,
      selectable: options.selectable !== undefined ? options.selectable : true,
      evented: options.evented !== undefined ? options.evented : true,
    });

    // Add the path to the canvas
    this.canvas.add(this.path);
    this.canvas.renderAll(); // Ensure the canvas is rendered
  }

  // Method to create the path data for the polygon with rounded corners
  createPathData() {
    const { points, cornerRadius } = this;
    if (points.length < 3) return ""; // Not enough points to create a polygon

    let pathData = "";

    // Helper function to create rounded corner path data
    const createRoundedCornerPath = (p1, p2, p3, radius) => {
      const dx1 = p1.x - p2.x;
      const dy1 = p1.y - p2.y;
      const dx2 = p3.x - p2.x;
      const dy2 = p3.y - p2.y;

      const angle = (Math.atan2(dy1, dx1) - Math.atan2(dy2, dx2)) / 2;
      const tan = Math.abs(Math.tan(angle));
      const segment = radius / tan;

      const length1 = Math.sqrt(dx1 * dx1 + dy1 * dy1);
      const length2 = Math.sqrt(dx2 * dx2 + dy2 * dy2);
      const length = Math.min(length1, length2);

      if (segment > length) {
        radius = length * tan;
      }

      const getProportionPoint = (point, segment, length, dx, dy) => {
        const factor = segment / length;
        return {
          x: point.x - dx * factor,
          y: point.y - dy * factor,
        };
      };

      const p1Cross = getProportionPoint(p2, segment, length1, dx1, dy1);
      const p2Cross = getProportionPoint(p2, segment, length2, dx2, dy2);

      const circleCenter = {
        x: p2.x * 2 - p1Cross.x - p2Cross.x,
        y: p2.y * 2 - p1Cross.y - p2Cross.y,
      };

      const dx = p2.x * 2 - p1Cross.x - p2Cross.x;
      const dy = p2.y * 2 - p1Cross.y - p2Cross.y;
      const d = Math.sqrt(dx * dx + dy * dy);

      const circlePoint = getProportionPoint(p2, d, radius, dx, dy);

      const startAngle = Math.atan2(
        p1Cross.y - circlePoint.y,
        p1Cross.x - circlePoint.x
      );
      const endAngle = Math.atan2(
        p2Cross.y - circlePoint.y,
        p2Cross.x - circlePoint.x
      );
      const sweepAngle = endAngle - startAngle;

      return `M${p1Cross.x},${p1Cross.y} A${radius},${radius} 0 0,1 ${p2Cross.x},${p2Cross.y} L${p2.x},${p2.y}`;
    };

    // Loop through the points to generate the path
    for (let i = 0; i < points.length; i++) {
      const p1 = points[i];
      const p2 = points[(i + 1) % points.length];
      const p3 = points[(i + 2) % points.length];

      if (i === 0) {
        pathData += `M${p1.x},${p1.y}`;
      }

      pathData += createRoundedCornerPath(p1, p2, p3, cornerRadius);
    }

    pathData += "Z"; // Close the path

    return pathData;
  }

  // Method to update the corner radius
  updateCornerRadius(newRadius) {
    this.cornerRadius = newRadius;
    this.path.set({ path: this.createPathData() });
    this.canvas.renderAll();
  }
}
