import { fabric } from 'fabric';
import { fabricPathToSVG, togglePathSelect } from '../Components/Tools/paintTools';
import { Bezier } from 'bezier-js';

export function drawDashedLine(canvas, path, point1, point2, lineObj = null) {
  let fabricObject = path;
  let p1AbsX = fabricObject.path[point1.pointIndex][point1.cmdIndex] - fabricObject.pathOffset.x;
  let p1AbsY = fabricObject.path[point1.pointIndex][point1.cmdIndex + 1] - fabricObject.pathOffset.y;
  let p2AbsX = fabricObject.path[point2.pointIndex][point2.cmdIndex] - fabricObject.pathOffset.x;
  let p2AbsY = fabricObject.path[point2.pointIndex][point2.cmdIndex + 1] - fabricObject.pathOffset.y;
  console.log('drawLine: fabricObject.canvas:', fabricObject.canvas, fabricObject.canvas?.viewportTransform)
  const zoom = fabricObject.canvas.getZoom()
  const transformedPoint1 = fabric.util.transformPoint(
    { x: p1AbsX, y: p1AbsY },
    fabric.util.multiplyTransformMatrices(fabricObject.canvas?.viewportTransform, fabricObject.calcTransformMatrix())
  );
  const transformedPoint2 = fabric.util.transformPoint(
    { x: p2AbsX, y: p2AbsY },
    fabric.util.multiplyTransformMatrices(fabricObject.canvas.viewportTransform, fabricObject.calcTransformMatrix())
  );
  console.log('drawLine: transformedPoints:', transformedPoint1, transformedPoint2, fabricObject)

  if (lineObj) {
    lineObj.set({
      x1: transformedPoint1.x / zoom,
      y1: transformedPoint1.y / zoom,
      x2: transformedPoint2.x / zoom,
      y2: transformedPoint2.y / zoom,
    });
    canvas.requestRenderAll();
    return lineObj;
  }
  console.log('drawLine: props:', point1, point2, lineObj)
  console.log('drawLine: Line Coords:', transformedPoint1.x / zoom, transformedPoint1.y / zoom, transformedPoint2.x / zoom, transformedPoint2.y / zoom)

  const line = new fabric.Line([transformedPoint1.x / zoom, transformedPoint1.y / zoom, transformedPoint2.x / zoom, transformedPoint2.y / zoom], {
    stroke: 'black',
    strokeWidth: 2,
    class: 'curveLine',
    strokeDashArray: [5, 5],
    selectable: false,
    evented: false,
  });

  console.log('drawLine: line:', line)

  canvas.add(line);
  return line;
}



function moveOppositeControl(curveControlSet, movedControl, canvas, polygon) {
  const { control1, control2, endControl } = curveControlSet;
  console.log('actionHandler: moveOppositeControl: ', curveControlSet, endControl, polygon)

  // Define the opposite control
  const oppositeControl = movedControl === control1 ? control2 : control1;

  // Get the vector from the central (end) control to the moved control
  const dx = polygon.path[movedControl.pointIndex][movedControl.cmdIndex] - polygon.path[endControl.pointIndex][endControl.cmdIndex];
  const dy = polygon.path[movedControl.pointIndex][movedControl.cmdIndex + 1] - polygon.path[endControl.pointIndex][endControl.cmdIndex + 1];

  // Calculate the angle and distance of the moved control relative to the central point
  const movedControlAngle = Math.atan2(dy, dx);
  const movedControlDistance = Math.sqrt(dx * dx + dy * dy);

  // Get the current distance and angle of the opposite control relative to the central point
  const oppositeDx = polygon.path[oppositeControl.pointIndex][oppositeControl.cmdIndex] - polygon.path[endControl.pointIndex][endControl.cmdIndex];
  const oppositeDy = polygon.path[oppositeControl.pointIndex][oppositeControl.cmdIndex + 1] - polygon.path[endControl.pointIndex][endControl.cmdIndex + 1];
  const oppositeDistance = Math.sqrt(oppositeDx * oppositeDx + oppositeDy * oppositeDy);

  // Maintain the opposite control's distance but adjust its angle to be symmetrical
  const newOppositeAngle = movedControlAngle + Math.PI;  // Maintain the straight line

  const newOppositeX = polygon.path[endControl.pointIndex][endControl.cmdIndex] + oppositeDistance * Math.cos(newOppositeAngle);
  const newOppositeY = polygon.path[endControl.pointIndex][endControl.cmdIndex + 1] + oppositeDistance * Math.sin(newOppositeAngle);

  // Update the path for the opposite control point
  polygon.path[oppositeControl.pointIndex][oppositeControl.cmdIndex] = newOppositeX;
  polygon.path[oppositeControl.pointIndex][oppositeControl.cmdIndex + 1] = newOppositeY;

  canvas.requestRenderAll();
}





function moveControlsWithEndControl(curveControlSet, endControl, canvas, polygon) {
  const { control1, control2 } = curveControlSet;

  const prevEndX = endControl.prevX || polygon.path[endControl.pointIndex][endControl.cmdIndex];
  const prevEndY = endControl.prevY || polygon.path[endControl.pointIndex][endControl.cmdIndex + 1];

  const endX = polygon.path[endControl.pointIndex][endControl.cmdIndex];
  const endY = polygon.path[endControl.pointIndex][endControl.cmdIndex + 1];

  const deltaX = endX - prevEndX;
  const deltaY = endY - prevEndY;

  polygon.path[control1.pointIndex][control1.cmdIndex] += deltaX;
  polygon.path[control1.pointIndex][control1.cmdIndex + 1] += deltaY;

  polygon.path[control2.pointIndex][control2.cmdIndex] += deltaX;
  polygon.path[control2.pointIndex][control2.cmdIndex + 1] += deltaY;

  // Store the current position of endControl as the previous position for the next move
  endControl.prevX = endX;
  endControl.prevY = endY;

  // Request canvas render update
  canvas.requestRenderAll();
}







function updateCurveControlLines(polygon, canvas) {
  console.log('updateCurveControlLines: ', polygon, canvas);
  Object.keys(polygon.curveControls).forEach((key) => {
    let curveControl = polygon.curveControls[key]
    console.log('updateCurveControlLines: curveControl:', key);

    drawDashedLine(
      canvas,
      polygon,
      curveControl.control1,
      curveControl.endControl,
      curveControl.lines.mainLine1 || curveControl.lines.lastLine1
    );

    drawDashedLine(
      canvas,
      polygon,
      curveControl.endControl,
      curveControl.control2,
      curveControl.lines.mainLine2 || curveControl.lines.lastLine2
    );
  });
}

export function createBezierControl(canvas, path, pointIndex, position, cmdIndex, handlesConnected, i, lineControls = null) {
  const control = new fabric.Control({
    cornerSize: 10,
    fill: cmdIndex == 5 ? 'red':"violet",
    handlesConnected: handlesConnected,
    selectable: true,
    left: position.x,
    point: cmdIndex == 5 ? path.path[pointIndex] : [],
    top: position.y,
    controlIndex: i * cmdIndex,
    pointIndex: pointIndex,
    cmdIndex: cmdIndex,
    lineControls: lineControls,
    render(ctx, left, top) {
      const size = this.cornerSize;
      ctx.save();
      ctx.fillStyle = this.fill;
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.arc(left, top, size / 2, 0, 2 * Math.PI, false);
      ctx.fill();
      ctx.stroke();
      ctx.restore();
    },
    positionHandler(dim, finalMatrix, fabricObject) {
      const x = fabricObject.path[this.pointIndex][this.cmdIndex] - fabricObject.pathOffset.x;
      const y = fabricObject.path[this.pointIndex][this.cmdIndex + 1] - fabricObject.pathOffset.y;
      const zoom = fabricObject.canvas.getZoom()
      // Transform the control point to absolute canvas space
      const transformedPoint = fabric.util.transformPoint(
        { x, y },
        fabric.util.multiplyTransformMatrices(fabricObject.canvas.viewportTransform, fabricObject.calcTransformMatrix())
      );

      this.absoluteX = transformedPoint.x / zoom;
      this.absoluteY = transformedPoint.y / zoom;
      // console.log('Absolute position after transform:', fabricObject, x, y, this.absoluteX, this.absoluteY);

      return transformedPoint;
    },
    actionHandler(eventData, transform, x, y) {
      const polygon = transform.target;
      const currentControl = polygon.controls[polygon.__corner];
      const currentCurveControlSet = polygon.curveControls?.[currentControl?.curveSetIndex];

      if (!polygon.path || !polygon.path[currentControl.pointIndex]) {
        console.warn('Path or path point is undefined during action.');
        return false;
      }

      const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
      const polygonBaseSize = getObjectSizeWithStroke(polygon);
      const size = polygon._getTransformedDimensions(0, 0);

      const finalPointPosition = {
        x: (mouseLocalPosition.x * polygonBaseSize.x) / size.x + polygon.pathOffset.x,
        y: (mouseLocalPosition.y * polygonBaseSize.y) / size.y + polygon.pathOffset.y,
      };

      polygon.path[currentControl.pointIndex][currentControl.cmdIndex] = finalPointPosition.x;
      polygon.path[currentControl.pointIndex][currentControl.cmdIndex + 1] = finalPointPosition.y;
      if (currentControl.handlesConnected && currentCurveControlSet) {
        if (currentControl.controlIndex === currentCurveControlSet.endControl.controlIndex) {
          moveControlsWithEndControl(currentCurveControlSet, currentControl, canvas, polygon);
        } else if (
          currentControl.controlIndex === currentCurveControlSet.control1.controlIndex ||
          currentControl.controlIndex === currentCurveControlSet.control2.controlIndex
        ) {
          moveOppositeControl(currentCurveControlSet, currentControl, canvas, polygon);
        }
      }

      recalculateBoundingBox(polygon, canvas);
      polygon.set({ objectCaching: false });
      polygon.dirty = true;
      canvas.renderAll(); // Update canvas in real-time

      return true;
    },
  });

  path.controls['p' + pointIndex + '-' + cmdIndex] = control;
  return control;
}


// export function createBezierControl(canvas, path, pointIndex, position, cmdIndex, handlesConnected, i, lineControls = null) {
//   const control = new fabric.Control({
//     cornerSize: 10,
//     fill: 'violet',
//     handlesConnected: handlesConnected,
//     selectable: true,
//     left: position.x,
//     point: cmdIndex == 5 ? path.path[pointIndex] : [],
//     top: position.y,
//     controlIndex: i * cmdIndex,
//     pointIndex: pointIndex,
//     cmdIndex: cmdIndex,
//     lineControls: lineControls,
//     render(ctx, left, top) {
//       const size = this.cornerSize;
//       ctx.save();
//       ctx.fillStyle = this.fill;
//       ctx.strokeStyle = 'black';
//       ctx.lineWidth = 1;
//       ctx.beginPath();
//       ctx.arc(left, top, size / 2, 0, 2 * Math.PI, false);
//       ctx.fill();
//       ctx.stroke();
//       ctx.restore();
//     },
//     positionHandler(dim, finalMatrix, fabricObject) {
//       const x = fabricObject.path[this.pointIndex][this.cmdIndex] - fabricObject.pathOffset.x;
//       const y = fabricObject.path[this.pointIndex][this.cmdIndex + 1] - fabricObject.pathOffset.y;

//       // Transform the control point to absolute canvas space
//       const transformedPoint = fabric.util.transformPoint(
//         { x, y },
//         fabric.util.multiplyTransformMatrices(fabricObject.canvas.viewportTransform, fabricObject.calcTransformMatrix())
//       );

//       // this.absoluteX = transformedPoint.x / 0.7;
//       // this.absoluteY = transformedPoint.y / 0.7;
//       console.log('Absolute position after transform:', fabricObject, x, y, this.absoluteX, this.absoluteY);

//       return transformedPoint;
//     },
//     actionHandler(eventData, transform, x, y) {
//       const polygon = transform.target;
//       const currentControl = polygon.controls[polygon.__corner];
//       const currentCurveControlSet = polygon.curveControls[currentControl.curveSetIndex];
//       console.log('actionHandler: eventData:', eventData);

//       console.log('actionHandler: currentControl, currentCurveControlSet:', polygon.__corner, currentControl, currentCurveControlSet)
//       if (!polygon.path || !polygon.path[currentControl.pointIndex]) {
//         console.warn('Path or path point is undefined during action.');
//         return false; 
//       }

//       const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
//       const polygonBaseSize = getObjectSizeWithStroke(polygon);
//       const size = polygon._getTransformedDimensions(0, 0);

//       const finalPointPosition = {
//         x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
//         y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y,
//       };

//       polygon.path[currentControl.pointIndex][currentControl.cmdIndex] = finalPointPosition.x;
//       polygon.path[currentControl.pointIndex][currentControl.cmdIndex + 1] = finalPointPosition.y;

//       if (currentControl.handlesConnected) {
//         if (currentControl.controlIndex === currentCurveControlSet.endControl.controlIndex) {
//           console.log('actionHandler: endControl is moved: ', currentControl)
//           moveControlsWithEndControl(currentCurveControlSet, currentControl, canvas, polygon);
//         }
//         if (currentControl.controlIndex === currentCurveControlSet.control1.controlIndex || currentControl.controlIndex === currentCurveControlSet.control2.controlIndex) {
//           moveOppositeControl(currentCurveControlSet, currentControl, canvas, polygon);
//         }
//       }
//       if (polygon.filter) {
//         chalkAndSponge(polygon, canvas, polygon.zoomForNodes, path.filterType);
//       }

//       console.log('actionHandler: new coords for moved point:', polygon.path[pointIndex])
//       // updateCurveControlLines(polygon, canvas);
//       recalculateBoundingBox(polygon, canvas);
//       polygon.set({ dirty: true });
//       polygon.canvas.requestRenderAll();
//       return true;
//     },

//   });

//   path.controls['p' + pointIndex + '-' + cmdIndex] = control;
//   return control;
// }

export const createPointControls = (canvas, path, setActiveNodes, activeNodes, handlesConnected, isCircularize = false) => {
  if (!path || !path.path) {
    console.error('Path object is missing or invalid.');
    return;
  }
  console.log('isCircularize: ', isCircularize);
  if (path.curveControls) {
    console.log('isCircularize: path.curveControls:', path.curveControls);

    removeCustomControls(canvas, path);

  }
  const pathData = path.path.map(([cmd, ...points]) => {
    if (cmd === 'M' || cmd === 'L') {
      return { x: points[0], y: points[1] };
    } else if (cmd === 'C' || cmd === 'c') {
      if (isCircularize == true) {
        return {
          x: points[4],
          y: points[5]
        }
      }
      return {
        control1: { x: points[0], y: points[1] },
        control2: { x: points[2], y: points[3] },
        end: { x: points[4], y: points[5] },
      };
    } else if (cmd.toLowerCase() === 'a') {
      return { x: points[6], y: points[7] }
    }
    return { x: 0, y: 0 };
  });
  console.log('isCircularize: pathData:', pathData);


  path.edit = true;
  path.controls = {};
  path.curveControls = {};
  let prevEndControl = null;
  let prevControl2 = null;
  let firstCurve = null;
  let curveInd = 0;
  path.path.forEach((point, index) => {
    console.log('isCircularize: point[0]:', point[0], isCircularize);

    if ((point[0] === 'C' || point[0] === 'c') && isCircularize == false) {
      const control1Pos = pathData[index].control1;
      const control2Pos = pathData[index].control2;
      const endPos = pathData[index].end;
      let i = 1;

      const control1 = createBezierControl(canvas, path, index, control1Pos, 1, handlesConnected, i);
      const control2 = createBezierControl(canvas, path, index, control2Pos, 3, handlesConnected, i);
      const endControl = createBezierControl(canvas, path, index, endPos, 5, handlesConnected, i);
      i += 1;
      console.log('createCPoint: Control Points:', { control1, control2, endControl });

      if (control1 && control2 && endControl) {
        let mainLine1 = null;
        let mainLine2 = null;

        if (prevEndControl && prevControl2) {
          // Check if the previous control points have defined absolute coordinates

          console.log('createCPoint: prevEndControl,prevControl2:', prevEndControl, prevControl2);
          console.log('createCPoint: prevEndControl,prevControl2: absolutes:', prevEndControl.absoluteX, prevControl2.absoluteX);

          mainLine1 = drawDashedLine(canvas, path, prevControl2, prevEndControl);
          mainLine2 = drawDashedLine(canvas, path, prevEndControl, control1);
          path.curveControls[index] = {
            control1: control1,
            endControl: prevEndControl,
            control2: prevControl2,
            lines: { mainLine1, mainLine2 },
          };
          curveInd = index;
          control1.curveSetIndex = curveInd;
          prevControl2.curveSetIndex = curveInd;
          prevEndControl.curveSetIndex = curveInd;

          console.log('createCPoint: path.curveControls, path.curveControls[index]:', path.curveControls[index]);

        }

        console.log('createCPoint: Lines:', { mainLine1, mainLine2 });

        prevEndControl = endControl;
        prevControl2 = control2;

        // console.log(`createCPoint: path.curveControls[index]: ${index}:`, path.curveControls);

        if (!firstCurve) {
          firstCurve = {
            control1,
            control2,
            endControl,
          };
        }
      } else {
        console.warn('One or more control points are undefined:', { control1, control2, endControl });
      }
    }


    else if (point[0] !== 'Z' || point[0] !== 'z') {
      const zoom = canvas.getZoom()
      const cp = new fabric.Control({
        actionName: 'modifyPolygon',
        pointIndex: index,
        cornerSize: 10,
        fill: 'red', // Default fill color
        selectable: true,
        point: point,
        evented: true,
        hoverCursor: 'pointer',
        left: pathData[index].x,
        top: pathData[index].y,
        render(ctx, left, top, styleOverride, fabricObject) {
          const size = this.cornerSize;
          ctx.save();
          ctx.fillStyle = this.fill;
          ctx.strokeStyle = 'black';
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.arc(left, top, size / 2, 0, 2 * Math.PI, false);
          ctx.fill();
          ctx.stroke();
          ctx.restore();
        },
        positionHandler(dim, finalMatrix, fabricObject) {
          if (!fabricObject?.path || !fabricObject?.path[this.pointIndex]) {
            console.warn('Path or path point is undefined.');
            return { x: 0, y: 0 };
          }

          let x = fabricObject.path[this.pointIndex][1] - fabricObject.pathOffset.x;
          let y = fabricObject.path[this.pointIndex][2] - fabricObject.pathOffset.y;
          const zoom = fabricObject.canvas.getZoom()
          const absolutePosition = fabric.util.transformPoint(
            { x, y },
            fabric.util.multiplyTransformMatrices(fabricObject.canvas.viewportTransform, fabricObject.calcTransformMatrix())
          );
          this.absoluteX = absolutePosition.x / zoom;
          this.absoluteY = absolutePosition.y / zoom;

          return absolutePosition;
        },
        actionHandler(eventData, transform, x, y) {
          const polygon = transform.target;
          const currentControl = polygon.controls[polygon.__corner];

          if (!polygon.path || !polygon.path[currentControl.pointIndex]) {
            console.warn('Path or path point is undefined during action.');
            return;
          }

          const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
          const polygonBaseSize = getObjectSizeWithStroke(polygon);
          const size = polygon._getTransformedDimensions(0, 0);

          const finalPointPosition = {
            x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
            y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y,
          };

          polygon.path[currentControl.pointIndex][1] = finalPointPosition.x;
          polygon.path[currentControl.pointIndex][2] = finalPointPosition.y;

          if (polygon.filter) {
            chalkAndSponge(polygon, canvas, polygon.zoomForNodes, path.filterType, activeNodes, setActiveNodes);
          }

          recalculateBoundingBox(polygon, polygon.canvas);
          polygon.dirty = true;
          polygon.canvas.requestRenderAll();
          return true;

        },
      });

      cp.absoluteX = pathData[index].x / zoom;
      cp.absoluteY = pathData[index].y / zoom;

      path.controls['p' + index] = cp;
      path.selectable = true;
    }
  });

  let distance = (p1, p2) =>
    Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));

  const isPointOnLine = (p1, p2, clickPoint, tolerance = 5) => {
    console.log('isPointOnLine: ', p1, p2, clickPoint);

    const d1 = distance({ x: p1.x, y: p1.y }, clickPoint);
    const d2 = distance({ x: p2.x, y: p2.y }, clickPoint);
    const lineLength = distance({ x: p1.x, y: p1.y }, { x: p2.x, y: p2.y });
    console.log('isPointOnLine: d1,d2,lineLength:', d1, d2, lineLength);
    console.log('isPointOnLine: return:', Math.abs(d1 + d2 - lineLength) <= tolerance);
    return Math.abs(d1 + d2 - lineLength) <= tolerance;
  };

  const isPointOnControl = (p1, clickPoint, tolerance = 5) => {
    let cp = { x: p1.x, y: p1.y }
    const distance1 = distance(cp, clickPoint);
    console.log('isPointOnControl: ', cp, clickPoint, distance1);

    if (distance1 <= tolerance) {
      return true;
    } else {
      return false;
    }

  }

  console.log('createCPoint: firstCurve: ', firstCurve);

  if (prevEndControl && firstCurve && firstCurve.control1) {

    // const globalPrevControl2 = getTransformedPoint(prevControl2, path,canvas);
    // const globalPrevEndControl = getTransformedPoint(prevEndControl, path,canvas);
    // const globalFirstControl1 = getTransformedPoint(firstCurve.control1, path,canvas);

    const lastLine1 = drawDashedLine(canvas, path, prevControl2, prevEndControl);
    const lastLine2 = drawDashedLine(canvas, path, prevEndControl, firstCurve.control1);

    path.curveControls[1] = {
      control1: firstCurve.control1,
      endControl: prevEndControl,
      control2: prevControl2,
      lines: { lastLine1, lastLine2 },
    };
    firstCurve.control1.curveSetIndex = 1;
    prevControl2.curveSetIndex = 1;
    prevEndControl.curveSetIndex = 1;

    console.log('createCPoint: Last Lines: ', lastLine1, lastLine2, path.curveControls[1]);
  }
  console.log('createCPoint: path.curveControls, path.curveControls[index]: final curveCs', path.curveControls);

  canvas.on('object:moving', (opt) => {
    if (!path.edit) return;

    const movingObject = opt.target;
    console.log('createCPoint: object:moving: path.curveControls: ', path.curveControls);

    // Update the lines dynamically during movement
    if (path?.curveControls) {
      Object.keys(path?.curveControls).forEach((key) => {
        const controlSet = path.curveControls[key];
        // const globalControl1 = getTransformedPoint(controlSet.control1, path,canvas);
        // const globalControl2 = getTransformedPoint(controlSet.control2, path,canvas);
        // const globalEndControl = getTransformedPoint(controlSet.endControl, path,canvas);
        console.log('createCPoint: object:moving: controlSet: ', key, controlSet);
        if (controlSet.lines.mainLine1 && controlSet.lines.mainLine2) {
          drawDashedLine(canvas, path, controlSet.control1, controlSet.endControl, controlSet.lines.mainLine1);
          drawDashedLine(canvas, path, controlSet.endControl, controlSet.control2, controlSet.lines.mainLine2);
        }
        else if (controlSet.lines.lastLine1 && controlSet.lines.lastLine2) {
          drawDashedLine(canvas, path, controlSet.control1, controlSet.endControl, controlSet.lines.lastLine1);
          drawDashedLine(canvas, path, controlSet.endControl, controlSet.control2, controlSet.lines.lastLine2);
        }
      });
    }

    canvas.requestRenderAll();
  });



  const isPointOnCurve = (p0, p1, p2, p3, clickPoint, tolerance, path) => {

    console.log(
      "createPointControls: isPointOnCurve: p0, p1, p2, p3:",
      p0, p1, p2, p3
    );
    const bezier = new Bezier(p0.x, p0.y, p1.x, p1.y, p2.x, p2.y, p3.x, p3.y);
    console.log(
      "createPointControls: isPointOnCurve: bezier:",
      bezier
    );

    const projection = bezier.project(clickPoint);

    const distanceToClick = Math.sqrt(
      Math.pow(clickPoint.x - projection.x, 2) +
      Math.pow(clickPoint.y - projection.y, 2)
    );

    const hull = bezier.hull(projection.t);
    path.set({
      hullPoint: hull
    });


    console.log(
      "createPointControls: isPointOnCurve: clickPoint, projection, distanceToClick:",
      clickPoint,
      projection,
      distanceToClick
    );


    return distanceToClick <= tolerance;
  };
  canvas.on("mouse:down", (opt) => {
    if (!path.edit) return;
  
    const pointer = canvas.getPointer(opt.e);
    const clickPoint = { x: pointer.x, y: pointer.y };
  
    let foundControl = false;
    let foundVertex = false;
  
    const controlKeys = Object.keys(path.controls).filter((key) => {
      return /^p\d+$/.test(key) || /^p\d+-5$/.test(key);
    });
  
    // Reset all control and vertex colors to red
    controlKeys.forEach((key) => {
      path.controls[key].fill = "red";
    });
  
    // Check for clicked control points
    controlKeys.forEach((key) => {
      const control = path.controls[key];
      if (
        control.absoluteX &&
        isPointOnControl(
          { x: control.absoluteX, y: control.absoluteY },
          clickPoint
        )
      ) {
        control.fill = "blue";
        foundControl = true;
        if (setActiveNodes) {
          setActiveNodes([control]);
        }
        return;
      }
    });
  
    let mCoords = { x: 0, y: 0 };
  
    // Check for clicked vertices
    if (!foundControl) {
      controlKeys.forEach((key, index, arr) => {
        if (index < arr.length - 1) {
          const control1 = path.controls[key];
          const control2 = path.controls[arr[index + 1]];
  
          if (control1.point && control1.point[0] === "M") {
            mCoords = { x: control1.absoluteX, y: control1.absoluteY };
          }
  
          if (control1.point && control2.point && control1.point[0] === "C") {
            const [p0, p1, p2, p3] = [
              control1,
              path.controls[`p${control1.pointIndex + 1}-1`] || path.controls[`p${control1.pointIndex}-3`],
              path.controls[`p${control1.pointIndex + 1}-3`] || path.controls[`p${control1.pointIndex}-1`],
              control2.point[0] === "Z"
                ? { absoluteX: mCoords.x, absoluteY: mCoords.y }
                : control2,
            ];
  
            if (
              isPointOnCurve(
                { x: p0.absoluteX, y: p0.absoluteY },
                { x: p1.absoluteX, y: p1.absoluteY },
                { x: p2.absoluteX || 0, y: p2.absoluteY || 0 },
                { x: p3.absoluteX, y: p3.absoluteY },
                clickPoint,
                5, path
              )
            ) {
              control1.fill = "green";
              control2.fill = "green";
              foundVertex = true;
              if (setActiveNodes) {
                setActiveNodes([control1, control2]);
              }
            }
          } else if (
            isPointOnLine(
              { x: control1.absoluteX, y: control1.absoluteY },
              { x: control2.absoluteX, y: control2.absoluteY },
              clickPoint
            )
          ) {
            control1.fill = "green";
            control2.fill = "green";
            foundVertex = true;
            if (setActiveNodes) {
              setActiveNodes([control1, control2]);
            }
          }
        }
      });
  
      // Handle closing line (last to first)
      const firstControl = path.controls[controlKeys[0]];
      const lastControl = path.controls[controlKeys[controlKeys.length - 1]];
      if (
        !foundVertex &&
        isPointOnLine(
          { x: firstControl.absoluteX, y: firstControl.absoluteY },
          { x: lastControl.absoluteX, y: lastControl.absoluteY },
          clickPoint
        )
      ) {
        firstControl.fill = "green";
        lastControl.fill = "green";
        foundVertex = true;
        if (setActiveNodes) {
          setActiveNodes([firstControl, lastControl]);
        }
      }
    }
  
    // Render changes only if any control or vertex was found
    if (foundControl || foundVertex) {
      canvas.requestRenderAll();
    }
  });

  console.log("createPointControls: path: ", path)
  canvas.setActiveObject(path);

  canvas.requestRenderAll();
};


export function recalculateBoundingBox(polygon, canvas) {
  const xValues = [];
  const yValues = [];

  if (polygon.curveControls) {
    updateCurveControlLines(polygon, canvas);
  }

  polygon.path.forEach(point => {
    if (point[0] !== 'Z' && point[0] !== 'z') {
      if (point[0] === 'C' || point[0] === 'c') {
        xValues.push(point[1], point[3], point[5]);
        yValues.push(point[2], point[4], point[6]);
      } else {
        xValues.push(point[1]);
        yValues.push(point[2]);
      }
    }
  });

  console.log('recalculateBoundingBox: ', xValues, yValues);

  const minX = Math.min(...xValues);
  const maxX = Math.max(...xValues);
  const minY = Math.min(...yValues);
  const maxY = Math.max(...yValues);

  console.log('recalculateBoundingBox: minX, maxX, minY, maxY:', minX, maxX, minY, maxY);

  const newWidth = maxX - minX;
  const newHeight = maxY - minY;

  // Calculate the new pathOffset
  const newPathOffset = {
    x: minX + newWidth / 2,
    y: minY + newHeight / 2,
  };

  // Adjust the `left` and `top` to maintain visual position
  const offsetChange = {
    x: newPathOffset.x - polygon.pathOffset.x,
    y: newPathOffset.y - polygon.pathOffset.y,
  };

  polygon.set({
    width: newWidth,
    height: newHeight,
    pathOffset: newPathOffset,
    left: polygon.left + offsetChange.x, // Adjust left
    top: polygon.top + offsetChange.y,  // Adjust top
  });

  polygon.setCoords();
 
}



let cachedSvgContent = null;
let chalkImage = null;
let filterType = null;
export const chalkAndSponge = (path, canvas, zoom, filter, activeNodes, setActiveNodes) => {
  filterType = filter
  if (!path.filter) {
    cachedSvgContent = null;
    chalkImage = null;
  }
  console.log('chalkAndSponge: props:', path, canvas, zoom, filter, activeNodes, setActiveNodes)
  if (!cachedSvgContent || filterType !== path.filterType) {
    fetch(filterType)
      .then(response => response.text())
      .then(svgText => {
        cachedSvgContent = svgText;
        if (!path.ogFill) {
          path.set({
            ogFill: path.fill,
            ogStroke: path.stroke,
            filterType: filterType
          })
        } else {
          path.set({
            filterType: filterType
          })
        }

        processChalkImage(path, canvas, zoom, activeNodes, setActiveNodes, cachedSvgContent);
      })
      .catch(error => console.error('Error loading chalk.svg:', error));
  } else {
    path.set({
      filterType: filterType
    })
    processChalkImage(path, canvas, zoom, activeNodes, setActiveNodes, cachedSvgContent);
  }
};

const processChalkImage = (path, canvas, zoom, activeNodes, setActiveNodes, svgContent) => {
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
  const svgElement = svgDoc.documentElement;
  const chalkPath = svgDoc.querySelector('path');
  const chalkG = svgDoc.querySelector('g');

  console.log('processChalkImage: svgContent:', path, zoom, svgContent)

  const boundingBox = path.getBoundingRect();
  const actualWidth = boundingBox.width / zoom;
  const actualHeight = boundingBox.height / zoom;
  console.log('processChalkImage: actualDimensions:', actualHeight, actualWidth, boundingBox)

  const svgString = path.toSVG();
  const pathDoc = parser.parseFromString(svgString, 'image/svg+xml');
  const newPathElement = pathDoc.querySelector('path');
  const newGElement = pathDoc.querySelector('g');


  if (chalkPath && newPathElement) {
    chalkPath.setAttribute('d', newPathElement.getAttribute('d'));
    // chalkPath.setAttribute('filter', 'url(#f111)');
    let style = newPathElement.getAttribute('style') || '';

    if (path.ogFill && path.ogStroke) {

      style = style.replace(/stroke:\s*[^;]+;/, `stroke: ${path.ogStroke};`);

      style = style.replace(/fill:\s*[^;]+;/, `fill: ${path.ogFill};`);
      style = style.replace(/stroke-opacity:\s*[^;]+;/, `stroke-opacity: 1;`);
      style = style.replace(/fill-opacity:\s*[^;]+;/, `fill-opacity: 1;`);



      chalkPath.setAttribute('style', style);
      console.log('processChalkImage: ogFill:', style, path);

    } else {
      console.log('processChalkImage: not ogFill:', style);

      chalkPath.setAttribute('style', style);
    }
    const transformMatrix = path.calcTransformMatrix();
    const transformString = `matrix(${transformMatrix.join(' ')})`;
    chalkG.setAttribute('transform', transformString);

    // chalkG.setAttribute('transform', newGElement.getAttribute('transform'))
    svgElement.setAttribute('height', `${actualHeight + (actualHeight / 10)}`);
    svgElement.setAttribute('width', `${actualWidth + (actualWidth / 10)}`);
    svgElement.setAttribute('viewBox', `${path.left - (path.left / 10)} ${path.top - (path.top / 10)} ${actualWidth + (actualWidth / 10)} ${actualHeight + (actualHeight / 10)}`);

    const modifiedSvgString = new XMLSerializer().serializeToString(svgDoc);
    const svgDataUrl = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(modifiedSvgString);

    if (chalkImage) {
      chalkImage.setSrc(svgDataUrl, () => {
        canvas.renderAll();
        console.log('processChalkImage: chalkImage: present: InCALLBACK:', svgElement);

      });
      console.log('processChalkImage: chalkImage: present', svgElement);
    } else {
      fabric.Image.fromURL(svgDataUrl, (img) => {
        img.set({
          left: path.left,
          top: path.top,
          angle: path.angle,
          originX: 'center',
          originY: 'center',
          width: actualWidth + (actualWidth / 10),
          height: actualHeight + (actualHeight / 10),
          filteredImage: true,
        });
        chalkImage = img;
        canvas.add(chalkImage);
        path.set({
          filter: true,
          zoomForNodes: zoom,
          fill: 'transparent',
          stroke: 'transparent',

        })
        const events = ['moving', 'scaling', 'rotating'];
        events.forEach((event) =>
          path.on(event, () => {
            chalkImage.set({
              left: path.left,
              top: path.top,
              // scaleX: path.scaleX,
              // scaleY: path.scaleY,
              angle: path.angle,
            });
          })
        );
        console.log('processChalkImage: chalkImage: just made:', svgElement);
        // var group = new fabric.Group([path, img], {
        //   left: path.left,
        //   top: path.top,
        //   originX: 'center',
        //   originY: 'center',

        // });

        // canvas.add(group);
        canvas.renderAll();
        togglePathSelect(canvas, path, setActiveNodes, activeNodes);
      });
    }
  }
};

export function getPointAtLength(path, targetLength, activeObj) {
  let accumulated = 0;
  const transformMatrix = activeObj.calcTransformMatrix();
  let lastPoint = [0, 0];
  let startPoint = [0, 0];

  for (let i = 0; i < path.length; i++) {
    const [command, ...coords] = path[i];
    let transformedLastPoint;
    let transformedCoords;
    if (command !== "Z") {
      transformedLastPoint = transformPoint(lastPoint, transformMatrix, activeObj);
      transformedCoords = transformPoint(coords, transformMatrix, activeObj);
    }
    else {
      transformedLastPoint = transformPoint(lastPoint, transformMatrix, activeObj);
      transformedCoords = startPoint;
    }


    if (command === "M") {
      // Move command: set starting point
      startPoint = transformedCoords;
      lastPoint = coords;
    } else if (command === "L" || command === "Z") {
      // Line segment
      const [x1, y1] = transformedLastPoint;
      const [x2, y2] = transformedCoords;
      const segmentLength = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

      if (accumulated + segmentLength >= targetLength) {
        const ratio = (targetLength - accumulated) / segmentLength;
        return {
          x: x1 + ratio * (x2 - x1),
          y: y1 + ratio * (y2 - y1),
        };
      }

      accumulated += segmentLength;
      lastPoint = coords;
    } else if (command === "C") {
      // Cubic Bézier curve
      const [cp1x, cp1y, cp2x, cp2y, x2, y2] = coords;
      const [x1, y1] = transformedLastPoint;

      // Transform all control points and the endpoint
      const [transCp1x, transCp1y] = transformPoint([cp1x, cp1y], transformMatrix, activeObj);
      const [transCp2x, transCp2y] = transformPoint([cp2x, cp2y], transformMatrix, activeObj);
      const [transX2, transY2] = transformPoint([x2, y2], transformMatrix, activeObj);

      // Create a cubic Bézier curve using Bezier.js
      const curve = new Bezier(x1, y1, transCp1x, transCp1y, transCp2x, transCp2y, transX2, transY2);

      const segmentLength = curve.length();

      if (accumulated + segmentLength >= targetLength) {
        const ratio = (targetLength - accumulated) / segmentLength;

        // Use Bezier.js to find the point at the specified ratio
        const { x, y } = curve.get(ratio);
        return { x, y };
      }

      accumulated += segmentLength;
      lastPoint = coords.slice(-2); // Update last point to the end of the cubic curve
    }
  }

  // Return the last transformed point if targetLength exceeds the path length
  const lastTransformedPoint = transformPoint(lastPoint, transformMatrix, activeObj);
  return {
    x: lastTransformedPoint[0],
    y: lastTransformedPoint[1],
  };
}


const drawLineForVisual = (clickPoint, projection, canvas) => {
  const line = new fabric.Line(
    [clickPoint.x, clickPoint.y, projection.x, projection.y],
    {
      stroke: "red",
      strokeWidth: 2,
      selectable: false,
      evented: false, // Prevent interaction with the line
    }
  );
  canvas.add(line);
}

export function drawNormalsForPath(skeletonSamples, canvas) {
  const normalLength = 20; // Length of each normal line
  skeletonSamples.forEach(sample => {
    const { point, normal } = sample;

    // Compute the start and end points for the normal line
    const startPoint = point;
    const endPoint = {
      x: point.x + normal.x * normalLength,
      y: point.y + normal.y * normalLength,
    };

    // Use drawLineForVisual to draw the normal line
    drawLineForVisual(startPoint, endPoint, canvas);
  });
}


export function calculatePathLength(path, transformMatrix, activeObj) {
  let length = 0;
  let lastPoint = [0, 0]; // Default starting point
  let startPoint = [0, 0]; // Track the starting point for the "Z" command
  console.log('distribute: calculatePathLength: ', path, transformMatrix, activeObj);

  for (let i = 0; i < path.length; i++) {
    const [command, ...coords] = path[i];
    console.log('distribute: calculatePathLength: b4 transformPoint:', coords, transformMatrix, activeObj);

    const transformed = transformPoint(coords, transformMatrix, activeObj);
    console.log('distribute: coords, trnasformedPoint: ', coords, transformed);
    if (command === "M") {
      // Move command, update start and last point
      startPoint = transformed;
      lastPoint = transformed;
    } else if (command === "L") {
      // Line segment
      if (lastPoint[0] !== undefined && lastPoint[1] !== undefined && transformed[0] !== undefined && transformed[1] !== undefined) {
        length += Math.sqrt((transformed[0] - lastPoint[0]) ** 2 + (transformed[1] - lastPoint[1]) ** 2);
      }
      lastPoint = transformed;
    }
    else if (command === "C") {
      // Cubic Bézier curve
      const [cp1x, cp1y, cp2x, cp2y, x2, y2] = coords;
      const [x1, y1] = lastPoint; // Use the last point as the starting point

      if (
        x1 !== undefined &&
        y1 !== undefined &&
        cp1x !== undefined &&
        cp1y !== undefined &&
        cp2x !== undefined &&
        cp2y !== undefined &&
        x2 !== undefined &&
        y2 !== undefined
      ) {
        // Transform all control points and end point
        const [transCp1x, transCp1y] = transformPoint([cp1x, cp1y], transformMatrix, activeObj);
        const [transCp2x, transCp2y] = transformPoint([cp2x, cp2y], transformMatrix, activeObj);
        const [transX2, transY2] = transformPoint([x2, y2], transformMatrix, activeObj);

        // Create a Bezier curve using Bezier.js
        const curve = new Bezier(
          x1, y1,
          transCp1x, transCp1y,
          transCp2x, transCp2y,
          transX2, transY2
        );

        // Add the length of the cubic Bézier curve
        length += curve.length();
      }

      // Update last point to the end of the cubic curve
      lastPoint = transformPoint([x2, y2], transformMatrix, activeObj);
    }
    else if (command === "Z") {
      // Close the path: connect last point to the starting point
      if (lastPoint[0] !== undefined && lastPoint[1] !== undefined && startPoint[0] !== undefined && startPoint[1] !== undefined) {
        length += Math.sqrt((startPoint[0] - lastPoint[0]) ** 2 + (startPoint[1] - lastPoint[1]) ** 2);
      }
    }
  }

  return length;
}


/**
 * Transform a point using the transformation matrix.
 */
export function transformPoint(coords, matrix, activeObj) {
  if (!coords || coords.length < 2) return [undefined, undefined];
  const [x, y] = coords;
  console.log('distribute: in transformPoint: ', x - activeObj.pathOffset.x, y - activeObj.pathOffset.y);
  const point = new fabric.Point(x - activeObj.pathOffset.x, y - activeObj.pathOffset.y);
  const transformed = fabric.util.transformPoint(point, matrix);
  return [transformed.x, transformed.y];
}

export function calculatePatternWidth(patternPath) {
  return (patternPath.ogPath ? patternPath.ogPath : patternPath.path).reduce((max, segment) => {
    const xValues = segment.slice(1).filter((_, i) => i % 2 === 0); // Extract x-coordinates
    return Math.max(max, ...xValues);
  }, 0);
}

export function calculatePatternHeight(patternPath) {
  return (patternPath.ogPath ? patternPath.ogPath : patternPath.path).reduce((max, segment) => {
    const yValues = segment.slice(1).filter((_, i) => i % 2 === 1); // Extract y-coordinates
    return Math.max(max, ...yValues);
  }, 0);
}


export const removeCustomControls = (canvas, path) => {
  if (path.edit) {
    console.log("removeCustomControls: Removing custom controls for path:", path);

    path.controls = fabric.Object.prototype.controls;

    path.edit = false;
    if (path.curveControls) {
      console.log('handleDeleteObject: Removing curve controls');
      canvas.getObjects().forEach(object => {
        if (object.class === "curveLine") {
          console.log('handleDeleteObject: Removing curveLine:', object);
          canvas.remove(object);
        }
      });
      delete path.curveControls;
    }
    path.setControlsVisibility({
      ml: true, mt: true, mr: true, mb: true,
      bl: true, br: true, tl: true, tr: true
    });

    path.set({ selectable: true });

    canvas.setActiveObject(path);

    canvas.requestRenderAll();
  } else {
    console.log("removeCustomControls: No custom controls found for path.");
  }
};


export const createEllipseControls = (canvas, ellipse) => {
  const { left, top, rx, ry } = ellipse;
  const angle = ellipse.angle || 0;

  ellipse.controls = {};

  // Control point for top-left
  const cpTopLeft = new fabric.Control({
    positionHandler: function (dim, finalMatrix, fabricObject) {
      const transform = fabricObject.calcTransformMatrix();
      const center = fabricObject.getCenterPoint();
      const p = fabric.util.transformPoint(
        { x: -rx, y: -ry },
        fabric.util.multiplyTransformMatrices(finalMatrix, transform)
      );
      return fabric.util.transformPoint(p, fabric.util.invertTransform(finalMatrix));
    },
    actionHandler: function (eventData, transform, x, y) {
      const ellipse = transform.target;
      const finalPoint = new fabric.Point(x, y);
      const center = ellipse.getCenterPoint();
      const scaleX = (finalPoint.x - center.x) / (rx * 2);
      const scaleY = (finalPoint.y - center.y) / (ry * 2);
      ellipse.set({
        scaleX: scaleX,
        scaleY: scaleY,
      });
      canvas.renderAll();
      return true;
    },
    actionName: 'scale',
    cornerSize: 12,
    cornerColor: 'red',
    originX: 'center',
    originY: 'center',
    transparentCorners: false,
    lockRotation: true,
  });

  ellipse.controls.topLeft = cpTopLeft;

  // Control point for bottom-right
  const cpBottomRight = new fabric.Control({
    positionHandler: function (dim, finalMatrix, fabricObject) {
      const transform = fabricObject.calcTransformMatrix();
      const center = fabricObject.getCenterPoint();
      const p = fabric.util.transformPoint(
        { x: rx, y: ry },
        fabric.util.multiplyTransformMatrices(finalMatrix, transform)
      );
      return fabric.util.transformPoint(p, fabric.util.invertTransform(finalMatrix));
    },
    actionHandler: function (eventData, transform, x, y) {
      const ellipse = transform.target;
      const finalPoint = new fabric.Point(x, y);
      const center = ellipse.getCenterPoint();
      const scaleX = (finalPoint.x - center.x) / (rx * 2);
      const scaleY = (finalPoint.y - center.y) / (ry * 2);
      ellipse.set({
        scaleX: scaleX,
        scaleY: scaleY,
      });
      canvas.renderAll();
      return true;
    },
    actionName: 'scale',
    cornerSize: 12,
    cornerColor: 'red',
    originX: 'center',
    originY: 'center',
    transparentCorners: false,
    lockRotation: true,
  });

  ellipse.controls.bottomRight = cpBottomRight;

  // Add controls to ellipse
  ellipse.setControlsVisibility({
    tl: true,
    br: true,
    ml: false,
    mt: false,
    mr: false,
    mb: false,
    mtr: false,
  });

  canvas.renderAll();
};


export const getObjectSizeWithStroke = object => {
  const stroke = new fabric.Point(
    object.strokeUniform ? 1 / object.scaleX : 1,
    object.strokeUniform ? 1 / object.scaleY : 1
  ).multiply(object.strokeWidth);
  return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
};


function getAdjacentPoints(path, currentIndex, count) {
  const totalPoints = path.length;
  let indices = [];

  for (let i = 1; i <= count; i++) {
    const prevIndex = (currentIndex - i + totalPoints) % totalPoints;
    const nextIndex = (currentIndex + i) % totalPoints;
    indices.push(prevIndex, nextIndex);
  }

  return indices;
}


