import React, { useState , useRef } from 'react';
import { TextField, Button, Box, IconButton, Typography, RadioGroup, FormControlLabel, Radio , InputAdornment} from '@mui/material';
import { Add, Remove , Clear, Upload } from '@mui/icons-material';
import Papa from 'papaparse'; 
import * as XLSX from 'xlsx';

const PieChartForm = ({ onAddChart }) => {
  const [entries, setEntries] = useState([{ label: '', data: '', color: '#000000' }]);
  const [chartType, setChartType] = useState('pie');
  const fileInputRef = useRef(null);

  const [fileName, setFileName] = useState('');
  const handleChange = (index, field, value) => {
    const newEntries = [...entries];
    newEntries[index][field] = value;
    setEntries(newEntries);
  };

  const handleUploadClick = (e) => {
    e.stopPropagation();

    fileInputRef.current.click();
  };

  const handleClearClick = () => {
    setFileName('');
  };


  const extractEntries = (fileData) => {
    return fileData.map((row) => ({
      label: row['Label'] || '',    
      data: row['Data'] || '',     
      color: row['Color'] || '#000000', 
    }));
  };

const handleFileUpload = (e) => {
  const file = e.target.files[0];

  if (file) {
    console.log('handleFileUpload: ', file);
    const reader = new FileReader();
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (fileExtension === 'csv') {
      reader.onload = (f) => {
        const data = f.target.result;
        const parsedData = Papa.parse(data, { header: true }).data;
        const extractedEntries = extractEntries(parsedData);
        setEntries(extractedEntries);
      };
      reader.readAsText(file);
    } else if (fileExtension === 'xlsx') {
      reader.onload = (f) => {
        const data = new Uint8Array(f.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(worksheet);

        const extractedEntries = extractEntries(parsedData);
        setEntries(extractedEntries);
      };
      reader.readAsArrayBuffer(file);
    }
    setFileName(file.name);
  }
};

  const handleAddEntry = () => {
    setEntries([...entries, { label: '', data: '', color: '#000000' }]);
  };

  const handleRemoveEntry = (index) => {
    const newEntries = entries.filter((_, i) => i !== index);
    setEntries(newEntries);
  };

  const handleChartTypeChange = (e) => {
    setChartType(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const labels = entries.map((entry) => entry.label);
      const data = entries.map((entry) => {
        const value = parseFloat(entry.data);
        if (isNaN(value)) throw new Error('Invalid data value');
        return value;
      });
      const backgroundColor = entries.map((entry) => entry.color);
  
      onAddChart(chartType, {
        labels,
        data,
        backgroundColor,
      });
  
    } catch (error) {
      alert(`Submission error: ${error.message}`);
    }
  };

  return (
    <Box sx={{ px: 1.5, py: 1.5 }}>
      
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography>{chartType.charAt(0).toUpperCase() + chartType.slice(1)} Chart</Typography>
      </Box>
      <RadioGroup
        row
        value={chartType}
        onChange={handleChartTypeChange}
        sx={{ my: 2 }}
      >
        <FormControlLabel value="pie" control={<Radio />} label="Pie" />
        <FormControlLabel value="doughnut" control={<Radio />} label="Doughnut" />
      </RadioGroup>
      <Box component="form" onSubmit={handleSubmit}>
        <Box>
          {entries.map((entry, index) => (
            <Box key={index} sx={{ my: 2 }}>
              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={16}>Data Set: {index + 1}</Typography>
                {entries.length > 1 && (
                  <IconButton onClick={() => handleRemoveEntry(index)}>
                    <Remove />
                  </IconButton>
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap={3} alignItems="center">
                <TextField
                  label="Label"
                  value={entry.label}
                  onChange={(e) => handleChange(index, 'label', e.target.value)}
                />
                <TextField
                  label="Data"
                  type="number"
                  value={entry.data}
                  onChange={(e) => handleChange(index, 'data', e.target.value)}
                  
                />
                <TextField
                  label="Background Color"
                  value={entry.color}
                  type="color"
                  onChange={(e) => handleChange(index, 'color', e.target.value)}
                  style={{ width:"100%" }}
                />
                <input ref={fileInputRef} type="file" accept=".xlsx, .xls, .csv" style={{ display: 'none' }} onChange={handleFileUpload} />
                      <TextField

                        value={fileName}
                        variant="outlined"
                        onClick={handleUploadClick}
                        placeholder="Upload a file"
                        inputProps={{
                          style: {
                            cursor: fileName ? 'default' : 'pointer',
                            color: '#ffffff'
                          }
                        }}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment sx={{ p: 0 }} position="end">
                              <IconButton sx={{ borderRadius: 0, "&:hover": { color: 'blue' } }} onClick={handleUploadClick}>
                                <Upload />
                              </IconButton>
                              {fileName && (
                                <IconButton sx={{ borderRadius: 0, "&:hover": { color: 'red' } }} onClick={handleClearClick}>
                                  <Clear />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
              </Box>
            </Box>
          ))}
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Button type="button" variant="contained" color="secondary" onClick={handleAddEntry}>
              <Add />
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Add {chartType.charAt(0).toUpperCase() + chartType.slice(1)} Chart
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PieChartForm;
