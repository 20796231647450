import React, { useState,useRef } from 'react';
import { TextField, Button, Box, IconButton, Typography, RadioGroup, FormControlLabel, Radio , InputAdornment} from '@mui/material';
import { Add, Remove , Clear, Upload } from '@mui/icons-material';
import Papa from 'papaparse'; 
import * as XLSX from 'xlsx';

const ScatterChartForm = ({ onAddChart }) => {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');

  const handleUploadClick = (e) => {
    e.stopPropagation();

    fileInputRef.current.click();
  };

  const handleClearClick = () => {
    setFileName('');
  };

const handleFileUpload = (e) => {
  const file = e.target.files[0];

  if (file) {
    const reader = new FileReader();
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (fileExtension === 'csv') {
      reader.onload = (event) => {
        const data = event.target.result;
        Papa.parse(data, {
          header: true,
          complete: (results) => {
            const parsedData = results.data;
            const groupedData = {};

            parsedData.forEach((row) => {
              const label = row.label || '';
              const x = parseFloat(row.x) || 0;
              const y = parseFloat(row.y) || 0;
              const backgroundColor = row.backgroundColor || '#000000';

              if (!groupedData[label]) {
                groupedData[label] = {
                  label: label,
                  data: [],
                  backgroundColor: backgroundColor,
                };
              }

              groupedData[label].data.push({ x, y });
            });

            const extractedDatasets = Object.values(groupedData);
            console.log('Extracted datasets:', extractedDatasets);
            setDatasets(extractedDatasets);
          },
          skipEmptyLines: true,
        });
      };
      reader.readAsText(file);
    } else if (fileExtension === 'xlsx') {
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const headers = parsedData[0];
        const groupedData = {};

        parsedData.slice(1).forEach((row) => {
          const label = row[headers.indexOf('label')] || '';
          const x = parseFloat(row[headers.indexOf('x')]) || 0;
          const y = parseFloat(row[headers.indexOf('y')]) || 0;
          const backgroundColor = row[headers.indexOf('backgroundColor')] || '#000000';

          if (!groupedData[label]) {
            groupedData[label] = {
              label: label,
              data: [],
              backgroundColor: backgroundColor,
            };
          }

          groupedData[label].data.push({ x, y });
        });

        const extractedDatasets = Object.values(groupedData);
        console.log('Extracted datasets:', extractedDatasets);
        setDatasets(extractedDatasets);
      };
      reader.readAsArrayBuffer(file);
    }
  }
};

  

  const [datasets, setDatasets] = useState([
    {
      label: '',
      data: [{ x: '', y: '' }],
      backgroundColor: '#000000',
    },
  ]);

  const handleDatasetChange = (index, field, value) => {
    const newDatasets = [...datasets];
    newDatasets[index] = { ...newDatasets[index], [field]: value };
    setDatasets(newDatasets);
  };

  const handleDataChange = (datasetIndex, dataIndex, axis, value) => {
    const newDatasets = [...datasets];
    const newData = [...newDatasets[datasetIndex].data];
    newData[dataIndex] = { ...newData[dataIndex], [axis]: parseFloat(value) || '' };
    newDatasets[datasetIndex] = { ...newDatasets[datasetIndex], data: newData };
    setDatasets(newDatasets);
  };

  const handleAddDataset = () => {
    setDatasets((prevDatasets) => [
      ...prevDatasets,
      {
        label: '',
        data: [{ x: '', y: '' }],
        backgroundColor: '#000000',
      },
    ]);
  };

  const handleRemoveDataset = (index) => {
    const newDatasets = datasets.filter((_, i) => i !== index);
    setDatasets(newDatasets);
  };

  const handleAddDataPoint = (datasetIndex) => {
    const newDatasets = [...datasets];
    const newData = [...newDatasets[datasetIndex].data, { x: '', y: '' }];
    newDatasets[datasetIndex] = { ...newDatasets[datasetIndex], data: newData };
    setDatasets(newDatasets);
  };

  const handleRemoveDataPoint = (datasetIndex, dataIndex) => {
    const newDatasets = [...datasets];
    const newData = newDatasets[datasetIndex].data.filter((_, i) => i !== dataIndex);
    newDatasets[datasetIndex] = { ...newDatasets[datasetIndex], data: newData };
    setDatasets(newDatasets);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    try {
      if (!datasets || datasets.length === 0) {
        throw new Error('No datasets available for the chart.');
      }
      onAddChart('scatter', { datasets });
      
    } catch (error) {
      alert(`Submission error: ${error.message}`);
    }
  };
  

  return (
    <Box sx={{ px: 1.5, py: 1.5 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography>Scatter Chart</Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        {datasets.map((dataset, datasetIndex) => (
          <Box key={datasetIndex} sx={{ my: 3 }}>
            <Typography fontSize={18} sx={{ mb: 2 }}>Dataset {datasetIndex + 1}</Typography>
            <Box display="flex" alignItems="center">
              <TextField
                fullWidth
                label="Dataset Label"
                value={dataset.label}
                onChange={(e) => handleDatasetChange(datasetIndex, 'label', e.target.value)}
                sx={{ mr: 2 }}
              />
              {datasets.length > 1 && (
                <IconButton onClick={() => handleRemoveDataset(datasetIndex)}>
                  <Remove />
                </IconButton>
              )}
            </Box>
            <TextField
              fullWidth
              label="Background Color"
              type="color"
              value={dataset.backgroundColor}
              onChange={(e) => handleDatasetChange(datasetIndex, 'backgroundColor', e.target.value)}
              sx={{ my: 2 }}
            />

            <Typography fontSize={16} sx={{ mb: 2 }}>Data Points</Typography>
            {dataset.data.map((point, dataIndex) => (
              <Box key={dataIndex} sx={{ display: 'flex', flexDirection:"column", alignItems: 'center', mb: 2 }}>
                <TextField
                  fullWidth
                  label={`X Value`}
                  type="number"
                  value={point.x}
                  onChange={(e) => handleDataChange(datasetIndex, dataIndex, 'x', e.target.value)}
                  sx={{ }}
                />
                <TextField
                  fullWidth
                  label={`Y Value`}
                  type="number"
                  value={point.y}
                  onChange={(e) => handleDataChange(datasetIndex, dataIndex, 'y', e.target.value)}
                  sx={{mt:2 ,mb:2}}
                />
                <input  ref={fileInputRef} type="file" accept=".xlsx, .xls, .csv" style={{ display: 'none' }} onChange={handleFileUpload} />
                <TextField

                  value={fileName}
                  variant="outlined"
                  onClick={handleUploadClick}
                  placeholder="Upload a file"
                  inputProps={{
                    style: {
                      cursor: fileName ? 'default' : 'pointer',
                      color: '#ffffff'
                    }
                  }}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment sx={{ p: 0 }} position="end">
                        <IconButton sx={{ borderRadius: 0, "&:hover": { color: 'blue' } }} onClick={handleUploadClick}>
                          <Upload />
                        </IconButton>
                        {fileName && (
                          <IconButton sx={{ borderRadius: 0, "&:hover": { color: 'red' } }} onClick={handleClearClick}>
                            <Clear />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  />
                {dataset.data.length > 1 && (
                  <IconButton onClick={() => handleRemoveDataPoint(datasetIndex, dataIndex)}>
                    <Remove />
                  </IconButton>
                )}
              </Box>
            ))}
            <Button variant="contained" color="secondary" onClick={() => handleAddDataPoint(datasetIndex)}>
              <Add /> Add Data Point
            </Button>
          </Box>
        ))}
        <Button variant="contained" color="secondary" onClick={handleAddDataset}>
          <Add /> Add Dataset
        </Button>

        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
          <Button type="submit" variant="contained" color="primary">
            Add Scatter Chart
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ScatterChartForm;
