import React, { useEffect, useState } from "react";
import {
  StyledBottomNav,
  StyledListButton,
  StyledSlider,
} from "../../Utils/styledComps";
import { Paper, Box, Tooltip, TextField, InputAdornment } from "@mui/material";
import {
  CenterFocusStrongOutlined,
  Fullscreen,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";
import {
  IoTabletLandscapeOutline,
  IoTabletPortraitOutline,
} from "react-icons/io5";
import { PiPresentation } from "react-icons/pi";

const Footer = ({
  onZoomChange,
  canvas,
  handlePresentation,
  zoom,
  footerRef,
  toggleOrientation,
  toggleVisibility,
  handleZoomOut,
  handleZoomIn,
  setZoom
}) => {
  function ValueLabelComponent(props) {
    const { children, value } = props;
    return (
      <Tooltip enterTouchDelay={0} arrow placement="bottom" title={value}>
        {children}
      </Tooltip>
    );
  }

  const [orientation, setOrientation] = useState("Portrait");
  const [zoomValue, setZoomValue] = useState((zoom * 100)); // Store zoom as string

  useEffect(() => {
    setZoomValue(Math.round(zoom * 100));
  }, [zoom]);

  const handleZoomInputChange = (e) => {
    let inputValue = e.target.value;

    if (/^-?\d*\.?\d*$/.test(inputValue)) {
      setZoomValue(inputValue);
    }
  };

 

  const handleZoomEnter = (e) => {
    if (e.key === "Enter") {
      const newZoomValue =Math.round(zoomValue)
      //  parseFloat(zoomValue);
      setZoomValue(newZoomValue);
      onZoomChange(null, newZoomValue);
    }
  };

  const handleOrientation = (orientation) => {
    toggleOrientation(orientation);
    setOrientation((prev) => (prev === "Portrait" ? "Landscape" : "Portrait"));
  };
  const roundToDecimals = (value, decimals) => {
    return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  };

  return (
    <Paper
      ref={footerRef}
      sx={{ position: "fixed", zIndex:1999, bottom: 0, left: 48, right: 0, height: "50px" }}
      elevation={20}
    >
      <StyledBottomNav>
        <Box sx={{ alignItems: "center", display: "flex" }}>
          <TextField
            type="text" 
            value={zoomValue} 
            onChange={handleZoomInputChange}  
            onKeyDown={handleZoomEnter} 
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip arrow title="Zoom Out">
                    <StyledListButton sx={{ height: "100%",p:1 }}>
                      <ZoomOut onClick={handleZoomOut} />
                    </StyledListButton>
                  </Tooltip>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <InputAdornment position="end">%</InputAdornment> {/* Show percentage sign */}
                  <Tooltip arrow title="Zoom In">
                    <StyledListButton sx={{ height: "100%",p:1 }}>
                      <ZoomIn onClick={handleZoomIn} />
                    </StyledListButton>
                  </Tooltip>
                </InputAdornment>
              ),
              inputProps: { 
                style: { 
                  textAlign: 'center',
                }
              },
              sx: {
                width: '150px',
                padding: 0,
                ml: 2,
                borderRadius: "5px",
                backgroundColor: 'transparent',
                '& input[type=number]': {
                  WebkitAppearance: 'none',  // Hide number spinners
                  MozAppearance: 'textfield',
                  appearance: 'textfield',
                },
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  WebkitAppearance: 'none',
                  margin: 0,
                },
                '& input[type=number]::-moz-inner-spin-button': {
                  MozAppearance: 'none',
                },
              }
            }}
          />
        </Box>

        {/* Other buttons and functionality */}
        <Tooltip arrow title="Center visible">
          <StyledListButton sx={{ maxWidth: "60px" }} onClick={toggleVisibility}>
            <CenterFocusStrongOutlined />
          </StyledListButton>
        </Tooltip>

        <Tooltip arrow placement="top" title="Presentation Mode">
          <StyledListButton sx={{ maxWidth: "60px" }} onClick={handlePresentation}>
            <PiPresentation />
          </StyledListButton>
        </Tooltip>

        <Tooltip
          arrow
          placement="top"
          title={
            orientation === "Portrait" ? "Landscape Mode" : "Portrait Mode"
          }
        >
          <StyledListButton
            sx={{ maxWidth: "60px" }}
            onClick={() => handleOrientation(orientation)}
          >
            {orientation === "Portrait" ? (
              <IoTabletPortraitOutline />
            ) : (
              <IoTabletLandscapeOutline />
            )}
          </StyledListButton>
        </Tooltip>

        <Tooltip
          arrow
          placement="top"
          title={`Canvas Dimensions: Height:${Math.floor(
            canvas?.getHeight() / canvas?.getZoom()
          )} || Width:${Math.floor(
            canvas?.getWidth() / canvas?.getZoom()
          )} || Zoom:${canvas?.getZoom()}`}
        >
          <div style={{ display: "flex", gap: 10, marginRight: 20 }}>
            <div
              style={{
                padding: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <p style={{ padding: 0, margin: 0, fontSize: "10px" }}>
                H-{Math.floor(canvas?.getHeight() / canvas?.getZoom())}
              </p>
              <p style={{ padding: 0, margin: 0, fontSize: "10px" }}>
                W-{Math.floor(canvas?.getWidth() / canvas?.getZoom())}
              </p>
              <p style={{ padding: 0, margin: 0, fontSize: "10px" }}>
              Z-{roundToDecimals(canvas?.getZoom(), 2)}
              </p>
            </div>
          </div>
        </Tooltip>
      </StyledBottomNav>
    </Paper>
  );
};

export default Footer;
