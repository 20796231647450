import React, { useState,useRef } from 'react';
import { TextField, Button, Box, IconButton, Typography, RadioGroup, FormControlLabel, Radio , InputAdornment} from '@mui/material';
import { Add, Remove , Clear, Upload } from '@mui/icons-material';
import Papa from 'papaparse'; 
import * as XLSX from 'xlsx';

const BoxPlotForm = ({ onSubmit }) => {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');
  
  const [boxes, setBoxes] = useState([{ quartiles: '', outliers: '', color: '#0000FF' }]);

  const handleUploadClick = (e) => {
    e.stopPropagation();

    fileInputRef.current.click();
  };

  const handleClearClick = () => {
    setFileName('');
  };

const handleFileUpload = (e) => {
  const file = e.target.files[0];

  if (file) {
    const reader = new FileReader();
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (fileExtension === 'csv') {
      reader.onload = (event) => {
        const data = event.target.result;

        Papa.parse(data, {
          header: true,
          complete: (results) => {
            const parsedData = results.data;

            const newBoxes = parsedData.map((row) => {
              const quartiles = typeof row.quartiles === 'string'
                ? row.quartiles.split(',').map(str => parseFloat(str.trim())).filter(num => !isNaN(num))
                : Array.isArray(row.quartiles) ? row.quartiles : [];

              const outliers = typeof row.outliers === 'string'
                ? row.outliers.split(',').map(str => parseFloat(str.trim())).filter(num => !isNaN(num))
                : Array.isArray(row.outliers) ? row.outliers : [];

              if (quartiles.length !== 3 || outliers.length !== 2) {
                console.error('Invalid data: Each quartiles must have 3 values and each outliers must have 2 values.');
                return null; 
              }

              return {
                quartiles,
                outliers,
                color: row.color || '#0000FF'
              };
            }).filter(Boolean);

            const updatedBoxes = [...boxes, ...newBoxes];
            setBoxes(updatedBoxes);
          },
          skipEmptyLines: true,
        });
      };
      reader.readAsText(file);
    } else if (fileExtension === 'xlsx') {
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const headers = parsedData[0];
        const newBoxes = parsedData.slice(1).map((row) => {
          const quartiles = typeof row[headers.indexOf('quartiles')] === 'string'
            ? row[headers.indexOf('quartiles')].split(',').map(str => parseFloat(str.trim())).filter(num => !isNaN(num))
            : Array.isArray(row[headers.indexOf('quartiles')]) ? row[headers.indexOf('quartiles')] : [];

          const outliers = typeof row[headers.indexOf('outliers')] === 'string'
            ? row[headers.indexOf('outliers')].split(',').map(str => parseFloat(str.trim())).filter(num => !isNaN(num))
            : Array.isArray(row[headers.indexOf('outliers')]) ? row[headers.indexOf('outliers')] : [];

          if (quartiles.length !== 3 || outliers.length !== 2) {
            console.error('Invalid data: Each quartiles must have 3 values and each outliers must have 2 values.');
            return null; 
          }

          return {
            quartiles,
            outliers,
            color: row[headers.indexOf('color')] || '#0000FF',
          };
        }).filter(Boolean);

        const updatedBoxes = [...boxes, ...newBoxes];
        setBoxes(updatedBoxes);
      };
      reader.readAsArrayBuffer(file);
    }
  }
};

  
  
  
  

  const handleAddBox = () => {
    // Add a new box with default values
    setBoxes([...boxes, { quartiles: '', outliers: '', color: '#0000FF' }]);
  };

  const handleRemoveBox = (index) => {
    // Remove the box at the given index
    const updatedBoxes = boxes.filter((_, i) => i !== index);
    setBoxes(updatedBoxes);
  };

  const handleBoxChange = (index, key, value) => {
    const updatedBoxes = boxes.map((box, i) => {
      if (i === index) {
        return { ...box, [key]: value };
      }
      return box;
    });
    setBoxes(updatedBoxes);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    try {
      const processedBoxes = boxes.map(box => {
        const quartiles = Array.isArray(box.quartiles)
          ? box.quartiles
          : box.quartiles.split(',')
              .map(str => parseFloat(str.trim()))
              .filter(num => !isNaN(num));
  
        const outliers = Array.isArray(box.outliers)
          ? box.outliers
          : box.outliers.split(',')
              .map(str => parseFloat(str.trim()))
              .filter(num => !isNaN(num));
  
        const { color } = box;
  
        if (quartiles.length === 0 || outliers.length === 0) {
          throw new Error('Invalid quartiles or outliers data.');
        }
  
        return { quartiles, outliers, color };
      });
  
      onSubmit('boxPlot', processedBoxes);
      console.log('handleSubmit: Box Plot Form: ', processedBoxes);
      
    } catch (error) {
      alert(`Submission error: ${error.message}`);
    }
  };
  

  return (
    <Box sx={{ px: 2, py: 2, maxWidth: '600px' }}>
      <Typography variant="h6" gutterBottom>
        Box Plot Settings
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        {boxes.map((box, index) => (
          <Box key={index} sx={{ mb: 2, display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            <Box sx={{ display: 'flex', padding: '15px 5px', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
              <Typography>Box {index + 1}</Typography>
              <IconButton color="error" onClick={() => handleRemoveBox(index)}>
                <Remove />
              </IconButton>
            </Box>
                      <TextField
            fullWidth
            label={`Box ${index + 1} Quartiles`}
            placeholder="Enter numbers (e.g., 10,20,30)"
            value={box.quartiles}
            onChange={(e) => {
              const value = e.target.value;
              // Validate input: Only numbers and commas allowed
              if (/^[\d,]*$/.test(value)) {
                handleBoxChange(index, 'quartiles', value);
              }
            }}
            helperText="Only numbers separated by commas are allowed."
            error={!!box.quartiles && !/^[\d,]*$/.test(box.quartiles)}
            sx={{ maxWidth: '100%' }}
          />
          <TextField
            fullWidth
            label="Box Outliers"
            placeholder="Enter numbers(e.g., 15,25)"
            value={box.outliers}
            onChange={(e) => {
              const value = e.target.value;
              // Validate input: Only numbers and commas allowed
              if (/^[\d,]*$/.test(value)) {
                handleBoxChange(index, 'outliers', value);
              }
            }}
            helperText="Only numbers separated by commas are allowed."
            error={!!box.outliers && !/^[\d,]*$/.test(box.outliers)}
            sx={{ maxWidth: '100%' }}
          />

            <TextField
              type="color"
              label={`Box ${index + 1} Color`}
              value={box.color}
              onChange={(e) => handleBoxChange(index, 'color', e.target.value)}
              sx={{ minWidth: '250px' }}
            />
            <input ref={fileInputRef} type="file" accept=".xlsx, .xls, .csv" style={{ display: 'none' }} onChange={handleFileUpload} />
            <TextField

              value={fileName}
              variant="outlined"
              onClick={handleUploadClick}
              placeholder="Upload a file"
              inputProps={{
                style: {
                  cursor: fileName ? 'default' : 'pointer',
                  color: '#ffffff'
                }
              }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment sx={{ p: 0 }} position="end">
                    <IconButton sx={{ borderRadius: 0, "&:hover": { color: 'blue' } }} onClick={handleUploadClick}>
                      <Upload />
                    </IconButton>
                    {fileName && (
                      <IconButton sx={{ borderRadius: 0, "&:hover": { color: 'red' } }} onClick={handleClearClick}>
                        <Clear />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              />
          </Box>
        ))}
        <Box sx={{ mb: 2 }}>
          <Button variant="contained" color="secondary" onClick={handleAddBox}>
            <Add /> Add Box
          </Button>
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Generate Box Plot
        </Button>
      </Box>
    </Box>
  );
};

export default BoxPlotForm;
