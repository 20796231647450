import { useState, useEffect, useRef } from "react";
import { CiCircleMinus } from "react-icons/ci";
import { PiTextbox } from "react-icons/pi";
import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleUp,
  FaArrowsAltH,
  FaArrowsAltV,
} from "react-icons/fa";
import {
  Toolbar,
  Box,
  Typography,
  List,
  IconButton,
  Tooltip,
  MenuItem,
  TextField,
  AppBar,
  Paper,
  Checkbox,
} from "@mui/material";
import {
  DrawerHeader,
  StyledAppBar,
  StyledDrawer,
  StyledListButton,
} from "../../Utils/styledComps";
import {
  AccountTreeOutlined,
  AddCircleOutline,
  Clear,
  ColorLensOutlined,
  Colorize,
  CreateOutlined,
  CropPortrait,
  DownloadOutlined,
  FileCopyOutlined,
  FileOpenOutlined,
  FilterBAndWOutlined,
  FolderCopyOutlined,
  FormatColorFill,
  Group,
  InfoOutlined,
  InsertDriveFileOutlined,
  Inventory2Outlined,
  LocalLibrary,
  ManageSearchOutlined,
  MenuOpen,
  Monitor,
  PhotoSizeSelectActualOutlined,
  Print,
  RouteOutlined,
  SaveAsOutlined,
  SaveOutlined,
  ScatterPlotOutlined,
  Share,
  SimCardDownloadOutlined,
  Sort,
  Straight,
  SubscriptionsOutlined,
  TimelineOutlined,
  UploadFileOutlined,
  UploadOutlined,
  UTurnRightOutlined,
  TripOriginOutlined,
  TurnSlightLeftOutlined,
  BlurCircularOutlined,
  LocationSearchingOutlined,
  HighlightAltOutlined,
  BarChart,
  SignalCellular4BarOutlined,
  PieChartOutlineOutlined,
  Brush,
  LensBlurOutlined,
  GradientOutlined,
  TextFields,
  TextIncreaseOutlined,
  BorderColor,
  FormatTextdirectionLToROutlined,
  FormatAlignLeftOutlined,
  FormatAlignCenterOutlined,
  FormatAlignRightOutlined,
  FormatAlignJustifyOutlined,
  Title,
  StyleOutlined,
  FormatUnderlinedOutlined,
  FormatBoldOutlined,
  FormatItalicOutlined,
  MergeOutlined,
  SuperscriptOutlined,
  SubscriptOutlined,
  Forward,
  DoorBackOutlined,
  DoorBack,
  BorderBottomOutlined,
  BorderTopOutlined,
  CropRotateOutlined,
  Rotate90DegreesCcwOutlined,
  Rotate90DegreesCwOutlined,
  FlipOutlined,
  Flip,
  HorizontalRuleOutlined,
  VerticalShadesOutlined,
  PanToolOutlined,
  Lock,
  AlignHorizontalCenter,
  ContentCutOutlined,
  ContentCopyOutlined,
  ContentPasteGoOutlined,
  ContentPasteSharp,
  UndoOutlined,
  RedoOutlined,
  PlaylistRemoveOutlined,
  PlaylistAddCheckCircleOutlined,
  SelectAllOutlined,
  CropSquareOutlined,
  ControlPointDuplicateOutlined,
  CopyAll,
  ContentPasteSearchOutlined,
  Grid4x4Outlined,
  BookOnlineOutlined,
  StraightenOutlined,
  ZoomIn,
  ZoomOut,
  ZoomOutMap,
  JoinFull,
  Remove,
  RemoveCircleOutline,
  CallSplitOutlined,
  Close,
  Mouse,
  IndeterminateCheckBoxTwoTone,
  Pages,
  Add,
  Transform,
  ChatBubble,
  SquareSharp,
  StarHalf,
  Equalizer,
  Grid4x4,
  ZoomOutMapOutlined,
  ZoomOutMapTwoTone,
  BackHand,
  CropRotateSharp,
  ArrowUpward,
  ArrowDownward,
  VerticalAlignTop,
  VerticalAlignBottom,
} from "@mui/icons-material";
import { event } from "jquery";
// import { HexAlphaColorPicker, HexColorInput, HexColorPicker } from 'react-colorful';
// import ColorPicker from 'react-best-gradient-color-picker';
import { useDispatch } from "react-redux";
import { fabric } from "fabric";
import {
  BiBarChart,
  BiChalkboard,
  BiCylinder,
  BiDoughnutChart,
  BiLineChart,
  BiPieChart,
  BiScatterChart,
  BiSprayCan,
  BiSquareRounded,
} from "react-icons/bi";
import {
  TbLine,
  TbArrowBigRight,
  TbOvalVertical,
  TbArrowBigRightLinesFilled,
  TbArrowCurveRight,
  TbSTurnDown,
  TbRipple,
  TbZeppelin,
} from "react-icons/tb";
import {
  LuCandlestickChart,
  LuMinusCircle,
  LuPlusCircle,
  LuRectangleHorizontal,
  LuTriangleRight,
} from "react-icons/lu";
import { GrCube, GrGallery } from "react-icons/gr";
import {
  MdLinearScale,
  MdOutlineHexagon,
  MdOutlineRectangle,
  MdUTurnRight,
} from "react-icons/md";
import {
  FaArrowRight,
  FaArrowsUpDownLeftRight,
  FaRegCircle,
  FaCloud,
  FaRegMoon,
  FaRegSquareFull,
  FaStar,
  FaSun,
  FaCircleNodes,
  FaRegObjectUngroup,
  FaT,
} from "react-icons/fa6";
import { IoTabletLandscape, IoTriangleOutline } from "react-icons/io5";
import { IoIosResize } from "react-icons/io";
import {
  PiArrowsVerticalBold,
  PiArrowElbowDownRightFill,
  PiParallelogram,
  PiPolygon,
  PiChatTeardrop,
  PiBracketsSquare,
  PiBracketsCurly,
  PiArrowArcRight,
  PiArrowArcLeft,
  PiArrowBendUpRight,
} from "react-icons/pi";
import { CgShapeHalfCircle, CgShapeRhombus, CgSmile } from "react-icons/cg";
import {
  BsFacebook,
  BsGoogle,
  BsHeptagon,
  BsInstagram,
  BsLinkedin,
  BsOctagon,
  BsPlusCircle,
  BsTwitterX,
} from "react-icons/bs";

import { TbHexagonNumber9 } from "react-icons/tb";
import { AiOutlineNodeIndex, AiOutlineThunderbolt } from "react-icons/ai";
import DownloadMenu from "./SBComponents/DownloadMenu/downloadMenu";
import { mainMenus } from "../../Utils/Constants/constants";
import { setBackgroundColor } from "../../Redux/slice/canvasPropsSlice";
import CustomPathPopoverContent from "./SBComponents/pathMenu";
import CustomColorPopoverContent from "./SBComponents/colorMenu";
import CustomViewPopoverContent from "./SBComponents/viewMenu";
import CustomCombinePopoverContent from "./SBComponents/combineMenu";
import CustomTextPopoverContent from "./SBComponents/textMenu";
import CustomAlignmentPopoverContent from "./SBComponents/alignmentMenu";
import CustomClipboardPopoverContent from "./SBComponents/clipboardMenu";
import CustomShapesPopoverContent from "./SBComponents/shapesMenu";
import CustomFilePopoverContent from "./SBComponents/fileMenu";
import CustomHomePopoverContent from "./SBComponents/homeMenu";
import CustomCanvasPopoverContent from "./SBComponents/canvasMenu";
import CustomPopover from "../../Utils/SideBarComps/customPopover";
import CustomSprayPopoverContent from "./SBComponents/sprayMenu";
import ColorPicker from "react-best-gradient-color-picker";
import { useNavigate } from "react-router-dom";
import CustomRotateCopiesPopoverContent from "./SBComponents/RotationCopies";

const Sidebar = ({
  undo,
  redo,
  chalkAndSponge,
  activeNodes,
  setActiveNodes,
  zoom,
  handlesConnected,
  setHandlesConnected,
  handleBendAlongPath,
  handlePathRotateCopies,
  linearizeNode,
  onAddChart,
  sidebarRef,
  appBarRef1,
  appBarRef2,
  combineSelectedPathsExclusion,
  combineSelectedPaths,
  combineSelectedObjectsIntersection,
  onAddCanvas,
  switchCanvas,
  combineSelectedObjectsFragmentation,
  changeOpacity,
  canvasImage,
  mainCanvas,
  onPredifenedSizeChange,
  onSizeChange,
  handleDrawingMode,
  fillColor,
  setOpacity,
  handleZoomToPage,
  opacity,
  combineSelectedObjectsDifference,
  onDownloadSVG,
  onAddTrapezium,
  onAddPolygon,
  pathSelect,
  setPathSelect,
  circularizeNode,
  convertObjectToPath,
  onPatternAlongPath,
  onAddLine,
  onDoubleArrow,
  selectedStrokeColor,
  setSelectedStrokeColor,
  onAddArrow,
  addNodeAtMidpoint,
  onAddStar,
  areaOfSpray,
  setAreaOfSpray,
  numberOfCopies,
  setNumberOfCopies,
  onAddRectangle,
  removeNodes,
  onAddSquare,
  onAddCircle,
  onAddTriangle,
  onFreeDrawing,
  onDisableFreeDrawing,
  onSetColor,
  onEraser,
  onUploadSVG,
  size,
  originalVisibility,
  activeObj,
  spraySelectRef,
  handleChangeView,
  handleSpraying,
  changeOpacityOfObj,
  opacityObj,
  horizonalGuidesRef,
  verticalGuidesRef,
  showGuides,
  setShowGuides,
  showRuler,
  setShowRuler,
  rulerToggleClick,
  handleZoomIn,
  handleZoomOut,
  zoomSelection,
  containerDim,
  setContainerDim,
  activeCanvasIndex,
  canvasArray,
  setActiveObj,
  rotationSettings,
  updateRotationSettings,
  setSelectedColorForRotationCopy,
  applyColorToSelectedCopy,
  selectedStrokeForRotationCopy,
  setSelectedStrokeForRotationCopy,
  selectedColorForRotationCopy,
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(
    () => console.log("mainCanvas in Sidebar:", mainCanvas),
    [mainCanvas]
  );
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [currentPopover, setCurrentPopover] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [tempSize, setTempSize] = useState({
    width: "",
    height: "",
    unit: "px",
  });
  const [activeButton, setActiveButton] = useState(null);
  var iconColor = "white";
  const [fontFamily, setFontFamily] = useState("Times New Roman");
  const [fontColor, setFontColor] = useState("");
  const [fontSize, setFontSize] = useState(20);
  const [values, setValues] = useState({
    mode: "Duplicated",
    tangentialRotate: false,
    rotate: false,
    objByPartRotate: false,
    groupMode: "Sequential",
    spacing: 0,
    normalOff: 0,
    tangentialOff: 0,
  });
  const [fontFamilies, setFontFamilies] = useState([
    "Arial",
    "Arvo",
    "Bank Gothic",
    "Calibri",
    "Calisto MT",
    "Candara",
    "Century Gothic",
    "Gadugi",
    "Georgia",
    "Lato",
    "Lucida Console",
    "Lucida Sans Unicode",
    "MS Serif",
    "Tahoma",
    "Times New Roman",
    "Trebuchet MS",
    "Verdana",
  ]);

  const fontSizes = [...Array(65).keys()].map((i) => i + 8);
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const downloadFormats = [
    "png",
    "jpeg",
    "svg",
    "pdf",
    "tiff",
    "bitmap",
    "dpi",
  ];

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = (callback, buttonName) => () => {
    if (buttonName == "download") {
      setAnchorEl(event.currentTarget);

      console.log("handleButtonClick: download: ", callback, buttonName);
      setActiveButton(buttonName);
      onDownloadSVG();
    } else {
      setActiveButton(buttonName);
      callback(event, buttonName);
    }
  };
  const handleUnitChange = (e) => {
    setTempSize({
      ...tempSize,
      unit: e.target.value,
    });
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setCurrentPopover(null);
    setPopoverAnchorEl(null);
    setSelectedFeature(null);
  };
  const addText = () => {
    const textbox = new fabric.Textbox("Enter your text here", {
      left: 50,
      top: 50,
      width: 200,
      fontSize: fontSize,
      class: "textbox",
      fontFamily: fontFamily,
      fontWeight: "10",
      hasControls: true,
      hasBorders: true,
      selectable: true,
      class: "textbox",
    });
    mainCanvas.add(textbox).setActiveObject(textbox);
    // setActiveObj([textbox]);
  };

  const handleFontUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fontURL = e.target.result;
        const fontName = file.name.split(".")[0];

        const newFontFace = new FontFace(fontName, `url(${fontURL})`);

        newFontFace
          .load()
          .then((loadedFont) => {
            document.fonts.add(loadedFont);

            // Add the custom font to the font family list
            setFontFamilies((prevFonts) => [...prevFonts, fontName]);

            // If there's an active text object, apply the new font immediately
            const activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === "text") {
              activeObject.set({ fontFamily: fontName });
              canvas.renderAll();
            }
          })
          .catch((err) => {
            console.error("Font loading error:", err);
          });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleFontChange = async (event) => {
    const selectedFont = event.target.value;
    console.log("handleFontChange:", selectedFont);
    setFontFamily(selectedFont);
    mainCanvas.getActiveObject().set("fontFamily", selectedFont);
    mainCanvas.requestRenderAll();
  };

  const handleFontColor = async (selectedFontColor) => {
    setFontColor(selectedFontColor);
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.set("fill", selectedFontColor);
      activeObject.set("stroke", selectedFontColor);
      mainCanvas.requestRenderAll();
    }
  };

  const handleFontSize = (e) => {
    const selectedFontSize = e.target.value;
    setFontSize(selectedFontSize);
    const activeObject = mainCanvas.getActiveObject();

    if (activeObject) {
      activeObject.set("fontSize", selectedFontSize);
      mainCanvas.requestRenderAll();
    }
  };

  const handleManualEntryClick = () => {
    setIsManualEntry(true);
    setFontSize("8");
  };

  const handleUnderline = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set("underline", !activeObject.get("underline"));
      mainCanvas.requestRenderAll();
    }
  };

  const handleBold = () => {
    const activeObject = mainCanvas.getActiveObject();
    console.log("handleBold: ", activeObject);
    if (activeObject && activeObject.type === "textbox") {
      console.log("handleBold: ", activeObject);

      activeObject.set(
        "fontWeight",
        activeObject.get("fontWeight") === "bold" ? "normal" : "bold"
      );
      mainCanvas.requestRenderAll();
    }
  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      console.log("handleKeyDown: ", e);
      if (mainCanvas) {
      }
      if (!mainCanvas) {
        return;
      }
      const activeObject = mainCanvas.getActiveObject();
      if (e.ctrlKey) {
        let key = e.key;
        console.log("handleKeyDown: key", key);

        if (key) {
          switch (key) {
            case "b":
              e.preventDefault();

              handleBold();
              break;
            case "i":
              e.preventDefault();

              handleItalics();
              break;
            case "e":
              e.preventDefault();
              if (activeObject.type === "textbox") {
                handleTextAlignCenter();
              } else {
                alignObjects("Center");
              }
              break;
            case "j":
              e.preventDefault();
              if (activeObject?.type === "textbox") {
                handleTextAlignJustified();
              } else {
                alignObjects("Middle");
              }
              break;
            case "l":
              e.preventDefault();
              if (activeObject?.type === "textbox") {
                handleTextAlignLeft();
              } else {
                alignObjects("Left");
              }
              break;
            case "r":
              e.preventDefault();
              if (activeObject?.type === "textbox") {
                handleTextAlignRight();
              } else {
                alignObjects("Right");
              }
              break;
            case "c":
              if (activeObject?.type === "textbox") {
                return;
              }
              e.preventDefault();
              handleCopyShape();

              break;
            case "v":
              if (activeObject && activeObject.type === "textbox") {
                return;
              }
              console.log(
                "handleKeyDown: case v: object not textbox: activeObject:",
                activeObject
              );
              e.preventDefault();
              handlePasteShape();

              break;
            case "x":
              if (activeObject?.type === "textbox") {
                return;
              }
              console.log(
                "handleKeyDown: case v: object not textbox: activeObject:",
                activeObject
              );
              e.preventDefault();
              handleCut();

              break;
            case 'a':
              e.preventDefault();

              const allObjects = mainCanvas.getObjects();

              if (allObjects.length > 0) {
                const activeSelection = new fabric.ActiveSelection(allObjects, {
                  canvas: mainCanvas,
                });

                mainCanvas.setActiveObject(activeSelection);
                mainCanvas.requestRenderAll();
              }
              break;
            case 'd':
              e.preventDefault();
              onAddCanvas(true);
              break;
            case 'm':
              e.preventDefault();
              onAddCanvas(false);
              break;
            case 'k':
              e.preventDefault();
              if (activeObject && activeObject.type === 'textbox') {
                openHyperlinkDialog(activeObject);
              }
              break;
            case 'f':
              e.preventDefault();
              console.log('handleKeyDown: case f:', e)
              openFindReplaceModal(false);
              break;
            case 'h':
              e.preventDefault();
              openFindReplaceModal(true);
              break;

            case 'z':
              e.preventDefault();
              undo();
              break;
            case 'y':
              e.preventDefault();
              redo();
              break;
            default:
              break;
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [mainCanvas]);

  let currentMatchIndex = 0;
  let matches = [];
  let currentModal = null;

  const openFindReplaceModal = (showReplaceField = false) => {
    // Close the previous modal if it's open
    if (currentModal) {
      currentModal.remove();
    }

    // Create new dialog container
    const dialogContainer = document.createElement('div');
    dialogContainer.innerHTML = `
      <div  style="position: relative; background-color: #36454F; border: 1px solid black; z-index: 1000; border-radius: 20px; padding: 20px; width: 300px; box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);">
        <h3 style="color: white;">${showReplaceField ? "Find and Replace" : "Find"}</h3>
        <label style="color: white;">
          Find Text:
          <input id="findTextInput" type="text" style="width: 100%; padding: 6px ; border-radius: 4px; border: 1px solid #ccc;" />
        </label>
        <br />
        <!-- Match Case Checkbox -->
        <label style="color: white;">
          <input id="matchCaseCheckbox" type="checkbox" style="margin-right: 5px;" />
          Match Case
        </label>
        <br />
        <!-- Match Whole Word Checkbox -->
        <label style="color: white;">
          <input id="matchWholeWordCheckbox" type="checkbox" style="margin-right: 5px;" />
          Match Whole Word
        </label>
        <br />
        ${showReplaceField
        ? `<label style="color: white;">
              Replace With:
              <input id="replaceTextInput" type="text" style="width: 100%; padding: 6px ; border-radius: 4px; border: 1px solid #ccc;" />
            </label>`
        : ""
      }
      <br />
      <div style="display: flex; column-gap:1px; flex-wrap: wrap; justify-content: space-between;">
      ${showReplaceField
        ? `
      <button id="prevMatchButton" style="width: 45%; margin-top: 10px; background-color: #FFC107; color: white; padding: 6px  ; border-radius: 4px; border: none; cursor: pointer;">Previous</button>
      <button id="nextMatchButton" style="width: 45%; margin-top: 10px; background-color: #FFC107; color: white; padding: 6px  ; border-radius: 4px; border: none; cursor: pointer;">Next</button>
  
      <button id="confirmReplaceButton" style="width: 45%; margin-top: 10px; background-color: #2196F3; color: white; padding: 6px  ; border-radius: 4px; border: none; cursor: pointer;">Replace</button>
      <button id="confirmReplaceAllButton" style="width: 45%; margin-top: 10px; background-color: #FF5722; color: white; padding: 6px  ; border-radius: 4px; border: none; cursor: pointer;">Replace All</button>
      `
        : ""
      }
      <button id="confirmFindButton" style="width: 45%; margin-top: 10px; background-color: #4CAF50; color: white; padding: 6px  ; border-radius: 4px; border: none; cursor: pointer;">Find</button>
      <button id="closeModalButton" style="width: 45%; margin-top: 10px; background-color: #f44336; color: white; padding: 6px  ; border-radius: 4px; border: none; cursor: pointer;">Close</button>
      </div>
      <div id="matchCount" style="color: white; margin-top: 10px;"></div>
    </div>`;

    dialogContainer.style.position = 'absolute';
    dialogContainer.style.top = '45%';
    dialogContainer.style.left = '80%';
    dialogContainer.style.transform = 'translate(-50%, -50%)';
    dialogContainer.style.zIndex = "1000";

    document.body.appendChild(dialogContainer);
    currentModal = dialogContainer;  // Update the currentModal

    const findTextInput = document.getElementById("findTextInput");
    const replaceTextInput = document.getElementById("replaceTextInput");

    // Event Listeners
    document.getElementById("confirmFindButton").onclick = () => {
      const findText = findTextInput.value.trim();
      const matchCase = document.getElementById("matchCaseCheckbox").checked;
      const matchWholeWord = document.getElementById("matchWholeWordCheckbox").checked;
      console.log('highlightMatches: findButtonClicked: ', findText);
      highlightMatches(findText, matchCase, matchWholeWord);
    };

    document.getElementById("findTextInput").oninput = (e) => {
      const findText = e.target.value.trim();
      const matchCase = document.getElementById("matchCaseCheckbox").checked;
      const matchWholeWord = document.getElementById("matchWholeWordCheckbox").checked;

      handleFindInput(findTextInput, findText, matchCase, matchWholeWord);
    };

    document.getElementById("matchCaseCheckbox").addEventListener('change', () => {
      const findText = findTextInput.value.trim();
      const matchCase = document.getElementById("matchCaseCheckbox").checked;
      const matchWholeWord = document.getElementById("matchWholeWordCheckbox").checked;
      highlightMatches(findText, matchCase, matchWholeWord);
    });

    document.getElementById("matchWholeWordCheckbox").addEventListener('change', () => {
      const findText = findTextInput.value.trim();
      const matchCase = document.getElementById("matchCaseCheckbox").checked;
      const matchWholeWord = document.getElementById("matchWholeWordCheckbox").checked;
      highlightMatches(findText, matchCase, matchWholeWord);
    });



    if (showReplaceField) {
      document.getElementById("confirmReplaceButton").onclick = () => {
        const findText = findTextInput.value.trim();
        const replaceText = replaceTextInput.value.trim();
        replaceSelected(findText, replaceText);
      };
      document.getElementById("nextMatchButton").onclick = () => {
        if (matches.length > 0) {
          currentMatchIndex = (currentMatchIndex + 1) % matches.length;
          highlightCurrentMatch();
        }
      };

      document.getElementById("prevMatchButton").onclick = () => {
        if (matches.length > 0) {
          currentMatchIndex = (currentMatchIndex - 1 + matches.length) % matches.length;
          highlightCurrentMatch();
        }
      };

      document.getElementById("confirmReplaceAllButton").onclick = () => {
        const findText = findTextInput.value.trim();
        const replaceText = replaceTextInput.value.trim();
        replaceAll(findText, replaceText);
      };
    }

    document.getElementById("closeModalButton").onclick = () => {
      console.log('highlightMatches: closeModalButtonClicked: ');
      clearHighlights();
      document.body.removeChild(dialogContainer);
    };
  };


  const handleFindInput = (findInputField, event, matchCase, matchWholeWord) => {
    highlightMatches(event, matchCase, matchWholeWord);

    findInputField.focus();
  };

  const highlightMatches = (searchText, matchCase, matchWholeWord) => {
    clearHighlights(); // Clear previous highlights first

    if (!searchText) return; // Do nothing if input is empty

    matches = [];
    currentMatchIndex = 0; // Reset current match index

    mainCanvas.getObjects("textbox").forEach((textbox) => {
      const text = textbox.text || "";

      let matchIndex = -1;

      // Temporarily enable editing mode to apply styles
      textbox.enterEditing();

      while ((matchIndex = findMatch(text, searchText, matchIndex + 1, matchCase, matchWholeWord)) !== -1) {
        matches.push({ textbox, matchIndex, text });

        // Apply yellow text color and underline style
        for (let i = matchIndex; i < matchIndex + searchText.length; i++) {
          textbox.setSelectionStyles(
            { fill: "yellow", underline: true }, // Set text color to yellow and underline
            i,
            i + 1
          );
        }
      }

      textbox.exitEditing(); // Exit editing mode
    });

    updateMatchCount();
    mainCanvas.requestRenderAll();
  };

  const findMatch = (text, searchText, startIndex, matchCase, matchWholeWord) => {
    let matchIndex = matchCase ? text.indexOf(searchText, startIndex) : text.toLowerCase().indexOf(searchText.toLowerCase(), startIndex);

    if (matchIndex !== -1 && matchWholeWord) {
      // Ensure the match is a whole word (surrounded by non-word characters or the start/end of the string)
      const prevChar = text.charAt(matchIndex - 1);
      const nextChar = text.charAt(matchIndex + searchText.length);
      if (/\w/.test(prevChar) || /\w/.test(nextChar)) {
        return findMatch(text, searchText, matchIndex + 1, matchCase, matchWholeWord); // Continue searching
      }
    }

    return matchIndex;
  };

  const highlightCurrentMatch = () => {
    // clearHighlights(); // Clear previous highlights
    console.log('highlightCurrentMatch: props: ', matches, currentMatchIndex, matches[currentMatchIndex])
    if (matches.length > 0 && matches[currentMatchIndex]) {
      const { textbox, matchIndex, text } = matches[currentMatchIndex];
      console.log('highlightCurrentMatch: in if: ', textbox, matchIndex, text)

      // Temporarily enable editing mode to apply styles
      textbox.enterEditing();

      // Clear all previous selections and highlight current one
      // textbox.setSelectionStyles({}, 0, text.length); // Clear previous selections
      for (let i = matchIndex; i < matchIndex + text.length; i++) {
        console.log('highlightCurrentMatch: in if: in for:', text, matchIndex)

        textbox.setSelectionStyles({ fill: "yellow", underline: true }, i, i + 1); // Apply highlight styles
      }

      textbox.exitEditing(); // Exit editing mode
    }

    updateMatchCount();
    mainCanvas.requestRenderAll();
  };

  const updateMatchCount = () => {
    const matchCountElement = document.getElementById("matchCount");
    if (matches.length > 0) {
      matchCountElement.textContent = `${currentMatchIndex + 1}/${matches.length}`;
    } else {
      matchCountElement.textContent = '';
    }
  };

  const replaceSelected = (findText, replaceText) => {
    if (matches.length > 0 && findText && replaceText) {
      const { textbox, matchIndex, text } = matches[currentMatchIndex];
      const newText = text.slice(0, matchIndex) + replaceText + text.slice(matchIndex + findText.length);

      // Reset styles and set the new text
      textbox.set("styles", {});
      textbox.set("text", newText);
      textbox.initDimensions(); // Force Fabric.js to recalculate text dimensions

      clearHighlights();
      highlightMatches(findText);
    }
  };

  const replaceAll = (findText, replaceText) => {
    if (matches.length > 0 && findText && replaceText) {
      matches.forEach(({ textbox, matchIndex, text }) => {
        const newText = text.slice(0, matchIndex) + replaceText + text.slice(matchIndex + findText.length);

        // Reset styles and set the new text
        textbox.set("styles", {});
        textbox.set("text", newText);
        textbox.initDimensions(); // Force Fabric.js to recalculate text dimensions
      });

      clearHighlights();
      highlightMatches(findText);
    }
  };

  const clearHighlights = () => {
    mainCanvas.getObjects("textbox").forEach((textbox) => {
      textbox.set("styles", {}); // Clear styles completely
      textbox.initDimensions(); // Recalculate text dimensions
    });
    matches = [];
    updateMatchCount();
    mainCanvas.requestRenderAll();
  };



  const openHyperlinkDialog = (textbox) => {
    if (currentModal) {
      currentModal.remove();
    }

    const dialogContainer = document.createElement('div');
    dialogContainer.innerHTML = `
    <div style="position: relative; background-color: #36454F; border: 1px solid black; z-index: 1000; border-radius: 20px; padding: 20px; width: 100%; box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);">
      <h3 style="color: white;">Add Hyperlink</h3>
      <label style="color: white;">
        Display Text:
        <input id="displayTextInput" type="text" style="width: 100%; padding: 6px ; border-radius: 4px; border: 1px solid #ccc;" />
      </label>
      <br />
      <label style="color: white;">
        Link URL:
        <input id="linkUrlInput" type="url" placeholder="https://example.com" style="width: 100%; padding: 6px ; border-radius: 4px; border: 1px solid #ccc;" />
      </label>
      <br />
      <button id="confirmLinkButton" style="margin-top: 10px; background-color: #4CAF50; color: white; padding: 6px  ; border-radius: 4px; border: none; cursor: pointer;">Confirm</button>
      <button id="cancelLinkButton" style="margin-top: 10px; background-color: #f44336; color: white; padding: 6px  ; border-radius: 4px; border: none; cursor: pointer;">Cancel</button>
    </div>
  `;

    dialogContainer.style.position = 'absolute';
    dialogContainer.style.top = '45%';
    dialogContainer.style.left = '80%';
    dialogContainer.style.transform = 'translate(-50%, -50%)';

    document.body.appendChild(dialogContainer);
    currentModal = dialogContainer;
    document.getElementById('confirmLinkButton').onclick = () => {
      const displayText = document.getElementById('displayTextInput').value;
      const linkUrl = document.getElementById('linkUrlInput').value;

      if (displayText && linkUrl) {
        embedHyperlink(textbox, displayText, linkUrl);
      }

      // Close the dialog
      document.body.removeChild(dialogContainer);
    };

    document.getElementById('cancelLinkButton').onclick = () => {
      // Close the dialog without making changes
      document.body.removeChild(dialogContainer);
    };
  };

  const embedHyperlink = (textbox, displayText, linkUrl) => {
    // Get the current selection range (or cursor position if no selection)
    const cursorStart = textbox.selectionStart || 0;
    const cursorEnd = textbox.selectionEnd || cursorStart;

    // Get the current text of the textbox
    const existingText = textbox.get('text') || '';

    // Split the text into parts: before, selected, and after
    const textBefore = existingText.slice(0, cursorStart);
    const textSelected = existingText.slice(cursorStart, cursorEnd);
    const textAfter = existingText.slice(cursorEnd);

    // Construct the new text: before the selection, the display text, and after the selection
    const newText = textBefore + displayText + textAfter;

    // Update the text of the textbox
    textbox.set('text', newText);

    // Apply hyperlink styling to the inserted displayText
    const start = cursorStart;
    const end = start + displayText.length;

    // Set the selection style to the newly inserted text
    for (let i = start; i < end; i++) {
      textbox.setSelectionStyles({
        fill: 'blue',
        underline: true,
      }, i, i + 1);
    }

    // Store the hyperlink metadata on the textbox object
    textbox.hyperlinks = textbox.hyperlinks || [];
    textbox.hyperlinks.push({
      startIndex: start,
      endIndex: end,
      url: linkUrl,
    });

    // Re-render the canvas to reflect the changes
    mainCanvas.requestRenderAll();
  };



  const handleItalics = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set(
        "fontStyle",
        activeObject.get("fontStyle") === "italic" ? "normal" : "italic"
      );
      mainCanvas.requestRenderAll();
    }
  };

  const handleMergeCenter = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set({ textAlign: "center" });
      mainCanvas.requestRenderAll();
    }
  };


  const superscripts = []; // Array to track superscripts

  const handleSuperscript = () => {
    const activeObject = mainCanvas.getActiveObject();

    if (activeObject && activeObject.type === "textbox") {
      const { selectionStart, selectionEnd, text, fontSize } = activeObject;

      if (selectionStart !== selectionEnd) {
        const selectedText = text.slice(selectionStart, selectionEnd);

        // Remove the selected text from the textbox
        const newText =
          text.slice(0, selectionStart) + text.slice(selectionEnd);
        activeObject.text = newText; // Update the textbox content

        const originalFontSize = fontSize || 40;

        // Calculate superscript position using context
        const context = mainCanvas.getContext("2d");
        context.font = `${originalFontSize}px ${activeObject.fontFamily}`;
        const textWidthBeforeSelection = context.measureText(
          text.slice(0, selectionStart)
        ).width;

        // Create the superscript text object
        const superscript = new fabric.Text(selectedText, {
          fontSize: originalFontSize * 0.7,
          left:
            activeObject.left +
            textWidthBeforeSelection * activeObject.scaleX,
          top: activeObject.top - originalFontSize * 0.3,
          fontFamily: activeObject.fontFamily,
          fill: activeObject.fill,
          selectable: false,
        });

        // Add superscript to the canvas
        mainCanvas.add(superscript);

        // Store the superscript and its position for accurate deletion
        superscripts.push({
          superscript,
          parentObject: activeObject,
          selectionStart,
          selectionEnd,
        });

        // Make sure the superscript moves with the textbox
        activeObject.on("moving", () => {
          superscript.set({
            left: activeObject.left + textWidthBeforeSelection * activeObject.scaleX,
            top: activeObject.top - originalFontSize * 0.3,
          });
          mainCanvas.requestRenderAll();
        });

        // Prevent the textbox from re-rendering the removed text
        activeObject.exitEditing();
        activeObject.enterEditing();

        mainCanvas.requestRenderAll();
      } else {
        console.log("No text selected for superscript.");
      }
    } else {
      console.log("No textbox selected.");
    }
  };

  // Remove the superscript associated with the selected text or nearest match
  document.addEventListener("keydown", (e) => {
    if ((e.key === "Backspace" || e.key === "Delete") && superscripts.length > 0) {
      const activeObject = mainCanvas.getActiveObject();

      if (activeObject && activeObject.type === "textbox") {
        const { selectionStart } = activeObject;

        // Find the nearest superscript to the current selection
        const index = superscripts.findIndex(
          (s) =>
            s.parentObject === activeObject &&
            selectionStart >= s.selectionStart &&
            selectionStart <= s.selectionEnd
        );

        if (index !== -1) {
          const { superscript } = superscripts[index];
          mainCanvas.remove(superscript); // Remove the superscript from canvas
          superscripts.splice(index, 1); // Remove it from the array
          mainCanvas.requestRenderAll();
        }
      }
    }
  });

  const subscripts = [];

  const handleSubscript = () => {
    const activeObject = mainCanvas.getActiveObject();

    if (activeObject && activeObject.type === "textbox") {
      const { selectionStart, selectionEnd, text, fontSize } = activeObject;

      if (selectionStart !== selectionEnd) {
        const selectedText = text.slice(selectionStart, selectionEnd);

        // Remove the selected text and update the textbox
        const newText =
          text.slice(0, selectionStart) + text.slice(selectionEnd);
        activeObject.set({ text: newText });

        // Force the textbox to re-render properly
        activeObject.exitEditing();
        activeObject.enterEditing();

        const originalFontSize = fontSize || 40;

        // Calculate the subscript position
        const context = mainCanvas.getContext("2d");
        context.font = `${originalFontSize}px ${activeObject.fontFamily}`;
        const textWidthBeforeSelection = context.measureText(
          text.slice(0, selectionStart)
        ).width;

        const subscript = new fabric.Text(selectedText, {
          fontSize: originalFontSize * 0.7,
          left:
            activeObject.left +
            textWidthBeforeSelection * activeObject.scaleX,
          top: activeObject.top + originalFontSize * 0.5,
          fontFamily: activeObject.fontFamily,
          fill: activeObject.fill,
          selectable: false,
        });

        // Add subscript to the canvas
        mainCanvas.add(subscript);

        // Store the subscript and its associated textbox
        subscripts.push({ subscript, selectionStart, selectionEnd, parentObject: activeObject });

        // Update subscript position when the parent textbox moves
        activeObject.on("moving", () => {
          const updatedTextWidthBeforeSelection = context.measureText(
            activeObject.text.slice(0, selectionStart)
          ).width;

          subscript.set({
            left:
              activeObject.left +
              updatedTextWidthBeforeSelection * activeObject.scaleX,
            top: activeObject.top + originalFontSize * 0.5,
          });
          mainCanvas.requestRenderAll();
        });

        mainCanvas.requestRenderAll();
      } else {
        console.log("No text selected for subscript.");
      }
    } else {
      console.log("No textbox selected.");
    }
  };

  // Remove the subscript when Backspace/Delete is pressed
  document.addEventListener("keydown", (e) => {
    if (
      (e.key === "Backspace" || e.key === "Delete") &&
      subscripts.length > 0
    ) {
      const activeObject = mainCanvas.getActiveObject();

      if (activeObject && activeObject.type === "textbox") {
        const { selectionStart, selectionEnd } = activeObject;

        // Find and remove the subscript that corresponds to the selected text
        subscripts.forEach((subscriptData, index) => {
          if (
            selectionStart >= subscriptData.selectionStart &&
            selectionEnd <= subscriptData.selectionEnd
          ) {
            const { subscript } = subscriptData;
            mainCanvas.remove(subscript);
            subscripts.splice(index, 1); // Remove from subscripts array
          }
        });

        mainCanvas.requestRenderAll();
      }
    }
  });



  const handleTextAlignLeft = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set("textAlign", "left");
      mainCanvas.requestRenderAll();
    }
  };

  const handleTextAlignCenter = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set("textAlign", "center");
      mainCanvas.requestRenderAll();
    }
  };

  const handleTextAlignRight = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set("textAlign", "right");
      mainCanvas.requestRenderAll();
    }
  };

  const handleTextAlignJustified = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set("textAlign", "justify");
      mainCanvas.requestRenderAll();
    }
  };

  const handleUpperCase = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set("text", activeObject.text.toUpperCase());
      mainCanvas.requestRenderAll();
    }
  };

  const handleLowerCase = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      activeObject.set("text", activeObject.text.toLowerCase());
      mainCanvas.requestRenderAll();
    }
  };

  const handleSentenceCase = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      const sentenceCased =
        activeObject.text.charAt(0).toUpperCase() +
        activeObject.text.slice(1).toLowerCase();
      activeObject.set("text", sentenceCased);
      mainCanvas.requestRenderAll();
    }
  };

  const handleCapitalizeEachWord = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "textbox") {
      const capitalized = activeObject.text.replace(/\b\w/g, (char) =>
        char.toUpperCase()
      );
      activeObject.set("text", capitalized);
      mainCanvas.requestRenderAll();
    }
  };
  let copiedObject = null;

  const handleCut = async () => {
    const activeObject = mainCanvas.getActiveObject();

    if (activeObject) {
      if (activeObject.type === "activeSelection") {
        copiedObject = activeObject.getObjects().map((obj) => {
          let clonedObj = null;
          obj.clone((cloned) => {
            clonedObj = cloned;
            console.log(
              "handleCut: activeSelection: Object copied:",
              clonedObj
            );
          });
          return clonedObj;
        });
        console.log("handleCut: activeSelection: copiedObject:", copiedObject);
      } else {
        copiedObject = await fabric.util.object.clone(activeObject);

        console.log("handleCut: singleObj: copiedObject:", copiedObject);
      }
      mainCanvas.set({
        objForCut: copiedObject,
      });
      if (copiedObject) {
        if (activeObject.type === "activeSelection") {
          activeObject.getObjects().forEach((obj) => {
            mainCanvas.remove(obj);
            mainCanvas.discardActiveObject();
            mainCanvas.requestRenderAll();
          });
        } else {
          mainCanvas.remove(activeObject);
          mainCanvas.discardActiveObject();
          mainCanvas.requestRenderAll();
        }
      } else {
        console.error("Cloning failed, copiedObject is null.");
      }
    }
  };

  const handleCopyShape = () => {
    const activeObject = mainCanvas.getActiveObject();
    console.log("handlePasteShape: copiedObject", copiedObject);

    if (activeObject) {
      if (activeObject.type === "activeSelection") {
        copiedObject = activeObject.getObjects().map((obj) => {
          let clonedObj = null;
          obj.clone((cloned) => {
            clonedObj = cloned;
            console.log("Object copied:", clonedObj);
          });
          return clonedObj;
        });
      } else {
        activeObject.clone((clonedObject) => {
          copiedObject = clonedObject;
          console.log("Object copied:", copiedObject);
        });
      }
    }
  };

  const handlePasteShape = () => {
    console.log("handlePasteShape: copiedObject before if", copiedObject);

    if (copiedObject || mainCanvas.objForCut) {
      console.log(
        "handlePasteShape: copiedObject",
        copiedObject,
        mainCanvas.objForCut
      );
      const pasteObj = copiedObject || mainCanvas.objForCut;
      console.log("handlePasteShape: pasteObj:", pasteObj);

      if (Array.isArray(pasteObj)) {
        const activeObject = mainCanvas.getActiveObject();
        const groupCenter = activeObject
          ? activeObject.getCenterPoint()
          : { x: 0, y: 0 };
        console.log(
          "handlePasteShape: activeObject, groupCenter:",
          activeObject,
          groupCenter
        );

        pasteObj.forEach((clonedObject) => {
          const center = clonedObject.getCenterPoint();
          const offsetX = center.x - groupCenter.x;
          const offsetY = center.y - groupCenter.y;
          console.log(
            "handlePasteShape: in forEach: center, offsetX, offsetY:",
            center,
            offsetX,
            offsetY
          );
          const canvasOffX = mainCanvas.getWidth() / 2 / zoom;
          const canvasOffY = mainCanvas.getHeight() / 2 / zoom;
          clonedObject.set({
            left: groupCenter.x + offsetX + canvasOffX,
            top: groupCenter.y + offsetY + canvasOffY,
            evented: true,
          });

          mainCanvas.add(clonedObject);
        });
      } else {
        pasteObj.clone((clonedClone) => {
          clonedClone.set({
            left: clonedClone.left + 10,
            top: clonedClone.top + 10,
            evented: true,
          });
          mainCanvas.add(clonedClone);
          mainCanvas.setActiveObject(clonedClone);
          mainCanvas.requestRenderAll();
        });
      }
    }
  };

  const handleDuplicate = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.clone((clonedObject) => {
        clonedObject.set({
          left: activeObject.left + 10,
          top: activeObject.top + 10,
          evented: true,
        });

        mainCanvas.add(clonedObject);
        mainCanvas.setActiveObject(clonedObject);
        mainCanvas.requestRenderAll();
      });
    }
  };

  const handlePasteAsPng = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      const id = activeObject.id;
      const dataUrl = activeObject.toDataURL({ format: "png" });

      createImageFromDataURL(dataUrl, activeObject, id);
    } else {
      console.error("No active object to paste as PNG.");
    }
  };

  const handlePasteAsJpeg = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      const id = activeObject.id;
      const dataUrl = activeObject.toDataURL({ format: "jpeg" });

      createImageFromDataURL(dataUrl, activeObject, id);
    } else {
      console.error("No active object to paste as JPEG.");
    }
  };

  const handlePasteAsBitmap = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      const id = activeObject.id;
      const dataUrl = activeObject.toDataURL({ format: "png" });

      createImageFromDataURL(dataUrl, activeObject, id);
    } else {
      console.error("No active object to paste as Bitmap.");
    }
  };

  const handlePasteAsSvg = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      const id = activeObject.id;
      const svgData = activeObject.toSVG();
      const blob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
      const dataUrl = URL.createObjectURL(blob);

      createImageFromDataURL(dataUrl, activeObject, id);
    } else {
      console.error("No active object to paste as SVG.");
    }
  };

  const createImageFromDataURL = (dataUrl, activeObject, id) => {
    const imgElement = new Image();
    imgElement.src = dataUrl;

    imgElement.onload = () => {
      const imgInstance = new fabric.Image(imgElement, {
        left: activeObject.left + 20,
        top: activeObject.top + 20,
        scaleX: activeObject.scaleX,
        scaleY: activeObject.scaleY,
      });

      mainCanvas.add(imgInstance);
      mainCanvas.setActiveObject(imgInstance);
      mainCanvas.requestRenderAll();

      clonedObjects[id] = imgInstance;
    };
  };

  const clonedObjects = [];

  const cloneObjectWithSameId = () => {
    const originalObject = mainCanvas.getActiveObject();

    if (!originalObject) {
      console.error("No active object to clone.");
      return;
    }

    originalObject.clone((clonedObject) => {
      clonedObject.set("id", originalObject.id);
      clonedObject.set("class", "cloned");
      clonedObject.set({
        left: originalObject.left + 50,
        top: originalObject.top + 50,
      });

      mainCanvas.add(clonedObject);
      clonedObjects.push(clonedObject);
      const synchronizeProperties = (source, target) => {
        const originalSet = source.set.bind(source);
        source.set = function (key, value) {
          originalSet(key, value);
          if (key === "fill") {
            target.set("fill", value);
          }
          if (key === "stroke") {
            target.set("stroke", value);
          }

          if (
            key === "width" ||
            key === "height" ||
            key === "scaleX" ||
            key === "scaleY"
          ) {
            target.set(key, value);
          }
        };

        target.set("stroke", source.stroke);
        target.set("fill", source.fill);
        target.set("width", source.width);
        target.set("height", source.height);
        target.set("scaleX", source.scaleX);
        target.set("scaleY", source.scaleY);
      };

      synchronizeProperties(originalObject, clonedObject);

      mainCanvas.renderAll();
      console.log("Cloned object with the same ID:", clonedObject);
    });
  };

  const selectAllObjects = () => {
    const allObjects = mainCanvas.getObjects();

    if (allObjects.length > 0) {
      const selection = new fabric.ActiveSelection(allObjects, {
        canvas: mainCanvas,
      });

      mainCanvas.setActiveObject(selection);
      mainCanvas.requestRenderAll();
    }
  };

  // function undo() {
  //   if (mainCanvas) {
  //     mainCanvas.undo();
  //   }
  // }

  // function redo() {
  //   mainCanvas.redo();
  // }

  const groupSelectedObjects = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "activeSelection") {
      activeObject.toGroup();
      activeObject.set({
        originX: "center",
        originY: "center",
      });
      mainCanvas.requestRenderAll();
    }
  };

  const ungroupSelectedObjects = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "group") {
      activeObject.toActiveSelection();
      mainCanvas.requestRenderAll();
    }
  };

  const selectMostRecentObject = () => {
    const allObjects = mainCanvas.getObjects();

    if (allObjects.length > 0) {
      const mostRecentObject = allObjects[allObjects.length - 1];
      mainCanvas.discardActiveObject();
      mainCanvas.setActiveObject(mostRecentObject);
      mainCanvas.renderAll();
    } else {
      console.log("No objects on the canvas.");
    }
  };

  const handlePopoverClick = (event, popover) => {
    if (popover === "canvas") {
      setSelectedFeature("Size");
    }
    console.log(
      "handlePopoverClick: event.currentTarget, popover: ",
      event.currentTarget,
      popover
    );
    setPopoverAnchorEl(event.currentTarget);
    setCurrentPopover(popover);
    setActiveButton(popover);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
    setCurrentPopover(null);
    setActiveButton(null);
    setSelectedFeature(null);
  };

  const handleBackButtonClick = () => {
    setSelectedFeature(null);
    setCurrentPopover(activeButton);
  };
  const isPopoverOpen = Boolean(popoverAnchorEl);
  const charts = [
    {
      class: "charts",
      label: "Pie Chart",
      icon: <BiPieChart />,
    },
    {
      class: "charts",
      label: "Histograms",
      icon: <BiBarChart />,
    },
    {
      class: "charts",
      label: "Venn Diagram",
      icon: <JoinFull />,
    },
    {
      class: "charts",
      label: "Scatter Plot",
      icon: <BiScatterChart />,
    },
    {
      class: "charts",
      label: "Line Chart",
      icon: <BiLineChart />,
    },
    {
      class: "charts",
      label: "Whisker Chart",
      icon: <LuCandlestickChart />,
    },
  ];
  const shapes = [
    {
      class: "basics",
      label: "Square",
      icon: <FaRegSquareFull />,
      onClick: onAddSquare,
    },
    {
      class: "basics",
      label: "Rounded Square",
      icon: <BiSquareRounded />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "roundedSquare"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Rectangle",
      icon: <MdOutlineRectangle />,
      onClick: onAddRectangle,
    },
    {
      class: "basics",
      class: "basics",

      label: "Rounded Rectangle",
      icon: <LuRectangleHorizontal />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "roundedRectangle"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Circle",
      icon: <FaRegCircle />,
      onClick: onAddCircle,
    },
    {
      class: "basics",
      class: "basics",

      label: "Oval",
      icon: <TbOvalVertical />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "oval"),
    },
    {
      class: "speech",
      class: "speech",

      label: "Speech Bubble 1",
      icon: <ChatBubble />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "roundedRectCallout"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Cylinder",
      icon: <BiCylinder />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "cylinder"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Cube",
      icon: <GrCube />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "cube"),
    },

    {
      class: "basics",
      class: "basics",

      label: "Triangle",
      icon: <IoTriangleOutline />,
      onClick: onAddTriangle,
    },
    {
      class: "basics",
      class: "basics",

      label: "Right Angle Triangle",
      icon: <LuTriangleRight />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "90Triangle"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Star",
      icon: <FaStar />,
      onClick: onAddStar,
    },
    {
      class: "lines",

      label: "Line",
      icon: <TbLine />,
      onClick: onAddLine,
    },

    {
      class: "lines",
      class: "lines",

      label: "Line Arrow",
      icon: <FaArrowRight />,
      onClick: onAddArrow,
    },
    {
      class: "lines",
      class: "lines",

      label: "Double Line Arrow",
      icon: <IoIosResize />,
      onClick: onDoubleArrow,
        
    },
    {
      class: "arrows",
      class: "arrows",

      label: "Big Right Arrow",
      icon: <TbArrowBigRight />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "bigRightArrow"
        ),
    },
    {
      class: "arrows",
      class: "arrows",

      label: "Double Sided Arrow",
      icon: <PiArrowsVerticalBold />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "doubleSidedArrow"
        ),
    },
    {
      class: "lines",

      label: "Curved Line",
      icon: <img height={25} width={25} src="/shapes/elbow.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "curvedLine"
        ),
    },
    {
      class: "lines",

      label: "Curved Line With Arrow",
      icon: <img height={30} width={30} src="/shapes/elbow-single.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "curvedPolyArrow"
        ),
    },
    {
      class: "lines",

      label: "Curved Line With 2 Arrows",
      icon: <img height={25} width={25} src="/shapes/elbow-double.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "curvedPolyDoubleArrow"
        ),
    },

    {
      class: "lines",
      class: "lines",

      label: "Elbow Connector",
      icon: (
        <img
          height={25}
          width={25}
          src="/shapes/elbow-connector-svgrepo-com.svg"
        />
      ),
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "elbowConnectorLine"
        ),
    },
    {
      class: "lines",
      class: "lines",

      label: "Elbow Connector With Arrow",
      icon: <PiArrowElbowDownRightFill />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "elbowConnectorLineArrow"
        ),
    },
    {
      class: "lines",
      class: "lines",

      label: "Elbow Connector With Double Arrow",
      icon: <img height={25} width={25} src="/shapes/double-elbow.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "elbowConnectorLineDoubleArrow"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Single Snipped Rectangle",
      icon: <img height={25} width={25} src="/shapes/path (4).svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "singleSnippedRect"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Same Side Snipped Rectangle",
      icon: (
        <img height={25} width={25} src="/shapes/double-snipped-rect.svg" />
      ),
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "doubleSameSnippedRect"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Diagonal Snipped Rectangle",
      icon: (
        <img height={25} width={25} src="/shapes/diagonal-snipped-rect.svg" />
      ),
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "doubleDiagonalSnippedREct"
        ),
    },

    {
      class: "basics",
      class: "basics",

      label: "Rounded And Snipped Rectangle",
      icon: (
        <img height={25} width={25} src="/shapes/snipped-rounded-rect.svg" />
      ),
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "snippedAndRoundedRect"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Parallelogram",
      icon: <PiParallelogram />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "parallelogram"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Trapezium",
      icon: <img height={25} width={25} src="/shapes/trapezium.svg" />,
      onClick: onAddTrapezium,
    },
    {
      class: "basics",

      label: "Rounded Trapezium",
      icon: (
        <img height={30} width={30} src="/shapes/trapezoid-svgrepo-com.png" />
      ),
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "roundedTrapezium"
        ),
    },
    {
      class: "basics",

      label: "Rhombus",
      icon: <CgShapeRhombus />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "rhombus"
        ),
    },
    {
      class: "basics",

      label: "Rounded Rhombus",
      icon: (
        <img
          height={25}
          width={25}
          src="/shapes/shape-rhombus-svgrepo-com.svg"
        />
      ),
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "roundedRhombus"
        ),
    },
    {
      class: "basics",

      label: "Polygon",
      icon: <PiPolygon />,
      onClick: onAddPolygon,
    },
    {
      class: "basics",

      label: "Hexagon",
      icon: <MdOutlineHexagon />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "hexagon"
        ),
    },
    {
      class: "basics",

      label: "Heptagon",
      icon: <BsHeptagon />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "heptagon"
        ),
    },
    {
      class: "basics",

      label: "Octagon",
      icon: <BsOctagon />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "octagon"
        ),
    },
    {
      class: "basics",

      label: "Nonagon",
      icon: <TbHexagonNumber9 />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "nonagon"
        ),
    },
    {
      class: "basics",

      label: "Decagon",
      icon: <img height={30} width={30} src="/shapes/decagon.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "decagon"
        ),
    },
    {
      class: "basics",

      label: "Hendecagon",
      icon: <img height={30} width={30} src="/shapes/hendagon.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "hendecagon"
        ),
    },
    {
      class: "basics",

      label: "Dodecagon",
      icon: <img height={30} width={30} src="/shapes/dodecagon.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "dodecagon"
        ),
    },
    {
      class: "specials",

      label: "Pie",
      icon: <img height={25} width={25} src="/shapes/pie.svg" />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "pie"),
    },
    {
      class: "specials",

      label: "Teardrop",
      icon: <PiChatTeardrop />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "teardrop"
        ),
    },
    {
      class: "specials",

      label: "Chord",
      icon: <SquareSharp />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "chord"),
    },
    {
      class: "basics",

      label: "Frame",
      icon: <img height={25} width={25} src="/shapes/frame.svg" />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "frame"),
    },
    {
      class: "basics",

      label: "Half Frame",
      icon: <img height={25} width={25} src="/shapes/half-frame.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "halfFrame"
        ),
    },
    {
      class: "basics",

      label: "L-Shape",
      icon: <img height={25} width={25} src="/shapes/l-shape.svg" />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "lShape"),
    },
    {
      class: "specials",

      label: "Smily",
      icon: <CgSmile />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "smily"),
    },
    {
      class: "specials",

      label: "Sun",
      icon: <FaSun />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "sun"),
    },
    {
      class: "basics",

      label: "Arc",
      icon: <CgShapeHalfCircle />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "bowArc"),
    },
    {
      class: "specials",

      label: "Cloud",
      icon: <FaCloud />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "cloud"),
    },
    {
      class: "specials",

      label: "Bevel",
      icon: <img height={25} width={25} src="/shapes/bevel.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "solidFrame"
        ),
    },
    {
      class: "specials",

      label: "Doughnut",
      icon: <BiDoughnutChart />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "doughnut"
        ),
    },
    {
      class: "specials",

      label: "Moon",
      icon: <FaRegMoon />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "crescent"
        ),
    },
    {
      class: "specials",

      label: "Thunderbolt",
      icon: <AiOutlineThunderbolt />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "thunderbolt"
        ),
    },
    {
      class: "specials",

      label: "No-Symbol",
      icon: <img height={25} width={25} src="/shapes/no-symbol.svg" />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "no"),
    },
    {
      class: "specials",

      label: "Square Brackets",
      icon: <PiBracketsSquare />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "squareBrackets"
        ),
    },
    {
      class: "specials",

      label: "Curly Brackets",
      icon: <PiBracketsCurly />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "curlyBrackets"
        ),
    },
    {
      class: "specials",
      label: "Folded Page",
      icon: <img height={25} width={25} src="/shapes/folded-paper.svg" />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "folded"),
    },
    {
      class: "arrows",
      label: "Curved Arrow",
      icon: <PiArrowArcRight />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "curvedArrow"
        ),
    },
    {
      class: "arrows",
      label: "Curved Arrow Left",
      icon: <PiArrowArcLeft />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "curvedArrowLeft"
        ),
    },
    // {
    //   class:"linesAndArrows",
    //   label:"Curved Arrow Top",
    //   icon:<DonutLargeSharp/>,
    //   onClick:() => handleDrawingMode(mainCanvas,fillColor,  selectedStrokeColor, "curvedArrowTop")
    // },
    // {
    //   class:"linesAndArrows",
    //   label:"Curved Arrow Bottom",
    //   icon:<DonutLargeSharp/>,
    //   onClick:() => handleDrawingMode(mainCanvas,fillColor,  selectedStrokeColor, "curvedArrowBottom")
    // },
    {
      class: "arrows",
      label: "Striped Arrow",
      icon: <TbArrowBigRightLinesFilled />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "stripedArrow"
        ),
    },
    {
      class: "arrows",
      label: "Notched Right Arrow",
      icon: <img height={25} width={25} src="/shapes/notched-arrow.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "notchedArrow"
        ),
    },
    {
      class: "arrows",
      label: "Right Callout Arrow",
      icon: <img height={25} width={25} src="/shapes/right-callout.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "rightCalloutArrow"
        ),
    },
    {
      class: "arrows",
      label: "Left-Right Callout Arrow",
      icon: <img height={25} width={25} src="/shapes/left-callout.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "leftRightCalloutArrow"
        ),
    },
    {
      class: "arrows",
      label: "Quad Callout Arrow",
      icon: <img height={25} width={25} src="/shapes/quad-callout.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "quadCalloutArrow"
        ),
    },
    {
      class: "arrows",
      label: "Chevron Arrow",
      icon: <img height={25} width={25} src="/shapes/chevron.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "chevronArrow"
        ),
    },
    {
      class: "arrows",
      label: "Quad Arrows",
      icon: <FaArrowsUpDownLeftRight />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "quadArrows"
        ),
    },
    {
      class: "arrows",
      label: "Triple Arrows",
      icon: <img height={25} width={25} src="/shapes/triple-arrows.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "tripleArrows"
        ),
    },
    {
      class: "arrows",
      label: "Curved Line Arrow",
      icon: <TbArrowCurveRight />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "curvedPolyArrow"
        ),
    },
    {
      class: "arrows",
      label: "Bent-Up Arrow",
      icon: <img height={25} width={25} src="/shapes/270.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "270Arrow"
        ),
    },
    {
      class: "arrows",
      label: "Left-Up Arrow",
      icon: <img height={25} width={25} src="/shapes/270-double.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "270DoubleArrow"
        ),
    },
    {
      class: "arrows",
      label: "U-Turn Arrow",
      icon: <MdUTurnRight />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "uTurnArrow"
        ),
    },
    {
      class: "arrows",
      class: "arrows",

      label: "Bent Arrow",
      icon: <PiArrowBendUpRight />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "rightAngleArrow"
        ),
    },
    {
      class: "banners",
      class: "banners",

      label: "Ribbon Banner 1",
      icon: (
        <img
          height={25}
          width={25}
          src="/shapes/ribbon-banner-svgrepo-com.svg"
        />
      ),
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "ribbonBanner"
        ),
    },
    {
      class: "banners",
      class: "banners",

      label: "Ribbon Banner 2",
      icon: (
        <img
          height={25}
          width={25}
          src="/shapes/ribbon-banner-2-svgrepo-com.svg"
        />
      ),
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "ribbonBanner2"
        ),
    },
    {
      class: "banners",
      label: "Wave Banner",
      icon: <img height={25} width={25} src="/shapes/flow-p-tape.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowPunchedTape"
        ),
    },
    {
      class: "equations",
      label: "Plus",
      icon: <Add />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "plus"),
    },
    ,
    {
      class: "equations",
      label: "Minus",
      icon: <Remove />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "minus"),
    },
    ,
    {
      class: "equations",
      label: "Multiply",
      icon: <Close />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "cross"),
    },
    ,
    {
      class: "equations",
      label: "Devide",
      icon: <StarHalf />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "devide"),
    },
    {
      class: "equations",
      label: "Equal",
      icon: <Equalizer />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "equals"),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Process",
      icon: <MdOutlineRectangle />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "rectangle"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Collate",
      icon: <img height={25} width={25} src="/shapes/collate.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowCollate"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Sort",
      icon: <img height={25} width={25} src="/shapes/flow-sort.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowSort"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Magnetic Sort",
      icon: <img height={25} width={25} src="/shapes/flow-mag-sort.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowMagSort"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Direct Access Storage",
      icon: <img height={25} width={25} src="/shapes/flow-das.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowDAS"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Summing Junction",
      icon: <img height={25} width={25} src="/shapes/flow-summing-junc.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowSummingJunction"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Manual Operation",
      icon: <img height={25} width={25} src="/shapes/flow-manual-op.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowManualOp"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Manual Input",
      icon: <img height={25} width={25} src="/shapes/flow-manual-inp.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowManualInp"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Alt Process",
      icon: <LuRectangleHorizontal />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "roundedRectangle"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Decision",
      icon: <img height={25} width={25} src="/shapes/decision.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowDecision"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Data",
      icon: <PiParallelogram />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "parallelogram"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Pre-Defined",
      icon: <img height={25} width={25} src="/shapes/flow-pre-defined.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowPreDefined"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Internal Storage",
      icon: <img height={25} width={25} src="/shapes/flow-internal.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowInternalStorage"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Document",
      icon: <img height={25} width={25} src="/shapes/flow-doc.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowDoc"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Multi Document",
      icon: <img height={25} width={25} src="/shapes/flow-multi-doc.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowMultiDoc"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Terminator",
      icon: <img height={25} width={25} src="/shapes/flow-terminator.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowTerminator"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Preparation",
      icon: <img height={25} width={25} src="/shapes/flow-prep.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowPreparation"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Connector",
      icon: <FaRegCircle />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "circle"),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Off Page Connector",
      icon: <img height={25} width={25} src="/shapes/flow-off-page.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowOffPConnector"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Card",
      icon: <img height={25} width={25} src="/shapes/flow-card.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowCard"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Punch Tape",
      icon: <img height={25} width={25} src="/shapes/flow-p-tape.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowPunchedTape"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Summing/Or",
      icon: <img height={25} width={25} src="/shapes/flow-summing.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowSumming"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Sequential Access Storage",
      icon: <img height={25} width={25} src="/shapes/flow-s-storage.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowSeqStorage"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Delay",
      icon: <img height={25} width={25} src="/shapes/flow-delay.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowDelay"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Extract",
      icon: <IoTriangleOutline />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "triangle"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Stored Data",
      icon: <img height={25} width={25} src="/shapes/flow-stored-data.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowStoredData"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Display",
      icon: <img height={25} width={25} src="/shapes/flow-display.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowDisplay"
        ),
    },
    {
      class: "speech",
      label: "Round Speech Bubble",
      icon: <img height={25} width={25} src="/shapes/round-speech.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "roundSpeech"
        ),
    },

    {
      label: "Library Of Images",
      icon: <GrGallery />,
      onClick: () => console.log("Lib Clicked!"),
      class: "images",
    },
  ];
  const files = [
    {
      label: "New",
      icon: <AddCircleOutline />,
      onClick: () => console.log("New Canvas Clicked!"),
    },
    {
      label: "Open",
      icon: <FileOpenOutlined />,
      onClick: () => console.log("Open File Clicked!"),
    },
    {
      label: "Save",
      icon: <SaveOutlined />,
      onClick: () => console.log("Save Clicked!"),
    },
    {
      label: "Save As",
      icon: <SaveAsOutlined />,
      onClick: () => console.log("Save As Clicked!"),
    },
    {
      label: "Import",
      icon: <UploadOutlined />,
      onClick: () => document.getElementById("upload-svg-input").click(),
    },
    {
      label: "Export",
      icon: <DownloadOutlined />,
      onClick: onDownloadSVG,
    },
    {
      label: "Print",
      icon: <Print />,
      onClick: () => console.log("Print Clicked!"),
    },
    {
      label: "Account Information",
      icon: <InfoOutlined />,
      onClick: () => console.log("Account Information Clicked!"),
    },
    {
      label: "Subscription",
      icon: <SubscriptionsOutlined />,
      onClick: () => navigate("/subscription"),
    },
    {
      label: "My Files",
      icon: <Inventory2Outlined />,
      onClick: () => console.log("My Files Clicked!"),
    },
    {
      label: "My Templates",
      icon: <FolderCopyOutlined />,
      onClick: () => console.log("My Templates Clicked!"),
    },
    {
      label: "Recent Files",
      icon: <InsertDriveFileOutlined />,
      onClick: () => console.log("Recent Files Clicked!"),
    },
    {
      label: "Version history",
      icon: <ManageSearchOutlined />,
      onClick: () => console.log("Version history Clicked!"),
    },
    {
      label: "Download Publication License",
      icon: <SimCardDownloadOutlined />,
      onClick: () => console.log("Download Publication License Clicked!"),
    },
    {
      label: "Duplicate",
      icon: <FileCopyOutlined />,
      onClick: () => console.log("Duplicate Clicked!"),
    },
    {
      label: "Share",
      icon: <Share />,
      onClick: () => console.log("Share Clicked!"),
    },
    {
      label: "Import (External)",
      icon: <UploadFileOutlined />,
      onClick: () => console.log("Import (External) Clicked!"),
    },
  ];
  const home = [
    {
      label: "Text",
      icon: <FaT />,
      onClick: () => console.log("Library Clicked!"),
    },
    {
      label: "Sort Icons",
      icon: <Sort />,
      onClick: () => console.log("Sort Clicked!"),
    },
    {
      label: "Background",
      icon: <Monitor />,
      onClick: () => console.log("BG Clicked!"),
    },
    {
      label: "Create Flow Chart",
      icon: <AccountTreeOutlined />,
      onClick: () => console.log("Flow Chart Clicked!"),
    },
    {
      label: "Community",
      icon: <Group />,
      onClick: () => console.log("Community Clicked!"),
    },
  ];
  const canvas = [
    {
      label: "Size",
      icon: <PhotoSizeSelectActualOutlined />,
      onClick: () => setSelectedFeature("Size"),
      children: [
        { label: "px X px" },
        { label: "cm X cm" },
        { label: "mm X mm" },
        { label: "inch X inch" },
        { label: "feet X feet" },
        {
          label: "A5",
          onClick: () => onPredifenedSizeChange("A5"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              A5
            </Typography>
          ),
        },

        {
          label: "A4",
          onClick: () => onPredifenedSizeChange("A4"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              A4
            </Typography>
          ),
        },
        {
          label: "A3",
          onClick: () => onPredifenedSizeChange("A3"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              A3
            </Typography>
          ),
        },
        {
          label: "A2",
          onClick: () => onPredifenedSizeChange("A2"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              A2
            </Typography>
          ),
        },
      ],
    },
    {
      label: "Background",
      icon: <ColorLensOutlined />,
      onClick: () => {
        setCurrentPopover("color-picker");
      },
    },
    {
      label: "Grids",
      icon: <Grid4x4 />,
      onClick: () => {
        setCurrentPopover("canvas-opacity");
      },
    },
    {
      label: "Poster",
      icon: <CropPortrait />,
      onClick: () => {
        setCurrentPopover("poster");
      },
      children: [
        {
          label: "3X4",
          onClick: () => onPredifenedSizeChange("3X4"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              3X4
            </Typography>
          ),
        },
        {
          label: "4X3",
          onClick: () => onPredifenedSizeChange("4X3"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              4X3
            </Typography>
          ),
        },
        {
          label: "54X40",
          onClick: () => onPredifenedSizeChange("54X40"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              54X40
            </Typography>
          ),
        },
        {
          label: "40X54",
          onClick: () => onPredifenedSizeChange("40X54"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              40X54
            </Typography>
          ),
        },
        {
          label: "48X36",
          onClick: () => onPredifenedSizeChange("48X36"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              48X36
            </Typography>
          ),
        },
        {
          label: "36X48",
          onClick: () => onPredifenedSizeChange("36X48"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              36X48
            </Typography>
          ),
        },
      ],
    },
    {
      label: "Banner",
      icon: <IoTabletLandscape />,
      onClick: () => {
        setCurrentPopover("poster");
      },
      children: [
        {
          label: "Full",
          onClick: () => onPredifenedSizeChange("fullBanner"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Full
            </Typography>
          ),
        },
        {
          label: "Half",
          onClick: () => onPredifenedSizeChange("halfBanner"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Half
            </Typography>
          ),
        },
        {
          label: "Skyscraper",
          onClick: () => onPredifenedSizeChange("skyscraper"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Skyscraper
            </Typography>
          ),
        },
        {
          label: "Wide",
          onClick: () => onPredifenedSizeChange("wideSkyscraper"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Wide
            </Typography>
          ),
        },
        {
          label: "Medium Banner",
          onClick: () => onPredifenedSizeChange("mediumBanner"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Medium Banner
            </Typography>
          ),
        },
        {
          label: "Leaderboard",
          onClick: () => onPredifenedSizeChange("leaderboard"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Leaderboard
            </Typography>
          ),
        },
        {
          label: "Half Page",
          onClick: () => onPredifenedSizeChange("halfPage"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Half Page
            </Typography>
          ),
        },
        {
          label: "Large Leaderboard",
          onClick: () => onPredifenedSizeChange("largeLeaderboard"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Wide
            </Typography>
          ),
        },
        {
          label: "Billboard",
          onClick: () => onPredifenedSizeChange("billboard"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Billboard
            </Typography>
          ),
        },
        {
          label: "Large Rectangle",
          onClick: () => onPredifenedSizeChange("largeRectangle"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Large Rectangle
            </Typography>
          ),
        },
        {
          label: "Small Square",
          onClick: () => onPredifenedSizeChange("smallSquare"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Small Square
            </Typography>
          ),
        },
        {
          label: "Square",
          onClick: () => onPredifenedSizeChange("square"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Square
            </Typography>
          ),
        },
        {
          label: "Vertical Banner",
          onClick: () => onPredifenedSizeChange("verticalBanner"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Vertical Banner
            </Typography>
          ),
        },
        {
          label: "Portrait",
          onClick: () => onPredifenedSizeChange("portrait"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Portrait
            </Typography>
          ),
        },
      ],
    },
    {
      label: "Social Media Posts",
      icon: <BsGoogle />,
      onClick: () => {
        setCurrentPopover("banner");
      },
      children: [
        {
          label: "LinkedIn",
          onClick: () => onPredifenedSizeChange("linkedIn"),
          icon: <BsLinkedin />,
        },
        {
          label: "Instagram",
          onClick: () => onPredifenedSizeChange("instagram"),
          icon: <BsInstagram />,
        },
        {
          label: "Facebook",
          onClick: () => onPredifenedSizeChange("facebook"),
          icon: <BsFacebook />,
        },
        {
          label: "X",
          onClick: () => onPredifenedSizeChange("wideSkyscraper"),
          icon: <BsTwitterX />,
        },
      ],
    },
    {
      label: "Abstract",
      icon: <IndeterminateCheckBoxTwoTone />,
      onClick: () => console.log("Community Clicked!"),
    },
    {
      label: "Pages",
      icon: <Pages />,
      onClick: () => console.log("Community Clicked!"),
    },
  ];
  const path = [
    {
      label: "Object to Path",
      icon: <Transform />,
      onClick: () => convertObjectToPath(),
    },
    {
      label: "Pattern Along Path",
      icon: <TimelineOutlined />,
      onClick: () => setSelectedFeature("Pattern Along Path"),
    },
    {
      label: "Mirror Rotations",
      icon: <CropRotateSharp />,
      // children: [
      //   { label: "Bar Chart", icon: <SignalCellular4BarOutlined /> },

      //   { label: "Pie Chart", icon: <PieChartOutlineOutlined /> },
      //   {
      //     label: "Histogram",
      //     icon: (
      //       <img
      //         height={25}
      //         width={25}
      //         src="/histogram-show-all-svgrepo-com.svg"
      //       />
      //     ),
      //   },
      // ],
      onClick: () => setSelectedFeature("Mirror Rotations"),
    },
    {
      label: "Bend Path",
      icon: <TbSTurnDown size={"21px"} />,
      onClick: () => handleBendAlongPath(),
    },
    {
      label: "Chalk And Sponge Filter",
      icon: <BiChalkboard size={"21px"} />,
      onClick: () =>
        chalkAndSponge(
          activeObj[0],
          mainCanvas,
          zoom,
          "/filters/chalk.svg",
          activeNodes,
          setActiveNodes
        ),
    },
    {
      label: "Ripple Filter",
      icon: <TbRipple size={"21px"} />,
      onClick: () =>
        chalkAndSponge(
          activeObj[0],
          mainCanvas,
          zoom,
          "/filters/ripple.svg",
          activeNodes,
          setActiveNodes
        ),
    },
  ];
  const color = [
    {
      label: "Fill Color",
      icon: <FormatColorFill />,
      onClick: () => document.getElementById("color-picker").click(),
    },
    {
      label: "Stroke Color",
      icon: <Colorize />,
      onClick: () => console.log("Color Clicked!"),
    },
    {
      label: "Opacity",
      icon: <GradientOutlined />,
      onClick: () => console.log("BG Clicked!"),
    },
  ];
  const text = [
    {
      label: "Text Box",
      icon: <PiTextbox />,
      onClick: () => {
        addText();
      },
    },
    {
      label: "Font Family",
      icon: <TextFields />,
      children: [
        {
          label: "Font Family",
          icon: (
            <>
              <TextField
                label="Enter Font Family"
                value={fontFamily}
                onChange={(event) => {
                  if (event.target.value === "upload") {
                    document.getElementById("font-upload-input").click();
                  } else {
                    handleFontChange(event);
                  }
                }}
                fullWidth
                select
                sx={{ width: "100%" }}
              >
                {fontFamilies.map((font) => (
                  <MenuItem key={font} value={font}>
                    {font}
                  </MenuItem>
                ))}
                <MenuItem value="upload">Upload Font</MenuItem>
              </TextField>
              <input
                type="file"
                accept=".ttf,.woff,.woff2,.otf"
                hidden
                id="font-upload-input"
                onChange={handleFontUpload}
              />
            </>
          ),
        },
      ],
      onClick: () => setSelectedFeature("Font Family"),
    },
    {
      label: "Font Size",
      icon: <TextIncreaseOutlined />,
      children: [
        {
          label: "Input",
          icon: (
            <>
              {isManualEntry ? (
                <TextField
                  label="Enter Font Size"
                  fullWidth
                  value={fontSize}
                  onChange={handleFontSize}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    min: 2, // Prevent values less than 2 in manual entry
                  }}
                  sx={{ marginBottom: "16px" }}
                  onBlur={() => setIsManualEntry(false)} // Exit manual entry on blur
                />
              ) : (
                <TextField
                  label="Select Font Size"
                  fullWidth
                  select
                  value={fontSize}
                  onChange={handleFontSize}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    min: 2, // Prevent values less than 2 in numeric input
                  }}
                  sx={{ marginBottom: "16px" }}
                >
                  {fontSizes.map((font) => (
                    <MenuItem key={font} value={font}>
                      {font}
                    </MenuItem>
                  ))}
                  <MenuItem onClick={handleManualEntryClick}>
                    Manual Entry
                  </MenuItem>
                </TextField>
              )}
            </>
          ),
        },
      ],
      onClick: () => setSelectedFeature("Font Size"),
    },
    {
      label: "Font Color",
      icon: <BorderColor />,
      children: [
        {
          id: 1,
          label: "Font color",
          icon: (
            <div
              style={{ display: "flex", alignItems: "center", padding: "4px" }}
            >
              <ColorPicker
                hideEyeDrop
                style={{
                  borderRadius: "10px",
                  padding: "1px",
                  width: "36px",
                  height: "36px",
                  marginTop: "-150px",
                }}
                value={fontColor}
                onChange={(color) => {
                  handleFontColor(color);
                }}
              />
            </div>
          ),
        },
      ],
      onClick: () => setSelectedFeature("Font Color"),

    },
    {
      label: "Text Alignment",
      icon: <FormatTextdirectionLToROutlined />,
      children: [
        {
          label: "Left",
          icon: <FormatAlignLeftOutlined />,
          onClick: handleTextAlignLeft,
        },
        {
          label: "Center",
          icon: <FormatAlignCenterOutlined />,
          onClick: handleTextAlignCenter,
        },
        {
          label: "Right",
          icon: <FormatAlignRightOutlined />,
          onClick: handleTextAlignRight,
        },
        {
          label: "Justified",
          icon: <FormatAlignJustifyOutlined />,
          onClick: handleTextAlignJustified,
        },
      ],
      onClick: () => setSelectedFeature("Text Alignment"),
    },

    {
      label: "Styles",
      icon: <StyleOutlined />,
      children: [
        {
          label: "Underline",
          icon: <FormatUnderlinedOutlined />,
          onClick: handleUnderline,
        },
        { label: "Bold", icon: <FormatBoldOutlined />, onClick: handleBold },
        {
          label: "Italics",
          icon: <FormatItalicOutlined />,
          onClick: handleItalics,
        },
        {
          label: "Merge & Center",
          icon: <MergeOutlined />,
          onClick: handleMergeCenter,
        },
        {
          label: "Superscript (30%)",
          icon: <SuperscriptOutlined />,
          onClick: handleSuperscript,
        },
        {
          label: "Subscript (30%)",
          icon: <SubscriptOutlined />,
          onClick: handleSubscript,
        },
      ],
      onClick: () => setSelectedFeature("Styles"),
    },
    {
      label: "Text Cases",
      icon: (
        <img
          height={25}
          width={25}
          src="/text/text-change-case-svgrepo-com.svg"
        />
      ),
      children: [
        {
          label: "Upper Case",
          icon: (
            <img
              height={25}
              width={25}
              src="/text/uppercase-or-lowercase-button-svgrepo-com.svg"
            />
          ),
          onClick: handleUpperCase,
        },
        {
          label: "Lower Case",
          icon: (
            <img
              height={25}
              width={25}
              src="/text/uppercase-or-lowercase-options-button-svgrepo-com.svg"
            />
          ),
          onClick: handleLowerCase,
        },
        {
          label: "Sentence Case",
          icon: (
            <img
              height={25}
              width={25}
              src="/text/paragraph-capital-svgrepo-com.svg"
            />
          ),
          onClick: handleSentenceCase,
        },
        {
          label: "Capitalize Each Word",
          icon: (
            <img
              height={25}
              width={25}
              src="/text/capitalize-ui-svgrepo-com.svg"
            />
          ),
          onClick: handleCapitalizeEachWord,
        },
      ],
      onClick: () => setSelectedFeature("Text Cases"),
    },
  ];

  const [selectedCheckbox, setSelectedCheckbox] = useState("alignToSlide");

  const handleCheckboxChange = (checkbox) => {
    setSelectedCheckbox(checkbox);
  };

  // Alignment Function
  const alignObjects = (alignment) => {
    const selectedObjects = mainCanvas.getActiveObjects();
    console.log("Selected Objects:", selectedObjects);

    if (!selectedObjects || selectedObjects.length === 0) return;

    const canvasWidth = mainCanvas.getWidth() / zoom;
    console.log("Alignment Canvas Width:", canvasWidth);
    const canvasHeight = mainCanvas.getHeight() / zoom;
    console.log("Alignment Canvas Height:", canvasHeight);

    // Handle alignment when multiple objects are selected, but not grouped
    if (
      selectedObjects.length > 1 &&
      selectedCheckbox !== "alignSelectedObjects"
    ) {
      // // Break the active selection
      mainCanvas.discardActiveObject();

      selectedObjects.forEach((object) => {
        const objBoundingBox = object.getBoundingRect(true); // Use true to get the absolute position
        // const zoom = mainCanvas.getZoom(); // Get the current zoom level
        switch (alignment) {
          case "Left":
            object.set({ left: objBoundingBox.width / 2 });
            break;

          case "Center":
            object.set({ left: canvasWidth / 2 });
            break;

          case "Right":
            object.set({ left: canvasWidth - objBoundingBox.width / 2 });
            break;

          case "Top":
            object.set({ top: objBoundingBox.height / 2 });
            break;

          case "Middle":
            object.set({
              left: canvasWidth / 2,
              top: canvasHeight / 2,
            });
            break;

          case "Bottom":
            object.set({
              top: canvasHeight - objBoundingBox.height / 2,
            });
            break;

          default:
            console.warn("Unknown alignment option:", alignment);
        }

        object.setCoords();
      });

      // Re-select the objects after alignment
      const activeSelection = new fabric.ActiveSelection(selectedObjects, {
        canvas: mainCanvas,
      });
      mainCanvas.setActiveObject(activeSelection);
      mainCanvas.renderAll();
      return; // Exit as the objects have been handled
    }

    if (selectedObjects.length === 1) {
      // Single object alignment
      const object = selectedObjects[0];
      const objBoundingBox = object.getBoundingRect(true);

      switch (alignment) {
        case "Left":
          object.set({ left: objBoundingBox.width / 2 / zoom });
          break;

        case "Center":
          object.set({ left: canvasWidth / 2 });
          break;

        case "Right":
          object.set({ left: canvasWidth - objBoundingBox.width / 2 / zoom });
          break;

        case "Top":
          object.set({ top: objBoundingBox.height / 2 / zoom });
          break;

        case "Middle":
          object.set({ left: canvasWidth / 2, top: canvasHeight / 2 });
          break;

        case "Bottom":
          object.set({
            top: canvasHeight - objBoundingBox.height / 2 / zoom,
          });
          break;

        default:
          console.warn("Unknown alignment option:", alignment);
      }
      object.setCoords();
    } else {
      // Multiple objects alignment
      const boundaries = selectedObjects.map((obj) => obj.getBoundingRect());

      // Compute group boundaries
      const groupLeft = Math.min(...boundaries.map((b) => b.left));
      const groupRight = Math.max(...boundaries.map((b) => b.left + b.width));
      const groupTop = Math.min(...boundaries.map((b) => b.top));
      const groupBottom = Math.max(...boundaries.map((b) => b.top + b.height));
      const groupCenterX = (groupLeft + groupRight) / 2;
      const groupCenterY = (groupTop + groupBottom) / 2;

      selectedObjects.forEach((object) => {
        const objBoundingBox = object.getBoundingRect();

        switch (alignment) {
          case "Left":
            object.set({
              left: groupLeft + (object.left - objBoundingBox.left),
            });
            break;

          case "Center":
            object.set({
              left:
                groupCenterX +
                (object.left - objBoundingBox.left) -
                objBoundingBox.width / 2,
            });
            break;

          case "Right":
            object.set({
              left:
                groupRight +
                (object.left - objBoundingBox.left) -
                objBoundingBox.width,
            });
            break;

          case "Top":
            object.set({ top: groupTop + (object.top - objBoundingBox.top) });
            break;

          case "Middle":
            object.set({
              left:
                groupCenterX +
                (object.left - objBoundingBox.left) -
                objBoundingBox.width / 2,
              top:
                groupCenterY +
                (object.top - objBoundingBox.top) -
                objBoundingBox.height / 2,
            });
            break;

          case "Bottom":
            object.set({
              top:
                groupBottom +
                (object.top - objBoundingBox.top) -
                objBoundingBox.height,
            });
            break;

          default:
            console.warn("Unknown alignment option:", alignment);
        }

        object.setCoords();
      });
    }

    mainCanvas.renderAll();
  };

  // Rotation Function
  const applyTransformation = (type) => {
    const activeObjects = mainCanvas.getActiveObjects(); // Get selected objects
    if (!activeObjects || activeObjects.length === 0) {
      console.error("No objects selected for transformation.");
      return;
    }

    activeObjects.forEach((object) => {
      switch (type) {
        case "Left 90":
          rotateObject(object, -90); // Rotate left by 90 degrees
          break;
        case "Right 90":
          rotateObject(object, 90); // Rotate right by 90 degrees
          break;
        case "Free Rotation":
          rotateObject(object, 45); // Default to 45° for demonstration
          break;
        case "Flip Horizontal":
          flipObject(object, "horizontal");
          break;
        case "Flip Vertical":
          flipObject(object, "vertical");
          break;
        default:
          console.warn("Unknown transformation type:", type);
          break;
      }
    });

    mainCanvas.requestRenderAll(); // Ensure the canvas updates after transformations
  };

  // Function to rotate a Fabric.js object
  const rotateObject = (object, angle) => {
    const currentAngle = object.angle || 0; // Get the current rotation angle
    object.rotate((currentAngle + angle) % 360); // Apply rotation, ensure angle stays within 0-360
    object.setCoords(); // Update object's coordinates
  };

  //  Function to flip a Fabric.js object
  const flipObject = (object, direction) => {
    if (direction === "horizontal") {
      object.set("flipX", !object.flipX); // Toggle the horizontal flip
      object.angle = Math.abs(object.angle - 360);
    } else if (direction === "vertical") {
      object.set("flipY", !object.flipY); // Toggle the vertical flip
      object.angle = Math.abs(object.angle - 360);
    } else {
      console.warn("Invalid flip direction:", direction);
    }

    object.setCoords(); // Update object's coordinates
  };

  function distributeHorizontally(mainCanvas) {
    if (!(mainCanvas instanceof fabric.Canvas)) {
      console.error(
        "Invalid mainCanvas instance passed to distributeHorizontallyAlignToSlide:",
        mainCanvas
      );
      return;
    }

    const activeObjects = mainCanvas.getActiveObjects();

    if (!activeObjects || activeObjects.length < 2) {
      alert("Please select at least two objects to distribute.");
      return;
    }

    // Break the active selection (optional to make adjustments easier)
    mainCanvas.discardActiveObject();

    // Sort objects by their `left` position
    const sortedObjects = activeObjects.sort(
      (a, b) => (a.left ?? 0) - (b.left ?? 0)
    );

    // Calculate the total width of all objects (considering scaling)
    const totalObjectsWidth = sortedObjects.reduce((acc, obj) => {
      return acc + (obj.width ?? 0) * (obj.scaleX ?? 1);
    }, 0);

    // Get the width of the canvas
    const canvasWidth = mainCanvas.getWidth() / zoom;
    console.log("Check Canvas Width: ", canvasWidth);

    // Calculate total available spacing for gaps
    const totalAvailableSpace = canvasWidth - totalObjectsWidth;

    if (totalAvailableSpace < 0) {
      alert("Objects exceed the canvas width. Resize or rearrange objects.");
      return;
    }

    // Calculate the gap between each object
    const gap = totalAvailableSpace / (sortedObjects.length + 1); // +1 for gaps on both sides

    // Start at the initial gap (padding from the left edge of the slide)
    let currentX = gap;

    // Distribute objects across the canvas
    sortedObjects.forEach((obj) => {
      const objWidth = (obj.width ?? 0) * (obj.scaleX ?? 1);

      // Position object at the calculated position
      obj.left = currentX + objWidth / 2;
      currentX += objWidth + gap; // Move to the next position

      obj.setCoords(); // Update Fabric.js object boundaries
    });

    // Re-select the objects after alignment
    const activeSelection = new fabric.ActiveSelection(sortedObjects, {
      canvas: mainCanvas,
    });
    mainCanvas.setActiveObject(activeSelection);
    mainCanvas.renderAll();
  }

  function distributeSelectedObjects(mainCanvas) {
    if (!(mainCanvas instanceof fabric.Canvas)) {
      console.error(
        "Invalid mainCanvas instance passed to distributeSelectedObjects:",
        mainCanvas
      );
      return;
    }

    const activeObjects = mainCanvas.getActiveObjects();

    if (!activeObjects || activeObjects.length < 3) {
      alert("Please select at least three objects to distribute.");
      return;
    }

    // Sort objects by their `left` position (horizontal alignment)
    const sortedObjects = activeObjects.sort(
      (a, b) => (a.left ?? 0) - (b.left ?? 0)
    );

    // Get the first and last objects
    const firstObject = sortedObjects[0];
    const lastObject = sortedObjects[sortedObjects.length - 1];

    // Calculate the bounding box edges
    const firstEdge =
      (firstObject.left ?? 0) -
      ((firstObject.width ?? 0) * (firstObject.scaleX ?? 1)) / 2;
    const lastEdge =
      (lastObject.left ?? 0) +
      ((lastObject.width ?? 0) * (lastObject.scaleX ?? 1)) / 2;

    // Total available space between the bounding boxes of the first and last objects
    const totalSpace = lastEdge - firstEdge;

    // Total width of all bounding boxes
    const totalObjectsWidth = sortedObjects.reduce(
      (acc, obj) => acc + (obj.width ?? 0) * (obj.scaleX ?? 1),
      0
    );

    // Total number of gaps (space between bounding boxes)
    const totalGaps = sortedObjects.length - 1;

    // Calculate gap size
    const gapSize = (totalSpace - totalObjectsWidth) / totalGaps;

    if (gapSize < 0) {
      alert(
        "Not enough space to distribute the objects. Please resize or rearrange."
      );
      return;
    }

    // Distribute objects with equal bounding box spacing
    let currentX = firstEdge; // Start at the left edge of the first object's bounding box

    sortedObjects.forEach((obj, index) => {
      const objWidth = (obj.width ?? 0) * (obj.scaleX ?? 1);

      if (index === 0) {
        // Position the first object
        obj.left = firstEdge + objWidth / 2;
      } else {
        // Move to the next position based on the previous bounding box and gap
        currentX +=
          gapSize +
          (sortedObjects[index - 1].width ?? 0) *
          (sortedObjects[index - 1].scaleX ?? 1);
        obj.left = currentX + objWidth / 2;
      }

      // Update the object's coordinates
      obj.setCoords();
    });

    // Render the canvas to apply changes
    mainCanvas.renderAll();
  }

  function distributeVertically(mainCanvas) {
    if (!(mainCanvas instanceof fabric.Canvas)) {
      console.error(
        "Invalid mainCanvas instance passed to distributeVertically:",
        mainCanvas
      );
      return;
    }

    const activeObjects = mainCanvas.getActiveObjects();

    // Break the active selection
    mainCanvas.discardActiveObject();

    if (!activeObjects || activeObjects.length < 2) {
      alert("Please select at least two objects to distribute.");
      return;
    }

    // Sort objects by their top position
    const sortedObjects = activeObjects.sort(
      (a, b) => (a.top ?? 0) - (b.top ?? 0)
    );

    // Calculate total height of selected objects (considering scaling)
    const totalObjectsHeight = sortedObjects.reduce((acc, obj) => {
      return acc + (obj.height ?? 0) * (obj.scaleY ?? 1);
    }, 0);

    const canvasHeight = mainCanvas.getHeight() / zoom;
    console.log("Check Canvas Height: " + canvasHeight);

    // Calculate total spacing and gaps
    const totalSpacing = canvasHeight - totalObjectsHeight;
    if (totalSpacing < 0) {
      alert("Objects exceed the canvas height. Resize or rearrange objects.");
      return;
    }
    const gap = totalSpacing / (sortedObjects.length + 1); // Add one extra gap for padding on both sides

    // Calculate starting position to ensure centering
    let currentY = gap;

    // Distribute objects vertically
    sortedObjects.forEach((obj) => {
      const objHeight = (obj.height ?? 0) * (obj.scaleY ?? 1);

      // Position object at calculated Y
      obj.top = currentY + objHeight / 2;
      currentY += objHeight + gap;

      obj.setCoords(); // Update Fabric.js object boundaries
    });

    // Re-select the objects after alignment
    const activeSelection = new fabric.ActiveSelection(sortedObjects, {
      canvas: mainCanvas,
    });
    mainCanvas.setActiveObject(activeSelection);
    mainCanvas.renderAll();
  }

  function distributeSelectedObjectsVertically(mainCanvas) {
    if (!(mainCanvas instanceof fabric.Canvas)) {
      console.error(
        "Invalid mainCanvas instance passed to distributeSelectedObjectsVertically:",
        mainCanvas
      );
      return;
    }

    const activeObjects = mainCanvas.getActiveObjects();

    if (!activeObjects || activeObjects.length < 3) {
      alert("Please select at least three objects to distribute.");
      return;
    }

    // Sort objects by their `top` position (vertical alignment)
    const sortedObjects = activeObjects.sort(
      (a, b) => (a.top ?? 0) - (b.top ?? 0)
    );

    // Get the first and last objects
    const firstObject = sortedObjects[0];
    const lastObject = sortedObjects[sortedObjects.length - 1];

    // Calculate the bounding box edges
    const firstEdge =
      (firstObject.top ?? 0) -
      ((firstObject.height ?? 0) * (firstObject.scaleY ?? 1)) / 2;
    const lastEdge =
      (lastObject.top ?? 0) +
      ((lastObject.height ?? 0) * (lastObject.scaleY ?? 1)) / 2;

    // Total available space between the bounding boxes of the first and last objects
    const totalSpace = lastEdge - firstEdge;

    // Total height of all bounding boxes
    const totalObjectsHeight = sortedObjects.reduce(
      (acc, obj) => acc + (obj.height ?? 0) * (obj.scaleY ?? 1),
      0
    );

    // Total number of gaps (space between bounding boxes)
    const totalGaps = sortedObjects.length - 1;

    // Calculate gap size
    const gapSize = (totalSpace - totalObjectsHeight) / totalGaps;

    if (gapSize < 0) {
      alert(
        "Not enough space to distribute the objects. Please resize or rearrange."
      );
      return;
    }

    // Distribute objects with equal bounding box spacing
    let currentY = firstEdge; // Start at the top edge of the first object's bounding box

    sortedObjects.forEach((obj, index) => {
      const objHeight = (obj.height ?? 0) * (obj.scaleY ?? 1);

      if (index === 0) {
        // Position the first object
        obj.top = firstEdge + objHeight / 2;
      } else {
        // Move to the next position based on the previous bounding box and gap
        currentY +=
          gapSize +
          (sortedObjects[index - 1].height ?? 0) *
          (sortedObjects[index - 1].scaleY ?? 1);
        obj.top = currentY + objHeight / 2;
      }

      // Update the object's coordinates
      obj.setCoords();
    });

    // Render the canvas to apply changes
    mainCanvas.renderAll();
  }

  const bringForward = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.bringForward();
      mainCanvas.renderAll();
    } else {
      console.log("No object selected");
    }
  };

  const sendBackward = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.sendBackwards();
      mainCanvas.renderAll();
    } else {
      console.log("No object selected");
    }
  };

  const bringToFront = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.bringToFront();
      mainCanvas.renderAll();
    } else {
      console.log("No object selected");
    }
  };

  const sendToBack = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.sendToBack();
      mainCanvas.renderAll();
    } else {
      console.log("No object selected");
    }
  };

  const download = [
    "png",
    "tiff",
    "bitmap",
    "jpeg",
    "svg",
    "pdf",
    "dpi defined",
  ];
  const alignment = [
    {
      label: "Bring Forward",
      icon: <ArrowUpward />,
      onClick: bringForward,
    },
    {
      label: "Send Backward",
      icon: <ArrowDownward />,
      onClick: sendBackward,
    },
    {
      label: "Bring To Front",
      icon: <VerticalAlignTop />,
      onClick: bringToFront,
    },
    {
      label: "Send To Back",
      icon: <VerticalAlignBottom />,
      onClick: sendToBack,
    },
    {
      label: "Lock",
      icon: <Lock />,
      onClick: () => console.log("Alignment"),
    },
    {
      label: "Alignment",
      icon: <FormatTextdirectionLToROutlined />,
      children: [
        {
          label: "Left",
          icon: <FormatAlignLeftOutlined />,
          onClick: () => alignObjects("Left"),
        },
        {
          label: "Center",
          icon: <FormatAlignCenterOutlined />,
          onClick: () => alignObjects("Center"),
        },
        {
          label: "Right",
          icon: <FormatAlignRightOutlined />,
          onClick: () => alignObjects("Right"),
        },
        {
          label: "Middle",
          icon: <FormatAlignJustifyOutlined />,
          onClick: () => alignObjects("Middle"),
        },
        {
          label: "Bottom",
          icon: <BorderBottomOutlined />,
          onClick: () => alignObjects("Bottom"),
        },
        {
          label: "Top",
          icon: <BorderTopOutlined />,
          onClick: () => alignObjects("Top"),
        },
        {
          label: "Align to Slide",
          icon: (
            <Checkbox
              checked={selectedCheckbox === "alignToSlide"}
              onChange={() => handleCheckboxChange("alignToSlide")}
            />
          ),
          onClick: () => console.log("Align to Slide mode selected"),
        },
        {
          label: "Align Selected Objects",
          icon: (
            <Checkbox
              checked={selectedCheckbox === "alignSelectedObjects"}
              onChange={() => handleCheckboxChange("alignSelectedObjects")}
            />
          ),
          onClick: () => console.log("Align Selected Objects mode selected"),
        },
      ],
      onClick: () => setSelectedFeature("Alignment"),
    },
    {
      label: "Rotation",
      icon: <CropRotateOutlined />,
      children: [
        {
          label: "Left 90",
          icon: <Rotate90DegreesCcwOutlined />,
          onClick: () => applyTransformation("Left 90"),
        },
        {
          label: "Right 90",
          icon: <Rotate90DegreesCwOutlined />,
          onClick: () => applyTransformation("Right 90"),
        },
        {
          label: "Free Rotation",
          icon: <CropRotateOutlined />,
          onClick: () => applyTransformation("Free Rotation"),
        },
        // {
        //   label: "Flip Horizontal",
        //   icon: <FlipOutlined />,
        //   onClick: () => applyTransformation("Flip Horizontal"),
        // },
        // {
        //   label: "Flip Vertical",
        //   icon: <FlipOutlined />,
        //   onClick: () => applyTransformation("Flip Vertical"),
        // },
        // {
        //   label: "Flip Horizontal",
        //   icon: <Flip sx={{ transform: 'rotate(90deg)' }} />, // Horizontal flip icon
        //   onClick: () => applyTransformation("Flip Horizontal"),
        // },
        // {
        //   label: "Flip Vertical",
        //   icon: <Flip sx={{ transform: 'rotate(0deg)' }} />, // Vertical flip icon
        //   onClick: () => applyTransformation("Flip Vertical"),
        // },
        {
          label: "Flip Horizontal",
          icon: <FaRegArrowAltCircleLeft />, // PowerPoint-like horizontal flip icon
          onClick: () => applyTransformation("Flip Horizontal"),
        },
        {
          label: "Flip Vertical",
          icon: <FaRegArrowAltCircleUp />, // PowerPoint-like vertical flip icon
          onClick: () => applyTransformation("Flip Vertical"),
        },
      ],
      onClick: () => setSelectedFeature("Rotation"),
    },
    {
      label: "Distribute",
      icon: <ScatterPlotOutlined />,
      children: [
        {
          label: "Distribute Horizontally",
          icon: <HorizontalRuleOutlined />,
          onClick: () => {
            if (selectedCheckbox === "alignToSlide") {
              distributeHorizontally(mainCanvas); // Call the align to slide function
            } else if (selectedCheckbox === "alignSelectedObjects") {
              distributeSelectedObjects(mainCanvas); // Call the align selected objects function
            } else {
              console.log("No valid alignment mode selected.");
            }
          },
        },
        {
          label: "Distribute Vertically",
          icon: <VerticalShadesOutlined />,
          onClick: () => {
            if (selectedCheckbox === "alignToSlide") {
              distributeVertically(mainCanvas); // Call the align to slide function
            } else if (selectedCheckbox === "alignSelectedObjects") {
              distributeSelectedObjectsVertically(mainCanvas); // Call the align selected objects function
            } else {
              console.log("No valid alignment mode selected.");
            }
          },
        },
        // { label: "Drag & Drop", icon: <PanToolOutlined /> },
      ],
      onClick: () => setSelectedFeature("Distribute"),
    },
  ];
  const clipboard = [
    {
      label: "Cut",
      icon: <ContentCutOutlined />,
      onClick: () => handleCut(),
    },
    {
      label: "Copy",
      icon: <ContentCopyOutlined />,
      onClick: () => handleCopyShape(),
    },
    {
      label: "Paste",
      icon: <ContentPasteGoOutlined />,
      onClick: () => handlePasteShape(),
    },
    {
      label: "Paste Special",
      icon: <ContentPasteSharp />,
      children: [
        {
          label: "Paste as PNG",
          icon: <span style={{ fontSize: "14px" }}>PNG</span>,
          onClick: () => handlePasteAsPng(),
        },
        {
          label: "Paste as JPEG",
          icon: <span style={{ fontSize: "14px" }}>JPEG</span>,
          onClick: () => handlePasteAsJpeg(),
        },
        {
          label: "Paste as BITMAP",
          icon: <span style={{ fontSize: "14px" }}>BITMAP</span>,
          onClick: () => handlePasteAsBitmap(),
        },
        {
          label: "Paste as SVG",
          icon: <span style={{ fontSize: "14px" }}>SVG</span>,
          onClick: () => handlePasteAsSvg(),
        },
      ],
      onClick: () => setSelectedFeature("Paste Special"),
    },
    {
      label: "Undo",
      icon: <UndoOutlined />,
      onClick: () => undo(),
    },
    {
      label: "Redo",
      icon: <RedoOutlined />,
      onClick: () => redo(),
    },
    {
      label: "Group",
      icon: <PlaylistAddCheckCircleOutlined />,
      onClick: () => groupSelectedObjects(),
    },
    {
      label: "Ungroup",
      icon: <PlaylistRemoveOutlined />,
      onClick: () => ungroupSelectedObjects(),
    },
    {
      label: "Select",
      icon: <CropSquareOutlined />,
      onClick: () => selectMostRecentObject(),
    },
    {
      label: "Select All",
      icon: <SelectAllOutlined />,
      onClick: () => selectAllObjects(),
    },
    {
      label: "Duplicate",
      icon: <ControlPointDuplicateOutlined />,
      onClick: () => handleDuplicate(),
    },
    {
      label: "Clone",
      icon: <CopyAll />,
      onClick: () => cloneObjectWithSameId(),
    },
  ];

  const view = [
    {
      label: "Grid",
      icon: <Grid4x4Outlined />,
      onClick: () => console.log("Size Clicked!"),
    },
    {
      label: "Guides",
      icon: <BookOnlineOutlined />,
      onClick: () => console.log("Color Clicked!"),
    },
    {
      label: "Ruler",
      icon: <StraightenOutlined />,
      onClick: () => {
        rulerToggleClick();
      },
    },
    {
      label: "Zoom In",
      icon: <ZoomIn />,
      // onClick: () => console.log("Flow Chart Zoom In +++"),
      onClick: () => {
        handleZoomIn();
        console.log("Flow Chart Zoom In +++");
      },
    },
    {
      label: "Zoom Out",
      icon: <ZoomOut />,
      onClick: () => {
        handleZoomOut();
        console.log("Flow Chart Zoom Out---");
      },
      // onClick: () => console.log("Flow Chart Zoom Out---"),
    },
    {
      label: "Zoom To Page",
      icon: <ZoomOutMap />,
      onClick: () => {
        handleZoomToPage();
      },
      // onClick: () => console.log("Zoom To Page"),
    },
    {
      label: "Zoom To Selection",
      icon: <ZoomOutMapTwoTone />,
      onClick: () => {
        // handleFullPage();
        zoomSelection();
      },
      // onClick: () => console.log("Zoom To Page"),
    },
    {
      label: "Clone",
      icon: <CopyAll />,
      onClick: () => {
        cloneObjectWithSameId();
      },
    },
    {
      label: "Copy",
      icon: <ContentCopyOutlined />,
      onClick: () => {
        handleCopyShape();
      },
    },
    {
      label: "Cut",
      icon: <ContentCutOutlined />,
      onClick: () => {
        handleCut();
      },
    },
    {
      label: "Paste",
      icon: <ContentPasteGoOutlined />,
      onClick: () => {
        handlePasteShape();
      },
    },
    {
      label: "Undo",
      icon: <UndoOutlined />,
      onClick: () => {
        console.log("Undo button clicked");
        undo();
      },
    },
    {
      label: "Redo",
      icon: <RedoOutlined />,
      onClick: () => {
        console.log("Redo button clicked");
        redo();
      },
    },
  ];
  const combine = [
    {
      label: "Union",
      icon: <JoinFull />,
      onClick: combineSelectedPaths,
    },
    {
      label: "Intersection",
      icon: <Close />,
      onClick: combineSelectedObjectsIntersection,
    },

    {
      label: "Exclusion",
      icon: <RemoveCircleOutline />,
      onClick: combineSelectedPathsExclusion,
    },
    {
      label: "Fragment",
      icon: <CallSplitOutlined />,
      onClick: combineSelectedObjectsFragmentation,
    },
    {
      label: "Subtraction",
      icon: <ContentCutOutlined />,
      onClick: combineSelectedObjectsDifference,
    },
  ];

  const renderPopoverContent = () => {
    switch (currentPopover) {
      case "spray":
        return (
          <CustomSprayPopoverContent
            mainCanvas={mainCanvas}
            spraySelectRef={spraySelectRef}
            handlePopoverClose={handlePopoverClick}
            handleSpraying={handleSpraying}
            activeObj={activeObj}
            areaOfSpray={areaOfSpray}
            setAreaOfSpray={setAreaOfSpray}
            numberOfCopies={numberOfCopies}
            setNumberOfCopies={setNumberOfCopies}
          />
        );
      case "shapes":
        return (
          <CustomShapesPopoverContent
            shapes={shapes}
            onAddChart={onAddChart}
            currentPopover={currentPopover}
            charts={charts}
            selectedFeature={selectedFeature}
            setSelectedFeature={setSelectedFeature}
            handlePopoverClose={handlePopoverClose}
          />
        );
      case "path":
        return (
          <CustomPathPopoverContent
            selectedFeature={selectedFeature}
            handleBackButtonClick={handleBackButtonClick}
            handlePopoverClose={handlePopoverClose}
            path={path}
            mainCanvas={mainCanvas}
            values={values}
            setValues={setValues}
            handlePathRotateCopies={handlePathRotateCopies}
            rotationSettings={rotationSettings}
            updateRotationSettings={updateRotationSettings}
            applyColorToSelectedCopy={applyColorToSelectedCopy}
            setSelectedColorForRotationCopy={setSelectedColorForRotationCopy}
            selectedColorForRotationCopy={selectedColorForRotationCopy}
            selectedStrokeForRotationCopy={selectedStrokeForRotationCopy}
            setSelectedStrokeForRotationCopy={setSelectedStrokeForRotationCopy}
            color={color}
            handleSpraying={handleSpraying}
            opacityObj={opacityObj}
            changeOpacityOfObj={changeOpacityOfObj}
          />
        );
      case "color":
        return (
          <CustomColorPopoverContent
            currentPopover={currentPopover}
            fillColor={fillColor}
            onSetColor={onSetColor}
            setSelectedFeature={setSelectedFeature}
            selectedFeature={selectedFeature}
            selectedStrokeColor={selectedStrokeColor}
            setSelectedStrokeColor={setSelectedStrokeColor}
            handleBackButtonClick={handleBackButtonClick}
            handlePopoverClose={handlePopoverClick}
            color={color}
            handleSpraying={handleSpraying}
            opacityObj={opacityObj}
            changeOpacityOfObj={changeOpacityOfObj}
          />
        );
      case "view":
        return (
          <CustomViewPopoverContent
            selectedFeature={selectedFeature}
            handleBackButtonClick={handleBackButtonClick}
            handlePopoverClose={handlePopoverClick}
            view={view}
            mainCanvas={mainCanvas}
            onAddCanvas={onAddCanvas}
            activeCanvasIndex={activeCanvasIndex}
            switchCanvas={switchCanvas}
            canvasArray={canvasArray}
            horizonalGuidesRef={horizonalGuidesRef}
            verticalGuidesRef={verticalGuidesRef}
            showGuides={showGuides}
            setShowGuides={setShowGuides}
            showRuler={showRuler}
            setShowRuler={setShowRuler}
            containerDim={containerDim}
            setContainerDim={setContainerDim}
          />
        );
      case "combinations":
        return (
          <CustomCombinePopoverContent
            selectedFeature={selectedFeature}
            handleBackButtonClick={handleBackButtonClick}
            handlePopoverClose={handlePopoverClick}
            combine={combine}
            originalVisibility={originalVisibility}
            handleChangeView={handleChangeView}
          />
        );
      case "text":
        return (
          <CustomTextPopoverContent
            selectedFeature={selectedFeature}
            handleBackButtonClick={handleBackButtonClick}
            handlePopoverClose={handlePopoverClick}
            text={text}
          />
        );
      // case "RotationCopies":
      //   return (
      //     <CustomRotateCopiesPopoverContent
      //     handlePathRotateCopies={handlePathRotateCopies}
      //     rotationSettings={rotationSettings}
      //     updateRotationSettings={updateRotationSettings}
      //     handlePopoverClose={handlePopoverClose}

      //     applyColorToSelectedCopy={applyColorToSelectedCopy}
      //     setSelectedColorForRotationCopy={setSelectedColorForRotationCopy}
      //     selectedColorForRotationCopy={selectedColorForRotationCopy}
      //     color={color}
      //     handleSpraying={handleSpraying}
      //     opacityObj={opacityObj}
      //     changeOpacityOfObj={changeOpacityOfObj}
      //   />
      //   );
      case "alignment":
        return (
          <CustomAlignmentPopoverContent
            selectedFeature={selectedFeature}
            handleBackButtonClick={handleBackButtonClick}
            handlePopoverClose={handlePopoverClick}
            alignment={alignment}
          />
        );
      case "clipboard":
        return (
          <CustomClipboardPopoverContent
            selectedFeature={selectedFeature}
            handleBackButtonClick={handleBackButtonClick}
            handlePopoverClose={handlePopoverClick}
            clipboard={clipboard}
          />
        );
      case "file":
        return (
          <CustomFilePopoverContent
            files={files}
            handlePopoverClose={handlePopoverClick}
          />
        );
      case "home":
        return (
          <CustomHomePopoverContent
            home={home}
            handlePopoverClose={handlePopoverClose}
          />
        );
      case "canvas":
        return (
          <CustomCanvasPopoverContent
            selectedFeature={selectedFeature}
            mainCanvas={mainCanvas}
            setSelectedFeature={setSelectedFeature}
            currentPopover={currentPopover}
            handlePopoverClose={handlePopoverClose}
            canvas={canvas}
            size={size}
            changeOpacity={changeOpacity}
            tempSize={tempSize}
            setTempSize={setTempSize}
            handleUnitChange={handleUnitChange}
            onSizeChange={onSizeChange}
            onPredifinedSizeChange={onPredifenedSizeChange}
            opacity={opacity}
            setOpacity={setOpacity}
            changeBackground={changeBackground}
            canvasColor={canvasColor}
            onAddCanvas={onAddCanvas}
            canvasImage={canvasImage}
            switchCanvas={switchCanvas}
          />
        );

      default:
        return null;
    }
  };

  const [canvasColor, setCanvasColor] = useState();
  const changeBackground = (e) => {
    if (mainCanvas) {
      setCanvasColor(e);
      if (!e.includes("gradient")) {
        // Solid color
        mainCanvas.setBackgroundImage(null);
        mainCanvas.backgroundColor = e;
        mainCanvas.renderAll();
        console.log("bgColor", mainCanvas, e);
      } else {
        // Gradient
        const linearGradientMatch = e.match(
          /linear-gradient\(([^,]+),\s*(.+)\)/
        );
        const radialGradientMatch = e.match(
          /radial-gradient\(([^,]+),\s*(.+)\)/
        );

        if (linearGradientMatch || radialGradientMatch) {
          let gradient;
          if (linearGradientMatch) {
            // Handle linear gradient
            const angle = linearGradientMatch[1].trim();
            const colorStopsString = linearGradientMatch[2].trim();
            const colorStopsArray = parseColorStops(colorStopsString);
            const coords = getGradientCoords(
              angle,
              mainCanvas.width,
              mainCanvas.height
            );

            gradient = new fabric.Gradient({
              type: "linear",
              gradientUnits: "pixels",
              coords: coords,
              colorStops: colorStopsArray,
            });
          } else if (radialGradientMatch) {
            // Handle radial gradient
            const colorStopsString = radialGradientMatch[2].trim();
            const colorStopsArray = parseColorStops(colorStopsString);

            gradient = new fabric.Gradient({
              type: "radial",
              gradientUnits: "pixels",
              coords: {
                x1: mainCanvas.width / 2,
                y1: mainCanvas.height / 2,
                r1: 0,
                x2: mainCanvas.width / 2,
                y2: mainCanvas.height / 2,
                r2: Math.max(mainCanvas.width, mainCanvas.height) / 2,
              },
              colorStops: colorStopsArray,
            });
          }

          mainCanvas.setBackgroundImage(null);

          mainCanvas.setBackgroundImage(null);
          mainCanvas.backgroundColor = gradient;
          mainCanvas.renderAll();
          console.log("bgColor: Applied gradient:", gradient);
        }
      }
    }
  };

  // Helper function to parse color stops
  const parseColorStops = (colorStopsString) => {
    return colorStopsString
      .split(/,(?![^(]*\))/)
      .map((stop) => {
        const parts = stop
          .trim()
          .match(/(rgba?\([^\)]+\)|#[0-9a-fA-F]+|rgb\([^\)]+\))\s*([\d.]+)%?/i);
        if (parts) {
          return {
            offset: parseFloat(parts[2]) / 100,
            color: parts[1].toLowerCase(), // Ensure color is in lowercase for compatibility
          };
        }
        return {
          offset: null,
          color: stop.trim(),
        };
      })
      .map((stop, index, array) => {
        if (isNaN(stop.offset)) {
          stop.offset = index / (array.length - 1);
        }
        return stop;
      });
  };

  // Function to calculate linear gradient coordinates based on angle
  const getGradientCoords = (angle, width, height) => {
    // Convert angle to radians
    const angleRad = (parseFloat(angle) * Math.PI) / 180;

    let x1, y1, x2, y2;

    x1 = width / 2 + (width / 2) * Math.cos(angleRad + Math.PI);
    y1 = height / 2 + (height / 2) * Math.sin(angleRad + Math.PI);
    x2 = width / 2 + (width / 2) * Math.cos(angleRad);
    y2 = height / 2 + (height / 2) * Math.sin(angleRad);

    return { x1, y1, x2, y2 };
  };

  const handleNodeSelect = () => {
    if (!activeObj) {
      alert("Please select a path object to edit its Node.");
      return;
    }
    if (pathSelect) {
      setPathSelect(false);
    } else {
      setPathSelect(true);
    }
    console.log("handleNodeSelect: ", pathSelect);
  };

  return (
    <div>
      <CustomPopover
        isOpen={isPopoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        currentPopover={currentPopover}
      >
        {renderPopoverContent()}
      </CustomPopover>

      <Paper sx={{ background: "#36454F" }}>
        <StyledAppBar
          ref={appBarRef1}
          elevation={0}
          position="fixed"
          open={open}
        >
          <Toolbar variant="dense" sx={{ justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <Typography variant="h6" align="right" noWrap>
                BioGraphix
              </Typography>

              <Tooltip arrow title="Download">
                <StyledListButton onClick={handleDownloadClick}>
                  <DownloadOutlined />
                </StyledListButton>
              </Tooltip>

              <DownloadMenu
                anchorEl={anchorEl}
                handleClose={handleCloseMenu}
                mainCanvas={mainCanvas}
                formats={downloadFormats}
                dpi={300}
              />
            </div>
            <div>
              <Tooltip arrow title="Upload">
                <StyledListButton
                  sx={{ p: 0, left: 10 }}
                  onClick={() =>
                    handleButtonClick(
                      document.getElementById("upload-svg-input").click(),
                      "upload"
                    )
                  }
                >
                  <UploadOutlined />
                </StyledListButton>
              </Tooltip>
            </div>
          </Toolbar>
        </StyledAppBar>
        <AppBar
          ref={appBarRef2}
          sx={{
            mt: 6,
            height: "30px",
            // color:"black",
            background: "#36454F",
          }}
          position="fixed"
          open={open}
        >
          <Toolbar
            sx={{
              display: "flex",
              color: "#D3D3D3",
              justifyContent: "space-between",
              "&.MuiToolbar-root": {
                p: 0,
                pb: 3,
              },
            }}
            variant="dense"
          >
            <Box display={"flex"} sx={{ pl: 7 }}>
              {/* <Tooltip arrow title="Color">
                <StyledListButton
                  onClick={(event) => handlePopoverClick(event, "color")}
                >
                  <FormatColorFill sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip> */}
              <Tooltip arrow title="Spray Selected Object">
                <StyledListButton
                  onClick={(event) => handlePopoverClick(event, "spray")}
                >
                  <BiSprayCan sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Alignment">
                <StyledListButton
                  onClick={(event) => handlePopoverClick(event, "alignment")}
                >
                  <AlignHorizontalCenter sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Text">
                <StyledListButton
                  onClick={(event) => handlePopoverClick(event, "text")}
                >
                  <Title sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Free Draw">
                <StyledListButton
                  active={activeButton === "free draw"}
                  onClick={() => {
                    if (activeButton == null) {
                      setActiveButton("free draw");
                      onFreeDrawing();
                    } else if (activeButton === "free draw") {
                      setActiveButton(null);
                      onDisableFreeDrawing()
                    }
                  }}
                >
                  <CreateOutlined sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Select">
                <StyledListButton
                  active={activeButton === "select"}
                  onClick={() => {
                    mainCanvas.selectable = true;
                    setActiveButton("select");
                    mainCanvas.isDrawingMode = false;
                  }}
                >
                  <Mouse sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Node Select">
                <StyledListButton
                  active={activeButton === "nodeSelect" && pathSelect}
                  onClick={() => {
                    mainCanvas.selectable = true;
                    setActiveButton("nodeSelect");
                    handleNodeSelect();
                  }}
                >
                  <FaCircleNodes sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              {pathSelect && (
                <>
                  <Tooltip arrow title="Add Node(s)">
                    <StyledListButton
                      onClick={() => {
                        addNodeAtMidpoint();
                      }}
                    >
                      <LuPlusCircle style={{ fontSize: 20 }} />
                    </StyledListButton>
                  </Tooltip>
                  <Tooltip arrow title="Remove Node">
                    <StyledListButton
                      onClick={() => {
                        removeNodes();
                      }}
                    >
                      <LuMinusCircle style={{ fontSize: 20 }} />
                    </StyledListButton>
                  </Tooltip>
                  <Tooltip arrow title="Connect Handles">
                    <Checkbox
                      checked={handlesConnected}
                      onChange={() => setHandlesConnected(!handlesConnected)}
                      color="primary"
                      inputProps={{ "aria-label": "Connect Handles Checkbox" }}
                    />
                  </Tooltip>

                  <Tooltip arrow title="Circularize Node">
                    <StyledListButton
                      active={activeButton === "nodeCircular" && pathSelect}
                      onClick={() => {
                        mainCanvas.selectable = true;
                        setActiveButton("nodeCircular");
                        circularizeNode();
                      }}
                    >
                      <AiOutlineNodeIndex style={{ fontSize: 20 }} />
                    </StyledListButton>
                  </Tooltip>
                  <Tooltip arrow title="Linearize Node">
                    <StyledListButton
                      active={activeButton === "nodeLinearize" && pathSelect}
                      onClick={() => {
                        mainCanvas.selectable = true;
                        setActiveButton("nodeLinearize");
                        linearizeNode();
                      }}
                    >
                      <MdLinearScale style={{ fontSize: 20 }} />
                    </StyledListButton>
                  </Tooltip>
                </>
              )}
            </Box>
            {/* <Box sx={{display:'flex', justifyContent:'center'}}>
                <Tooltip title='opacity'>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <Typography fontSize={14}>O:</Typography>
                    <TextField 
                      sx={{backgroundColor:'#D3D3D3',borderRadius:5,maxWidth:'50px'}} 
                      inputProps={{
                        style:{
                          color:'white',                           
                          padding:0,borderRadius:5,fontSize:14,textAlign:'center'
                        }
                      }} 
                      disabled value={`${opacity*100}%`}
                      
                    />
                  </div>
                </Tooltip>
              </Box> */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Tooltip arrow title="Clipboard">
                <StyledListButton
                  onClick={(event) => handlePopoverClick(event, "clipboard")}
                >
                  <ContentPasteSearchOutlined sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Undo">
                <StyledListButton
                  sx={{ borderRight: "1px solid #54444429" }}
                  onClick={() => {
                    undo();
                  }}
                >
                  <UndoOutlined sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Redo">
                <StyledListButton
                  sx={{ borderRight: "1px solid #54444429" }}
                  onClick={() => {
                    redo();
                  }}
                >
                  <RedoOutlined sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Clear">
                <StyledListButton onClick={onEraser}>
                  <Clear sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </AppBar>

        <StyledDrawer ref={sidebarRef} variant="permanent" open={open}>
          <DrawerHeader sx={{ mb: 0 }}>
            <IconButton onClick={handleDrawerClose}>
              <MenuOpen />
            </IconButton>
          </DrawerHeader>
          <List>
            <label style={{ display: "flex" }} htmlFor="color-picker">
              <input
                type="color"
                id="color-picker"
                style={{ display: "none" }}
                onChange={dispatch(setBackgroundColor())}
              />
            </label>
            <label style={{ display: "flex" }} htmlFor="upload-svg-input">
              <input
                id="upload-svg-input"
                type="file"
                accept=".svg"
                style={{ display: "none" }}
                onChange={onUploadSVG}
              />
            </label>
            {mainMenus?.map((menu, index) => (
              <div key={index}>
                <StyledListButton
                  sx={{ mb: 1.5, flexDirection: "column", rowGap: 1 }}
                  active={activeButton === menu.name}
                  onClick={(event) => handlePopoverClick(event, menu.name)}
                >
                  {menu.icon}
                  <Typography color={"inherit"} fontSize={10}>
                    {menu.label}
                  </Typography>
                </StyledListButton>
              </div>
            ))}
          </List>
        </StyledDrawer>
      </Paper>
    </div>
  );
};

export default Sidebar;
